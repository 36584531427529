export const VIEWS = {
  UNRELATED_PROBLEM: 'unrelated_problem',
  ORDER_NO_ACTION: 'order_no_action',
  ORDER_SUMMARY: 'order_summary',
  NOT_FOUND: 'not_found',
  NOT_APPROVED: 'not_approved',
  FIND_ORDER: 'find_order',
  EMAIL_SURVEY: 'email_survey',

  // Modals
  ISSUE_SUMMARY: 'issue_summary_modal',
  GENERIC_ERROR_MODAL: 'generic_error_modal',

  // Claim Item Related
  CLAIM_ITEM_WISMO: 'claim_item_wismo',
  CLAIM_ITEM_REFUND_REMEDY_OPTIONS: 'claim_item_refund_remedy_options',
  CLAIM_ITEM_REFUND_REMEDY_SURVEY: 'claim_item_refund_remedy_survey',
  CLAIM_ITEM_ACTION_SELECTION: 'claim_item_action_selection',
  CLAIM_ITEM_DAMAGED_PHOTO_UPLOAD: 'claim_item_damaged_photo_upload',
  CLAIM_ITEM_DAMAGED_DESCRIPTION: 'claim_item_damaged_description',
  CLAIM_ITEM_EDIT_QUANTITY: 'claim_item_edit_quantity',
  CLAIM_ITEM_LOST_DETAILS: 'claim_item_lost_details',
  CLAIM_ITEM_PAYPAL_EMAIL_COLLECT: 'claim_item_paypal_email_collect',
  CLAIM_ITEM_PAYPAL_LANDING: 'claim_item_paypal_landing',
  CLAIM_ITEM_PHONE_VERIFICATION: 'claim_item_phone_verification',
  CLAIM_ITEM_PHONE_VERIFICATION_LIMIT: 'claim_item_phone_verification_limit',
  CLAIM_ITEM_RESOLUTION_PREFERENCE: 'claim_item_resolution_preference',
  CLAIM_ITEM_REVIEW_EDIT_DETAILS: 'claim_item_review_edit_details',
  CLAIM_ITEM_REVIEW_EDIT_ISSUE_TYPE: 'claim_item_review_edit_issue_type',
  CLAIM_ITEM_REVIEW_MAIN: 'claim_item_review_main',
  CLAIM_ITEM_SHIPPING_INFORMATION: 'claim_item_shipping_information',
  CLAIM_ITEM_SHIPPING_INFORMATION_EDIT: 'claim_item_shipping_information_edit',
  CLAIM_ITEM_STOLEN_DETAILS: 'claim_item_stolen_details',
  CLAIM_ITEM_STOLEN_POLICE_REPORT: 'claim_item_stolen_police_report',
  CLAIM_ITEM_SUBMISSION: 'claim_item_submission',
  CLAIM_ITEM_APPROVAL_ANIMATION: 'claim_item_approval_animation',
  CLAIM_ITEM_PERISHABLE_SELECTION: 'claim_item_perishable_selection',
  CLAIM_ITEM_SHIPPING_ISSUE_SELECTION: 'claim_item_shipping_issue_selection',
  CLAIM_ITEM_GIFT_CARD_TYPE_SELECTION: 'claim_item_gift_card_type_selection',
  CLAIM_ITEM_GIFT_CARD_DETAILS: 'claim_item_gift_card_details',
  CLAIM_ITEM_GIFT_CARD_DISCLAIMER: 'claim_item_gift_card_disclaimer',
  CLAIM_ITEM_VENMO_DETAILS: 'claim_item_venmo_details',

  // Claim Item Blocking Related
  CLAIM_ITEM_BLOCKING_HOW_CAN_WE_HELP: 'claim_item_blocking_how_can_we_help',
  CLAIM_ITEM_BLOCKING_PACKAGE_NEVER_ARRIVED: 'claim_item_blocking_package_never_arrived',
  CLAIM_ITEM_BLOCKING_CONFIRM_TRACKING_NUMBER:
    'claim_item_blocking_confirm_tracking_number',
  CLAIM_ITEM_BLOCKING_ENTER_TRACKING_NUMBER:
    'claim_item_blocking_enter_tracking_number',
  CLAIM_ITEM_BLOCKING_CONFIRM_CARRIER_UPDATE:
    'claim_item_blocking_confirm_carrier_update',
  CLAIM_ITEM_BLOCKING_ENTER_TRACKING_URL:
    'claim_item_blocking_enter_tracking_url',
  CLAIM_ITEM_BLOCKING_DO_YOU_HAVE_TRACKING_NUMBER:
    'claim_item_blocking_do_you_have_tracking_number',
  CLAIM_ITEM_BLOCKING_HANG_TIGHT: 'claim_item_blocking_hang_tight',
  CLAIM_ITEM_BLOCKING_LOADING_CLAIM_FLOW:
    'claim_item_blocking_loading_claim_flow',
};
