import React from 'react';
import { FC } from 'react';
import Typography from '../../components/UI/Typography';
import { colors } from '../../constants/styles';

export interface IEmphasisSpanProps {
  children: React.ReactNode;
}
const EmphasisSpan: FC<IEmphasisSpanProps> = (props: IEmphasisSpanProps) => {
  return (
    <Typography variant="p5" semibold color={colors.routeText1}>
      {props.children}
    </Typography>
  );
};

export const TypographyContent = {
  issueOutsideTitle: 'Issue other than loss, theft or damage?',
  contactSupportTitle: 'Contact Support',
  description: (email: string): JSX.Element => (
    <Typography variant="p2" color={colors.routeText1}>
      Route has put together an email for you with all your order information to
      send to <EmphasisSpan>{email}</EmphasisSpan>. Make sure to review it and
      add any other relevant details before sending.
    </Typography>
  ),
};

export const ButtonContents = {
  emailDraft: 'View email draft',
};

export const EmailDraft = (
  orderNumber: string,
  customerName: string
): string => {
  return `Hi,

My items related to my order ${orderNumber} is still having issues. Is there anyway that you could still help me solve this issues I am having?


Thank you,
${customerName}`;
};
