import styled from "styled-components";
import Typography from "../../../../../components/UI/Typography";
import { aboveSize } from "../../../../../utils/Breakpoints";

export const Column = styled.div`
	display: flex;
	flex-direction: column;
`

export const Title = styled(Typography)`
	margin-bottom: 8px;
`
export const SubTitle = styled(Typography)`
	margin-bottom: 16px;
`
export const FeeTable = styled.img`
	max-width: 100%;
	align-self: center;
	padding: 0 16px;
	${aboveSize.sm`
		max-width: 500px;
	`}
`
export const AfterText = styled(Typography)`
	margin-top: 16px;
	margin-bottom: 8px;
	${aboveSize.sm`
		padding: 0 30%;
	`}
`
