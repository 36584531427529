import {
  observable,
  action,
  makeObservable,
  reaction,
  runInAction,
} from 'mobx';
import { RootStore } from '../RootStore';
import * as api from '../../services/api';
import { OrderDetails } from '../../models/OrderDetails.model';
import {
  MerchantContactTypeEnum,
  OrderInsuredStatusEnum,
} from '../../models/Enums/OrderEnum.model';
import dayjs from '../../utils/dayjs';
import { Dayjs } from 'dayjs';
import { RouteOrder } from '../../models/RouteOrder.model';
import { Shipment } from '../../models/ShipmentDetails.model';
import { DeliveryStatusEnum } from '../../models/Enums/DeliveryStatusEnum.model';
import { MerchantContacts } from '../../models/MerchantDetails';
import {
  DollsKillMerchantID,
  HibbettMerchantID,
} from '../_constants/Common.constants';

export class OrderBasicInfo {
  constructor(number?: string, email?: string, source?: string) {
    this.number = number ?? '';
    this.email = email ?? '';
    this.source = source ?? '';
    makeObservable(this);
  }
  [key: string]: string;
  @observable number = '';
  @observable email = '';
  @observable source = '';
}
export interface IOrderStore {
  loading: boolean;
  orderBasicInfo: OrderBasicInfo | null;
  routeOrder: RouteOrder | null;
}

export class OrderStore implements IOrderStore {
  [key: string]: unknown;
  private rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
    reaction(
      () => this.orderBasicInfo,
      (orderInfo: OrderBasicInfo | null) => {
        if (orderInfo) {
          window.localStorage.setItem(
            'orderBasicInfo',
            JSON.stringify(orderInfo)
          );
        } else {
          window.localStorage.removeItem('orderBasicInfo');
        }
      }
    );
    reaction(
      () => this.routeOrder,
      (routeOrder: RouteOrder | null) => {
        if (routeOrder) {
          this.rootStore.itemStore.setOrderGroupedItems(routeOrder.order);
          this.merchantId = routeOrder.merchant.id;
          this.rootStore.splitStore.loadClientUserEmail(routeOrder.order.email);
          this.rootStore.splitStore.loadClientMerchantId(routeOrder.merchant.id);
        } else {
          this.rootStore.itemStore.reset();
          this.rootStore.splitStore.resetClients();
        }
      }
    );
  }

  @observable merchantId = '';
  @observable loading = false;
  @observable orderBasicInfo = JSON.parse(
    window.localStorage.getItem('orderBasicInfo') as string
  ) as OrderBasicInfo | null;

  @observable routeOrder: RouteOrder | null = null;

  @action fetchOrder(): RouteOrder | null {
    if (this.routeOrder) {
      return this.routeOrder;
    }

    this.findOrderFromBasicInformation();
    return this.routeOrder;
  }

  setOrder(routeOrder: RouteOrder): void {
    runInAction(() =>
      this.setOrderBasicInfo(
        routeOrder.order.email,
        routeOrder.order.orderNumber
      )
    );
    runInAction(() => (this.routeOrder = routeOrder));
  }

  @action setOrderBasicInfo(
    email: string,
    orderNumber: string,
    source?: string
  ): void {
    runInAction(
      () =>
        (this.orderBasicInfo = new OrderBasicInfo(orderNumber, email, source))
    );
  }

  @action reset(): void {
    runInAction(() => (this.orderBasicInfo = null));
    runInAction(() => (this.routeOrder = null));
    runInAction(() => (this.routePreAssessment = null));
  }

  @action set(key: string, value: unknown): void {
    this[key] = value;
  }

  @action startLoading(): void {
    this.loading = true;
  }

  @action stopLoading(): void {
    this.loading = false;
  }

  getOrderDetails(): OrderDetails | null {
    return this.routeOrder ? this.routeOrder.order : null;
  }

  @action async findOrderFromBasicInformation(): Promise<void> {
    this.startLoading();
    if (this.orderBasicInfo) {
      try {
        const rep = await api.getOrder(
          this.orderBasicInfo.email,
          this.orderBasicInfo.number,
          this.rootStore.commonStore.shieldSession
        );
        if (rep.status === 200 && rep.data) {
          this.rootStore.findOrderStore.setUser(this.orderBasicInfo.email);
          this.setOrder(rep.data);
          this.stopLoading();
        } else {
          this.rootStore.routingStore.push('/');
        }
      } catch (e) {
        this.stopLoading();
        this.rootStore.routingStore.push('/');
      }
    } else {
      this.rootStore.routingStore.push('/');
    }
  }

  /**
   *Determine if order does not have any action to it,
   *by being not insured or not within timeframe with no active issues
   * @returns {boolean}
   * @memberof OrderStore
   */
  orderHasNoAction(): boolean {
    return (
      this.routeOrder !== null &&
      //Order not insured
      (!this.orderInsured() ||
        //Order not within timeframe and no previous active claim issues
        (!this.orderWithinTimeFrame() && !this.orderHasActiveIssues()))
    );
  }

  /**
   *Determine if order is Insured
   *
   * @returns {boolean}
   * @memberof OrderStore
   */
  orderInsured(): boolean {
    return (
      this.routeOrder !== null &&
      this.routeOrder.order.insuredStatus !== OrderInsuredStatusEnum.NOT_INSURED
    );
  }

  /**
   *Determine if order has at least one item within timeframe
   *
   * @returns {boolean}
   * @memberof OrderStore
   */
  orderWithinTimeFrame(): boolean {
    if (!this.routeOrder) return false;

    for (const item of this.routeOrder.order.instanceItems) {
      if (
        item.claimFileWindowEnd &&
        dayjs(item.claimFileWindowEnd).isBefore(dayjs())
      ) {
        return false;
      }
    }

    return true;
  }

  /**
   *Determine if order has at least one active issue
   *
   * @returns {boolean}
   * @memberof OrderStore
   */
  orderHasActiveIssues(): boolean {
    if (!this.routeOrder) return false;

    return this.routeOrder.order.claims.some(claim => claim.claimId);
  }

  getLastDateToFileClaim(): Date | null {
    if (!this.routeOrder) return null;
    let last: Dayjs | null = null;

    for (const item of this.routeOrder?.order.instanceItems) {
      if (
        (item.claimFileWindowEnd && last === null) ||
        (item.claimFileWindowEnd &&
          dayjs(item.claimFileWindowEnd).isAfter(last))
      ) {
        last = dayjs(item.claimFileWindowEnd);
      }
    }

    return last?.toDate() || null;
  }

  getMerchantContacts(type: MerchantContactTypeEnum): MerchantContacts | null {
    if (!this.routeOrder) return null;

    for (const merchantContact of this.routeOrder?.merchant.merchantContacts) {
      if (merchantContact.type === type) {
        return merchantContact;
      }
    }
    return null;
  }

  getEstimatedDelivery(shipmentId: string | undefined): string | null {
    if (!this.routeOrder || !shipmentId) return null;

    for (const shipment of this.routeOrder.shipments) {
      if (shipment.id === shipmentId && shipment.estimatedDeliveryDate) {
        return shipment.estimatedDeliveryDate;
      }
    }
    return null;
  }

  getNearestEstimatedDelivery(): Date | null {
    if (!this.routeOrder) return null;
    let nearest: Dayjs | null = null;

    for (const shipment of this.routeOrder.shipments) {
      if (
        (shipment.estimatedDeliveryDate && nearest === null) ||
        (shipment.estimatedDeliveryDate &&
          dayjs(shipment.estimatedDeliveryDate).isBefore(nearest))
      ) {
        nearest = dayjs(shipment.estimatedDeliveryDate);
      }
    }

    return nearest?.toDate() || null;
  }

  getDeliveryDate(shipmentId: string): string | null {
    if (!this.routeOrder) return null;

    for (const shipment of this.routeOrder.shipments) {
      if (shipment.id === shipmentId && shipment.deliveryTime) {
        return shipment.deliveryTime;
      }
    }
    return null;
  }

  isDelivered(shipmentId: string): boolean {
    if (!this.routeOrder) return false;

    for (const shipment of this.routeOrder.shipments) {
      if (
        shipment.id === shipmentId &&
        shipment.deliveryStatus === DeliveryStatusEnum.DELIVERED
      ) {
        return true;
      }
    }
    return false;
  }

  getShipment(shipmentId: string): Shipment | null {
    if (!this.routeOrder) return null;

    for (const shipment of this.routeOrder.shipments) {
      if (shipment.id === shipmentId) {
        return shipment;
      }
    }
    return null;
  }

  isHibbettMerchant(): boolean {
    if (!this.routeOrder) return false;

    return this.routeOrder.merchant.id === HibbettMerchantID;
  }

  isDollsKillMerchant(): boolean {
    if (!this.routeOrder) return false;

    return this.routeOrder.merchant.id === DollsKillMerchantID;
  }

  getMerchantName(): string | null {
    if (!this.routeOrder) return null;

    return this.routeOrder.merchant.name;
  }

  getTrackingNumber(shipmentId: string | undefined): string | null {
    if (!this.routeOrder || !shipmentId) return null;

    for (const shipment of this.routeOrder.shipments) {
      if (shipment.id === shipmentId) {
        return shipment.trackingNumber ? shipment.trackingNumber : null;
      }
    }

    return null;
  }

  getCarrierUpdateStatus(shipmentId: string | undefined): string | undefined {
    if (!this.routeOrder || !shipmentId) return undefined;

    for (const shipment of this.routeOrder.shipments) {
      if (
        shipment.id === shipmentId &&
        shipment.checkpoints &&
        shipment.checkpoints.length > 0
      ) {
        return shipment.checkpoints[shipment.checkpoints.length - 1]
          ?.subtag_message;
      }
    }
  }

  getCarrierUpdateCurrentLocation(
    shipmentId: string | undefined
  ): string | undefined {
    if (!this.routeOrder || !shipmentId) return undefined;

    for (const shipment of this.routeOrder.shipments) {
      if (
        shipment.id === shipmentId &&
        shipment.checkpoints &&
        shipment.checkpoints.length > 0
      ) {
        return shipment.checkpoints[shipment.checkpoints.length - 1]?.location;
      }
    }
  }
}
