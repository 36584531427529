export const TypographyContent = {
  tracking: (number: string | null): string => {
    return `Tracking #: ${number ? number : ''}`;
  },
};

export const ButtonContent = {
  yes: 'Yes',
  no: 'No',
  cancel: 'Cancel',
};
