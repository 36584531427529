import React from 'react';
import { Container, RouteLogo } from './HeaderLogo.styles';

import RouteLogoImg from '../../../../shared/assets/images/route-logo-black.svg';

const BrandedLogo: React.FC = () => {
  return (
    <Container>
      <RouteLogo src={RouteLogoImg} alt="Route logo" />
    </Container>
  );
};

export default BrandedLogo;
