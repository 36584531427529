/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { observable, makeObservable, action } from 'mobx';
import { RootStore } from '../RootStore';

export interface IReviewStore {
  editIssueType: boolean;
  editPhotos: boolean;
  editDetails: boolean;
  editResolution: boolean;
}

export interface PhotoImageFile {
  imageUrl?: string;
  file: File;
}

export class ReviewStore implements IReviewStore {
  [key: string]: unknown;
  private rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
  }

  @observable damagedPhotoUrlsEdits: Array<PhotoImageFile> = [];

  @observable editIssueType = false;

  @observable editPhotos = false;

  @observable editDetails = false;

  @observable editResolution = false;

  // Additional Details (Damage), Stolen Details (Stolen), Lost Details (Lost)
  @observable editedDetailsField = '';

  @observable isReview = false;

  @observable popUpOpen = false;


  @action changeEditIssueType(): void {
    this.isReview = true;
    this.editIssueType = !this.editIssueType;
  }

  @action changeEditPhotos(): void {
    this.isReview = true;
    this.editPhotos = !this.editPhotos;
  }

  @action changeEditDetails(): void {
    this.isReview = true;
    this.editDetails = !this.editDetails;
  }

  @action changeEditResolution(): void {
    this.isReview = true;
    this.editResolution = !this.editResolution;
  }

  @action reset(): void {
    this.editIssueType = false;
    this.editPhotos = false;
    this.editDetails = false;
    this.editResolution = false;
    this.editedDetailsField = '';
    this.isReview = false;
  }

  @action setDetailsField(val: string): void {
    this.editedDetailsField = val;
  }

  @action setPopUpOpen(val: boolean): void {
    this.popUpOpen = val;
  }
}
