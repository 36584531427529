import styled from 'styled-components';
import { SlideLeft } from '../../../styles/global.styles';
import { belowSize } from '../../../utils/Breakpoints';

export const Container = styled(SlideLeft)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-self: stretch;
  overflow-x: hidden;
  ${belowSize.md`
    margin-left: 10px;
  `}
`;
