export const TypographyContent = {
  title: 'POLICE REPORT',
  policeReportPlaceholder: 'Police Report Number',
  policeDepartmentPlaceholder: 'Police Department',
  policeDepartment: 'POLICE DEPARTMENT',
};

export const ButtonContent = {
  next: 'Next',
  cancel: 'Cancel',
};
