import styled from 'styled-components';
import { colors } from '../../../constants/styles';

export const ToolTipContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: -32px;
  margin-top: 8px;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
  animation: fadeIn 300ms ease-in;
`;

export const ToolTipWrapper = styled.div`
  margin-top: -65px;

  background: ${colors.routeBackground1};
  box-shadow: 0px 14px 18px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  display: flex;
  padding: 16px;
  position: absolute;

  width: 60px;
  z-index: 3;
`;

export const ToolTipCaret = styled.div`
  height: 12px;
  width: 12px;
  background: ${colors.routeBackground1};
  position: absolute;
  margin: -16px 10px 0 0;
  transform: rotate(45deg);
  border-radius: 2px;
`;
