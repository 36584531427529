import styled from 'styled-components';
import { colors } from '../../../constants/styles';
import { SlideLeft } from '../../../styles/global.styles';
import { belowSize } from '../../../utils/Breakpoints';

export const Container = styled(SlideLeft)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-self: stretch;

  margin: 80px 40px;
  ${belowSize.md`
    margin: 40px 0px;
  `}
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  align-items: stretch;
`;

export const Separator = styled.div`
  margin: 24px 0;
  background-image: linear-gradient(
    to right,
    ${colors.routeText2} 10%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: top;
  background-size: 6px 1px;
  background-repeat: repeat-x;
  height: 1px;
`;
