import React from 'react';
import { Container } from './Header.styles';

import HeaderLogo from '@brands/components/Header';

const Header: React.FC = () => {
  return (
    <Container>
      <HeaderLogo />
    </Container>
  );
};

export default Header;
