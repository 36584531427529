/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect } from 'react';
import {
  TypographyContent,
} from './ClaimedItemsReviewIssueType.constants';
import { MainContainer } from '../ClaimedItemsReview.styles';
import { Column, Content, SelectableRow } from './ClaimedItemsReviewIssueType.styles';
import Typography from '../../../../components/UI/Typography';
import { colors } from '../../../../constants/styles';
import { Icon, VerticalSeparator } from '../../../../styles/global.styles';
import Button from '../../../../components/Buttons/Button';
import YesIcon from '../../../../assets/icons/yes-icon.svg';
import NoIcon from '../../../../assets/icons/no-icon.svg';
import { IClaimedItemsReviewIssueType } from './interface';
import { VIEWS } from '../../../../constants/views';
import { broadcastView } from '../../../../services/broadcast';



const ClaimedItemsReviewIssueType: FC<IClaimedItemsReviewIssueType> = (props: IClaimedItemsReviewIssueType) => {
  const { yesAction, noAction } = props;

  useEffect(() => {
    broadcastView(VIEWS.CLAIM_ITEM_REVIEW_EDIT_ISSUE_TYPE)
  }, [])

  return <MainContainer>
    <Content>
      <Column>
        <Typography variant="h2" color={colors.routeText1} semibold>
          {TypographyContent.title}
        </Typography>

        <VerticalSeparator distance={16} />

        <Typography variant="p4" color={colors.routeText1}>
          {TypographyContent.description}
        </Typography>

        <VerticalSeparator distance={16} />

        <SelectableRow
          onClick={yesAction}
        >
          <Column>
            <Typography variant="p4" color={colors.routeText1} semibold>
              {TypographyContent.yesText}
            </Typography>
          </Column>
          <Button
            overrideSizeIcon="26px"
            mode="light"
            overrideBackground={colors.white}
            icon={<Icon src={YesIcon} verticalAlign="middle" />}
          />
        </SelectableRow>

        <VerticalSeparator distance={16} />

        <SelectableRow
          onClick={noAction}
        >
          <Column>
            <Typography variant="p4" color={colors.routeText1} semibold>
              {TypographyContent.noText}
            </Typography>
          </Column>
          <Button
            overrideSizeIcon="26px"
            mode="light"
            overrideBackground={colors.white}
            icon={<Icon src={NoIcon} verticalAlign="middle" />}
          />
        </SelectableRow>
      </Column>

      <VerticalSeparator distance={24} />

      <div>
      </div>

    </Content>
  </MainContainer>
};

export default ClaimedItemsReviewIssueType;
