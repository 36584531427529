import styled, { css } from 'styled-components';
import { colors } from '../../../constants/styles';

export interface IContainerProps {
  multiItem?: boolean;
  noShadow?: boolean;
}

export const Wrapper = styled.div<IContainerProps>`
  display: flex;

  justify-content: center;
  align-items: center;

  //make whole component centered again
  transform: ${(props: IContainerProps) => {
    return props.multiItem ? 'translateX(-5px)' : 'unset';
  }};
`;

export const Container = styled.div`
  display: flex;
  border: 1px solid ${colors.white};
  border-radius: 5px;

  ${(props: IContainerProps) => {
    return props.noShadow ? '' : 'box-shadow: 0px 14px 18px 0px #00000026;';
  }};

  justify-content: center;
  align-items: center;

  position: relative;
  z-index: 3;
  transform: ${(props: IContainerProps) => {
    return props.multiItem ? 'translateX(10px) translateY(-8px)' : 'unset';
  }};

  ${(props: IContainerProps) => {
    const cssString = `
        &:before,
        &:after {
          box-shadow: -3px 2px 2px rgba(0, 0, 0, 0.1);
          content: '';
          border-radius: 5px;
          position: absolute;
          width: 100%;
          height: 100%;
        }
        &:before {
          right: 5px;
          top: 4px;
          z-index: -1;
        }
        &:after {
          right: 10px;
          top: 8px;
          z-index: -2;
        }
    `;

    return props.multiItem
      ? css`
          ${cssString}
        `
      : '';
  }};
`;

export interface IIcon {
  overrideSize?: number;
  overrideRadius?: number;
  overrideQuantitySize?: number;
}

export const Icon = styled.img`
  height: ${(props: IIcon) =>
    props.overrideSize ? `${props.overrideSize}px` : '60px'};
  width: ${(props: IIcon) =>
    props.overrideSize ? `${props.overrideSize}px` : '60px'};
  border-radius: ${(props: IIcon) =>
    props.overrideRadius ? `${props.overrideRadius}px` : '3px'};
  object-fit: cover;
`;

export const Quantity = styled.div`
  position: absolute;
  transform: translateY(
      ${(props: IIcon) =>
    props.overrideSize ? props.overrideSize * -0.46 + 'px' : '-24px'}
    )
    translateX(
      ${(props: IIcon) =>
    props.overrideSize ? props.overrideSize * 0.46 + 'px' : '24px'}
    );
  background: ${colors.routeButtonFill4};
  width: ${(props: IIcon) =>

    props.overrideQuantitySize ? props.overrideQuantitySize + 'px' : props.overrideSize ? props.overrideSize * 0.28 + 'px' : '28px'};
  height: ${(props: IIcon) =>
    props.overrideQuantitySize ? props.overrideQuantitySize + 'px' : props.overrideSize ? props.overrideSize * 0.28 + 'px' : '28px'};
  display: flex;
  border: 1px solid ${colors.white};
  border-radius: 50%;
  justify-content: center;
  align-items: center;
`;
