import React from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  Inner,
  FooterLink,
  FooterLinks,
} from './Footer.style';

import FooterLogo from '@brands/components/Footer';
import { broadCastAction } from '../../../services/broadcast';
import { ACTIONS } from '../../../constants/actions';

const Footer: React.FC = () => {
  return (
    <Container>
      <Inner>
        <Link
          data-testid="home-link"
          to="/"
          style={{ marginRight: 'auto', display: 'inline-block' }}
        >
          <FooterLogo />
        </Link>
        <FooterLinks>
          <FooterLink href="https://learn.microsoft.com/en-us/clarity/faq" onClick={() => broadCastAction(ACTIONS.NAVIGATE_EXTERNAL)}>
            Cookie Use
          </FooterLink>
          <FooterLink href="https://route.com/privacy/" onClick={() => broadCastAction(ACTIONS.NAVIGATE_EXTERNAL)}>
            Privacy Policy
          </FooterLink>
          <FooterLink href="https://route.com/terms-and-conditions/" onClick={() => broadCastAction(ACTIONS.NAVIGATE_EXTERNAL)}>
            Terms & Conditions
          </FooterLink>
        </FooterLinks>
      </Inner>
    </Container>
  );
};

export default Footer;
