/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { observable, makeObservable, action, runInAction } from 'mobx';
import { ClaimedItem, IClaimedItem } from '../../models/Claim.model';
import { RootStore } from '../RootStore';
import * as api from '../../services/api';
import { ImageTypeEnum } from '../../models/API/PhotoUploadEndpoint';
import { compressImageFile, getBase64 } from '../../utils';
import { analyticsCodes, logEvent } from '../../services/analytics';

export interface IDamagedStore {
  loading: boolean;
}

export interface PhotoImageFile {
  imageUrl?: string;
  file: File;
  itemIndex?: number;
}

export class DamagedStore implements IDamagedStore {
  [key: string]: unknown;
  private rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
  }

  @observable damagedPhotoUrls: Array<PhotoImageFile> = [];

  @observable loading = false;

  @observable currentItem: undefined | IClaimedItem = undefined;

  @action initialize(): void {
    this.currentItem = this.rootStore.claimStore.claimedItems[0];
  }

  @action addPhotoUrlForItem(file: File, url: string): void {
    const photo = this.damagedPhotoUrls.filter(filtering => filtering.itemIndex === this.getCurrentIndex()).find(finding => finding.file === file);
    if (photo) {
      photo.imageUrl = url;
    }
  }

  @action async compressAndAddFileForItem(file: File): Promise<File> {
    const compressed = await compressImageFile(file);
    runInAction(() => {
      this.damagedPhotoUrls.push({ file: compressed, itemIndex: this.getCurrentIndex() });
    });
    return compressed;
  }

  @action removePhotoUrl(photo: PhotoImageFile): void {
    const index = this.damagedPhotoUrls.indexOf(photo);
    if (index > -1) {
      this.damagedPhotoUrls.splice(index, 1);
    }
  }

  @action setCurrentItem(item: ClaimedItem): void {
    this.currentItem = item;
  }

  @action async nextItemToUploadPhoto(): Promise<void> {
    if (this.currentItem) {
      this.currentItem.proofPhotos = this.damagedPhotoUrls.filter(filtered => filtered.itemIndex === this.getCurrentIndex()).map(
        damagedPhoto => damagedPhoto.file
      );
      this.currentItem.uploadedPhotos = true;

      const remainingItems = this.rootStore.claimStore.claimedItems.filter(
        item => item.uploadedPhotos === false
      );

      if (remainingItems.length !== 0) {
        runInAction(() => {
          this.currentItem = remainingItems[0];
        });

        this.rootStore.routingStore.replace(
          `/item/damage/${
            this.rootStore.claimStore.claimedItems.length -
            remainingItems.length +
            1
          }`
        );
      } else {
        runInAction(() => {
          this.currentItem = undefined;
          this.resetUploadStatusOnClaimedItems();
        });
        if (this.rootStore.reviewStore.isReview) {
          this.rootStore.routingStore.replace(`/item/review-details`)
        } else {
          if (!this.rootStore.claimStore.needsAffidavitValidation()) {
            this.uploadPhotos();
          }
          this.rootStore.routingStore.replace(`/item/damage/description`);
        }
      }
    } else {
      this.rootStore.commonStore.displayError();
    }
  }

  @action setCurrentItemPerishable(val: boolean): void {
    if (this.currentItem) {
      this.currentItem.perishable = val;
    }
  }

  @action async uploadPhotos(): Promise<void> {
    for (const item of this.rootStore.claimStore.claimedItems) {
      if (item && item.proofPhotos) {
        const orderDetails = this.rootStore.orderStore.routeOrder!.order;
        runInAction(() => (this.loading = true));
        const promises = [];
        for (const file of item.proofPhotos) {
          promises.push(
            api.uploadPhoto(
              {
                email: orderDetails.email,
                image: await getBase64(file),
                instanceItems: item.item.items.map(item => item.id),
                imageType: file.type as ImageTypeEnum,
              },
              orderDetails.id
            )
          );
        }
        const errors = (await Promise.all(promises)).filter(
          resp => resp.status >= 400
        );
        runInAction(() => (this.loading = false));
        if (errors.length >= 1) {
          throw errors;
        }

        logEvent(analyticsCodes.photoUploadSuccess, {
          numberOfPhotos: item.proofPhotos.length,
        });
      }
    }
  }

  @action resetPhotos(): void {
    this.damagedPhotoUrls = [];
  }

  @action resetUploadStatusOnClaimedItems(): void {
    for (const item of this.rootStore.claimStore.claimedItems) {
      item.uploadedPhotos = false;
    }
  }

  @action reset(): void {
    this.loading = false;
    this.resetPhotos();
    this.currentItem = undefined;
  }

  getCurrentIndex(): number {
    return (
      this.rootStore.claimStore.claimedItems.filter(
        item => item.uploadedPhotos !== false
      ).length + 1
    );
  }
}
