import { makeObservable, action, observable, runInAction } from 'mobx';
import * as api from '../../services/api';
import { RootStore } from '../RootStore';
import { Campaigns } from '../_constants/EmailSurveyStore.constants';
export class EmailSurveyStore {
  private rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
  }

  @observable loading = false;

  @observable feedbackSent = false;

  @observable downloadEnabled = false;

  @observable feedbackContent = '';

  @observable rating?: number;

  @observable id?: string;

  @action async setAndSendRating(id: string, rating: string): Promise<void> {
    this.setIdAndRating(id, rating);

    if (this.id && this.rating) {
      this.startLoading();
      const ret = await api.submitRating(this.id, this.rating, Campaigns.nps);

      if (ret.status === 200) {
        this.stopLoading();
      } else {
        this.rootStore.commonStore.displayError();
      }
    }
  }

  @action setIdAndRating(id: string, rating: string): void {
    this.rating = parseInt(rating);
    this.id = id;
  }

  @action async sendFeedbackReason(): Promise<void> {
    if (this.id && this.feedbackContent) {
      this.startLoading();
      const ret = await api.addFeedbackDetails(this.id, this.feedbackContent);
      this.stopLoading();
      if (ret.status === 200) {
        runInAction(() => (this.feedbackSent = true));
      } else {
        this.rootStore.commonStore.displayError();
      }
    }
  }

  @action setFeedbackContent(val: string): void {
    this.feedbackContent = val;
  }

  @action startLoading(): void {
    this.loading = true;
  }
  @action stopLoading(): void {
    this.loading = false;
  }

  @action setDownloadEnabled(): void {
    this.downloadEnabled = true;
  }

  @action reset(): void {
    this.id = undefined;
    this.rating = undefined;
    this.feedbackContent = '';
    this.feedbackSent = false;
    this.downloadEnabled = false;
  }
}
