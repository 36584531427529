/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import {
  ButtonContainer,
  MoreInfoContainer,
  TextLink,
  TrackingNumberContainer,
} from './HangTight.styles';
import { useStores } from '../../../../hooks/app.hook';
import ForegroundContentModal from '../../../../components/ForegroundContentModal';
import { logEvent, analyticsCodes } from '../../../../services/analytics';
import ForegroundContentDetails from '../../../../components/ForegroundContentDetails';
import Typography from '../../../../components/UI/Typography';
import { colors } from '../../../../constants/styles';
import Button from '../../../../components/Buttons/Button';
import HangTightPackage from '../../../../assets/icons/hang-tight-package.svg';
import { Icon } from '../../../../styles/global.styles';
import { ButtonContent, HelpCenterLink, TypographyContent } from './HangTight.constants';
import {
  ClaimBlockingScreens,
} from '../../../../stores/_constants/ClaimBlockingFlow.constants';
import { broadCastAction, broadcastView } from '../../../../services/broadcast';
import { VIEWS } from '../../../../constants/views';
import { ACTIONS } from '../../../../constants/actions';
import { isMobileView } from '../../../../utils/Breakpoints';
import { queryAndConvertIfPossibleTimezone } from '../../../../utils';
import { replaceClaimPolicyLink } from '../utils';

const HangTight: FC = observer(() => {
  const {
    claimStore,
    routingStore,
    commonStore,
    orderStore,
    claimBlockingStore,
  } = useStores();

  const screen = ClaimBlockingScreens.HANG_TIGHT;

  const item = claimStore.displayItem;

  if (!item) {
    commonStore.displayError();
    return null;
  }

  const okayClickHandle = () => {
    claimStore.reset();
    claimBlockingStore.reset();
    broadCastAction(ACTIONS.CANCEL_CLAIM_FILING);
    routingStore.replace('/order-information');
  };

  const showTracking = (): boolean => {
    return claimBlockingStore.shouldHangTightShowTracking();
  };

  useEffect(() => {
    broadcastView(VIEWS.CLAIM_ITEM_BLOCKING_HANG_TIGHT);
    logEvent(analyticsCodes.screenView, {
      screen: claimBlockingStore.getAnalytics(screen),
      merchantId: orderStore.getOrderDetails()?.merchantID,
      orderId: orderStore.getOrderDetails()?.id,
    });
  }, []);

  const visitHelpCenterContent = () => {
    return (
      <>
        {TypographyContent.visitHelpCenter}
        <TextLink href={HelpCenterLink} target="_blank" onClick={() => broadCastAction(ACTIONS.NAVIGATE_EXTERNAL)}>{TypographyContent.helpCenter}</TextLink>
        {TypographyContent.forMoreInfo}
      </>
    );
  }

  const backButtonHandle = () => {
    broadCastAction(ACTIONS.NAVIGATE_BACK_CLICK);
    routingStore.goBack();
  };

  const getDescription = () => {
    return replaceClaimPolicyLink(queryAndConvertIfPossibleTimezone(item.item.wismoDescriptionAlt));
  }

  return (
    <ForegroundContentModal open>
      <ForegroundContentDetails
        icon={<Icon src={HangTightPackage} alt="hang tight package icon" />}
        title={item.item.wismoHeaderAlt}
        description={getDescription()}
        centered={!isMobileView()}
        backButton={backButtonHandle}
      >
        {showTracking() && (
          <TrackingNumberContainer>
            <Typography color={colors.routeText1} variant={'l1'} semibold>
              {TypographyContent.tracking(
                orderStore.getTrackingNumber(item.item.shipmentId)
              )}
            </Typography>
          </TrackingNumberContainer>
        )}

        <ButtonContainer>
          <Button
            onClick={okayClickHandle}
            fullWidth
            overrideBackground={colors.routeButtonFill6}
            mode="light"
          >
            {ButtonContent.okay}
          </Button>
        </ButtonContainer>

        <MoreInfoContainer centered={!isMobileView()}>
          <Typography color={colors.routeText2} variant={'l1'} semibold>
            {visitHelpCenterContent()}
          </Typography>
        </MoreInfoContainer>

      </ForegroundContentDetails>
    </ForegroundContentModal>
  );
});

export default HangTight;
