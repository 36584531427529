import styled from 'styled-components';
import { SlideLeft } from '../../../styles/global.styles';

export const Container = styled(SlideLeft)`
  height: 100%;
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  white-space: pre-line;
  align-content: flex-start;
  overflow-y: hidden;
  img {
    display: flex;
    margin: auto auto -25px auto;
  }
`;

export const NoTrackingContainer = styled.div`
  padding-bottom: 10px;
  overflow-y: auto;
`;

export const NoTrackingIcon = styled.img`
  height: 40%;
  min-height: 10vh;
`;
