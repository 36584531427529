import styled from 'styled-components';
import { colors } from '../../../../constants/styles';

const selectedStyles = `
    background-color: #cccccc;
    color: white;
    cursor: pointer
`;

export const Wrapper = styled.div`
  margin-right: 5px;
  position: relative;
  display: flex;
  align-items: center;
`;

export const SelectButtonWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;

export const CountryIconWrapper = styled.div`
  margin-right: 2px;
`;

export const SelectItemsWrapper = styled.div`
  position: absolute;
  top: 2.55em;
  left: -0.3em;
  border: 1px solid #cccccc;
  border-radius: 8px;
  width: 15em;
  height: 20em;
  overflow-y: scroll;
  z-index: 2;

  ::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${colors.lightGray};
    border-radius: 5px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${colors.divider};
    border-radius: 10px;
  }
`;

export const SelectOptionWrapper = styled.div`
  height: 3em;
  display: flex;
  align-items: center;
  padding-left: 10px;
  background-color: #fbfbfb;
  &:hover {
    ${selectedStyles}
  }
`;
