// based on web-order-tracking repo in Gitlab
import styled, { keyframes } from 'styled-components';
import mapPin from './mapPin.png';

export const MapViewContainer = styled.div`
  margin: 10px 0 -160px 0;
  border-radius: 28px 28px 0 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
`;

const bounceIn = keyframes`
    0%{
        opacity: 0;
        transform: scale(0.3);
    }
    50%{
        opacity: 0.9;
        transform: scale(1.1);
    }
    80%{
        opacity: 1;
        transform: scale(0.89);
    }
    100%{
        opacity: 1;
        transform: scale(1);
    }
`;

export const LogoPin = styled.div`
    background-image: url(${mapPin});
    background-position: center;
    background-size: cover;
    height: 80px;
    width: 72px;
    position: absolute;
    left: -36px;
    top: -94px;
    display: flex;
    justify-content: center;
    filter: drop-shadow(0px 8px 8px rgba(0, 0, 0, 0.2));

    opacity: 0;
    animation: ${bounceIn} 450ms linear;
    animation-fill-mode: forwards;
`;

export const Logo = styled.div`
  margin-top: 7px;
`;

export const Pin = styled.div`
    background-color: #29D6D6;
    height: 16px;
    width: 16px;
    border-radius: 50%;
`;
