/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Container } from './ClaimedItemsDamagedDescription.styles';
import { useStores } from '../../../../hooks/app.hook';
import ForegroundContentHeader from '../../../../components/ForegroundContentHeader';
import ForegroundContentModal from '../../../../components/ForegroundContentModal';
import {
  ButtonContent,
  TypographyContent,
} from './ClaimedItemsDamagedDescription.constants';
import TextField from '../../../../components/Inputs/TextField';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import { logEvent, analyticsCodes } from '../../../../services/analytics';
import { VIEWS } from '../../../../constants/views';
import { broadCastAction, broadcastView } from '../../../../services/broadcast';
import { ACTIONS } from '../../../../constants/actions';

const ClaimedItemsDamagedDescription: FC = observer(() => {
  const { claimStore, routingStore, commonStore } = useStores();

  const { description, loading } = claimStore;
  const item = claimStore.displayItem;

  if (!item) {
    commonStore.displayError();
    return null;
  }

  useEffect(() => {
    broadcastView(VIEWS.CLAIM_ITEM_DAMAGED_DESCRIPTION)
  }, [])

  const nextClickHandle = () => {
    claimStore.setClaimDescription();
    claimStore.verifyResolutionScreenOrSubmitClaim();
  };

  const closeClickHandle = () => {
    broadCastAction(ACTIONS.CANCEL_CLAIM_FILING);
    claimStore.reset();
    routingStore.replace('/order-information');
  };

  const backClickHandle = () => {
    broadCastAction(ACTIONS.NAVIGATE_BACK_CLICK);
    routingStore.goBack();
  };

  useEffect(() => {
    logEvent(analyticsCodes.screenView, {
      screen: analyticsCodes.provideDetails,
    });
  }, []);

  return (
    <ForegroundContentModal
      open
      mainButton={ButtonContent.next}
      mainButtonClick={nextClickHandle}
      secondaryButton={ButtonContent.cancel}
      secondaryButtonClick={closeClickHandle}
    >
      <ForegroundContentHeader
        claimedItems={claimStore.claimedItems}
        // hasClaimedItemsSelected already checks nullable value
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        displayItem={item!}
        clickButtonHandle={backClickHandle}
        title={TypographyContent.title}
      >
        {loading ? (
          <LoadingSpinner />
        ) : (
          <Container>
            <TextField
              autoFocus
              value={description}
              noLine
              noTitle
              multiline
              isError={false}
              placeholder={TypographyContent.descriptionPlaceholder}
              onChange={value => {
                claimStore.setDescription(value);
              }}
            />
          </Container>
        )}
      </ForegroundContentHeader>
    </ForegroundContentModal>
  );
});

export default ClaimedItemsDamagedDescription;
