import React, { useRef, Ref } from 'react';
import ItemIcon from '../IconOrLogos/ItemIcon';
import Typography from '../UI/Typography';
import { IClaimSelectableRowProps } from './interface';
import { ClickableContainer, Row, Column, SubTitleContent, SubTitlePreText } from './ClaimSelectableRow.styles';
import { TypographyContents } from './ClaimSelectableRow.constants';
import { colors } from '../../constants/styles';
import { DateFormatterLong } from '../../utils';
import {
  DeliveryStatusEnum,
  deliveryStatusLocalized,
} from '../../models/Enums/DeliveryStatusEnum.model';
import { useStores } from '../../hooks/app.hook';
import {
  ClaimFlowType,
  mainFlowMap,
} from '../../stores/_constants/ClaimBlockingFlow.constants';

const ClaimSelectableRow = React.forwardRef(
  (props: IClaimSelectableRowProps, ref: Ref<HTMLDivElement>) => {
    const { targetItems, statusSubtitle } = props;
    const displayItem = Array.isArray(targetItems)
      ? targetItems[0]
      : targetItems;
    const { orderStore, animationStore } = useStores();

    const domRef = useRef<HTMLDivElement>(null);
    if (!ref) {
      ref = domRef;
    }

    const clickHandle = () => {
      if (props.clickHandle) {
        animationStore.setAnimationTarget(ref);
        props.clickHandle(targetItems);
      }
    };

    const dateFormatOn = (text: string, date: string) => {
      const dateFormatted = DateFormatterLong(date);
      return `${text} ${dateFormatted}`;
    };

    const getTitle = (): string => {
      if (Array.isArray(targetItems)) {
        return TypographyContents.selectAll;
      }
      return displayItem.name;
    };

    const getSubtitleText = () => {
      if (displayItem.isDisabled && displayItem.disabledReason) {
        return displayItem.disabledReason
      }
      if (
        displayItem.preassessmentResult &&
        mainFlowMap[displayItem.preassessmentResult] !==
        ClaimFlowType.REGULAR_FLOW
      ) {
        return displayItem.wismoSummaryText;
      }
      if (displayItem.shipmentId) {
        if (orderStore.isDelivered(displayItem.shipmentId)) {
          const deliveryDate = orderStore.getDeliveryDate(
            displayItem.shipmentId
          );
          return deliveryDate
            ? dateFormatOn(TypographyContents.deliveredOn, deliveryDate)
            : TypographyContents.delivered;
        } else {
          const etaDate = orderStore.getEstimatedDelivery(
            displayItem.shipmentId
          );
          if (etaDate) {
            return dateFormatOn(TypographyContents.arrivingOn, etaDate);
          } else {
            const status =
              orderStore.getShipment(displayItem.shipmentId)?.deliveryStatus ??
              DeliveryStatusEnum.REGISTERED;
            return deliveryStatusLocalized(status);
          }
        }
      }
      return TypographyContents.shipping;
    };

    const getSubtitleContent = () => {
      // Pre text content
      let preTextContent;

      if (displayItem.preassessmentResult) {
        switch (mainFlowMap[displayItem.preassessmentResult]) {

          // 22 Aug, 2023 - Keeping this logic if we want to add pretext content again

          default:
            preTextContent = null;
            break;
        }
      }

      // Text content
      const subTitleText = getSubtitleText();

      // Compose contents
      return <SubTitleContent>
        {preTextContent && (
          <SubTitlePreText>
            {preTextContent}
          </SubTitlePreText>
        )}
        <Typography variant="l1" color={colors.routeText2}>
          {subTitleText}
        </Typography>
      </SubTitleContent>;
    }

    const getQuantity = (): number => {
      if (Array.isArray(targetItems)) {
        return targetItems.reduce(
          (acc, groupedItems) => acc + groupedItems.items.length,
          0
        );
      }
      return targetItems.items.length;
    };

    return (
      <ClickableContainer
        clickable={!!props.clickHandle}
        onClick={clickHandle}
        data-testid="clickable"
        ref={ref}
        disabled={props.disabled}
      >
        <Row>
          <ItemIcon
            imageUrl={displayItem.imageUrl ?? ''}
            alt={`${displayItem.name} icon`}
            quantity={getQuantity()}
          />
          <Column>
            <Typography variant="l1" semibold color={colors.routeText1}>
              {getTitle()}
            </Typography>
            {statusSubtitle && getSubtitleContent()}
          </Column>
        </Row>
      </ClickableContainer>
    );
  }
);

export default ClaimSelectableRow;
