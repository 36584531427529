/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../../../../hooks/app.hook';
import {
  Content,
  MainButtonContainer,
  Separator,
  ShippingInfoContainer,
  LeftShippingInfoContent,
  RightShippingInfoContent,
} from './ClaimedItemsShippingInformationCollection.styles';
import BackIcon from '../../../../assets/icons/back-icon.svg';
import Typography from '../../../../components/UI/Typography';
import { colors } from '../../../../constants/styles';
import Button from '../../../../components/Buttons/Button';
import { VerticalSeparator } from '../../../../styles/global.styles';
import { TypographyContent } from './ClaimedItemsShippingInformationCollection.constants';
import { analyticsCodes, logEvent } from '../../../../services/analytics';
import ForegroundContentPage from '../../../../components/ForegroundContentPage';
import { broadCastAction, broadcastView } from '../../../../services/broadcast';
import { VIEWS } from '../../../../constants/views';
import { ACTIONS } from '../../../../constants/actions';

const ClaimedItemsShippingInformationCollection: FC = observer(() => {
  const {
    routingStore,
    remedyStore,
    orderStore,
    claimStore,
    commonStore,
  } = useStores();

  const { displayItem } = claimStore;

  const item = displayItem?.item;

  useEffect(() => {
    broadcastView(VIEWS.CLAIM_ITEM_SHIPPING_INFORMATION)
    logEvent(analyticsCodes.screenView, {
      screen: analyticsCodes.shippingAddressInfoCollection,
    });
  }, []);

  if (!item) {
    commonStore.displayError();
    return null;
  }

  const {
    firstName,
    lastName,
    address1,
    address2,
    city,
    state,
    zip,
    country,
    phone,
    company,
  } = remedyStore;

  const backClickHandle = () => {
    broadCastAction(ACTIONS.NAVIGATE_BACK_CLICK);
    routingStore.goBack();
  };

  const editPage = () => {
    routingStore.replace('/item/resolution/shipping-information-edit');
  };

  const nextPage = () => {
    routingStore.push('/item/review-details');
  };

  const defaultSeparator = () => {
    return (
      <React.Fragment>
        <VerticalSeparator distance={20} />
        <Separator />
        <VerticalSeparator distance={20} />
      </React.Fragment>
    );
  };

  return (
    <ForegroundContentPage
      navigationHandle={backClickHandle}
      navigationIcon={BackIcon}
      navigationIconLabel={'back'}
      itemImage={item.imageUrl ?? ''}
      itemLabel={orderStore.getMerchantName() + ' ' + item.name}
      itemQuantity={claimStore.claimedItems.reduce(
        (acc, items) => acc + items.item.quantity,
        0
      )}
    >
      <Content>
        <Typography align={'center'} color={colors.routeText1} variant={'p7'}>
          {TypographyContent.title}
        </Typography>

        {defaultSeparator()}

        <ShippingInfoContainer>
          <LeftShippingInfoContent>
            {(firstName || lastName) && (
              <React.Fragment>
                <Typography
                  align={'center'}
                  color={colors.routeText1}
                  bold={true}
                  variant={'p3'}
                >
                  {`${firstName} ${lastName}`}
                </Typography>
                <br />
              </React.Fragment>
            )}

            {address1 && (
              <React.Fragment>
                <Typography
                  align={'center'}
                  color={colors.routeText1}
                  variant={'p3'}
                >
                  {address1}
                </Typography>
                <br />
              </React.Fragment>
            )}

            {address2 && (
              <React.Fragment>
                <Typography
                  align={'center'}
                  color={colors.routeText1}
                  variant={'p3'}
                >
                  {address2}
                </Typography>
                <br />
              </React.Fragment>
            )}

            {city && (
              <React.Fragment>
                <Typography
                  align={'center'}
                  color={colors.routeText1}
                  variant={'p3'}
                >
                  {`${city}, ${state} ${zip}`}
                </Typography>
                <br />
              </React.Fragment>
            )}

            {country && (
              <React.Fragment>
                <Typography
                  align={'center'}
                  color={colors.routeText1}
                  variant={'p3'}
                >
                  {country}
                </Typography>
                <br />
              </React.Fragment>
            )}

            {phone && (
              <React.Fragment>
                <Typography
                  align={'center'}
                  color={colors.routeText1}
                  variant={'p3'}
                >
                  {phone}
                </Typography>
                <br />
              </React.Fragment>
            )}

            {company && (
              <Typography
                align={'center'}
                color={colors.routeText1}
                variant={'p3'}
              >
                {company}
              </Typography>
            )}
          </LeftShippingInfoContent>

          <RightShippingInfoContent>
            <Button mode="light" onClick={editPage} fullWidth={true}>
              <Typography align={'center'} color={colors.black} variant={'p3'}>
                {TypographyContent.editButton}
              </Typography>
            </Button>
          </RightShippingInfoContent>
        </ShippingInfoContainer>

        {defaultSeparator()}

        <MainButtonContainer>
          <Button
            onClick={nextPage}
            overrideBackground={colors.darkButtonBackground}
            fullWidth
          >
            {TypographyContent.continueButton}
          </Button>
        </MainButtonContainer>
      </Content>
    </ForegroundContentPage>
  );
});

export default ClaimedItemsShippingInformationCollection;
