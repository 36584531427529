import { observable, makeObservable, action } from 'mobx';
import { RootStore } from './RootStore';
import { SplitFactory } from '@splitsoftware/splitio';

export class SplitStore {
  private rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
  }

  @observable loading = true;

  clientUserEmail?: SplitIO.IClient;
  clientMerchantId?: SplitIO.IClient;

  @action treatmentAllowedMerchantId(splitName: string): boolean {
    if (this.clientMerchantId) {
      const treatment: SplitIO.Treatment = this.clientMerchantId.getTreatment(splitName);

      if (treatment === 'on') {
        return true;
      }
    }
    return false;
  }

  @action treatmentAllowedUserEmail(splitName: string): boolean {
    if (this.clientUserEmail) {
      const treatment: SplitIO.Treatment = this.clientUserEmail.getTreatment(splitName);

      if (treatment === 'on') {
        return true;
      }
    }
    return false;
  }

  @action loadClientUserEmail(email: string): void {
    // disable split on test env
    if (process.env.NODE_ENV !== 'test') {
      const factory: SplitIO.ISDK = SplitFactory({
        core: {
          authorizationKey: process.env.REACT_APP_SPLITIO_API_KEY ?? '',
          key: email,
        },
        startup: {
          readyTimeout: 1.5, // 1.5 sec
        },
      });

      this.clientUserEmail = factory.client();
    }
  }

  @action loadClientMerchantId(merchantId: string): void {
    // disable split on test env
    if (process.env.NODE_ENV !== 'test') {
      const factory: SplitIO.ISDK = SplitFactory({
        core: {
          authorizationKey: process.env.REACT_APP_SPLITIO_API_KEY ?? '',
          key: merchantId,
        },
        startup: {
          readyTimeout: 1.5, // 1.5 sec
        },
      });

      this.clientMerchantId = factory.client();
    }
  }

  @action resetClients(): void {
    if (this.clientUserEmail) {
      this.clientUserEmail?.destroy();
    }
    this.clientUserEmail = undefined;
    if (this.clientMerchantId) {
      this.clientMerchantId?.destroy();
    }
    this.clientMerchantId = undefined;
  }

  @action stopLoading(): void {
    this.loading = false;
  }
}
