/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../../../hooks/app.hook';
import ForegroundContentModal from '../../../components/ForegroundContentModal';
import ForegroundContentDetails from '../../../components/ForegroundContentDetails';
import { AnalyticsContent, ButtonContent, TextFieldContent, TypographyContent, VenmoLink } from './ClaimedItemsVenmoDetails.constants';
import { broadCastAction, broadcastView } from '../../../services/broadcast';
import { VIEWS } from '../../../constants/views';
import { ACTIONS } from '../../../constants/actions';
import { Column, ViewDetailsLink } from './ClaimedItemsVenmoDetails.styles';
import TextField from '../../../components/Inputs/TextField';
import { analyticsButtons, analyticsCodes, logEvent, logEventNew } from '../../../services/analytics';
import VenmoIcon from '../../../assets/icons/venmo-icon.svg';
import { Icon } from '../../../styles/global.styles';

const ClaimedItemsVenmoDetails: FC = observer(() => {

  const rootStore = useStores();
  const {
    claimStore,
    orderStore,
    routingStore,
    commonStore,
    remedyStore,
  } = rootStore;

  const {
    venmoInput,
    venmoConfirmationInput,
    errors
  } = remedyStore;
  const item = claimStore.displayItem;

  if (!item) {
    commonStore.displayError();
    return null;
  }

  const backButtonHandle = () => {
    broadCastAction(ACTIONS.NAVIGATE_BACK_CLICK);
    logEventNew(AnalyticsContent.enterMailButtonTapped, rootStore, {
      button_function: analyticsButtons.goBack,
    });
    routingStore.goBack();
  };

  const cancelClickHandle = () => {
    claimStore.reset();
    logEventNew(AnalyticsContent.enterMailButtonTapped, rootStore, {
      button_function: analyticsButtons.cancel,
    });
    broadCastAction(ACTIONS.CANCEL_CLAIM_FILING);
    routingStore.replace('/order-information');
  };

  const continueClickHandle = () => {
    logEventNew(AnalyticsContent.enterMailButtonTapped, rootStore, {
      button_function: analyticsButtons.continue,
    });
    routingStore.push('/item/review-details');
  };

  const handleDontHaveVenmo = () => {
    logEventNew(AnalyticsContent.enterMailButtonTapped, rootStore, {
      button_function: analyticsButtons.noVenmo,
    });
  };

  useEffect(() => {
    logEvent(analyticsCodes.screenView, {
      screen: analyticsCodes.venmoDetailsCollection,
      merchantId: orderStore.getOrderDetails()?.merchantID,
      orderId: orderStore.getOrderDetails()?.id,
    });
    broadcastView(VIEWS.CLAIM_ITEM_VENMO_DETAILS);
  }, []);

  const getDescription = () => {
    return (
      <>
        {TypographyContent.description}
        <br /><ViewDetailsLink href={VenmoLink} target="_blank" onClick={handleDontHaveVenmo}>{TypographyContent.dontHaveVenmo}</ViewDetailsLink>
      </>
    );
  }

  const isNextDisabled = (): boolean => {
    return !remedyStore.venmoDetailsVerify();
  };

  return (

    <ForegroundContentModal
      open
      mainButton={ButtonContent.continue}
      mainButtonClick={continueClickHandle}
      mainButtonDisabled={isNextDisabled()}
      secondaryButton={ButtonContent.cancel}
      secondaryButtonClick={cancelClickHandle}
    >
      <ForegroundContentDetails
        title={TypographyContent.title}
        backButton={backButtonHandle}
        description={getDescription()}
        icon={<Icon src={VenmoIcon} alt="venmo icon" />}
        centered
        verticalStretch
      >
        <Column>
          <TextField
            placeholder={TextFieldContent.input.placeholder}
            title={TextFieldContent.input.placeholder}
            errorText={TextFieldContent.input.errorText}
            textType="text"
            value={venmoInput}
            onChange={(value: string) => remedyStore.setVenmoInput(value)}
            isError={errors.venmoInput}
            fontSize='14px'
          />
          <TextField
            placeholder={TextFieldContent.confirmationInput.placeholder}
            title={TextFieldContent.confirmationInput.placeholder}
            errorText={TextFieldContent.confirmationInput.errorText}
            textType="text"
            value={venmoConfirmationInput}
            onChange={(value: string) => remedyStore.setVenmoConfirmationInput(value)}
            isError={errors.venmoConfirmationInput}
            fontSize='14px'
          />
        </Column>
      </ForegroundContentDetails>
    </ForegroundContentModal>

  );
});

export default ClaimedItemsVenmoDetails;
