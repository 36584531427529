export const ButtonContent = {
  sendFeedback: 'Send Feedback',
  downloadRoute: 'Download Route',
};

export const TypographyContent = {
  title: 'We appreciate your feedback.',
  description: `We are always looking for ways to improve.`,
  subDescription: 'Please let us know the main reason for your score.',
  thankYou: 'Thank you! Your feedback was sent.',
  downloadAppTitle: 'Like Route? Then you may want to try our app.',
  downloadAppSubtitle:
    'It’s the easiest way to resolve issues, and you can also track all your orders in one place and discover new brands you’ll love.',
};

export const Links = {
  downloadRoute: 'https://get.route.com/gibK/b0a2a516',
};
