import {
  observable,
  makeObservable,
  action,
  reaction,
  runInAction,
} from 'mobx';
import { RootStore } from '../RootStore';
import { GroupedItem } from '../../models/GroupedItems.model';
import { Claim, ClaimedItem, IClaimedItem } from '../../models/Claim.model';
import {
  MerchantSettingFlag,
  ResolutionEnum,
} from '../../models/Enums/OrderEnum.model';
import * as api from '../../services/api';
import {
  CreateClaimRequest,
  CreateClaimResponse,
} from '../../models/API/CreateClaimEndpoint';
import { ClaimTypeEnum } from '../../models/Enums/ClaimEnum.model';
import {
  convertClaimItemsToCreateClaimItems,
  convertGroupedItemToClaimedItem,
} from '../../utils/model';
import { analyticsCodes, logEvent } from '../../services/analytics';
import { SplitNames } from '../_constants/SplitStore.constants';
import {
  PrepaidCardDetails,
  RemedyConfig,
  RemedyShippingInformation,
} from '../../models/Remedy.model';
import { InstantAssessmentResultEnum } from '../../models/Enums/PreAssessmentEnum.model';
import { broadCastAction } from '../../../shared/services/broadcast';
import { ACTIONS } from '../../../shared/constants/actions';
import { VenmoInputEnum, VenmoInputEnumType } from './Business/InputParser';
import { DamagedOrSpoiledMerchantIds } from './ClaimStore.constants';
import { ClaimTextCategories } from '../../models/ClaimTextCategories.model';
import { TextCategory } from '../../models/Enums/TextCategory.model';
import { PERSONA_VERIFICATION_STATUSES, PERSONA_VERIFICATION_TYPES } from '../../models/Persona.model';
import { CAPO_MERCHANT_ID } from '../../constants/exclude';

export interface IClaimStore {
  loading: boolean;
  claimedItems: Array<IClaimedItem>;
  displayItem: ClaimedItem | null;
  editingQuantity: boolean;
  itemQuantityMap: Map<GroupedItem, number>;
  paypalEmail?: string;
}

export class ClaimStore implements IClaimStore {
  [key: string]: unknown;
  private rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
    reaction(
      () => this.claimedItems,
      (claimedItems: Array<IClaimedItem>) => {
        if (claimedItems.length > 0) {
          this.claimedItems.forEach(claimedItem => {
            this.itemQuantityMap.set(
              claimedItem.item,
              claimedItem.item.quantity
            );
          });
        } else {
          this.itemQuantityMap = new Map();
        }
      }
    );
  }

  @observable loading = false;

  @observable claimAccepted = false;

  @observable showInstantApprovalCheckbox = false;

  @observable claimFinished = false;

  @observable loadingAnimationFinished = false;

  @observable submitInProgress = false;

  @observable submittingClaimFromReviewDetailsScreen = false;

  @observable claim: Claim | null = null;

  @observable displayItem: ClaimedItem | null = null;

  @observable editingQuantity = false;

  @observable itemQuantityMap = new Map();

  @observable description = '';

  @observable claimedItems: Array<IClaimedItem> = [];

  @observable paypalEmail? = '';

  @observable shippingInfo: RemedyShippingInformation = {};

  @observable refundOptionsSurveySent = false;

  @observable viewDisclaimer = false;

  @action initializeClaim(type: ClaimTypeEnum) {

    // PERSONA VERIFICATION
    if (this.needsPersonaVerification()) {
      this.rootStore.fraudStore.setClaimStartedProps(type);
      this.rootStore.routingStore.push('/item/verification');
      return;
    }

    logEvent(analyticsCodes.claimStarted, {
      merchant: this.rootStore.orderStore.merchantId,
      loggedIn: false,
      claimType: type,
      claimAmount: this.calculateClaimedAmount(),
      uniqueItems: this.claimedItems.length,
      quantity: this.calculateClaimedQuantity(),
      claimInitiatedFrom: this.rootStore.commonStore.isMerchantSource()
        ? analyticsCodes.merchantPortal
        : analyticsCodes.resolveCenter,
    });
    broadCastAction(ACTIONS.START_CLAIM);
    runInAction(() => this.claim = new Claim(type));
    switch (type) {
      case ClaimTypeEnum.DAMAGED:
        this.rootStore.damagedStore.initialize();
        break;

      default:
    }

    this.rootStore.remedyStore.reset();
    if (this.rootStore.orderStore.routeOrder) {
      this.rootStore.remedyStore.getRemedyActions(
        this.claimedItems.map(item => {
          return item.item.orderItemId;
        }),
        this.rootStore.orderStore.routeOrder.order.id
      );
    }
    const orderDetails = this.rootStore.orderStore.getOrderDetails();
    if (orderDetails) {
      if (orderDetails.shippingDetails) {
        this.setShippingInfo(
          orderDetails.shippingDetails,
          orderDetails.firstName,
          orderDetails.lastName
        );
        this.rootStore.remedyStore.initShippmentVars(
          orderDetails.shippingDetails,
          orderDetails.firstName,
          orderDetails.lastName
        );
      } else {
        this.rootStore.remedyStore.setFirstName(orderDetails.firstName);
        this.rootStore.remedyStore.setLastName(orderDetails.lastName);
      }
    }

    this.continueClaim();

  }

  continueClaim(): void {
    if (this.needsPhoneValidation()) {
      this.rootStore.routingStore.push('/item/phone-verification');
    } else if (this.hasAtLeastOneEligibleForPerishable()) {
      this.rootStore.routingStore.push('/item/perishable');
    } else {
      this.selectClaimRouteFlow();
    }
  }

  selectClaimRouteFlow(replace?: boolean): void {
    let path = '';
    switch (this.claim?.type) {
      case ClaimTypeEnum.DAMAGED:
        path = `/item/damage${this.claimedItems.length > 1 ? '/1' : ''}`;
        break;
      case ClaimTypeEnum.STOLEN:
        path = '/item/stolen';
        break;
      case ClaimTypeEnum.LOST:
        path = '/item/lost';
        break;
      default:
    }
    if (replace) {
      this.rootStore.routingStore.replace(path);
    } else {
      this.rootStore.routingStore.push(path);
    }
  }

  @action finishClaim(): void {
    if (this.needsAffidavitValidation()) {
      this.rootStore.routingStore.push('/item/review-details');
    } else {
      this.submitClaim();
    }
  }

  @action setClaimAttribute(key: string, value: string): void {
    if (this.claim) {
      this.claim[key] = value;
    }
  }

  @action setClaimedItems(
    items: Array<GroupedItem>,
    displayItem: GroupedItem
  ): void {
    this.claimedItems = convertGroupedItemToClaimedItem(items);
    this.displayItem =
      this.claimedItems.find(claimedItem => claimedItem.item === displayItem) ??
      this.claimedItems[0];
  }

  @action reset(): void {
    this.loading = false;
    this.claim = null;
    this.claimedItems = [];
    this.itemQuantityMap = new Map();
    this.claimAccepted = false;
    this.showInstantApprovalCheckbox = false;
    this.claimFinished = false;
    this.loadingAnimationFinished = false;
    this.submitInProgress = false;
    this.submittingClaimFromReviewDetailsScreen = false;
    this.viewDisclaimer = false;
    this.resetClaim();
  }

  @action resetClaim(): void {
    this.claim = null;
    this.description = '';
    this.refundOptionsSurveySent = false;
    this.resetRemedyConfigs();
    this.rootStore.reviewStore.reset();
    this.rootStore.damagedStore.reset();
    this.rootStore.stolenStore.reset();
    this.rootStore.phoneVerificationStore.reset();
    this.rootStore.remedyStore.reset();
    this.rootStore.fraudStore.reset();
  }

  @action resetRemedyConfigs(): void {
    this.paypalEmail = '';
    this.shippingInfo = {};
  }

  @action setPerishable(item: ClaimedItem, value: boolean): void {
    const index = this.claimedItems.findIndex(
      claimedItem => claimedItem === item
    );
    this.claimedItems[index] = {
      ...this.claimedItems[index],
      perishable: value,
    };
  }

  @action setEditingQuantity(val = true): void {
    logEvent(analyticsCodes.editQuantity, {
      tapped: analyticsCodes.resolveQuantity,
    });
    runInAction(() => (this.editingQuantity = val));
  }

  @action setItemQuantity(item: GroupedItem, quantity: number): void {
    if (quantity <= item.instanceItemsQuantity && quantity > 0) {
      this.itemQuantityMap.set(item, quantity);
    }
  }

  @action setViewDisclaimer(open: boolean) {
    this.viewDisclaimer = open;
  }

  @action updateQuantities(): void {
    for (const cItem of this.claimedItems) {
      cItem.item.quantity = this.itemQuantityMap.get(cItem.item);
    }
  }

  @action setCustomerPreferredResolution(resolutionType: ResolutionEnum): void {
    if (this.claim) {
      this.claim.customerPreferredResolution = resolutionType;
    }
  }

  @action setDescription(val: string): void {
    this.description = val;
    if (this.claim) this.claim.description = this.description;
  }

  @action setClaimPoliceReport(
    policeReport: string,
    policeDepartment: string
  ): void {
    if (policeReport !== '') {
      this.setClaimAttribute('policeReport', policeReport);
    }
    if (policeDepartment !== '') {
      this.setClaimAttribute('policeDepartment', policeDepartment);
    }
  }

  @action setClaimDescription(): void {
    this.setClaimAttribute('description', this.description);
  }

  @action setPaypalEmail(value: string): void {
    this.paypalEmail = value;
  }

  @action setShippingInfo(
    value: RemedyShippingInformation,
    firstName?: string,
    lastName?: string
  ): void {
    this.shippingInfo = value;
    if (firstName) this.shippingInfo.firstName = firstName;
    if (lastName) this.shippingInfo.lastName = lastName;
  }

  @action setRefundOptionsSurveySent(value: boolean): void {
    this.refundOptionsSurveySent = value;
  }

  @action async submitClaim(): Promise<void> {
    const preassessmentId = this.rootStore.orderStore.routeOrder?.order
      ?.preAssessmentId;
    const orderDetails = this.rootStore.orderStore.routeOrder?.order;

    if (this.claim && preassessmentId && orderDetails) {
      logEvent(analyticsCodes.claimSubmitted, {
        merchant: this.rootStore.orderStore.merchantId,
        loggedIn: false,
        claimType: this.claim.type,
        claimAmount: this.calculateClaimedAmount(),
        uniqueItems: this.claimedItems.length,
        quantity: this.calculateClaimedQuantity(),
        claimInitiatedFrom: this.rootStore.commonStore.isMerchantSource()
          ? analyticsCodes.merchantPortal
          : analyticsCodes.resolveCenter,
      });
      broadCastAction(ACTIONS.SUBMIT_CLAIM);

      const request: CreateClaimRequest = {
        claimType: this.claim.type,
        instanceItems: convertClaimItemsToCreateClaimItems(this.claimedItems),
        platform: this.rootStore.commonStore.platform,
        source: this.rootStore.commonStore.source,
        preassessmentId: preassessmentId,
        description: this.claim.description,
        customerEmailAddress: orderDetails.email,

        ...(this.claim.policeDepartment && {
          policeDepartment: this.claim.policeDepartment,
        }),

        ...(this.claim.policeReport && {
          policeReportNumber: this.claim.policeReport,
        }),

        ...(this.claim.customerPreferredResolution && {
          customerPreferredResolution: this.claim.customerPreferredResolution,
        }),

        ...(this.paypalEmail &&
          this.claim.customerPreferredResolution ===
          ResolutionEnum.REFUND_PAYPAL && {
          remedyConfig: this.createPaypalRemedyConfig(this.paypalEmail),
        }),

        ...(this.claim.customerPreferredResolution ===
          ResolutionEnum.REFUND_VENMO && {
          remedyConfig: this.createVenmoRemedyConfig(this.rootStore.remedyStore.venmoInput, this.rootStore.remedyStore.venmoInputChoice),
        }),

        ...(this.shippingInfo &&
          this.claim.customerPreferredResolution === ResolutionEnum.REPLACE && {
          remedyConfig: this.createShippingInfoRemedyConfig(
            this.shippingInfo
          ),
        }),

        ...(this.rootStore.remedyStore.giftCardEmail !== '' &&
          this.rootStore.remedyStore.giftCardFullName !== '' &&
          this.claim.customerPreferredResolution === ResolutionEnum.PREPAID_CARD_MASTERCARD && {
          remedyConfig: this.createPrepaidRemedyConfig(
            {
              email: this.rootStore.remedyStore.giftCardEmail,
              fullName: this.rootStore.remedyStore.giftCardFullName,
              tenderType: ResolutionEnum.PREPAID_CARD_MASTERCARD,
            }
          ),
        }),

        ...(this.claim.customerPreferredResolution === ResolutionEnum.GIFT_CARD_MERCHANT && {
          remedyConfig: this.createPrepaidRemedyConfig(
            {
              tenderType: ResolutionEnum.GIFT_CARD_MERCHANT,
            }
          ),
        }),

        ...(this.displayItem?.item.preassessmentResult && {
          claimBlockingReason: this.displayItem?.item.preassessmentResult,
        }),

        ...(this.rootStore.claimBlockingStore.trackingNumber && {
          customerProvidedTrackingId: this.rootStore.claimBlockingStore
            .trackingNumber,
        }),

        ...(this.rootStore.claimBlockingStore.trackingURL && {
          customerProvidedTrackingUrl: this.rootStore.claimBlockingStore
            .trackingURL,
        }),

        ...(this.rootStore.claimBlockingStore.carrier && {
          customerProvidedCarrierName: this.rootStore.claimBlockingStore
            .carrier,
        }),

        personaResult: {
          inquiry_id: this.rootStore.fraudStore.personaInquiryId,
          verification_status: this.rootStore.fraudStore.verificationStatus,
          verification_error: this.rootStore.fraudStore.error,
        }
      };

      try {
        this.startLoading();
        if (this.submittingClaimFromReviewDetailsScreen) {
          this.submitInProgress = true;
        }

        if (this.claim.type === ClaimTypeEnum.DAMAGED) {
          await this.rootStore.damagedStore.uploadPhotos();
        }

        const rep = await api.submitClaim(request, orderDetails.id);
        this.stopLoading();
        if (rep.status === 200 && rep.data) {
          if (
            this.submittingClaimFromReviewDetailsScreen &&
            rep.data.instantAssessmentResult ===
            InstantAssessmentResultEnum.APPROVED
          ) {
            this.claimAccepted = true;
          }

          const createClaimResponse = rep.data as CreateClaimResponse;
          await this.rootStore.orderStore.findOrderFromBasicInformation();
          this.rootStore.issueStore.initialize(
            createClaimResponse.issueItems[0].issueId,
            createClaimResponse.instantAssessmentResult
          );
          if (this.submittingClaimFromReviewDetailsScreen) {
            this.claimFinished = true;
          } else {
            this.rootStore.routingStore.push('/issue-summary');
          }
        } else {
          this.rootStore.commonStore.displayError();
        }
      } catch (e) {
        this.stopLoading();
        this.rootStore.commonStore.displayError();
      }
    }
  }

  @action verifyResolutionScreenOrSubmitClaim(): void {
    const order = this.rootStore.orderStore.routeOrder;
    if (order) {
      if (order.merchant.preferredResolution !== ResolutionEnum.NONE) {
        this.rootStore.routingStore.push('/item/resolution');
      } else {
        this.submitClaim();
      }
    } else {
      this.rootStore.commonStore.displayError();
    }
  }

  @action startLoading(): void {
    this.loading = true;
  }
  @action stopLoading(): void {
    this.loading = false;
  }

  @action setShowInstantApprovalCheckbox(show: boolean): void {
    this.showInstantApprovalCheckbox = show;
  }

  @action acceptedCompleteEventHandler(): void {
    this.submitInProgress = false;
    this.setShowInstantApprovalCheckbox(false);
    this.rootStore.routingStore.push('/issue-summary');
  }

  @action progressLoopCompleteEventHandler(): void {
    if (this.claimFinished) {
      this.loadingAnimationFinished = true;
      if (this.claimAccepted) {
        this.setShowInstantApprovalCheckbox(true);
      } else {
        this.submitInProgress = false;
        this.rootStore.routingStore.push('/issue-summary');
      }
    }
  }

  @action setPhoneVerified(): void {
    if (this.claim) {
      this.claim.phoneVerified = true;
    }
  }

  hasAtLeastOneEligibleForPerishable(): boolean {
    return (
      this.claimedItems.find(
        claimedItem => claimedItem.item.eligibleForPerishable
      ) !== undefined
    );
  }

  hasAtLeastOneShouldDisplayClaim(): boolean {
    return (
      this.claimedItems.find(claimedItem => claimedItem.item.shouldDisplay) !==
      undefined
    );
  }

  isSelectedItems(): boolean {
    return (
      this.claimedItems &&
      this.claimedItems.length > 0 &&
      this.displayItem !== null
    );
  }

  needsPhoneValidation(): boolean {
    return (
      !!this.rootStore.orderStore.routeOrder?.order.phoneVerificationRequired &&
      !this.claim?.phoneVerified &&
      this.rootStore.splitStore.treatmentAllowedUserEmail(
        SplitNames.PHONE_VERIFICATION_SPLIT_NAME
      ) &&
      !this.rootStore.commonStore.isMerchantSource() &&
      this.rootStore.featureStore.validateFeature(
        MerchantSettingFlag.PHONE_VERIFICATION
      )
    );
  }

  needsAffidavitValidation(): boolean {
    return (
      this.rootStore.splitStore.treatmentAllowedUserEmail(
        SplitNames.AFFIDAVIT_SPLIT_NAME
      ) &&
      !this.affidavitAcknowledged() &&
      this.rootStore.featureStore.validateFeature(
        MerchantSettingFlag.AFFIDAVIT_VERIFICATION
      )
    );
  }

  needsRemedyConfigValidation(): boolean {
    return (
      !this.rootStore.commonStore.isMerchantSource() &&
      this.rootStore.splitStore.treatmentAllowedUserEmail(
        SplitNames.REMEDY_DATA_COLLECTION
      ) &&
      this.rootStore.featureStore.validateFeature(
        MerchantSettingFlag.CUSTOMER_DATA_COLLECTION
      )
    );
  }

  needsPersonaVerification(): boolean {

    return this.rootStore.orderStore.routeOrder?.persona.verification_on === PERSONA_VERIFICATION_TYPES.ALL
      && !this.rootStore.commonStore.isMerchantSource()
      && !this.rootStore.commonStore.isAdminToolsSource()
      && this.rootStore.orderStore.routeOrder.merchant.id !== CAPO_MERCHANT_ID
      && this.rootStore.fraudStore.verificationStatus === PERSONA_VERIFICATION_STATUSES.NOT_AVAILABLE;
  }

  needsPhotoOnDamaged(): boolean {
    return (
      this.rootStore.featureStore.validateFeature(
        MerchantSettingFlag.REQUIRED_PHOTO_ON_DAMAGED
      )
    );
  }

  getClaimTextCategories(): ClaimTextCategories {
    const textCategories = {
      damaged: TextCategory.DEFAULT,
      lost: TextCategory.DEFAULT,
      stolen: TextCategory.DEFAULT,
      neverArrived: TextCategory.DEFAULT,
      other: TextCategory.DEFAULT,
      return: TextCategory.DEFAULT,
      cancel: TextCategory.DEFAULT,
    }

    if (DamagedOrSpoiledMerchantIds.has(this.rootStore.orderStore.merchantId)) {
      textCategories.damaged = TextCategory.DAMAGE_OR_SPOILED
    }

    return textCategories
  }

  calculateClaimedQuantity(): number {
    return this.claimedItems.reduce(
      (acc, items) => acc + items.item.quantity,
      0
    );
  }

  calculateClaimedAmount(): number {
    return this.claimedItems.reduce(
      (acc, items) => acc + items.item.totalAmount,
      0
    );
  }

  affidavitAcknowledged(): boolean {
    return !!this.claim?.affidavitAcknowledged;
  }

  @action setAffidavitAcknowleged(val: boolean): void {
    runInAction(() => {
      if (this.claim) {
        this.claim.affidavitAcknowledged = val;
      }
    });
  }

  createPaypalRemedyConfig(email: string): RemedyConfig {
    return { refundToEmail: email };
  }

  createVenmoRemedyConfig(venmoInput: string, venmoInputChoice: VenmoInputEnumType): RemedyConfig {
    return {
      ...(venmoInputChoice === VenmoInputEnum.EMAIL && {
        refundToEmail: venmoInput,
      }),
      ...(venmoInputChoice === VenmoInputEnum.PHONE && {
        refundToPhoneNumber: venmoInput,
      }),
      ...(venmoInputChoice === VenmoInputEnum.USER_ID && {
        refundToUserHandle: venmoInput.trim().replace(/^@/, ''),
      }),

    };
  }

  createShippingInfoRemedyConfig(
    shippingInfo: RemedyShippingInformation
  ): RemedyConfig {
    return { shippingDetails: shippingInfo };
  }

  createPrepaidRemedyConfig(
    prepaidCardDetails: PrepaidCardDetails
  ): RemedyConfig {
    return { prepaidCardDetails: prepaidCardDetails };
  }

  get hasClaimedItems(): boolean {
    return this.claimedItems.length > 0
  }

  isClaimInitialized(): boolean {
    return this.claim !== null;
  }
}
