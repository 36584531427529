/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosResponse } from 'axios';
import { getCountries } from 'libphonenumber-js';
import { RouteOrder } from '../models/RouteOrder.model';
import {
  CreateClaimRequest,
  CreateClaimResponse,
} from '../models/API/CreateClaimEndpoint';
import { PhotoUploadRequest } from '../models/API/PhotoUploadEndpoint';
import { IssueStatusSummary } from '../models/Issue.model';
import {
  CountryCodes,
  PhoneRequest,
  PhoneVerificationError,
  PhoneVerifyRequest,
} from '../models/PhoneVerification.model';

const baseUrl = process.env.REACT_APP_API_URL;
const appGatewayUrl = process.env.REACT_APP_APP_GATEWAY_URL;

export const getOrder = async (
  email: string,
  orderNumber: string,
  sessionId?: string,
): Promise<AxiosResponse> => {
  try {
    const rep = await axios.get<RouteOrder>(
      `${baseUrl}/resolve/v3/web/protected-orders/${encodeURIComponent(
        orderNumber.trim()
      )}`,
      {
        params: { email: email.trim() },
        ...(sessionId && {
          headers: {
            'x-shield-session-id': sessionId,
          },
        }),
      }
    );
    return rep;
  } catch (err) {
    console.error(err);
    // @ts-ignore
    return err.response;
  }
};

export const getRemedyAvailability = async (
  items: Array<string>,
  orderNumber: string
): Promise<AxiosResponse> => {
  try {
    const rep = await axios.post(
      `${baseUrl}/resolve/v2/web/orders/${orderNumber}/remedy_actions`,
      { instanceItemIds: items }
    );
    return rep;
  } catch (err) {
    console.error(err);
    // @ts-ignore
    return err.response;
  }
};

export const getAddressToGeolocation = async (
  address: Promise<Array<string | undefined>> | undefined | string
): Promise<number[]> => {
  try {
    return axios
      .get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${address}.json?access_token=${process.env.REACT_APP_MAPBOX_API_TKN}`
      )
      .then(resp => resp.data.features[0].geometry.coordinates);
  } catch {
    console.error(`Error to get geolocation`);
    return [-1, -1];
  }
};

export const uploadPhoto = async (
  request: PhotoUploadRequest,
  orderId: string
): Promise<AxiosResponse> => {
  try {
    const rep = await axios.post(
      `${baseUrl}/v2/resolve/web/orders/${orderId}/items/add-photo`,
      request
    );
    return rep;
  } catch (err) {
    console.error(err);
    // @ts-ignore
    return err.response;
  }
};

export const submitClaim = async (
  request: CreateClaimRequest,
  orderId: string
): Promise<AxiosResponse> => {
  try {
    const rep = await axios.post<CreateClaimResponse>(
      `${baseUrl}/v2/resolve/web/orders/${orderId}/claims`,
      request
    );
    return rep;
  } catch (err) {
    console.error(err);
    // @ts-ignore
    return err.response;
  }
};

export const getCountryCodes = async (): Promise<CountryCodes> => {
  try {
    const rep = await axios.get<CountryCodes>(`${baseUrl}/v1/country-codes`);
    const clientSideCountries = getCountries();
    for (const country of rep.data) {
      if (!clientSideCountries.includes(country.abbrev)) {
        // console.error(`${country.abbrev} missing from client side validation`)
      }
    }
    // sort by country name
    let ret = rep.data.sort((a, b) => a.name.localeCompare(b.name));
    // put 'US' first
    ret = ret.filter(el => el.abbrev !== 'US');
    ret.unshift({ code: '1', name: 'United States', abbrev: 'US' });
    return ret;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

const phoneVerificationRequest = async (
  orderId: string,
  requestBody: PhoneRequest | PhoneVerifyRequest,
  endpoint: string
): Promise<AxiosResponse> => {
  try {
    const rep = await axios.post<null | PhoneVerificationError>(
      `${baseUrl}/v1/issues/${orderId}/${endpoint}`,
      requestBody
    );
    return rep;
  } catch (err) {
    console.error(err);
    // @ts-ignore
    return err.response;
  }
};

export const requestPhoneVerificationCode = async (
  orderId: string,
  requestBody: PhoneRequest
): Promise<AxiosResponse> => {
  return await phoneVerificationRequest(orderId, requestBody, 'phone');
};

export const sendPhoneVerificationCode = async (
  orderId: string,
  requestBody: PhoneVerifyRequest
): Promise<AxiosResponse> => {
  return await phoneVerificationRequest(orderId, requestBody, 'phone/verify');
};

export const submitRating = async (
  emailId: string,
  rating: number,
  campaign: string
): Promise<AxiosResponse> => {
  try {
    const rep = await axios.put(
      `${appGatewayUrl}/resolve/v2/web/issues/emails/${emailId}/rating`,
      { value: rating, campaign: campaign }
    );
    return rep;
  } catch (err) {
    console.error(err);
    // @ts-ignore
    return err.response;
  }
};

export const addFeedbackDetails = async (
  emailId: string,
  details: string
): Promise<AxiosResponse> => {
  try {
    const rep = await axios.put(
      `${appGatewayUrl}/resolve/v2/web/issues/emails/${emailId}/rating/details`,
      { details: details }
    );
    return rep;
  } catch (err) {
    console.error(err);
    // @ts-ignore
    return err.response;
  }
};

export const getIssueInformation = async (
  issueId: string
): Promise<AxiosResponse> => {
  try {
    const rep = await axios.get<IssueStatusSummary>(
      `${appGatewayUrl}/resolve/v2/web/issues/${issueId}/status`
    );
    return rep;
  } catch (err) {
    console.error(err);
    // @ts-ignore
    return err.response;
  }
};
