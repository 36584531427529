import styled from 'styled-components';

export const Container = styled.div`
  height: 30px;
  z-index: 1;
  display: flex;
  padding: 25px 48px;
  background: #FFFFFF;

  min-height: 30px;
  justify-content: center;

  @media (min-height: 75px) and (max-height: 500px) {
    position: relative;
    margin-top: 118px;
    height: 100%;
  }
`;

export const Inner = styled.div`
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  max-width: 1088px;
  padding: 0 24px;
  flex-grow: 1;
  align-items: center;
`;


export const FooterLink = styled.a`
  color: #6A6A6D;
  font-family: RM Neue;
  font-size: 12px;
  line-height: 14.16px;
  padding-left: 24px;
  white-space: nowrap;
  text-decoration: none;
  text-decoration-line: underline;
  text-underline-offset: 3px;
`;

export const FooterLinks = styled.div`
  display: flex;
  flex-flow: wrap;
  margin-left: -24px;
`;
