/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import {
  Container,
  PhotoThumbnailStyled,
  Row,
  SliderPhotoRow,
  DetailsContainer,
} from './PerishableItems.styles';
import Typography from '../../../components/UI/Typography';
import { colors } from '../../../constants/styles';
import { useStores } from '../../../hooks/app.hook';
import ForegroundContentHeader from '../../../components/ForegroundContentHeader';
import ForegroundContentModal from '../../../components/ForegroundContentModal';
import { TypographyContent } from './PerishableItems.constants';
import TextField from '../../../components/Inputs/TextField';
import { VIEWS } from '../../../constants/views';
import { broadCastAction, broadcastView } from '../../../services/broadcast';
import { ACTIONS } from '../../../constants/actions';

const PerishableItems: FC = observer(() => {
  const { claimStore, routingStore } = useStores();
  const { displayItem: item, description } = claimStore;

  useEffect(() => {
    broadcastView(VIEWS.CLAIM_ITEM_PERISHABLE_SELECTION)
  }, [])

  const nextClickHandle = () => {
    claimStore.selectClaimRouteFlow();
  };

  const closeClickHandle = () => {
    claimStore.reset();
    broadCastAction(ACTIONS.CANCEL_CLAIM_FILING);
    routingStore.replace('/order-information');
  };

  const backClickHandle = () => {
    routingStore.goBack();
  };

  return (
    <ForegroundContentModal
      open
      mainButton={TypographyContent.button}
      mainButtonClick={nextClickHandle}
      secondaryButton={TypographyContent.cancel}
      secondaryButtonClick={closeClickHandle}
    >
      <ForegroundContentHeader
        // hasClaimedItemsSelected already checks nullable value
        claimedItems={claimStore.claimedItems}
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        displayItem={item!}
        clickButtonHandle={backClickHandle}
        clickEditQuantityHandle={() => {
          claimStore.setEditingQuantity();
        }}
        title={TypographyContent.title}
      >
        <Container>
          <Typography variant="p5" color={colors.routeText1}>
            {TypographyContent.subHeading}
          </Typography>
          <Row>
            <SliderPhotoRow>
              {claimStore.claimedItems.map((groupedItem, index) => {
                return groupedItem.item.eligibleForPerishable ? (
                  <div
                    key={index}
                    data-testid="toggle-perishable"
                    onClick={() => {
                      claimStore.setPerishable(
                        groupedItem,
                        !groupedItem.perishable
                      );
                    }}
                  >
                    <PhotoThumbnailStyled
                      highlight={groupedItem.perishable}
                      key={index}
                      imageUrl={groupedItem.item.imageUrl}
                    />
                  </div>
                ) : null;
              })}
            </SliderPhotoRow>
          </Row>

          <DetailsContainer>
            <Typography variant="p5" color={colors.routeText1}>
              {TypographyContent.detailsHeader}
            </Typography>
            <TextField
              value={description}
              noLine
              noTitle
              multiline
              isError={false}
              placeholder={TypographyContent.detailsPlaceholder}
              onChange={value => {
                claimStore.setDescription(value);
              }}
            />
          </DetailsContainer>
        </Container>
      </ForegroundContentHeader>
    </ForegroundContentModal>
  );
});

export default PerishableItems;
