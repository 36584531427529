import imageCompression from 'browser-image-compression';
import dayjs from './dayjs';

export type ValueOf<T> = T[keyof T];

export const DateFormatterLong = (date: string): string => {
  if (!date || date === '') return '-';
  return new Date(date).toLocaleDateString('en-US', {
    weekday: 'short',
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });
};

export const DateFormatterShort = (date: string): string => {
  if (!date || date === '') return '-';
  return new Date(date).toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
  });
};

export const getBase64 = (file: File): Promise<string> => {
  const reader = new FileReader();
  return new Promise<string>((resolve, reject) => {
    reader.onload = ev => {
      //get plain base64 without metadata and ensure padding
      let encoded = ev.target?.result?.toString().replace(/^data:(.*,)?/, '');
      if (encoded) {
        if (encoded.length % 4 > 0) {
          encoded += '='.repeat(4 - (encoded.length % 4));
        }
        resolve(encoded);
      } else {
        reject();
      }
    };
    reader.readAsDataURL(file);
  });
};

export const extractFileType = (fileName: string): string => {
  const parts = fileName.split('.');
  return parts[parts.length - 1];
};

export const compressImageFile = async (imageFile: File): Promise<File> => {
  const options = {
    maxSizeMB: 0.5,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };
  try {
    return imageCompression(imageFile, options);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const fallBackLogo = (name: string): string => {
  return `${process.env.REACT_APP_LOGO_API_URL}/${name}`;
};

export const convertCountryCodeToUnicode = (countryCode: string): string => {
  if (!countryCode) return '';
  // https://stackoverflow.com/a/42235254
  const base = 127397;
  const first = countryCode[0].charCodeAt(0) + base;
  const second = countryCode[1].charCodeAt(0) + base;
  const ret = String.fromCodePoint(first, second);
  return ret;
};

export const printVersion = (): void => {
  if (process.env.NODE_ENV !== 'test') {
    console.log(
      ...[
        // eslint-disable-line
        `%c ${process.env.REACT_APP_NAME} %c v${process.env.REACT_APP_VERSION} ${process.env.REACT_APP_CUSTOM_NODE_ENV === 'production'
          ? ''
          : process.env.REACT_APP_CUSTOM_NODE_ENV
        } %c ${process.env.REACT_APP_BRAND ? process.env.REACT_APP_BRAND : 'ROUTE'}`,
        'background:#5dc8db ; padding: 3px; color: #000; border-radius: 3px 0 0 3px;',
        `background:#000 ; padding: 3px; color: #fff;`,
        `background:#5dc8db ; padding: 3px; color: #000;  border-radius: 0px 3px 3px 0px; font-weight: bold;`,
      ].filter(Boolean)
    );
  }
}

// This auxiliary function finds backend hardcoded EST time in a certain format
// to convert to user Local timezone and display it converted.
// Example formatted input: 11/29/2023 at 4:24 PM EST
// Example formatted output: 11/29/2023 at 6:24 PM
export const queryAndConvertIfPossibleTimezone = (text: string): string => {

  // 29th Nov, 2023 - This is the time format that the backend sends
  const timeRegex = /(?<date>\d{2}\/\d{2}\/\d{4}) at (?<time>\d{1,2}:\d{2}) (?<half>AM|PM) EST/g;

  // Count occurrences of the date format
  const regexMatches = text.match(timeRegex)?.length || 0;
  for (let i = 0; i < regexMatches; i++) {
    const extractedParts = timeRegex.exec(text);

    // If regex group matching doesn't find anything, return original text without changes.
    if (!extractedParts) return text;

    if (extractedParts.length > 3) {
      const extractedFullDateAndTimeString = extractedParts[0];
      const extractedDate = extractedParts.groups?.date;
      const extractedTime = extractedParts.groups?.time;
      const extractedAmPm = extractedParts.groups?.half;
      const formattedString = `${extractedDate} ${extractedTime} ${extractedAmPm}`

      const newDate = dayjs.tz(formattedString, 'MM/DD/YYYY h:mm A', "EST").local() // converts to local

      text = text.replace(extractedFullDateAndTimeString, `${newDate.format('MM/DD/YYYY')} at ${newDate.format('h:mm A')}`)
    }
  }
  return text;
}
