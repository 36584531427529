import { CountryCode } from 'libphonenumber-js';

export enum InvalidReasonTypeEnum {
  INCORRECT_CODE = 'incorrect_code',
  INVALID_PHONE_NUMBER = 'invalid_phone_number',
  MUST_WAIT = 'must_wait',
  ALREADY_VERIFIED = 'already_verified',
}

export interface PhoneVerificationError {
  waitTime: number;
  invalidReason: InvalidReasonTypeEnum;
}

export type VerificationType = 'call' | 'sms';

export interface PhoneRequest {
  phoneNumber: string;
  verificationType: VerificationType;
}

export interface PhoneVerifyRequest {
  phoneNumber: string;
  code: string;
}

export interface Country {
  name: string;
  abbrev: CountryCode;
  code: string;
}

export type CountryCodes = Array<Country>;
