import styled from 'styled-components';
import { belowSize } from '../../utils/Breakpoints';
import { colors } from '../../constants/styles';

export interface IExtendedProps {
  extended?: boolean;
}

export const ContainerFlex = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  align-items: stretch;
  overflow: auto;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  flex-grow: 1;
  max-width: 750px;
  width: 45%;
  padding: 8% 28% 5% 28%;
  ${belowSize.lg`
     padding: 8% 20% 5% 20%;
  `}
  ${belowSize.sm`
     padding: 10% 4%;
  `}
`;
export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
`
export const ButtonColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  margin-top: 16px;
`;

export const PaperCard = styled.div`
  display: flex;
  flex-direction: column;
  background: ${colors.white};
  overflow: hidden;
  border-radius: 12px;
`

export const BackButton = styled.div`
  display: flex;
  z-index: 1;
  position: relative;
  padding-left: 8.25px;
  padding-top: 31px;
  padding-bottom: 20px;
`;