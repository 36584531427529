import { observable, action, makeObservable, runInAction } from 'mobx';
import { RootStore } from '../RootStore';
import {
  InstanceItem,
  OrderClaim,
  OrderDetails,
} from '../../models/OrderDetails.model';
import { GroupedItem } from '../../models/GroupedItems.model';
export interface IItemStore {
  orderItemsGroupedShipmentAndItems?: Array<GroupedItem>;
  orderItemsGroupedShipmentAndItemsNotInsured?: Array<Array<GroupedItem>>;
  orderItemsGroupedIssueStatus?: Array<Array<GroupedItem>>;
}

export class ItemStore implements IItemStore {
  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
  }

  @observable orderItemsGroupedShipmentAndItems = [];
  @observable orderItemsGroupedShipmentAndItemsNotInsured = [];
  @observable orderClaims: Array<OrderClaim> = [];

  @action setOrderGroupedItems(order: OrderDetails): void {
    runInAction(() =>
      this.setOrderItemsGroupedShipmentAndItems(order.instanceItems)
    );
    runInAction(() =>
      this.setOrderItemsGroupedShipmentAndItemsNotInsured(order.instanceItems)
    );
    runInAction(() => this.setOrderClaims(order.claims));
  }

  @action setOrderClaims(claims: Array<OrderClaim>): void {
    this.orderClaims = claims;
  }

  @action setOrderItemsGroupedShipmentAndItems(
    instanceItems: Array<InstanceItem>
  ): void {
    const shipmentItems: Array<GroupedItem> =
      Object.values(
        instanceItems
          .filter(item => !item.isDisabled)
          .reduce(function (obj, item) {
            const groupId = `${item.orderItemId}-${item.shipmentId ?? ''}`;
            obj[groupId] = obj[groupId] || new GroupedItem();
            obj[groupId].items.push(item);
            return obj;
          }, Object.create(null))
      ) || [];
    // @ts-ignore
    this.orderItemsGroupedShipmentAndItems = Object.values(
      // @ts-ignore
      shipmentItems.reduce((obj, item: GroupedItem) => {
        const groupId = item.shipmentId ?? 'no_shipment';
        obj[groupId] = obj[groupId] || [];
        obj[groupId].push(item);
        return obj;
      }, Object.create(null))
    );
  }

  @action setOrderItemsGroupedShipmentAndItemsNotInsured(
    instanceItems: Array<InstanceItem>
  ): void {
    const shipmentItems: Array<GroupedItem> =
      Object.values(
        instanceItems
          .filter(item => item.isDisabled)
          .reduce(function (obj, item) {
            const groupId = `${item.orderItemId}-${item.shipmentId ?? ''}`;
            obj[groupId] = obj[groupId] || new GroupedItem();
            obj[groupId].items.push(item);
            return obj;
          }, Object.create(null))
      ) || [];
    // @ts-ignore
    this.orderItemsGroupedShipmentAndItemsNotInsured = Object.values(
      // @ts-ignore
      shipmentItems.reduce((obj, item: GroupedItem) => {
        const groupId = item.shipmentId ?? 'no_shipment';
        obj[groupId] = obj[groupId] || [];
        obj[groupId].push(item);
        return obj;
      }, Object.create(null))
    );
  }

  @action reset(): void {
    this.orderItemsGroupedShipmentAndItems = [];
    this.orderItemsGroupedShipmentAndItemsNotInsured = [];
    this.orderClaims = [];
  }
}
