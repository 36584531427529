import {
  observable,
  makeObservable,
  action,
  runInAction,
  reaction,
} from 'mobx';
import {
  Country,
  CountryCodes,
  InvalidReasonTypeEnum,
  PhoneVerificationError,
  VerificationType,
} from '../../models/PhoneVerification.model';
import { RootStore } from '../RootStore';
import * as api from '../../services/api';
import { AsYouType, CountryCode, getCountries } from 'libphonenumber-js';
import { SnackMessages } from '../_constants/PhoneVerificationStore.constants';
import { analyticsCodes, logEvent } from '../../services/analytics';
export interface IPhoneVerificationStore {
  loading: boolean;
  phoneInput: string;
  verificationCode: string;
}

export class PhoneVerificationStore implements IPhoneVerificationStore {
  [key: string]: unknown;
  private rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
    reaction(
      () => this.selectedCountry,
      (selectedCountry: Country | undefined) => {
        if (selectedCountry) {
          this.phoneInput = '';
        }
      }
    );
  }

  @observable loading = false;
  @observable sendCodeLoading = false;
  @observable verifyCodeLoading = false;

  @observable countryCodes: CountryCodes = [];
  @observable selectedCountry: Country | undefined;

  @observable phoneInput = '';
  @observable fullPhone = '';
  @observable verificationCode = '';
  @observable verificationSent = false;
  @observable verificationType?: VerificationType;
  @observable validPhone = false;
  @observable errorPhone = false;
  @observable errorVerificationCode = false;

  @observable snackMessage = '';

  @action async loadCountryCodes(): Promise<void> {
    runInAction(() => (this.loading = true));
    const codes = await api.getCountryCodes();
    runInAction(() => (this.countryCodes = codes));
    runInAction(() => (this.selectedCountry = this.countryCodes[0]));
    runInAction(() => (this.loading = false));
  }

  @action async sendVerificationCode(): Promise<void> {
    if (this.rootStore.orderStore.routeOrder && this.verificationType) {
      const requestBody = {
        phoneNumber: this.fullPhone,
        verificationType: this.verificationType,
      };
      try {
        runInAction(() => (this.sendCodeLoading = true));
        const rep = await api.requestPhoneVerificationCode(
          this.rootStore.orderStore.routeOrder.order.id,
          requestBody
        );
        if (rep.status === 200) {
          runInAction(() => (this.verificationSent = true));
          runInAction(
            () => (this.snackMessage = SnackMessages.verificationSent)
          );
          setTimeout(() => {
            runInAction(() => (this.snackMessage = ''));
          }, 2000);
        } else if (rep.status === 400) {
          const verificationError = rep.data as PhoneVerificationError;
          if (
            verificationError.invalidReason ===
            InvalidReasonTypeEnum.INVALID_PHONE_NUMBER
          ) {
            runInAction(() => (this.errorPhone = true));
          } else if (
            verificationError.invalidReason === InvalidReasonTypeEnum.MUST_WAIT
          ) {
            runInAction(
              () =>
              (this.snackMessage = SnackMessages.reachedLimit(
                rep.data.waitTime ?? SnackMessages.fewQuantity
              ))
            );
            setTimeout(() => {
              runInAction(() => (this.snackMessage = ''));
            }, rep.data.waitTime * 60 * 1000);
          } else if (
            verificationError.invalidReason ===
            InvalidReasonTypeEnum.ALREADY_VERIFIED
          ) {
            this.rootStore.claimStore.setPhoneVerified();
            this.nextStep();
          }
        } else {
          this.rootStore.commonStore.displayError();
        }
        runInAction(() => (this.sendCodeLoading = false));
      } catch (err) {
        console.error(err);
        this.rootStore.commonStore.displayError();
      }
    }
  }

  @action async verifyCode(): Promise<void> {
    const requestBody = {
      phoneNumber: this.fullPhone,
      code: this.verificationCode,
    };
    if (this.rootStore.orderStore.routeOrder) {
      try {
        runInAction(() => (this.verifyCodeLoading = true));
        const rep = await api.sendPhoneVerificationCode(
          this.rootStore.orderStore.routeOrder.order.id,
          requestBody
        );
        if (rep.status === 200) {
          this.rootStore.claimStore.setPhoneVerified();
          logEvent(analyticsCodes.screenView, {
            screen: analyticsCodes.phoneCompleted,
            claimInitiatedFrom: this.rootStore.commonStore.isMerchantSource()
              ? analyticsCodes.merchantPortal
              : analyticsCodes.resolveCenter,
          });
          this.nextStep();
        } else if (rep.status === 400) {
          const verificationError = rep.data as PhoneVerificationError;
          if (
            verificationError.invalidReason ===
            InvalidReasonTypeEnum.INCORRECT_CODE
          ) {
            runInAction(() => (this.errorVerificationCode = true));
          } else if (
            verificationError.invalidReason === InvalidReasonTypeEnum.MUST_WAIT
          ) {
            this.rootStore.routingStore.replace(
              '/item/phone-verification/limit'
            );
          } else if (
            verificationError.invalidReason ===
            InvalidReasonTypeEnum.ALREADY_VERIFIED
          ) {
            this.nextStep();
          }
        } else {
          this.rootStore.commonStore.displayError();
        }
        runInAction(() => (this.verifyCodeLoading = false));
      } catch (err) {
        this.rootStore.commonStore.displayError();
      }
    }
  }

  @action phoneInputChange(val: string): void {
    const countries = getCountries();
    if (
      this.selectedCountry &&
      countries.includes(this.selectedCountry.abbrev)
    ) {
      const asYouType = new AsYouType(this.selectedCountry.abbrev);

      //Needed for parenthesis deletion correctly
      if (val.includes('(') && !val.includes(')')) {
        val = val.slice(0, -1);
      }

      const value = asYouType.input(val);

      if (asYouType.getNumber()) {
        if (
          asYouType.getNumber()?.isPossible() &&
          asYouType.getNumber()?.isValid()
        ) {
          this.validPhone = true;
        } else {
          this.validPhone = false;
        }

        runInAction(() => (this.phoneInput = value));
        runInAction(
          () =>
            (this.fullPhone = (asYouType?.getNumber()?.number as string) || '')
        );
      } else {
        runInAction(() => (this.phoneInput = ''));
        runInAction(() => (this.fullPhone = ''));
      }
    } else {
      // currently there are 2 countries coming from backend API that aren't supported by validation library
      // 'AN': Netherlands Antilles and 'DG' Diego Garcia.
      // If these countries are selected, need to just set the phone number as it is and add country code manually.
      runInAction(() => (this.phoneInput = val));
      runInAction(
        () => (this.fullPhone = `+${this.selectedCountry?.code}${val}` ?? '')
      );
    }
  }
  @action verificationInputChange(val: string): void {
    runInAction(() => (this.verificationCode = val));
  }

  @action countrySelectorChange(val: CountryCode): void {
    const c = this.countryCodes.find(country => country.abbrev === val);
    this.selectedCountry = c;
  }

  @action setVerificationType(val: VerificationType): void {
    this.verificationType = val;
  }

  @action removeSnackMessage(): void {
    this.snackMessage = '';
  }

  @action reset(): void {
    this.loading = false;
    this.sendCodeLoading = false;
    this.verifyCodeLoading = false;

    this.validPhone = false;
    this.errorPhone = false;
    this.errorVerificationCode = false;
    this.phoneInput = '';
    this.fullPhone = '';
    this.verificationCode = '';
    this.verificationSent = false;
    this.verificationType = undefined;
    this.snackMessage = '';
  }

  nextStep(): void {
    if (this.rootStore.claimStore.hasAtLeastOneEligibleForPerishable()) {
      this.rootStore.routingStore.replace('/item/perishable');
    } else {
      this.rootStore.claimStore.selectClaimRouteFlow(true);
    }
  }
}
