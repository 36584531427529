import { ShippingDetails } from '../../models/OrderDetails.model';

export const addressArray = (
  shippingDetails: ShippingDetails | undefined,
  extended = false
): Array<string> => {
  if (!shippingDetails) return ['-'];
  return [
    shippingDetails.address1,
    `${shippingDetails.city} ${shippingDetails.state}, ${shippingDetails.zip}${
      extended ? ' ' + shippingDetails.country : ''
    }`,
    `${extended ? '' : shippingDetails.country}`,
  ];
};
