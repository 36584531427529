import styled from 'styled-components';
import { aboveSize, belowSize } from '../../utils/Breakpoints';

export const Container = styled.div`
  display: flex;
  overflow: hidden;
  flex-grow: 1;
  flex-direction: column;

  align-items: stretch;

  ${belowSize.md`
     margin: 5%;
  `}
`;

export const TitleContainer = styled.div`
  margin: 8px 0;
  display: flex;
`;

export const DescriptionContainer = styled.div`
  ${aboveSize.md`
    width: 75%;
  `}
`;

export const Logo = styled.img`
  width: 60px;
  margin-bottom: 8px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const DetailsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  overflow: hidden; //keeps height from overflowing (sets min-height to 0)
  ${belowSize.md`
    flex-direction: column-reverse;
  `}

  margin: 40px 0 48px 0;
`;

export const ItemContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const MerchantContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;
