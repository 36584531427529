import { Ref } from 'react';

export const getAnchorTopOffset = (
  anchor: Ref<HTMLDivElement> | null,
  anchored: Ref<HTMLDivElement> | null
): number | string => {
  if (
    !anchor ||
    typeof anchor === 'function' ||
    !anchor.current ||
    !anchored ||
    typeof anchored === 'function' ||
    !anchored.current
  ) {
    return '';
  }

  const aPosition = anchor.current.getBoundingClientRect();
  const bPosition = anchored.current.getBoundingClientRect();
  const startOffset = aPosition.top - bPosition.top;
  return startOffset;
};
