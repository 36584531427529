import React, { FC } from 'react';
import { IForegroundContentModalProps } from './interface';
import {
  Container,
  ButtonColumn,
  ContainerFlex,
  PaperCard,
  ContentWrapper,
  BackButton,
} from './ForegroundContentModalNew.styles';
import BackIcon from '../../assets/icons/back-icon.svg';
import Button from '../Buttons/Button';
import CleanButton from '../Buttons/CleanButton';
import { colors } from '../../constants/styles';
import { Icon } from '../../styles/global.styles';
import { broadCastAction } from '../../services/broadcast';
import { useStores } from '../../hooks/app.hook';
import { ACTIONS } from '../../constants/actions';

const ForegroundContentModalNew: FC<IForegroundContentModalProps> = (
  props: IForegroundContentModalProps
) => {
  const {
    children,
    backButtonEnabled,
    backButtonHandleOverride,
    mainButton,
    mainButtonDisabled,
    secondaryButton,
  } = props;
  const { routingStore } = useStores();

  const defaultBackButton = () => {
    if (backButtonHandleOverride) {
      backButtonHandleOverride();
      return;
    }
    broadCastAction(ACTIONS.NAVIGATE_BACK_CLICK);
    routingStore.goBack();
  }
  return (
    <ContainerFlex>
      <Container>
        <ContentWrapper>
          <PaperCard>
            {backButtonEnabled && (
              <>
                <BackButton>
                  <Button
                    mode="light"
                    overrideBackground={colors.transparent}
                    ariaLabel="back"
                    onClick={defaultBackButton}
                    icon={<Icon src={BackIcon} verticalAlign="middle" />}
                  />
                </BackButton>
              </>
            )}
            {children}
          </PaperCard>
        </ContentWrapper>

        <ButtonColumn>
          {mainButton && (
            <Button
              mode="dark"
              fullWidth
              disabled={mainButtonDisabled}
              onClick={() =>
                props.mainButtonClick ? props.mainButtonClick() : ''
              }
            >
              {mainButton}
            </Button>
          )}
          {secondaryButton && (
            <CleanButton
              noTextDecoration
              onClick={() =>
                props.secondaryButtonClick ? props.secondaryButtonClick() : ''
              }
            >
              {secondaryButton}
            </CleanButton>
          )}
        </ButtonColumn>
      </Container>
    </ContainerFlex>
  );
};

export default ForegroundContentModalNew;
