import { makeAutoObservable } from 'mobx';
import { RootStore } from './RootStore';

export class FeatureStore {
  private rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  validateFeature(featureSetting: string): boolean {
    if (!this.rootStore.orderStore.routeOrder) {
      return false;
    }
    if (!this.rootStore.orderStore.routeOrder.merchant.merchantSettings) {
      return true;
    }

    const features = this.rootStore.orderStore.routeOrder.merchant
      .merchantSettings;

    const feature = features.filter(f => f.setting === featureSetting);

    if (feature && feature.length >= 1) {
      return feature[0].flag === 'enabled';
    }

    return false;
  }
}
