/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import {
  CloseContainer,
  Column,
  Container,
  ForwardIn,
  Row,
  TitleContainer,
} from './ClaimedItemsPhoneVerificationLimit.styles';
import { useStores } from '../../../../hooks/app.hook';
import BlurredWrapper from '../../../../components/UI/BlurredWrapper';
import ForegroundContentModal from '../../../../components/ForegroundContentModal';
import {
  ButtonContent,
  LinkContent,
  TypographyContent,
} from './ClaimedItemsPhoneVerificationLimit.constants';
import Typography from '../../../../components/UI/Typography';
import { colors } from '../../../../constants/styles';
import XIcon from '../../../../assets/icons/x-icon';
import CleanButton from '../../../../components/Buttons/CleanButton';
import ReachLimit from '../../../../assets/images/reached-your-limit.svg';
import { VIEWS } from '../../../../constants/views';
import { broadCastAction, broadcastView } from '../../../../services/broadcast';
import { ACTIONS } from '../../../../constants/actions';

const ClaimedItemsPhoneVerificationLimit: FC = observer(() => {
  const { claimStore, routingStore } = useStores();

  useEffect(() => {
    broadcastView(VIEWS.CLAIM_ITEM_PHONE_VERIFICATION_LIMIT)
  }, [])

  const supportButtonHandle = () => {
    broadCastAction(ACTIONS.CONTACT_SUPPORT);
    window.location.href = LinkContent.support;
  };

  const closeButtonHandle = () => {
    broadCastAction(ACTIONS.CANCEL_CLAIM_FILING);
    claimStore.reset();
    routingStore.replace('/order-information');
  };

  return (
    <BlurredWrapper open>
      <ForwardIn>
        <ForegroundContentModal
          open
          mainButton={ButtonContent.support}
          mainButtonClick={supportButtonHandle}
        >
          <Container>
            <Row>
              <CloseContainer>
                <CleanButton onClick={closeButtonHandle}>
                  <XIcon color={colors.routeButtonFill1} />
                </CleanButton>
              </CloseContainer>
              <TitleContainer>
                <Typography variant="p7" color={colors.routeText1}>
                  {TypographyContent.title}
                </Typography>
              </TitleContainer>
            </Row>
            <Column>
              <img src={ReachLimit} alt={`eye crossed`} />
              <Typography variant="p6" color={colors.routeText2} align="center">
                {TypographyContent.description}
              </Typography>
            </Column>
          </Container>
        </ForegroundContentModal>
      </ForwardIn>
    </BlurredWrapper>
  );
});

export default ClaimedItemsPhoneVerificationLimit;
