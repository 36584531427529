import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 15px auto 45px auto;
  align-content: center;
  max-width: 1032px;
  width: calc(100vw - 48px);

  @media (max-width: 680px) {
    min-height: 50px;
    width: 100%;
  }
`;

export const RouteLogo = styled.img`
  @media (max-width: 680px) {
    height: 40px;
    margin-left: 15px;
    width: 100px;
  }
`;

export const DownloadImg = styled.img`
  margin-left: 15px;
  @media (max-width: 680px) {
    margin-right: 10px;
    right: 0;
    float: right;
  }
`;
