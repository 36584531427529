export const ButtonContent = {
  cancel: 'Cancel',
  continue: 'Continue',
};

export const TypographyContent = {
  title: "Virtual Prepaid Mastercard®",
  description: 'Enter your full name and email address to receive your refund via Prepaid Mastercard. Once your claim has been approved, your Prepaid Mastercard will be sent to the email address provided.',
  viewDetails: 'View Details',
}

export const TextFieldContent = {
  firstName: {
    placeholder: 'First name',
    errorText: 'Please fill this information',
  },
  lastName: {
    placeholder: 'Last name',
    errorText: 'Please fill this information',
  },
  email: {
    placeholder: 'Email',
    errorText: 'Please provide a valid email',
  },
  confirmEmail: {
    placeholder: 'Confirm Email',
    errorText: "Email did not match the email above",
  },
};


export const AnalyticsContent = {
  enterMailButtonTapped: 'Resolve-GiftCardRefund-EnterEmail-Button-Tapped',
}