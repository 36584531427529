import { FC } from "react"
import { IGiftCardDisclaimerTooltipProps } from "./interface"
import { TooltipNewDesign } from "../UI/TooltipNewDesign/TooltipNewDesign"
import styled from "styled-components"
import Typography from "../UI/Typography"
import { TypographyContent } from "./GiftCardDisclaimerTooltip.constants"
import { colors } from "../../constants/styles"
import ToolTipIcon from '../../../shared/assets/icons/tooltip.svg';
import ToolTipIconBlack from '../../../shared/assets/icons/tooltip-black.svg';

export const GiftCardDisclaimerTooltip: FC<IGiftCardDisclaimerTooltipProps> = (props: IGiftCardDisclaimerTooltipProps) => {
    return (
        <TooltipContainer>
            <>
                <TooltipNewDesign isOpen={props.isOpen} onClose={() => { props.setIsOpen(false) }} centered={props.toolTipCentered}>
                    <ToolTipColumn>
                        <Typography variant="p8" color={colors.routeText1} bold>
                            {TypographyContent.giftCardEmailDisclaimerTitle}
                        </Typography>
                        <Typography variant="p3" color={colors.routeText1}>
                            {TypographyContent.giftCardEmailDisclaimerDescription.replace('{merchantName}', props.merchant ?? 'the Merchant')}
                        </Typography>
                    </ToolTipColumn>
                </TooltipNewDesign>

                <Icon
                    alt={`Gift card email information tooltip`}
                    src={props.isOpen ? ToolTipIconBlack : ToolTipIcon}
                    onClick={(e) => { props.setIsOpen(!props.isOpen); e.stopPropagation() }}
                />
            </>
        </TooltipContainer>
    )
}

export const ToolTipColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`
export const TooltipContainer = styled.div`
  position: relative;
  display: flex;
`

export const Icon = styled.img`
  height: 30px;
  width: 30px;
  cursor: pointer;
`;
