import styled from 'styled-components';
import { colors } from '../../../constants/styles';

export const Container = styled.div<{
  fullHeight?: boolean;
}>`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  flex-grow: 1;
  ${props => {
    return props.fullHeight ? 'height: 100%' : '';
  }};
`;

export const ToolTipContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ToolTipExtender = styled.div`
  height: 12px;
  width: 12px;
  background: ${colors.routeBackground1};
  position: absolute;
  margin: 7px 10px 0 0;
  transform: rotate(45deg);
  border-radius: 2px;
`;
export const InputLineContainer = styled.div<{
  isError?: boolean;
  noLine?: boolean;
}>`
  border-bottom: ${props => (props.noLine ? '0px' : '1px')} solid
    ${props => (props.isError ? colors.routeError1 : colors.divider)};
  display: flex;
  justify-content: stretch;
  height: 60px;
  flex-grow: 1;
`;

export const Element = styled.input<{
  textColor?: string;
  fontSize?: string;
  lineHeight?: string;
  fontWeight?: string;
  hasAnimatedLabel?: boolean;
}>`
  border: none;
  border-radius: 8px;
  font-family: RM Neue;
  font-size: ${props => (props.fontSize ? props.fontSize : '17px')};
  line-height: ${props => (props.lineHeight ? props.lineHeight : '24px')};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : '400')};
  color: ${props => (props.textColor ? props.textColor : colors.routeText1)};
  flex-grow: 1;
  width: 100%;

  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${colors.routeText2};
    opacity: ${props => (props.hasAnimatedLabel ? '0' : '1')};
  }

  &:focus {
    outline: none;
  }
`;

export const TextAreaElement = styled.textarea<{
  textColor?: string;
  fontSize?: string;
  lineHeight?: string;
  fontWeight?: string;
  hasAnimatedLabel?: boolean;
}>`
  border: none;
  border-radius: 8px;
  resize: none;
  font-family: RM Neue;
  font-size: ${props => (props.fontSize ? props.fontSize : '14px')};
  line-height: ${props => (props.lineHeight ? props.lineHeight : '18px')};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : '400')};
  color: ${props => (props.textColor ? props.textColor : colors.routeText1)};
  flex-grow: 1;

  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${colors.routeText2};
  }

  &:focus {
    outline: none;
  }

  ::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${colors.lightGray}33; //20% opacity
    border-radius: 5px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${colors.divider}80;
    border-radius: 10px;
  }
`;

export const InputContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 100%;
`;

interface ILabelAnimated {
  smallFontSize: string;
  smallLineHeight: string;
  smallFontColor: string | undefined;
  smallOffsetX?: number;
  smallOffsetY?: number;
  largeFontSize: string;
  largeLineHeight: string;
  largeFontColor: string | undefined;
  largeOffsetX?: number;
  largeOffsetY?: number;
  errorFontColor?: string | undefined;
  isError?: boolean | undefined;
}

const getLabelMargins = (props: ILabelAnimated, large = true, adjustX = 0, adjustY = 0) => {
  let offsetX, offsetY;

  if (large) {
    offsetX = props.largeOffsetX ? props.largeOffsetX : 0;
    offsetY = props.largeOffsetY ? props.largeOffsetY : -30;
  }
  else {
    offsetX = props.smallOffsetX ? props.smallOffsetX : 0;
    offsetY = props.smallOffsetY ? props.smallOffsetY : 0;
  }

  offsetX += adjustX;
  offsetY += adjustY;

  return '0px 0px ' + offsetY + 'px ' + offsetX + 'px ';
};

export const LabelAnimated = styled.div<ILabelAnimated>`
  display: flex;
  align-items: end;
  visibility: visible;
  height: 25px;
  max-width: 100%;

  position: relative;
  font-weight: 400;
  pointer-events: none;
  color: ${props => props.isError && props.errorFontColor ? props.errorFontColor : props.largeFontColor};

  transition-timing-function: ease;
  transition-duration: 0.5s;
  transition-property: margin font-size line-height color;

  &.large {
    font-size: ${props => props.largeFontSize};
    line-height: ${props => props.largeLineHeight};
    margin: ${(props) => getLabelMargins(props, true)};
  }

  &.small {
    font-size: ${props => props.smallFontSize};
    line-height: ${props => props.smallLineHeight};
    margin: ${(props) => getLabelMargins(props, false)};
  }
`;

export const LabelSpan = styled.span`
  overflow: hidden;
`

export const Label = styled.div`
  display: flex;
  align-items: end;
  height: 25px;
  padding-left: 2px;

  &.hidden {
    visibility: hidden;
  }
`;

export const ToolTipWrapper = styled.div`
  margin-top: -65px;

  background: ${colors.routeBackground1};
  box-shadow: 0px 14px 18px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  display: flex;
  padding: 16px;
  position: absolute;

  font-family: RM Neue;

  width: 270px;
  z-index: 3;
`;

export const CloseIcon = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;
export const Icon = styled.img`
  bottom: 0;
  margin-bottom: -35px;
`;

export const ErrorContainer = styled.div`
  padding-top: 5px;
`;

export const Row = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-grow: 1;
`;
