/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../../../../hooks/app.hook';
import PayPalRefund from '../../../../components/PayPalRefund';
import BackIcon from '../../../../assets/icons/back-icon.svg';
import { colors } from '../../../../constants/styles';
import { analyticsButtons, analyticsCodes, logEvent, logEventNew } from '../../../../services/analytics';
import ForegroundContentPage from '../../../../components/ForegroundContentPage';
import { broadCastAction, broadcastView } from '../../../../services/broadcast';
import { VIEWS } from '../../../../constants/views';
import { ACTIONS } from '../../../../constants/actions';
import { AnalyticsContent } from './ClaimedItemsPaypalEmailCollection.constants';

const ClaimedItemsPaypalEmailCollection: FC = observer(() => {
  const rootStore = useStores();
  const { claimStore, routingStore, remedyStore, orderStore } = rootStore;
  const { displayItem: item } = claimStore;
  const { routeOrder } = orderStore;

  if (!item || !routeOrder) {
    //never reaching code
    return null;
  }

  const { paypalEmail } = remedyStore;

  useEffect(() => {
    broadcastView(VIEWS.CLAIM_ITEM_PAYPAL_EMAIL_COLLECT)
    logEvent(analyticsCodes.screenView, {
      screen: analyticsCodes.paypalEmailCollection,
    });
  }, []);

  const backClickHandle = () => {
    broadCastAction(ACTIONS.NAVIGATE_BACK_CLICK);
    logEventNew(AnalyticsContent.enterMailButtonTapped, rootStore, {
      button_function: analyticsButtons.goBack,
    });
    routingStore.goBack();
  };

  const skipButtonHandle = () => {
    claimStore.resetRemedyConfigs();
    routingStore.push('/item/review-details');
  };

  const nextPage = () => {
    claimStore.setPaypalEmail(paypalEmail);
    logEventNew(AnalyticsContent.enterMailButtonTapped, rootStore, {
      button_function: analyticsButtons.continue,
    });
    routingStore.push('/item/review-details');
  };

  return (
    <ForegroundContentPage
      navigationHandle={backClickHandle}
      navigationIconLabel={'back'}
      navigationIcon={BackIcon}
      itemImage={item.item.imageUrl ?? ''}
      itemLabel={orderStore.getMerchantName() + ' ' + item.item.name}
      itemQuantity={claimStore.claimedItems.reduce(
        (acc, items) => acc + items.item.quantity,
        0
      )}
      rightButtonHandle={skipButtonHandle}
      rightButtonText={'Skip'}
      rightButtonColor={colors.routeText2}
      rightButtonFontSize={15}
      rightButtonFontWeight={500}
    >
      <PayPalRefund showEmail={true} email={paypalEmail} onClick={nextPage} />
      <br />
    </ForegroundContentPage>
  );
});

export default ClaimedItemsPaypalEmailCollection;
