/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { observable, makeObservable, action, runInAction } from 'mobx';
import { RootStore } from '../RootStore';
import { IssueStatusSummary } from '../../models/Issue.model';
import { InstantAssessmentResultEnum } from '../../models/Enums/PreAssessmentEnum.model';
import * as api from '../../services/api';
import { OrderClaim } from '../../models/OrderDetails.model';

export class IssueStore {
  [key: string]: unknown;
  private rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
  }

  @observable loading = false;

  @observable currentClaim?: OrderClaim;

  @observable instantAssessmentResult?: InstantAssessmentResultEnum;

  @observable issueStatusSummary?: IssueStatusSummary;

  @observable popUpOpen = false;

  @action initialize(
    claimId: string,
    result: InstantAssessmentResultEnum
  ): void {
    runInAction(() => {
      this.currentClaim = this.findClaimById(claimId);
    });
    this.instantAssessmentResult = result;
    this.getIssueInformation(claimId);
  }

  @action reset(): void {
    this.loading = false;
    this.currentClaim = undefined;
    this.issueStatusSummary = undefined;
  }

  getIssueInformation(issueId: string): void {
    this.loading = true;
    api
      .getIssueInformation(issueId)
      .then(rep => {
        runInAction(() => {
          this.issueStatusSummary = rep.data;
          this.loading = false;
        });
      })
      .catch(() => {
        this.rootStore.commonStore.displayError();
      })
      .finally(() => {
        runInAction(() => {
          this.loading = false;
        });
      });
  }

  setIssue(currentClaim: OrderClaim): void {
    runInAction(() => {
      this.currentClaim = currentClaim;
      this.getIssueInformation(currentClaim.claimId);
    });
  }

  findClaimById(claimId: string): OrderClaim | undefined {
    if (this.rootStore.orderStore.routeOrder?.order?.claims) {
      return this.rootStore.orderStore.routeOrder?.order?.claims.find(
        c => claimId === c.claimId
      );
    }
  }

  setPopUpOpen(val: boolean): void {
    this.popUpOpen = val;
  }

  get hasIssue(): boolean {
    return !!this.currentClaim
  }
}
