import { MESSAGE_TYPES, WEB_MOBILE_CHANNEL } from "../constants/messageChannels"

export const postError = () => {
	const channel = new BroadcastChannel(WEB_MOBILE_CHANNEL);

	channel.postMessage({
		type: MESSAGE_TYPES.GENERIC_ERROR,
	});
	channel.close();
}

export const postView = ({ id }: { id: string }) => {
	const channel = new BroadcastChannel(WEB_MOBILE_CHANNEL);

	channel.postMessage({
		type: MESSAGE_TYPES.VIEW_RENDERED,
		payload: { id }
	});
	channel.close();

}

export const postAction = ({ action_id }: { action_id: string }) => {
	const channel = new BroadcastChannel(WEB_MOBILE_CHANNEL);

	channel.postMessage({
		type: MESSAGE_TYPES.ACTION,
		payload: { id: action_id }
	});
	channel.close();
}
