import { observer } from 'mobx-react';
import React, { FC, useEffect } from 'react';
import { useState } from 'react';
import EmailModal from '../../components/EmailModal';
// import Paper from '../../components/UI/Paper';
import { useStores } from '../../hooks/app.hook';
import { isMobileView } from '../../utils/Breakpoints';
import {
  ButtonContents,
  EmailDraft,
  TypographyContent,
} from './UnrelatedProblem.constants';
import {
  CustomCentralized,
  SummaryStyled,
  Wrapper,
} from './UnrelatedProblem.styles';
import { MerchantContactTypeEnum } from '../../models/Enums/OrderEnum.model';
import Footer from '../../components/UI/Footer';
import Header from '../../components/UI/Header';
import { broadCastAction, broadcastView } from '../../services/broadcast';
import { VIEWS } from '../../constants/views';
import { ACTIONS } from '../../constants/actions';

const UnrelatedProblem: FC = observer(() => {
  const { routingStore, orderStore, claimStore, commonStore, claimBlockingStore } = useStores();
  const routeOrder = orderStore.routeOrder;
  const claimEligible = claimStore.hasAtLeastOneShouldDisplayClaim();
  const title = claimEligible
    ? TypographyContent.issueOutsideTitle
    : TypographyContent.contactSupportTitle;
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const mobile = isMobileView();

  useEffect(() => {
    broadcastView(VIEWS.UNRELATED_PROBLEM)
  }, [])

  const closeHandle = () => {
    broadCastAction(ACTIONS.CANCEL_CLAIM_FILING)
    claimStore.reset();
    claimBlockingStore.reset();
    routingStore.replace('/order-information');
  };

  const closeEmailModalHandle = () => {
    broadCastAction(ACTIONS.CLOSE_MODAL)
    setEmailModalOpen(false);
  };

  const item = claimStore.displayItem;

  if (!item) {
    commonStore.displayError();
    return null;
  }

  if (!routeOrder) {
    // never reaching code, router won't allow render without order
    routingStore.replace('/error');
    return null;
  } else {
    const supportContacts = orderStore.getMerchantContacts(
      MerchantContactTypeEnum.SUPPORT
    );

    return (
      <Wrapper>
        {!mobile && <Header />}
        <CustomCentralized>
          <SummaryStyled
            closeHandle={closeHandle}
            actionHandle={() => {
              broadCastAction(ACTIONS.CONTACT_SUPPORT);
              setEmailModalOpen(true)
            }}
            actionButtonText={ButtonContents.emailDraft}
            order={routeOrder.order}
            title={title}
            description={TypographyContent.description(
              supportContacts?.email ?? routeOrder.merchant.name
            )}
            merchant={{
              name: routeOrder.merchant.name,
              imageURL: routeOrder.merchant.imageURL,
            }}
            displayItem={item}
            claimedItems={claimStore.claimedItems}
          />
        </CustomCentralized>
        {!mobile && <Footer />}
        <EmailModal
          description={EmailDraft(
            routeOrder.order.orderNumber,
            `${routeOrder.order.firstName} ${routeOrder.order.lastName}`
          )}
          emailTo={
            orderStore.getMerchantContacts(MerchantContactTypeEnum.SUPPORT)
              ?.email ?? ''
          }
          open={emailModalOpen}
          onClose={closeEmailModalHandle}
        />
      </Wrapper>
    );
  }
});

export default UnrelatedProblem;
