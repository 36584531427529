/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable import/no-webpack-loader-syntax */
import React, { FC, useState, useEffect } from 'react';
import { MapViewContainer, LogoPin, Logo, Pin } from './TrackingMap.styles';

import ReactMapGL, { Source, Layer, Marker } from 'react-map-gl';
import { IMapViewProps } from './interface';
import { getAddressToGeolocation } from '../../services/api';
import MerchantLogo from '../IconOrLogos/MerchantLogo';

// added the following 6 lines.
import mapboxgl from 'mapbox-gl';

// The following is required to stop "yarn build" from transpiling mapbox code.
// notice the exclamation point in the import.
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const MapView: FC<IMapViewProps> = props => {
  const { merchant, shipment } = props;
  const [viewState, setViewState] = useState({
    longitude: -98,
    latitude: 39,
    zoom: 9,
  });

  const [checkpoints, setCheckpoints] = useState([]);
  const [lineData, setLineData] = useState(null);
  const shipmentFeed = shipment?.checkpoints;

  useEffect(() => {
    if (!shipmentFeed) return;
    const filteredCheckpoints = shipmentFeed.filter(cp => cp.location);
    const mappedCheckpoints = filteredCheckpoints.map(async item => {
      const geolocation = await getAddressToGeolocation(item.location);
      item.lon = geolocation[0];
      item.lat = geolocation[1];
      return item;
    });

    Promise.all(mappedCheckpoints).then(res => {
      const cp = res.filter(item => {
        return item.lon !== -1 && item.lat !== -1;
      });

      if (cp[cp.length - 1]) {
        setViewState({
          longitude: cp[cp.length - 1].lon || -1,
          latitude: cp[cp.length - 1].lat || -1,
          zoom: 9,
        });
      }

      const list = cp.map(item => {
        return [item.lon, item.lat];
      });
      // @ts-ignore
      setCheckpoints(cp);

      const lData = {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: list,
        },
      };
      // @ts-ignore
      setLineData(lData);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipment]);

  return (
    <MapViewContainer>
      <ReactMapGL
        reuseMaps
        {...viewState}
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_API_TKN}
        mapStyle="mapbox://styles/routewapp/ck0sfcnuh1cqp1cpybctk21k2"
        onMove={evt => setViewState(evt.viewState)}
      >
        <React.Fragment>
          {checkpoints.length &&
            checkpoints.map((item, index) => {
              return (
                //@ts-ignore
                //prettier-ignore
                <Marker key={index} longitude={item.lon} latitude={item.lat}>
                  <Pin />
                  {index === checkpoints.length - 1 && (
                    <LogoPin>
                      <Logo>
                        <MerchantLogo
                          src={merchant.imageURL}
                          alt={`${merchant.name} logo`}
                          name={merchant.name}
                          data-testid="merchant-logo"
                        />
                      </Logo>
                    </LogoPin>
                  )}
                </Marker>
              );
            })}
          {/* @ts-ignore */}
          {lineData && lineData.geometry.coordinates?.length && (
            // @ts-ignore
            <Source id="lineLayerSource" type="geojson" data={lineData}>
              <Layer
                id="lineLayer"
                type="line"
                layout={{
                  'line-join': 'bevel',
                  'line-cap': 'square',
                }}
                paint={{
                  'line-color': '#29D6D6',
                  'line-width': 2,
                }}
              />
            </Source>
          )}
        </React.Fragment>
      </ReactMapGL>
    </MapViewContainer>
  );
};

export default MapView;
