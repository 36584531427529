import { observer } from 'mobx-react';
import React, { FC, useEffect } from 'react';
import {
  StatusIconCentered,
  InfoContainer,
  Separator,
  OrderItemsContainer,
  OrderItemIcon,
  OrderItemText,
  StatusInfo,
  GrayedBackground,
  ClaimContent,
  Row,
  TranslatedIcon,
  TextContainer,
  Link,
  LineRow,
} from './IssueSummary.styles';
import Typography from '../../../components/UI/Typography';
import { colors } from '../../../constants/styles';
import { useStores } from '../../../hooks/app.hook';
import { fallBackLogo } from '../../../utils';
import { logPageView, analyticsCodes } from '../../../services/analytics';
import ForegroundContentPage from '../../../components/ForegroundContentPage';
import { Icon, VerticalSeparator } from '../../../styles/global.styles';
import InProgressIcon from '../../../assets/icons/in-progress-icon.svg';
import NotApprovedIcon from '../../../assets/icons/not-approved-icon.svg';
import ResolvedIcon from '../../../assets/icons/resolved-icon.svg';
import SecuredByPaypal from '../../../assets/icons/secured-by-paypal.svg';
import ItemIcon from '../../../components/IconOrLogos/ItemIcon';
import InfoIcon from '../../../assets/icons/info-icon.svg';
import { isMobileView } from '../../../utils/Breakpoints';
import LoadingSpinner from '../../../components/LoadingSpinner';
import {
  IssueStatusEnum,
  ResolutionEnum,
} from '../../../models/Enums/OrderEnum.model';
import { Links, TypographyContent } from './IssueSummary.constants';
import BlurredWrapper from '../../../components/UI/BlurredWrapper';

import ContinueButton from '@brands/components/ContinueButton';
import { broadCastAction, broadcastView } from '../../../services/broadcast';
import { VIEWS } from '../../../constants/views';
import { ACTIONS } from '../../../constants/actions';
import { GiftCardDisclaimerTooltip } from '../../../components/GiftCardDisclaimerTooltip/GiftCardDisclaimerTooltip';

const IssueSummary: FC = observer(() => {
  const { routingStore, issueStore, claimStore, claimBlockingStore, orderStore } = useStores();

  //RouteWithIssue router guarantee its existence
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { loading, currentClaim, issueStatusSummary, popUpOpen } = issueStore;

  useEffect(() => {
    broadcastView(VIEWS.ISSUE_SUMMARY);
    logPageView(analyticsCodes.claimSummary, window.location.pathname, {
      screen: analyticsCodes.resolveSummary,
    });
  }, []);

  const closeHandle = () => {
    claimStore.reset();
    claimBlockingStore.reset();
    issueStore.reset();
    routingStore.push('/order-information');
  };

  const selectIcon = () => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    switch (issueStatusSummary!.issueStatus) {
      case IssueStatusEnum.APPROVED_RESULT:
      case IssueStatusEnum.PENDING_REVIEW_RESULT:
      case IssueStatusEnum.IN_REVIEW_RESULT:
      case IssueStatusEnum.ACTION_REQUIRED_RESULT:
      case IssueStatusEnum.REMEDY_STATUS_CANCELLED:
      case IssueStatusEnum.REMEDY_STATUS_REPLACEMENT_IN_PROGRESS:
      case IssueStatusEnum.REMEDY_STATUS_REFUND_IN_PROGRESS:
        return InProgressIcon;
      case IssueStatusEnum.SOFT_DENIED_RESULT:
      case IssueStatusEnum.NOT_APPROVED_RESULT:
        return NotApprovedIcon;
      case IssueStatusEnum.REMEDY_STATUS_FINALIZED:
        return ResolvedIcon;
      default:
        return '';
    }
  };



  const hasShippingDetails = () => {
    if (issueStatusSummary?.shippingDetails) {
      if (
        issueStatusSummary.shippingDetails.firstName ||
        issueStatusSummary.shippingDetails.lastName ||
        issueStatusSummary.shippingDetails.address1 ||
        issueStatusSummary.shippingDetails.address2 ||
        issueStatusSummary.shippingDetails.city ||
        issueStatusSummary.shippingDetails.state ||
        issueStatusSummary.shippingDetails.zip
      ) {
        return true;
      }
    }
    return false;
  };

  const mobile = isMobileView();

  if (loading || !issueStatusSummary) {
    return <LoadingSpinner />;
  }

  const defaultSeparator = () => {
    return (
      <React.Fragment>
        <VerticalSeparator distance={20} />
        <Separator />
        <VerticalSeparator distance={20} />
      </React.Fragment>
    );
  };

  const replaceInfo = () => {
    if (!hasShippingDetails()) return null;
    return (
      <React.Fragment>
        <InfoContainer>
          <div>
            <Typography
              color={colors.routeText1}
              variant={'l1'}
              align={'left'}
              semibold
            >
              {TypographyContent.replacementShippingInfo}
            </Typography>

            <VerticalSeparator distance={8} />

            {(issueStatusSummary?.shippingDetails?.firstName ||
              issueStatusSummary?.shippingDetails?.lastName) && (
                <React.Fragment>
                  <Typography
                    color={colors.routeText1}
                    variant={'p2'}
                    align={'left'}
                  >
                    {`${issueStatusSummary?.shippingDetails?.firstName} ${issueStatusSummary?.shippingDetails?.lastName}`}
                  </Typography>
                  <br />
                </React.Fragment>
              )}

            {issueStatusSummary?.shippingDetails?.address1 && (
              <React.Fragment>
                <Typography
                  color={colors.routeText1}
                  variant={'p2'}
                  align={'left'}
                >
                  {issueStatusSummary.shippingDetails.address1}
                </Typography>
                <br />
              </React.Fragment>
            )}

            {issueStatusSummary?.shippingDetails?.address2 && (
              <React.Fragment>
                <Typography
                  color={colors.routeText1}
                  variant={'p2'}
                  align={'left'}
                >
                  {issueStatusSummary.shippingDetails.address2}
                </Typography>
                <br />
              </React.Fragment>
            )}

            {(issueStatusSummary?.shippingDetails?.city ||
              issueStatusSummary?.shippingDetails?.state ||
              issueStatusSummary?.shippingDetails?.zip) && (
                <React.Fragment>
                  <Typography
                    color={colors.routeText1}
                    variant={'p2'}
                    align={'left'}
                  >
                    {TypographyContent.addressInfo(
                      issueStatusSummary?.shippingDetails?.city,
                      issueStatusSummary?.shippingDetails?.state,
                      issueStatusSummary?.shippingDetails?.zip
                    )}
                  </Typography>
                  <br />
                </React.Fragment>
              )}

            {issueStatusSummary &&
              issueStatusSummary.remedyFulfillments.length > 0 &&
              issueStatusSummary.remedyFulfillments[0].shipments.length > 0 &&
              issueStatusSummary.remedyFulfillments[0].shipments[0].status &&
              issueStatusSummary.remedyFulfillments[0].shipments[0]
                .createdOn && (
                <React.Fragment>
                  <Typography
                    color={colors.routeText1}
                    variant={'p2'}
                    align={'left'}
                  >
                    {TypographyContent.shippingStatus(
                      issueStatusSummary.remedyFulfillments[0].shipments[0]
                        .status,
                      issueStatusSummary.remedyFulfillments[0].shipments[0]
                        .createdOn
                    )}
                  </Typography>
                  <br />
                </React.Fragment>
              )}

            {issueStatusSummary &&
              issueStatusSummary.remedyFulfillments.length > 0 &&
              issueStatusSummary.remedyFulfillments[0].shipments.length > 0 &&
              issueStatusSummary?.remedyFulfillments[0]?.shipments[0]
                ?.expectedDeliveryDate && (
                <React.Fragment>
                  <Typography
                    color={colors.routeText1}
                    variant={'p2'}
                    align={'left'}
                  >
                    {TypographyContent.edd(
                      issueStatusSummary.remedyFulfillments[0].shipments[0]
                        .expectedDeliveryDate
                    )}
                  </Typography>
                  <br />
                </React.Fragment>
              )}
          </div>
        </InfoContainer>

        {defaultSeparator()}
      </React.Fragment>
    );
  };

  const genericInfo = (title: string, value: string, subvalue?: string) => {
    return (
      <>
        <Typography
          color={colors.routeText1}
          variant={'l1'}
          align={'left'}
          semibold
        >
          {title}
        </Typography>
        <Typography
          color={colors.routeText1}
          variant={'p2'}
          align={'left'}
        >
          {value}
        </Typography>
        {subvalue && (
          <Typography
            color={colors.routeText1}
            variant={'p2'}
            align={'left'}
          >
            {subvalue}
          </Typography>)
        }
      </>
    )
  }

  const refundInfo = () => {
    if (!issueStatusSummary.refundToEmail) return null;
    return (
      <React.Fragment>
        <InfoContainer>
          <div>
            <Typography
              color={colors.routeText1}
              variant={'l1'}
              align={'left'}
              semibold
            >
              {TypographyContent.refundPayPal}
            </Typography>

            <VerticalSeparator distance={8} />

            <Typography color={colors.routeText1} variant={'p2'} align={'left'}>
              {TypographyContent.refundEmail(issueStatusSummary.refundToEmail)}
            </Typography>

            <VerticalSeparator distance={8} />

            <Icon src={SecuredByPaypal} />
          </div>
        </InfoContainer>
        {defaultSeparator()}
      </React.Fragment>
    );
  };

  const prepaidCardInfo = () => {
    const prepaidEmail = issueStatusSummary.prepaidCreditCardRecipient;
    const prepaidName = issueStatusSummary.prepaidCreditCardName;
    if (!prepaidEmail || !prepaidName) return null;
    return genericInfo(TypographyContent.prepaidCardInfo, prepaidEmail, prepaidName);
  };

  const giftCardMerchantInfo = () => {
    const giftCardEmail = orderStore.routeOrder?.order.email ?? '';
    return (
      <>
        <Typography
          color={colors.routeText1}
          variant={'l1'}
          align={'left'}
          semibold
        >
          {TypographyContent.giftCardInfo}
        </Typography>
        <LineRow>
          <Typography
            color={colors.routeText1}
            variant={'p2'}
            align={'left'}
          >
            {giftCardEmail}
          </Typography>
          <GiftCardDisclaimerTooltip
            isOpen={popUpOpen}
            setIsOpen={(b) => { issueStore.setPopUpOpen(b) }}
            email={orderStore.routeOrder?.order.email ?? 'your email'}
            merchant={orderStore.routeOrder?.merchant.name ?? 'the Merchant'}
          />
        </LineRow>
      </>
    )
  };

  const venmoInfo = () => {
    const value = issueStatusSummary.refundToEmail ?? issueStatusSummary.refundToPhoneNumber ?? issueStatusSummary.refundToUserHandle;
    if (!value) return null;
    return genericInfo(TypographyContent.venmoAccount, value);
  };

  const resolutionPreferenceInfo = () => {
    if (!issueStatusSummary?.customerPreferredResolution) {
      return '';
    }

    return (
      <React.Fragment>
        <Typography
          color={colors.routeText1}
          variant={'l1'}
          align={'left'}
          semibold
        >
          {TypographyContent.preferredResolutionTitle}
        </Typography>
        <Typography color={colors.routeText1} variant={'p2'} align={'left'}>
          {TypographyContent.resolutionType(issueStatusSummary?.customerPreferredResolution)}
        </Typography>
        <br />
      </React.Fragment>
    );
  };

  return (
    <BlurredWrapper open>
      <ForegroundContentPage
        navigationHandle={closeHandle}
        itemImage={
          issueStatusSummary.issueItem[0].imageUrl ??
          fallBackLogo(issueStatusSummary.issueStatusHeader)
        }
        itemLabel={
          issueStatusSummary.storeName +
          ' ' +
          issueStatusSummary.issueItem[0].name
        }
        itemQuantity={issueStatusSummary.issueItem.length}
      >
        <InfoContainer>
          <div>
            <StatusInfo>
              <Typography
                color={colors.routeText1}
                variant={'l1'}
                align={!mobile ? 'left' : 'center'}
                semibold
              >
                <StatusIconCentered src={selectIcon()} verticalAlign="middle" />{' '}
                {issueStatusSummary.issueStatusHeader}
              </Typography>

              <VerticalSeparator distance={8} />

              <Typography
                color={colors.routeText1}
                variant={'p2'}
                align={!mobile ? 'left' : 'center'}
              >
                {issueStatusSummary.issueStatusReason}
              </Typography>
            </StatusInfo>
          </div>
        </InfoContainer>

        {defaultSeparator()}

        {issueStatusSummary?.customerPreferredResolution === ResolutionEnum.REFUND_PAYPAL && refundInfo()}
        {issueStatusSummary?.customerPreferredResolution === ResolutionEnum.REPLACE && replaceInfo()}

        <InfoContainer>
          <GrayedBackground>
            <div>
              <ClaimContent>
                <Typography
                  color={colors.routeText1}
                  variant={'l1'}
                  align={!mobile ? 'left' : 'center'}
                  semibold
                >
                  {TypographyContent.claimDetails}
                </Typography>

                <VerticalSeparator distance={18} />

                <OrderItemsContainer>
                  <OrderItemIcon>
                    <ItemIcon
                      imageUrl={
                        issueStatusSummary.issueItem[0].imageUrl ??
                        fallBackLogo(issueStatusSummary.issueStatusHeader)
                      }
                      alt={`item`}
                      overrideSize={!mobile ? 56 : 56}
                      overrideRadius={!mobile ? 6 : 3}
                      overrideQuantitySize={22}
                      quantity={currentClaim?.claimItems?.length}
                      noStack={true}
                      noShadow={true}
                    />
                  </OrderItemIcon>

                  <OrderItemText>
                    <Typography
                      color={colors.routeText1}
                      variant={'l1'}
                      align={'left'}
                      semibold
                    >
                      {issueStatusSummary.storeName +
                        ' ' +
                        issueStatusSummary.issueItem[0].name}
                    </Typography>
                  </OrderItemText>
                </OrderItemsContainer>

                <br />

                <Typography
                  color={colors.routeText1}
                  variant={'l1'}
                  align={'left'}
                  semibold
                >
                  {TypographyContent.issueReported}
                </Typography>
                <Typography
                  color={colors.routeText1}
                  variant={'p2'}
                  align={'left'}
                >
                  {TypographyContent.claimTypeDefaultText(
                    issueStatusSummary.issueTypeSelected
                  )}
                </Typography>

                <br />

                {issueStatusSummary.description && (
                  <>
                    <React.Fragment>
                      <Typography
                        color={colors.routeText1}
                        variant={'l1'}
                        align={'left'}
                        semibold
                      >
                        {TypographyContent.notes}
                      </Typography>
                      <Typography
                        color={colors.routeText1}
                        variant={'p2'}
                        align={'left'}
                      >
                        {issueStatusSummary.description}
                      </Typography>
                    </React.Fragment>
                    <br />
                  </>
                )}

                {resolutionPreferenceInfo()}

                {issueStatusSummary?.customerPreferredResolution === ResolutionEnum.PREPAID_CARD && prepaidCardInfo()}
                {issueStatusSummary?.customerPreferredResolution === ResolutionEnum.REFUND_VENMO && venmoInfo()}
                {issueStatusSummary?.customerPreferredResolution === ResolutionEnum.GIFT_CARD_MERCHANT && giftCardMerchantInfo()}


                <ContinueButton />
              </ClaimContent>
            </div>
          </GrayedBackground>

          <VerticalSeparator distance={20} />
          <Separator />
          <Row>
            <TranslatedIcon src={InfoIcon} />
            <TextContainer>
              <Typography variant="p2" color={colors.routeText2}>
                {TypographyContent.support}
              </Typography>
              <Link
                href={Links.helpCenter}
                target="_blank"
                rel="external noopener noreferrer"
                onClick={() => broadCastAction(ACTIONS.NAVIGATE_EXTERNAL)}
              >
                <Typography
                  variant="l1"
                  semibold
                  color={colors.routeText1}
                  underline
                  underlineOffset={3}
                >
                  {TypographyContent.viewHelpCenter}
                </Typography>
              </Link>
            </TextContainer>
          </Row>
        </InfoContainer>
      </ForegroundContentPage>
    </BlurredWrapper>
  );
});

export default IssueSummary;
