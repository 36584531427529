import styled from 'styled-components';
import { aboveSize } from '../../../../utils/Breakpoints';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-self: stretch;
  overflow-x: hidden;
  padding: 16px;
`;

export const Row = styled.div`
  display: flex;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  flex-grow: 1;
  margin-bottom: 32px;
`;

export const CloseContainer = styled.div`
  z-index: 2;
`;
export const TitleContainer = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  margin-left: 12px;
  ${aboveSize.md`
    justify-content: center;
    margin-left: -12px;
  `}
`;

export const ForwardIn = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  align-items: stretch;

  @keyframes TRANSITION-IN {
    0% {
      -webkit-transform: scale(0.5);
      opacity: 0;
      -webkit-filter: blur(50px);
    }
    100% {
      -webkit-transform: scale(1);
      -webkit-filter: blur(0px);
    }
  }

  animation-name: TRANSITION-IN;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
`;
