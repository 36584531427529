import { ACTIONS } from "../../../constants/actions";
import { broadCastAction } from "../../../services/broadcast";
import { TextLink } from "../../../styles/global.styles";

const POLICY_TEXT = "Route’s claim policy";

const ROUTE_CLAIM_POLICY_LINK = 'https://shoppers.help.route.com/hc/en-us/articles/6027133429143-Where-is-my-Order-';

export const replaceClaimPolicyLink = (text: string): React.ReactNode => {
    if (!text.includes(POLICY_TEXT)) return text;
    const parts = text.split(POLICY_TEXT);
    if (parts.length !== 2) return text; // don't break text if something comes different from BE

    return (
        <>
            {parts[0]}<TextLink href={ROUTE_CLAIM_POLICY_LINK} target="_blank" onClick={() => broadCastAction(ACTIONS.NAVIGATE_EXTERNAL)}>{POLICY_TEXT}</TextLink>{parts[1]}
        </>
    )
}