export const TypographyContent = {
  tracking: (number: string | null): string => {
    return `Tracking #: ${number ?? ''}`;
  },
  visitHelpCenter: `Still need help? Visit our `,
  helpCenter: 'Help Center',
  forMoreInfo: ' for more information.',
};

export const ButtonContent = {
  okay: 'Okay',
};

export const HelpCenterLink = 'https://shoppers.help.route.com/hc/en-us/articles/6017831679511';