import React, { FC } from 'react';
import { Icon } from '../../../styles/global.styles';
import { IPhotoThumbnailProps } from './interface';
import DeleteIcon from '../../../assets/icons/delete-icon.svg';

import { Thumbnail } from './PhotoThumbnail.styles';
import Button from '../../Buttons/Button';
import { colors } from '../../../constants/styles';

const PhotoThumbnail: FC<IPhotoThumbnailProps> = (
  props: IPhotoThumbnailProps
) => {
  const { imageUrl, deleteClick } = props;
  return (
    <Thumbnail
      className={props.className}
      imageUrl={imageUrl}
      data-testid="thumbnail"
    >
      {deleteClick && (
        <Button
          overrideSizeIcon="24px"
          mode="light"
          overrideBackground={colors.white}
          icon={<Icon src={DeleteIcon} />}
          onClick={deleteClick}
        />
      )}
    </Thumbnail>
  );
};

export default PhotoThumbnail;
