import React, { FC, useState } from 'react';
import { fallBackLogo } from '../../../utils';
import { IMerchantLogo } from './interface';
import Logo from './MerchantLogo.styles';

const MerchantLogo: FC<IMerchantLogo> = (props: IMerchantLogo) => {
  //call mobile api for the default logo image based off first letter of merchant's name
  const defaultImageUrl = `${process.env.REACT_APP_API_URL}/v1/logo/${props.alt}`;

  const [src, setSrc] = useState(props.src ?? fallBackLogo(props.name));
  return (
    <Logo src={src} alt={props.alt} onError={() => setSrc(defaultImageUrl)} big={props.big} />
  );
};

export default MerchantLogo;
