import React, { FC } from 'react';
import { IForegroundContentPageProps } from './interface';
import {
  Container,
  Wrapper,
  CustomCentralized,
  CustomPaper,
  TopInfo,
  ItemContent,
  BottomInfo,
  Store,
  ScrollContainer,
  FullSlide,
  NavigationButtons,
  ButtonContainer,
  RightButton,
  Grid,
  NavButton,
  NavText,
} from './ForegroundContentPage.styles';
import Button from '../Buttons/Button';
import { colors } from '../../constants/styles';
import CloseIcon from '../../assets/icons/close-icon.svg';
import { isMobileView } from '../../utils/Breakpoints';
import ItemIcon from '../IconOrLogos/ItemIcon';
import { Icon, VerticalSeparator } from '../../styles/global.styles';
import Typography from '../UI/Typography';
import CleanButton from '../Buttons/CleanButton';

const ForegroundContentPage: FC<IForegroundContentPageProps> = (
  props: IForegroundContentPageProps
) => {
  const {
    children,
    navigationHandle,
    navigationIconLabel,
    navigationIcon,
    isRefund,
    itemImage,
    itemQuantity,
    itemLabel,
    rightButtonHandle,
    rightButtonText,
    rightButtonFontSize,
    rightButtonFontWeight,
    rightButtonColor,
    fullHeight,
    noGlobalNav = false,
    contentHeaderTitle,
  } = props;

  const mobile = isMobileView();

  const renderContent = () => {
    if (!noGlobalNav) {
      return (
        <ScrollContainer
          data-id="foreground-bottom-scroll"
          fullHeight={fullHeight}
        >
          {children}
        </ScrollContainer>
      );
    }

    return (
      <Grid>
        <NavButton>
          <Button
            mode="light"
            overrideBackground={colors.transparent}
            ariaLabel={navigationIconLabel}
            onClick={navigationHandle}
            icon={<Icon src={navigationIcon} verticalAlign="middle" />}
          />
        </NavButton>
        <NavText>
          <Typography variant="h5" color={colors.routeText1} align="center">
            {contentHeaderTitle}
          </Typography>
        </NavText>
        <ScrollContainer
          data-id="foreground-bottom-scroll"
          fullHeight={fullHeight}
          noGlobalNav
        >
          {children}
        </ScrollContainer>
      </Grid>
    );
  };

  return (
    <Wrapper>
      <CustomCentralized>
        <FullSlide transition={props.transition}>
          <CustomPaper>
            <Container>
              {!noGlobalNav && (
                <NavigationButtons>
                  <ButtonContainer>
                    <Button
                      mode="light"
                      overrideBackground={colors.routeButtonFill5}
                      ariaLabel={navigationIconLabel ?? 'close'}
                      onClick={navigationHandle}
                      icon={
                        <Icon
                          src={navigationIcon ?? CloseIcon}
                          verticalAlign="middle"
                        />
                      }
                      overrideSizeIcon={!mobile ? '50px' : '40px'}
                    />

                    {rightButtonHandle && (
                      <RightButton>
                        <CleanButton
                          onClick={rightButtonHandle}
                          overrideFontSize={rightButtonFontSize}
                          overrideFontWeight={rightButtonFontWeight}
                          overrideTextColor={rightButtonColor}
                        >
                          {rightButtonText}
                        </CleanButton>
                      </RightButton>
                    )}
                  </ButtonContainer>
                </NavigationButtons>
              )}

              <TopInfo noGlobalNav={noGlobalNav}>
                {!isRefund && (
                  <React.Fragment>
                    <ItemContent>
                      <ItemIcon
                        imageUrl={itemImage ?? ''}
                        alt={`item`}
                        overrideSize={!mobile ? 237 : 116}
                        overrideRadius={!mobile ? 12 : 3}
                        overrideQuantitySize={40}
                        quantity={itemQuantity}
                        noStack={false}
                      />

                      <VerticalSeparator distance={!mobile ? 32 : 24} />

                      <Store>
                        <Typography
                          align={'center'}
                          color={colors.routeText1}
                          variant={'p3'}
                        >
                          {itemLabel}
                        </Typography>
                      </Store>
                    </ItemContent>
                  </React.Fragment>
                )}
              </TopInfo>

              <BottomInfo
                data-id="foreground-bottom-container"
                noGlobalNav={noGlobalNav}
              >
                {renderContent()}
              </BottomInfo>
            </Container>
          </CustomPaper>
        </FullSlide>
      </CustomCentralized>
    </Wrapper>
  );
};

export default ForegroundContentPage;
