import { observer } from 'mobx-react';
import { FC, useEffect } from 'react';
import Typography from '../../components/UI/Typography';
import { useStores } from '../../hooks/app.hook';
import {
  Banner,
  BannerContainer,
  Column,
  Container,
  CustomCentralized,
  MerchantContainer,
  MoreInfoContainer,
} from './OrderIssueAction.styles';
import { TypographyContents, Links } from './OrderIssueAction.constants';
import ActionItems from '../../components/ActionItems';
import OrderDetails from '../../components/OrderDetails';
import MerchantLogo from '../../components/IconOrLogos/MerchantLogo';
import GeneralInfo from '../../components/GeneralInfo';
import { MerchantContactTypeEnum } from '../../models/Enums/OrderEnum.model';
import { DateFormatterLong } from '../../utils';
import { addressArray } from '../../components/OrderDetails/OrderDetails.utils';
import Footer from '../../components/UI/Footer';
import { isMobileView } from '../../utils/Breakpoints';
import { colors } from '../../constants/styles';
import { GroupedItem } from '../../models/GroupedItems.model';
import { analyticsCodes, logPageView } from '../../services/analytics';
import { VerticalSeparator } from '../../styles/global.styles';
import { OrderClaim } from '../../models/OrderDetails.model';
import { broadcastView } from '../../services/broadcast';
import { VIEWS } from '../../constants/views';

const OrderIssueAction: FC = observer(() => {
  const {
    orderStore,
    routingStore,
    claimStore,
    itemStore,
    issueStore,
    claimBlockingStore,
  } = useStores();
  const mobile = isMobileView();
  if (!orderStore.routeOrder) {
    // never reaching code, router won't allow to render without order
    routingStore.replace('/error');
    return null;
  }
  const { order: orderDetails, merchant } = orderStore.routeOrder;
  const { hasClaimedItems } = claimStore;
  const { hasIssue } = issueStore;
  const supportContacts = orderStore.getMerchantContacts(
    MerchantContactTypeEnum.SUPPORT
  );

  useEffect(() => {
    if (!hasClaimedItems && !hasIssue) {
      broadcastView(VIEWS.ORDER_SUMMARY);
    }
  }, [hasClaimedItems, hasIssue]);

  useEffect(() => {
    logPageView(analyticsCodes.resolveAccessed, window.location.pathname, {
      merchant: orderStore.merchantId,
    });
  }, [orderStore.merchantId]);

  const selectItemsToClaimHandle = (
    items: Array<GroupedItem>,
    displayItem: GroupedItem
  ) => {
    claimStore.reset();
    claimBlockingStore.reset();
    claimStore.setClaimedItems(items, displayItem);
    claimBlockingStore.setPreassessmentResult(displayItem.preassessmentResult);
    routingStore.push('/item');
  };

  const setClaim = (claim: OrderClaim) => {
    // action will only be available when items with claims and claim statuses are clicked
    if (claim.claimId && claim.claimStatusId) {
      issueStore.setIssue(claim);
      routingStore.push('/issue-summary');
    }
  };

  const displayEmailSubtitle = () =>
    itemStore.orderClaims.length > 0
      ? mobile
        ? TypographyContents.subtitleMobile(orderDetails.email)
        : TypographyContents.subtitle(orderDetails.email)
      : null;

  const isDollsKillMerchant = (): boolean => {
    return orderStore.isDollsKillMerchant();
  };

  return (
    <Container>
      <BannerContainer>
        <Banner
          bannerUrl={merchant.heroImageURL}
          contained={isDollsKillMerchant()}
        />
      </BannerContainer>
      <MerchantContainer>
        <MerchantLogo
          src={merchant.imageURL}
          alt={`${merchant.name} logo`}
          name={merchant.name}
        />
      </MerchantContainer>
      <CustomCentralized>
        <Column>
          <Typography variant="h1" color={colors.routeText1}>
            {TypographyContents.title}
          </Typography>
          <VerticalSeparator distance={8} />
          <Typography variant="p1" color={colors.routeText1} align="center">
            {displayEmailSubtitle()}
          </Typography>
        </Column>
        <ActionItems
          claimedItems={itemStore.orderClaims}
          shipmentIssueItems={itemStore.orderItemsGroupedShipmentAndItems}
          shipmentIssueItemsNotInsured={
            itemStore.orderItemsGroupedShipmentAndItemsNotInsured
          }
          selectItemsToClaimHandle={selectItemsToClaimHandle}
          selectClaim={setClaim}
        />
        <MoreInfoContainer>
          <OrderDetails
            sourceOrderNumber={orderDetails.sourceOrderNumber}
            orderDate={DateFormatterLong(orderDetails.createdOn)}
            email={orderDetails.email}
            shippingAddress={addressArray(orderDetails.shippingDetails, true)}
            estimatedDelivery={DateFormatterLong(
              orderStore.getNearestEstimatedDelivery()?.toDateString() ?? ''
            )}
            customerName={`${orderDetails.firstName} ${orderDetails.lastName}`}
            extended
            copyDisabled
            titleVariant="h5"
            titleSemibold={false}
            titleColor={colors.routeText1}
            title={TypographyContents.orderDetails}
          />
          <br />
          <br />

          <GeneralInfo
            merchantPhone={supportContacts?.phone}
            supportEmail={supportContacts?.email}
            supportSite={supportContacts?.websiteUrl}
            viewHelpCenterURL={
              orderStore.isHibbettMerchant()
                ? Links.hibbettHelpCenter
                : undefined
            }
          />
        </MoreInfoContainer>
      </CustomCentralized>
      {!mobile && <Footer />}
    </Container>
  );
});

export default OrderIssueAction;
