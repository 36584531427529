import noTrackingImg from './NoTrackImg.png';
export const TypographyContent = {
  title: `Where's My Package`,
  noTrackTitle: 'Waiting for tracking',
  noTrackDesc: `Keep in mind, this could be due to the item being backordered or the merchant is experiencing high order volume.

  As such, we cannot yet process your claim.`,
  noTrackingTooEarlyDesc:
    'Your order is waiting to ship. Sit back and relax while it’s be prepped.',
  noTrackImg: noTrackingImg,
  noTrackingAlt: 'No tracking available',
};

export const ButtonContent = {
  exit: 'Back to Resolve Center',
  report: 'Report a problem',
};
