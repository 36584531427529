/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../../../../hooks/app.hook';
import PayPalRefund from '../../../../components/PayPalRefund';
import BackIcon from '../../../../assets/icons/back-icon.svg';
import ForegroundContentPage from '../../../../components/ForegroundContentPage';
import { VIEWS } from '../../../../constants/views';
import { broadCastAction, broadcastView } from '../../../../services/broadcast';
import { ACTIONS } from '../../../../constants/actions';

const ClaimedItemsPaypalEmailLanding: FC = observer(() => {
  const { routingStore, claimStore, orderStore } = useStores();
  const { displayItem: item } = claimStore;
  const { routeOrder } = orderStore;

  if (!item || !routeOrder) {
    //never reaching code
    return null;
  }

  useEffect(() => {
    broadcastView(VIEWS.CLAIM_ITEM_PAYPAL_LANDING)
  }, [])

  const backClickHandle = () => {
    broadCastAction(ACTIONS.NAVIGATE_BACK_CLICK);
    routingStore.goBack();
  };

  const nextPage = () => {
    routingStore.push('/item/resolution/paypal-email');
  };

  return (
    <ForegroundContentPage
      navigationHandle={backClickHandle}
      navigationIconLabel={'back'}
      navigationIcon={BackIcon}
      itemImage={item.item.imageUrl ?? ''}
      itemLabel={orderStore.getMerchantName() + ' ' + item.item.name}
      itemQuantity={claimStore.claimedItems.reduce(
        (acc, items) => acc + items.item.quantity,
        0
      )}
    >
      <PayPalRefund showEmail={false} email={''} onClick={nextPage} />
      <br />
    </ForegroundContentPage>
  );
});

export default ClaimedItemsPaypalEmailLanding;
