import React, { FC } from 'react';
import { IForegroundDualContentPageProps } from './interface';
import {
  Container,
  Wrapper,
  CustomCentralized,
  CustomPaper,
  TopContainerButton,
  ScrollContainer,
  FullSlide,
  Content,
  Line,
} from './ForegroundDualContentPage.styles';
import Button from '../Buttons/Button';
import { colors } from '../../constants/styles';
import CloseIcon from '../../assets/icons/close-icon.svg';
import { isMobileView } from '../../utils/Breakpoints';
import { Icon } from '../../styles/global.styles';

const ForegroundDualContentPage: FC<IForegroundDualContentPageProps> = (
  props: IForegroundDualContentPageProps
) => {
  const {
    children,
    navigationHandle,
    navigationIconLabel,
    navigationIcon,
  } = props;

  const mobile = isMobileView();

  const childrenTop = children?.[0];
  const childrenBottom = children?.[1];

  return (
    <Wrapper>
      <CustomCentralized>
        <FullSlide transition={props.transition}>
          <CustomPaper>
            <Container>
              <Content>
                <TopContainerButton>
                  <Button
                    mode="light"
                    overrideBackground={colors.lightGrayButtonBackground}
                    ariaLabel={navigationIconLabel ?? 'close'}
                    onClick={navigationHandle}
                    icon={
                      <Icon
                        src={navigationIcon ?? CloseIcon}
                        verticalAlign="middle"
                      />
                    }
                    overrideSizeIcon={!mobile ? '50px' : '40px'}
                  />
                </TopContainerButton>

                {childrenTop}
              </Content>

              <Line />

              <Content data-id="foreground-bottom-container">
                <ScrollContainer data-id="foreground-bottom-scroll">
                  {childrenBottom}
                </ScrollContainer>
              </Content>
            </Container>
          </CustomPaper>
        </FullSlide>
      </CustomCentralized>
    </Wrapper>
  );
};

export default ForegroundDualContentPage;
