import styled, { css } from 'styled-components';
import { colors } from '../../../constants/styles';

export interface ISwitch {
  active: boolean;
}

export const Switch = styled.label`
  text-align: left;
  width: 52px;
  height: calc(52px / 2);
  border-radius: 30%;
  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: ${colors.divider};
`;

export const Filler = styled.span`
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 60px;
  box-shadow: ${(props: ISwitch) => {
    return props.active
      ? `0 0 0 1.5px ${colors.routeButtonFill1}`
      : `0 0 0 1.5px ${colors.divider}`;
  }};
  background-color: ${(props: ISwitch) => {
    return props.active ? colors.routeButtonFill1 : `${colors.routeButtonFill1}00`;
  }};

  &:before,
  &:after {
    content: '';
    cursor: pointer;
    position: absolute;
  }

  &:before {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background-color: ${colors.divider};
    border-radius: 60px;
    transition: opacity 0.2s ease-out 0.1s, transform 0.2s ease-out 0.1s;
    transform: scale(1);
    opacity: 1;

    ${(props: ISwitch) => {
      return props.active
        ? css`
            transform: scale(0);
            opacity: 0.7;
          `
        : '';
    }}
  }
  &:after {
    top: 50%;
    z-index: 30;
    transition: transform 0.4s cubic-bezier(0.44, -0.12, 0.07, 1.15);
    width: calc(52px / 2);
    height: calc(52px / 2);
    transform: ${(props: ISwitch) => {
      return props.active
        ? ' translate3d(100%, -50%, 0)'
        : 'translate3d(0, -50%, 0)';
    }};
    background-color: #fff;
    border-radius: 100%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  }
`;

export const Input = styled.input`
  opacity: 0;
  position: absolute;
  &:focus {
    box-shadow: 0 0 0 4px ${colors.routeButtonFill1};
  }
`;
