import styled, { keyframes } from 'styled-components';
import { aboveSize, belowSize } from '../utils/Breakpoints';
import { ISeparator } from './interface';
import { colors } from '../constants/styles';

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const Centralized = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-grow: 1;
  padding: 0 18%;

  ${belowSize.md`
    padding: 0 2.25%;
  `};
  ${aboveSize.xg`
    padding: 0 25%;
  `};
`;

export interface IIconProps {
  verticalAlign?: string;
  overrideSize?: number;
}

export const Icon = styled.img`
  vertical-align: ${(props: IIconProps) => props.verticalAlign ?? 'unset'};
  height: ${(props: IIconProps) => `${props.overrideSize}px` ?? 'unset'};
  width: ${(props: IIconProps) => `${props.overrideSize}px` ?? 'unset'};
`;

export const ForwardIn = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  align-items: stretch;

  @keyframes TRANSITION-IN {
    0% {
      -webkit-transform: scale(0.5);
      opacity: 0;
      -webkit-filter: blur(50px);
    }
    100% {
      -webkit-transform: scale(1);
      -webkit-filter: blur(0px);
    }
  }

  animation-name: TRANSITION-IN;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
`;

export const slideLeftKeys = keyframes`
    from {
      -webkit-transform: translateX(100%);
      -webkit-transform:  translateX(100%);
      -ms-transform:  translateX(100%);
      -moz-transform: translateX(100%);
      -o-transform: translateX(100%);
      transform: translateX(100%);
    }
    to {

      -webkit-transform: translateX(0%);
      -webkit-transform:  translateX(0%);
      -ms-transform:  translateX(0%);
      -moz-transform: translateX(0%);
      -o-transform: translateX(0%);
      transform: translateX(0%);
    }
`;

export const SlideLeft = styled.div`
  /* transform: translateX(100%);
  -webkit-transform: translateX(100%); */
  animation: ${slideLeftKeys} 0.5s forwards;
  -webkit-animation: ${slideLeftKeys} 0.5s forwards;
`;

export const NoSlide = styled.div`
  /* transform: translateX(100%);
  -webkit-transform: translateX(100%); */
  animation: ${slideLeftKeys} 0s forwards;
  -webkit-animation: ${slideLeftKeys} 0s forwards;
`;

export const slideUpKeys = keyframes`
    from {
      transform: translateY(100%);
      -webkit-transform:  translateY(100%);
      -ms-transform:  translateY(100%);
      -moz-transform: translateY(100%);
      -o-transform: translateY(100%);
    }
    to {
      transform:  translateY(0%);
      -webkit-transform: translateY(0%);
      -ms-transform:  translateY(0%);
      -moz-transform: translateY(0%);
      -o-transform: translateY(0%);
    }
`;

export const SlideUp = styled.div`
  display: flex;
  transform-origin: 50% 100%;
  -webkit-transform-origin: 50% 100%;

  animation: ${slideUpKeys} 0.5s forwards;
  -webkit-animation: ${slideUpKeys} 0.5s forwards;
`;

export const VerticalSeparator = styled.div<ISeparator>`
  margin: ${(props: ISeparator) => props.distance / 2}px 0;
`;

export const HorizontalSeparator = styled.div<ISeparator>`
  margin: 0 ${(props: ISeparator) => props.distance / 2}px;
`;

export const TextLink = styled.a`
  color: ${colors.routeText2};
  font-weight: 600;
`
