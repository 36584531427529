import { observable, makeObservable, action } from 'mobx';
import { RootStore } from './RootStore';
import { Ref } from 'react';

export interface IAnimationStore {
  anchorElement: Ref<HTMLDivElement> | null;
}

export class AnimationStore implements IAnimationStore {
  [key: string]: unknown;
  private rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
  }

  @observable anchorElement: Ref<HTMLDivElement> | null = null;

  @action setAnimationTarget(element: Ref<HTMLDivElement>): void {
    this.anchorElement = element;
  }

  @action reset(): void {
    this.anchorElement = null;
  }
}
