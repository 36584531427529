import { observable, action, makeObservable } from 'mobx';
import { RootStore } from '../RootStore';
import * as api from '../../services/api';
import { RouterStore } from 'mobx-react-router';

export class FindOrderErrors {
  constructor() {
    makeObservable(this);
  }
  [key: string]: boolean;
  @observable number = false;
  @observable email = false;
}

export interface IFindOrderStore {
  loading: boolean;
  number: string;
  email: string;
  errors?: FindOrderErrors;
}

export class FindOrderStore implements IFindOrderStore {
  [key: string]: unknown;
  private rootStore: RootStore;
  private routingStore: RouterStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.routingStore = rootStore.routingStore;
    makeObservable(this);
  }

  @observable loading = false;

  @observable number = '';
  @observable email = '';

  @observable errors = new FindOrderErrors();

  @action set(key: string, value: string): void {
    this[key] = value;
    this.resetSpecificError(key);
  }

  @action setErrors(key: string, value: boolean): void {
    this.errors[key] = value;
  }

  @action resetSpecificError(key: string): void {
    this.errors[key] = false;
  }

  @action resetErrors(): void {
    this.errors = new FindOrderErrors();
  }

  @action startLoading(): void {
    this.loading = true;
  }

  @action stopLoading(): void {
    this.loading = false;
  }

  @action reset(): void {
    this.loading = false;
    this.email = '';
    this.number = '';
    this.resetErrors();
  }

  @action setLocalStorage(): void {
    this.rootStore.orderStore.setOrderBasicInfo(
      this.email.trim(),
      this.number.trim(),
      this.rootStore.commonStore.source
    );
  }

  @action async findOrderIssueDetails(): Promise<boolean> {
    this.startLoading();

    const { orderStore, commonStore } = this.rootStore;
    try {
      const rep = await api.getOrder(this.email, this.number, this.rootStore.commonStore.shieldSession);
      if (rep.status === 200 && rep.data) {
        orderStore.setOrder(rep.data);
        this.setUser(this.email);
        this.routingStore.push('/order-information');
        return true;
      } else if (rep.status && rep.status >= 500) {
        commonStore.displayError();
      } else {
        this.setErrors('number', true);
        this.setErrors('email', true);
      }
    } catch (e) {
      this.rootStore.commonStore.displayError();
    }
    this.stopLoading();
    return false;
  }

  setUser(email: string): void {
    const identityRequest = {
      userIdentities: { email },
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function identityCallback(result: any) {
      if (result.getUser()) {
        //IDSync request succeeded, mutate attributes or query for the MPID as needed
        return;
      }
      // @ts-ignore
      const codes = window.mParticle.Identity.HTTPCodes;

      switch (result.httpCode) {
        case codes.noHttpCoverage:
          // retry the IDSync request
          // @ts-ignore
          window.mParticle.Identity.login(identityRequest, identityCallback);
          break;
        case codes.activeIdentityRequest:
        case 429:
          //inspect your implementation if this occurs frequency
          //otherwise retry the IDSync request
          // @ts-ignore
          window.mParticle.Identity.login(identityRequest, identityCallback);
          break;
        default:
          console.log(result.body);
      }
    }
    if (process.env.NODE_ENV === 'production') {
      // @ts-ignore
      window.mParticle.Identity.login(identityRequest, identityCallback);
    }
  }
}
