import React, { FC } from 'react';
import { IGroupedItemQuantityRowProps } from './interface';
import {
  NameContainer,
  QuantityEditorContainer,
  Row,
  TargetQuantityContainer,
} from './GroupedItemQuantityRow.styles';
import ItemIcon from '../IconOrLogos/ItemIcon';
import Typography from '../UI/Typography';
import { colors } from '../../constants/styles';
import Button from '../Buttons/Button';
import PlusIcon from '../../assets/icons/bigger-plus-icon.svg';
import MinusIcon from '../../assets/icons/minus-icon.svg';
import { Icon } from '../../styles/global.styles';
import { observer } from 'mobx-react';

const GroupedItemQuantityRow: FC<IGroupedItemQuantityRowProps> = observer(
  (props: IGroupedItemQuantityRowProps) => {
    const { item, targetQuantity, subtractClickHandle, addClickHandle } = props;

    return (
      <Row>
        <ItemIcon
          noStack
          quantity={item.instanceItemsQuantity}
          imageUrl={item.imageUrl}
          alt={`icon of ${item.name}`}
        />
        <NameContainer>
          <Typography variant="p5" semibold color={colors.routeText1}>
            {item.name}
          </Typography>
        </NameContainer>
        <QuantityEditorContainer>
          <Button
            mode="light"
            overrideBackground={colors.white}
            icon={
              <Icon
                src={MinusIcon}
                alt="subtract icon"
                verticalAlign="middle"
              />
            }
            shadowed
            disabled={targetQuantity === 1}
            onClick={subtractClickHandle}
            testId="minus-button"
          />
          <TargetQuantityContainer>
            <Typography color={colors.routeText1} variant="p6" semibold>
              {targetQuantity}
            </Typography>
          </TargetQuantityContainer>
          <Button
            mode="light"
            overrideBackground={colors.white}
            icon={<Icon src={PlusIcon} alt="add icon" verticalAlign="middle" />}
            shadowed
            disabled={targetQuantity === item.instanceItemsQuantity}
            onClick={addClickHandle}
            testId="plus-button"
          />
        </QuantityEditorContainer>
      </Row>
    );
  }
);

export default GroupedItemQuantityRow;
