import { css, FlattenSimpleInterpolation } from 'styled-components';
import { AnimationType, IAnimation } from './animationTypes';
import {
  getItemSelectOptionButtonAnimation,
  getItemSelectOptionIconAnimation,
  getItemSelectOptionTextAnimation,
} from './itemSelect/itemSelectAnimation.styles';

const none = css``;

export enum AnimatedElement {
  Button,
  Icon,
  Text,
}

export function getElementAnimation(
  animatedElement: AnimatedElement,
  animation?: IAnimation
): FlattenSimpleInterpolation {
  if (!animation) {
    return none;
  }
  switch (animatedElement) {
    case AnimatedElement.Button:
      switch (animation.type) {
        case AnimationType.itemSelect:
          return getItemSelectOptionButtonAnimation(animation.index || 0);
        default:
          return none;
      }
    case AnimatedElement.Icon:
      switch (animation.type) {
        case AnimationType.itemSelect:
          return getItemSelectOptionIconAnimation(animation.index || 0);
        default:
          return none;
      }
    case AnimatedElement.Text:
      switch (animation.type) {
        case AnimationType.itemSelect:
          return getItemSelectOptionTextAnimation(animation.index || 0);
        default:
          return none;
      }
    default:
      return none;
  }
}
