import styled from 'styled-components';
import { colors } from '../../../constants/styles';
import { SlideLeft } from '../../../styles/global.styles';

export const Container = styled(SlideLeft)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-self: stretch;
  margin: 10px 0px 0px 0px;
  overflow-x: hidden;
`;

export const ClickableContainer = styled.div`
  display: flex;
  padding: 8px;
  align-items: center;
  cursor: pointer;

  background: ${colors.routeButtonFill4};
  border-radius: 6px;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  background: rgba(255, 255, 255, 0);
  border-radius: 5px;
  margin-right: 12px;
  min-width: 34px;
  min-height: 34px;
`;
