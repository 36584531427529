export enum DeliveryStatusEnum {
  DELIVERED = 'Delivered',
  AVAILABLE_FOR_PICKUP = 'AvailableForPickup',
  CREATED = 'Created',
  NOT_ACTIVE = 'NotActive',
  NOT_AVAILABLE = 'NotAvailable',
  ACTIVE = 'Active',
  REGISTERED = 'Registered',
  EXCEPTION = 'Exception',
  EXPIRED = 'Expired',
  UNKNOWN_ERROR = 'UnknownError',
  UNKNOWN_CARRIER = 'UnknownCarrier',
  CANCELLED = 'Cancelled',
  PENDING = 'Pending',
  INFO_RECEIVED = 'InfoReceived',
  IN_TRANSIT = 'InTransit',
  OUT_FOR_DELIVERY = 'OutForDelivery',
  ATTEMPT_FAIL = 'AttemptFail',
  RETURN_TO_SENDER = 'ReturnToSender',
}

export const deliveryStatusLocalized = (e: DeliveryStatusEnum): string => {
  switch (e) {
    case DeliveryStatusEnum.DELIVERED:
      return 'Delivered';
    case DeliveryStatusEnum.AVAILABLE_FOR_PICKUP:
      return 'Available for Pickup';
    case DeliveryStatusEnum.NOT_ACTIVE:
      return 'Finding Order Details...';
    case DeliveryStatusEnum.NOT_AVAILABLE:
      return 'Waiting for Tracking';
    case DeliveryStatusEnum.EXCEPTION:
      return 'Carrier Problem';
    case DeliveryStatusEnum.EXPIRED:
      return 'Tracking Expired';
    case DeliveryStatusEnum.UNKNOWN_ERROR:
      return 'Unknown Error';
    case DeliveryStatusEnum.UNKNOWN_CARRIER:
      return 'Carrier Not Supported';
    case DeliveryStatusEnum.CANCELLED:
      return 'Cancelled';
    case DeliveryStatusEnum.PENDING:
      return 'Shipping';
    case DeliveryStatusEnum.INFO_RECEIVED:
      return 'Info Received';
    case DeliveryStatusEnum.IN_TRANSIT:
      return 'In Transit';
    case DeliveryStatusEnum.OUT_FOR_DELIVERY:
      return 'Out for Delivery';
    case DeliveryStatusEnum.ATTEMPT_FAIL:
      return 'Attempt Fail';
    case DeliveryStatusEnum.RETURN_TO_SENDER:
      return 'Return to Sender';

    case DeliveryStatusEnum.CREATED:
    case DeliveryStatusEnum.ACTIVE:
    case DeliveryStatusEnum.REGISTERED:
    default:
      return 'Getting Info...';
  }
};
