import React, { FC, useState } from 'react';
import Typography from '../../UI/Typography';
import { Column, Container } from './OrderDetailsRow.styles';
import { IOrderDetailsRowProps } from './interface';
import CleanButton from '../../Buttons/CleanButton';
import { colors } from '../../../constants/styles';
import Tooltip from '../../UI/Tooltip/Tooltip';
import { TypographyContents } from '../OrderDetails.constants';

const OrderDetailsRow: FC<IOrderDetailsRowProps> = (
  props: IOrderDetailsRowProps
) => {
  const [copiedOpen, setCopiedOpen] = useState(false);

  const itemCopyText = () => {
    return `${props.title}: ${props.value}`;
  };

  const arrayRowValued = (arr: Array<string>) => {
    const filtered = arr.filter(str => {
      return str !== null && str !== '';
    });
    return filtered.map((val, i) => {
      return (
        <div key={i}>
          <Typography variant="p4" color={colors.routeText2}>
            {val}
          </Typography>
          <br />
        </div>
      );
    });
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(itemCopyText());
    if (props.onCopy) props.onCopy();
    setCopiedOpen(true);
    setTimeout(() => setCopiedOpen(false), 1500);
  };

  return (
    <Container>

      <Column>
        <Typography variant="l1" semibold color={colors.routeText1}>
          {props.title}
        </Typography>
        <Typography variant="p2" color={colors.routeText2}>
          {Array.isArray(props.value)
            ? arrayRowValued(props.value)
            : props.value}
        </Typography>
      </Column>
      {!props.copyDisabled && (
        <Tooltip text={TypographyContents.copied} open={copiedOpen} />
      )}
      {!props.copyDisabled && <CleanButton icon="copy" onClick={handleCopy} />}
    </Container>
  );
};

export default OrderDetailsRow;
