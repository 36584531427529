import React, { FC, useState } from 'react';
import { ISwitchButtonProps } from './interface';

import { Filler, Input, Switch } from './SwitchButton.styles';

const SwitchButton: FC<ISwitchButtonProps> = (props: ISwitchButtonProps) => {
  const [active, setActive] = useState(props.active ?? false);
  return (
    <Switch className={props.className}>
      <Input
        type="checkbox"
        onChange={() => {
          setActive(!active);
          props.setActive(!active);
        }}
      />
      <Filler active={active}></Filler>
    </Switch>
  );
};

export default SwitchButton;
