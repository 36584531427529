import styled from 'styled-components';
import {
  getElementAnimation,
  AnimatedElement,
} from '../../../animations/animationFactory';
import { IAnimation } from '../../../animations/animationTypes';

export const ClickableContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  cursor: pointer;
  &:hover {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
  }
`;

export interface IValueStyle {
  animation?: IAnimation;
}

export const Value = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;

  animation: ${(props: IValueStyle) =>
    getElementAnimation(AnimatedElement.Text, props.animation)};
`;
