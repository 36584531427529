/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import {
  Column,
  Container,
  DescriptionTypo,
  InputsContainer,
  LeftRow,
  Row,
  ShieldContainer,
  ShieldIcon,
  TermsOfServiceContainer,
  TextLink,
} from './ClaimedItemsPhoneVerification.styles';
import { colors } from '../../../constants/styles';
import { useStores } from '../../../hooks/app.hook';
import ForegroundContentHeader from '../../../components/ForegroundContentHeader';
import ForegroundContentModal from '../../../components/ForegroundContentModal';
import {
  ButtonContent,
  TypographyContent,
  InputContent,
  AltContent,
  LinkRef,
} from './ClaimedItemsPhoneVerification.constants';
import CallIcon from '../../../assets/icons/call-icon.svg';
import TextIcon from '../../../assets/icons/text-icon.svg';
import LoadingSpinner from '../../../components/LoadingSpinner';
import TextField from '../../../components/Inputs/TextField';
import Typography from '../../../components/UI/Typography';
import Button from '../../../components/Buttons/Button';
import { HorizontalSeparator, Icon } from '../../../styles/global.styles';
import { CountryCode } from 'libphonenumber-js';
import { VerificationType } from '../../../models/PhoneVerification.model';
import Snackbar from '../../../components/UI/Snackbar';
import CleanButton from '../../../components/Buttons/CleanButton';
import PhoneShield from '../../../assets/images/phone-shield.svg';
import { analyticsCodes, logEvent } from '../../../services/analytics';
import { broadCastAction, broadcastView } from '../../../services/broadcast';
import { VIEWS } from '../../../constants/views';
import { ACTIONS } from '../../../constants/actions';

const ClaimedItemsPhoneVerification: FC = observer(() => {
  const {
    routingStore,
    commonStore,
    claimStore,
    phoneVerificationStore,
  } = useStores();

  const {
    verificationSent,
    verificationCode,
    sendCodeLoading,
    verifyCodeLoading,
    loading,
    validPhone,
    errorPhone,
    errorVerificationCode,
    phoneInput,
    snackMessage,
  } = phoneVerificationStore;
  const item = claimStore.displayItem;

  useEffect(() => {
    phoneVerificationStore.loadCountryCodes();
    broadcastView(VIEWS.CLAIM_ITEM_PHONE_VERIFICATION)
    logEvent(analyticsCodes.screenView, {
      screen: analyticsCodes.phoneStarted,
      claimInitiatedFrom: commonStore.isMerchantSource()
        ? analyticsCodes.merchantPortal
        : analyticsCodes.resolveCenter,
    });
  }, []);

  if (!item) {
    commonStore.displayError();
    return null;
  }

  const handlePhoneInputChange = (val: string) => {
    phoneVerificationStore.phoneInputChange(val);
  };
  const handleCountrySelectChange = (val: CountryCode) => {
    phoneVerificationStore.countrySelectorChange(val);
  };
  const handleVerificationChange = (val: string) => {
    phoneVerificationStore.verificationInputChange(val);
  };

  const sendCodeHandle = (verificationType?: VerificationType) => {
    if (verificationType) {
      phoneVerificationStore.setVerificationType(verificationType);
    }

    phoneVerificationStore.sendVerificationCode();
  };

  const nextClickHandle = () => {
    phoneVerificationStore.verifyCode();
  };

  const closeClickHandle = () => {
    broadCastAction(ACTIONS.CANCEL_CLAIM_FILING);
    claimStore.reset();
    routingStore.replace('/order-information');
  };

  const backClickHandle = () => {
    broadCastAction(ACTIONS.NAVIGATE_BACK_CLICK);
    routingStore.goBack();
    phoneVerificationStore.reset();
  };

  const closeSnackbar = () => {
    phoneVerificationStore.removeSnackMessage();
  };

  const termsOfService = () => {
    return <Typography
      variant="p2"
      color={colors.routeText1}
      align="center"
    >
      {TypographyContent.termsOfService}<TextLink href={LinkRef.termsOfService} target="_blank" rel="noreferrer" onClick={() => broadCastAction(ACTIONS.NAVIGATE_EXTERNAL)}>Terms of Service</TextLink>{TypographyContent.dataRates}
    </Typography>
  }

  return (
    <>
      <Snackbar
        isOpen={snackMessage !== ''}
        message={snackMessage}
        closeHandle={closeSnackbar}
      />
      <ForegroundContentModal
        open
        mainButton={ButtonContent.next}
        mainButtonClick={nextClickHandle}
        mainButtonDisabled={
          !verificationSent || verificationCode === '' || verifyCodeLoading
        }
        secondaryButton={ButtonContent.cancel}
        secondaryButtonClick={closeClickHandle}
        radius={8}
      >
        <ForegroundContentHeader
          claimedItems={claimStore.claimedItems}
          // hasClaimedItemsSelected already checks nullable value
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          displayItem={item!}
          clickButtonHandle={backClickHandle}
          clickEditQuantityHandle={() => {
            claimStore.setEditingQuantity();
          }}
          title={TypographyContent.title}
          centered
        >
          {claimStore.loading || verifyCodeLoading ? (
            <LoadingSpinner />
          ) : (
            <Container>
              <Column>
                <DescriptionTypo variant="p2" color={colors.routeText2}>
                  {TypographyContent.description}
                </DescriptionTypo>
                <InputsContainer>
                  <div>
                    {loading ? (
                      <LoadingSpinner />
                    ) : (
                      <TextField
                        inputMode="numeric"
                        placeholder={InputContent.phoneNumber}
                        value={phoneInput}
                        onChange={handlePhoneInputChange}
                        phoneProps={{
                          value: phoneVerificationStore.selectedCountry?.abbrev,
                          selectItems: phoneVerificationStore.countryCodes,
                          onChange: handleCountrySelectChange,
                        }}
                        isError={errorPhone}
                        errorText={InputContent.invalidPhoneNumber}
                        toolTipText={InputContent.phoneTooltip}
                      />
                    )}
                    <TextField
                      placeholder={InputContent.verificationCode}
                      value={phoneVerificationStore.verificationCode}
                      onChange={handleVerificationChange}
                      isError={errorVerificationCode}
                      errorText={InputContent.invalidVerificationCode}
                      toolTipText={InputContent.verificationCodeTooltip}
                    />
                    {verificationSent ? (
                      <LeftRow>
                        <CleanButton
                          onClick={() => {
                            sendCodeHandle();
                          }}
                        >
                          <Row>{ButtonContent.resend}</Row>
                        </CleanButton>
                      </LeftRow>
                    ) : null}
                  </div>
                </InputsContainer>
                {sendCodeLoading ? (
                  <LoadingSpinner />
                ) : verificationSent ? (
                  (
                    <ShieldContainer>
                      <ShieldIcon
                        src={PhoneShield}
                        alt={AltContent.shieldIcon}
                      />
                    </ShieldContainer>
                  )
                ) : (
                  <Row>
                    <Button
                      fullWidth
                      disabled={!validPhone}
                      onClick={() => {
                        sendCodeHandle('call');
                        logEvent(analyticsCodes.tapped, {
                          screen: analyticsCodes.phoneCall,
                        });
                      }}
                    >
                      <Row>
                        <Icon src={CallIcon} />
                        {ButtonContent.call}
                      </Row>
                    </Button>
                    <HorizontalSeparator distance={10} />
                    <Button
                      fullWidth
                      disabled={!validPhone}
                      onClick={() => {
                        sendCodeHandle('sms');
                        logEvent(analyticsCodes.tapped, {
                          screen: analyticsCodes.phoneSms,
                        });
                      }}
                    >
                      <Row>
                        <Icon src={TextIcon} />
                        {ButtonContent.sms}
                      </Row>
                    </Button>
                  </Row>
                )}
                <TermsOfServiceContainer>

                  {termsOfService()}

                </TermsOfServiceContainer>
              </Column>
            </Container>
          )}
        </ForegroundContentHeader>
      </ForegroundContentModal>
    </>
  );
});

export default ClaimedItemsPhoneVerification;
