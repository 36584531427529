import styled from 'styled-components';
import { Icon } from '../../../styles/global.styles';
import { belowSize } from '../../../utils/Breakpoints';

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: left;
  align-content: left;
  // align-items: center;
`;

export const Separator = styled.div`
  border: 0.5px solid rgba(216, 216, 216, 0.7);
`;

export const StatusIconCentered = styled(Icon)`
  margin-top: -3px;
  margin-right: 5px;
`;

export const OrderItemsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
`;

export const OrderItemIcon = styled.div`
  display: flex;
  justify-content: flex-start;
  align-self: start;
`;

export const OrderItemText = styled.div`
  display: flex;
  justify-content: flex-start;
  align-self: center;
  padding-left: 10px;
`;

export const StatusInfo = styled.div`
    display: flex;
    flex-direction: column;
    // justify-content: center;
`;

export const GrayedBackground = styled.div`
  ${belowSize.md`
    border-radius: 17px;
    background: #0000000A;
  `}
`;

export const ClaimContent = styled.div`
  display: flex;
  flex-direction: column;
  ${belowSize.md`
    margin: 32px;
  `}
`;

export const Row = styled.div`
  display: flex;
  flex-grow: 1;
  margin-left: 8px;
  margin-top: 12px;
  margin: 12px 0px 12px 8px;
`;

export const TranslatedIcon = styled.img`
  align-self: flex-start;
  margin-right: 10px;
  margin-top: 1px;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Link = styled.a`
  text-decoration: none;
`;

export const LineRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
