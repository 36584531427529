import styled from 'styled-components';

export const BlurredWrapperContainer = styled.div`
  position: fixed;
  z-index: 9999;
  background: #9595954d; //30% opacity
  overflow: visible;
  height: 100dvh;
  width: 100vw;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  backdrop-filter: blur(10px);
  @-moz-document url-prefix() {
    background-color: rgba(220, 220, 220, 0.9);
  }
  animation-name: blurring;
  animation-duration: 0.8s;
  @keyframes blurring {
    0% {
      backdrop-filter: blur(0px);
    }
    100% {
      backdrop-filter: blur(10px);
    }
  }
`;
