export const SOURCE_ORIGINS = {
  MERCHANT_PORTAL: 'portal_web',
  ADMIN_TOOLS: 'admin-tools',

};

export const APP_SOURCE_ORIGINS = {
  ALL_PROTECTED_ORDERS_APP: 'all_protected_orders_app',
  MAP_APP: 'map_app',
  MERCHANT_HISTORY_APP: 'merchant_history_app',
  MERCHANT_RESOLVE_CENTER_APP: 'merchant_resolve_center_app',
  ORDER_DETAILS_APP: 'order_details_app',
  ORDER_HISTORY_APP: 'order_history_app',
  ORDER_MAP_APP: 'order_map_app',
  ORDER_PACKAGES_APP: 'order_packages_app',
  ORDER_DETAILS_APP_WEB_WRAPPER_ORDER_DETAILS: 'order_details_app_web_wrapper_order_details',
  ORDER_DETAILS_APP_WEB_WRAPPER_ORDER_HISTORY: 'order_details_app_web_wrapper_order_history',
  ORDER_DETAILS_APP_WEB_WRAPPER_PIZZA_TRACKER: 'order_details_app_web_wrapper_pizza_tracker',
}

export const HibbettMerchantID = 'merch_qoJ9q8AZqEDNTpBnhhpT';

export const DollsKillMerchantID = 'merch_CR5qcaJAHf8WFHsGCse7';
