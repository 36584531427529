import styled from 'styled-components';
import { colors } from '../../constants/styles';
import { aboveSize, belowSize } from '../../utils/Breakpoints';

export const Container = styled.div`
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-items: stretch;
  margin: 0 25%;
  text-align: center;
  flex-grow: 1;
  ${belowSize.md`
    margin: 0 5%;
  `}
  ${aboveSize.lg`
    margin: 0 30%;
  `}
`;

export const Logo = styled.img`
  margin: 48px 0;
  width: 100px;
  align-self: center;
`;

export const TitleContainer = styled.div`
  margin-bottom: 4.8vh;
`;

export const TextFieldContainer = styled.div`
  display: flex;
  height: 18vh;
  background-color: ${colors.white};
  padding: 8px;
  border-radius: 20px;
  margin: 2vh 0;
  border: 1px solid #dddddd;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Row = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: center;
  flex-grow: 1.5;
`;

export const Separator = styled.div`
  margin-bottom: 48px;
  background: #eeeeee;
  height: 1px;
  width: 560px;

  border-radius: 0px;
`;

export const RouteLogo = styled.img`
  margin-right: 24px;
  height: 64px;
  margin-top: 4px;
`;

export const FiveStarImage = styled.img`
  margin-top: 4px;
  width: 50px;
`;

export const LinkRoute = styled.a`
  color: ${colors.routeBlue};
`;
