import { makeObservable, observable } from 'mobx';
import { InstanceItem } from './OrderDetails.model';
import { PreAssessmentResultEnum } from './Enums/PreAssessmentEnum.model';

// GroupedItem represents a group of items that don't have claims and can be claimed together.
export class GroupedItem {
  public items = new Array<InstanceItem>();

  @observable private _quantity = -1;

  get quantity(): number {
    return this._quantity > 0 ? this._quantity : this.items.length;
  }

  set quantity(val: number) {
    this._quantity = val;
  }

  get singlePrice(): number {
    return this.items[0].price;
  }

  get totalAmount(): number {
    return this._quantity > 0
      ? this.singlePrice * this._quantity
      : this.singlePrice * this.items.length;
  }

  get instanceItemsQuantity(): number {
    return this.items.length;
  }

  get orderItemId(): string {
    return this.items[0]?.orderItemId;
  }
  get name(): string {
    return this.items[0]?.name;
  }
  get imageUrl(): string {
    return this.items[0]?.imageUrl ?? '';
  }
  get shipmentId(): string | undefined {
    return this.items[0]?.shipmentId;
  }

  get eligibleForPerishable(): boolean {
    return this.items[0]?.eligibleForPerishable
      ? this.items[0]?.eligibleForPerishable
      : false;
  }

  get shouldDisplay(): boolean {
    return this.items.some(item => item.shouldDisplay);
  }

  get isDisabled(): boolean | undefined {
    return this.items[0]?.isDisabled;
  }

  get disabledReason(): string | undefined {
    return this.items[0]?.disabledReason;
  }

  get wismoSummaryText(): string {
    return this.items[0]?.wismoSummaryText;
  }

  get wismoHeader(): string {
    return this.items[0]?.wismoHeader;
  }

  get wismoDescription(): string {
    return this.items[0]?.wismoDescription;
  }

  get wismoHeaderAlt(): string {
    return this.items[0]?.wismoHeaderAlt;
  }

  get wismoDescriptionAlt(): string {
    return this.items[0]?.wismoDescriptionAlt;
  }

  get preassessmentResult(): PreAssessmentResultEnum | undefined {
    return this.items[0]?.preassessmentResult;
  }

  constructor(init?: Partial<GroupedItem>) {
    Object.assign(this, init);
    makeObservable(this);
  }
}
