/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import {
  AnimationContent,
  Container,
  TextContent,
  Animation,
} from './LoadingClaimFlow.styles';
import { useStores } from '../../../../hooks/app.hook';
import ForegroundContentModal from '../../../../components/ForegroundContentModal';
import Typography from '../../../../components/UI/Typography';
import { colors } from '../../../../constants/styles';
import { ButtonContent, TypographyContent } from './LoadingClaimFlow.constants';
import lottie, { AnimationEventName } from 'lottie-web';
import ResolveToCheck from '../../../../assets/animations/resolve/resolveLoading.json';
import { analyticsCodes, logEvent } from '../../../../services/analytics';
import { broadCastAction, broadcastView } from '../../../../services/broadcast';
import { VIEWS } from '../../../../constants/views';
import { ACTIONS } from '../../../../constants/actions';

const LoadingClaimFlow: FC = observer(() => {
  const {
    claimStore,
    routingStore,
    commonStore,
    claimBlockingStore,
    orderStore,
  } = useStores();

  const lottieContainer = useRef<HTMLDivElement>(null);
  const item = claimStore.displayItem;

  if (!item) {
    commonStore.displayError();
    return null;
  }

  const closeClickHandle = () => {
    claimStore.reset();
    claimBlockingStore.reset();
    broadCastAction(ACTIONS.CANCEL_CLAIM_FILING);
    routingStore.replace('/order-information');
  };

  useEffect(() => {
    broadcastView(VIEWS.CLAIM_ITEM_BLOCKING_LOADING_CLAIM_FLOW);
    logEvent(analyticsCodes.screenView, {
      screen: analyticsCodes.loadingClaimFlow,
      merchantId: orderStore.getOrderDetails()?.merchantID,
      orderId: orderStore.getOrderDetails()?.id,
    });
  }, []);

  useEffect(() => {
    if (lottieContainer.current) {
      const animation = lottie.loadAnimation({
        container: lottieContainer.current,
        renderer: 'svg',
        loop: false,
        autoplay: true,
        animationData: ResolveToCheck,
        name: 'transferring-animation',
      });
      const completeEventName: AnimationEventName = 'complete';
      animation.addEventListener(completeEventName, () => {
        claimBlockingStore.finishClaimBlocking();
      });
    }
  }, []);

  return (
    <ForegroundContentModal
      open
      mainButton={ButtonContent.cancel}
      mainButtonClick={closeClickHandle}
    >
      <Container>
        <AnimationContent>
          <Animation ref={lottieContainer} id={'transferring-animation'} />
          <Typography variant="p7" color={colors.routeText1} semibold>
            <TextContent>{TypographyContent.animationText}</TextContent>
          </Typography>
        </AnimationContent>
      </Container>
    </ForegroundContentModal>
  );
});

export default LoadingClaimFlow;
