export const PERSONA_VERIFICATION_TYPES = {
    NONE: "none",
    ALL: "all_claim_types"
}

export const PERSONA_VERIFICATION_STATUSES = {
    NOT_AVAILABLE: "not_available",
    COMPLETED: "completed",
    ERROR: "error",
}
type PersonaVerificationType = typeof PERSONA_VERIFICATION_TYPES[keyof typeof PERSONA_VERIFICATION_TYPES];

export interface PersonaConfiguration {
    verification_on: PersonaVerificationType;
}

export interface PersonaResult {
    inquiry_id: string;
    verification_status: typeof PERSONA_VERIFICATION_STATUSES[keyof typeof PERSONA_VERIFICATION_STATUSES];
    verification_error: number;
}