import React from 'react';
import { ILoadingSpinnerProps } from './interface';
import { Container, StyledSpinner } from './LoadingSpinner.styles';

const LoadingSpinner: React.FC<ILoadingSpinnerProps> = (
  props: ILoadingSpinnerProps
) => {
  return (
    <Container className={props.className}>
      <StyledSpinner data-testid="spinner-svg" viewBox="0 0 50 50">
        <circle
          className="path"
          cx="25"
          cy="25"
          r="20"
          fill="none"
          strokeWidth="4"
        />
      </StyledSpinner>
    </Container>
  );
};

export default LoadingSpinner;
