/* eslint-disable @typescript-eslint/no-explicit-any */
import { observer } from 'mobx-react';
import React, { ReactNode } from 'react';
import { Redirect, Route } from 'react-router-dom';
import LoadingSpinner from '../LoadingSpinner';
import { useStores } from '../../hooks/app.hook';

type Props = {
  path: string | Array<string>;
  exact?: boolean;
  issueRequired?: boolean;
  children?: any;
};

const RouteWithOrder: React.FC<Props> = observer(({ children, ...rest }) => {
  const { orderStore, issueStore } = useStores();
  const { issueRequired } = rest;

  const validateOrderBasicInfo = () => {
    return orderStore.orderBasicInfo;
  }

  const validateIssue = () => {
    return !(issueRequired && !issueStore.currentClaim);
  }

  const validateRequirements = () => {
    return (
      validateOrderBasicInfo() && validateIssue()
    );
  };

  const orderOrLoading = (children: ReactNode) => {
    return orderStore.fetchOrder() ? children : <LoadingSpinner />;
  };

  return (
    <Route
      {...rest}
      render={({ location }) =>
        validateRequirements() ? (
          orderOrLoading(children)
        )
          :
          (validateOrderBasicInfo() ? (
            <Redirect
              to={{
                pathname: '/order-information',
                state: { from: location },
              }}
            />
          ) : (
            <Redirect
              to={{
                pathname: '/',
                state: { from: location },
              }}
            />
          )
          )
      }
    />
  );
});

export default RouteWithOrder;
