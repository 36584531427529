import styled from 'styled-components';
import { belowSize } from '../../utils/Breakpoints';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ImageMain = styled.img`
  z-index: 30;
`;

export const ImageSecondary = styled.img`
  margin-left: -30px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 2% 40% 0 8%;
  ${belowSize.md`
    margin: 2% 5% 0 5%;
  `}
`;

export const BalloonsContainer = styled.div`
  display: flex;
  ${belowSize.md`
    margin-left: -30px;
  `}
`;

export const TextContainer = styled.div`
  margin: 6px 0px;
`;
