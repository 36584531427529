import { FC, Fragment, useState } from 'react';
import CleanButton from '../Buttons/CleanButton';
import Typography from '../UI/Typography';
import { IOrderDetailsProps } from './interface';
import {
  ButtonContainer,
  Column,
  ContentRow,
  InvisibleSeparator,
  Row,
  Separator,
  TooltipContainer,
} from './OrderDetails.styles';
import OrderDetailsRow from './OrderDetailsRow';
import { ButtonContents, TypographyContents } from './OrderDetails.constants';
import { colors } from '../../constants/styles';
import Tooltip from '../UI/Tooltip/Tooltip';
import { WindowSize, breakpoints } from '../../utils/Breakpoints';

const OrderDetails: FC<IOrderDetailsProps> = (props: IOrderDetailsProps) => {
  const {
    shippingAddress,
    orderDate,
    sourceOrderNumber: orderNumber,
    email,
    estimatedDelivery,
    customerName: storeOrderId,
  } = props;
  const [copiedOpen, setCopiedOpen] = useState(false);
  const notMobile = WindowSize() > breakpoints.lg;
  const copyAllText = () => {
    let copyString = '';
    copyString += `Order number: ${orderNumber}\n`;
    copyString += `Order email: ${email}\n`;
    copyString += `Order date: ${orderDate}\n`;
    copyString += `Shipped To: ${shippingAddress}`;
    return copyString;
  };

  const handleCopyAll = () => {
    navigator.clipboard.writeText(copyAllText());
    if (props.onCopy) props.onCopy();
    setCopiedOpen(true);
    setTimeout(() => setCopiedOpen(false), 1500);
  };

  const orderContent = () => {
    if (props.extended) {
      return (
        <ContentRow>
          <Column>
            <OrderDetailsRow
              value={orderNumber ?? '-'}
              title={TypographyContents.orderNumber}
              copyDisabled
            />
            {notMobile && <Separator />}
            <OrderDetailsRow
              value={storeOrderId ?? '-'}
              title={TypographyContents.customerName}
              copyDisabled
            />
          </Column>
          <InvisibleSeparator />
          <Column>
            <OrderDetailsRow
              value={orderDate}
              title={TypographyContents.orderDate}
              copyDisabled
            />
            {notMobile && <Separator />}
            <OrderDetailsRow
              value={estimatedDelivery ?? '-'}
              title={TypographyContents.estimatedDeliveryDate}
              copyDisabled
            />
          </Column>
          <InvisibleSeparator />
          <Column>
            <OrderDetailsRow
              value={email}
              title={TypographyContents.email}
              copyDisabled
            />
            {notMobile && <Separator />}
            <OrderDetailsRow
              value={shippingAddress}
              title={TypographyContents.shippedTo}
              copyDisabled
            />
          </Column>
        </ContentRow>
      );
    } else {
      return (
        <Fragment>
          <OrderDetailsRow
            value={orderNumber ?? '-'}
            title={TypographyContents.orderNumber}
          />
          <Separator />
          <OrderDetailsRow value={email} title={TypographyContents.email} />
          <Separator />
          <OrderDetailsRow
            value={orderDate}
            title={TypographyContents.orderDate}
          />
          <Separator />
          <OrderDetailsRow
            value={shippingAddress}
            title={TypographyContents.shippedTo}
          />
        </Fragment>
      );
    }
  };

  return (
    <Column extended={props.extended} extendedVertical={props.extendedVertical}>
      <Row>
        <Typography
          variant={props.titleVariant ?? 'p5'}
          color={props.titleColor ?? 'text'}
          semibold={props.titleSemibold ?? true}
        >
          {props.title ?? TypographyContents.title}
        </Typography>
        {!props.copyDisabled && (
          <ButtonContainer>
            <TooltipContainer>
              <Tooltip text={TypographyContents.copied} open={copiedOpen} />
            </TooltipContainer>
            <CleanButton
              onClick={handleCopyAll}
              overrideTextColor={colors.routeText1}
            >
              {ButtonContents.copyAll}
            </CleanButton>
          </ButtonContainer>
        )}
      </Row>
      {orderContent()}
    </Column>
  );
};

export default OrderDetails;
