import React, { FC, useEffect } from 'react';
import {
  Container,
  ImageMain,
  Column,
  ImageSecondary,
  BalloonsContainer,
  TextContainer,
} from './GenericError.styles';

import { useStores } from '../../hooks/app.hook';
import GenericErrorsBalloons1 from '../../assets/images/generic-error-1.svg';
import GenericErrorsBalloons2 from '../../assets/images/generic-error-2.svg';
import ForegroundContentModal from '../../components/ForegroundContentModal';
import Typography from '../../components/UI/Typography';
import {
  ButtonContents,
  LinkContent,
  TypographyContent,
} from './GenericError.constants';
import { observer } from 'mobx-react';
import { colors } from '../../constants/styles';
import BlurredWrapper from '../../components/UI/BlurredWrapper';
import { ForwardIn } from '../../styles/global.styles';
import { broadCastAction, broadcastView } from '../../services/broadcast';
import { VIEWS } from '../../constants/views';
import { ACTIONS } from '../../constants/actions';

const GenericError: FC = observer(() => {
  const { commonStore, routingStore } = useStores();
  const { error } = commonStore;

  useEffect(() => {
    if (error) {
      broadcastView(VIEWS.GENERIC_ERROR_MODAL)
    }
  }, [error])

  const closeButtonHandle = () => {
    commonStore.reset();
    routingStore.push('/');
  };

  const supportButtonHandle = () => {
    broadCastAction(ACTIONS.CONTACT_SUPPORT);
    commonStore.reset();
    window.location.href = LinkContent.support;
  };

  return (
    <BlurredWrapper open={error}>
      <ForwardIn>
        <ForegroundContentModal
          open={error}
          mainButton={ButtonContents.contactSupport}
          mainButtonClick={supportButtonHandle}
          secondaryButton={ButtonContents.close}
          secondaryButtonClick={closeButtonHandle}
        >
          <Container>
            <BalloonsContainer>
              <ImageMain alt="balloons image 1" src={GenericErrorsBalloons2} />
              <ImageSecondary
                alt="balloons image 2"
                src={GenericErrorsBalloons1}
              />
            </BalloonsContainer>
            <Column>
              <TextContainer>
                <Typography variant="h2" color={colors.black}>
                  {TypographyContent.title}
                </Typography>
              </TextContainer>
              <TextContainer>
                <Typography variant="p5" color={colors.black}>
                  {TypographyContent.description}
                </Typography>
              </TextContainer>
            </Column>
          </Container>
        </ForegroundContentModal>
      </ForwardIn>
    </BlurredWrapper>
  );
});

export default GenericError;
