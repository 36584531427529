import React, { useState, useRef } from 'react';
import {
  Wrapper,
  SelectItemsWrapper,
  SelectButtonWrapper,
  SelectOptionWrapper,
  CountryIconWrapper,
} from './CountrySelect.styles';
import SelectUpIcon from '../../../../assets/icons/select-up.svg';
import SelectDownIcon from '../../../../assets/icons/select-down.svg';
import { useClickOutside } from '../../../../hooks/app.hook';
import { Country } from '../../../../models/PhoneVerification.model';
import { Icon } from '../../../../styles/global.styles';
import { convertCountryCodeToUnicode } from '../../../../utils';

const CountrySelect: React.FC<ICountrySelectProps> = (
  props: ICountrySelectProps
) => {
  const clickRef = useRef<HTMLDivElement>(null);
  const onClickOutside = () => {
    if (showSelectItems) setShowSelectItems(false);
  };
  useClickOutside(clickRef, onClickOutside);
  const [showSelectItems, setShowSelectItems] = useState(false);

  const onCountryOptionSelection = (e: React.MouseEvent<HTMLDivElement>) => {
    e.persist();
    // @ts-ignore
    const country = e.target.getAttribute('data-name');
    props.onChange(country);
  };

  const getSelectionOptions = () => {
    return props.selectItems.map((item: Country) => {
      return (
        <SelectOptionWrapper
          onClick={onCountryOptionSelection}
          data-name={item.abbrev}
          key={item.abbrev}
        >
          {item.name}
        </SelectOptionWrapper>
      );
    });
  };

  return (
    <Wrapper
      ref={clickRef}
      onClick={() => setShowSelectItems(!showSelectItems)}
    >
      <SelectButtonWrapper>
        <CountryIconWrapper>
          {convertCountryCodeToUnicode(props.value)}
        </CountryIconWrapper>
        {showSelectItems ? (
          <Icon src={SelectUpIcon} />
        ) : (
          <Icon src={SelectDownIcon} />
        )}
      </SelectButtonWrapper>
      {showSelectItems && (
        <SelectItemsWrapper>{getSelectionOptions()}</SelectItemsWrapper>
      )}
    </Wrapper>
  );
};

export default CountrySelect;
