export const TypographyContent = {
  title: 'Oops, something went wrong on our end.',
  description: `We’re working on fixing the problem.`,
};

export const ButtonContents = {
  contactSupport: 'Contact Support',
  close: 'Close',
};

export const LinkContent = {
  support:
    'https://help.route.com/hc/en-us/requests/new?ticket_form_id=360001187214&description=File%20a%20new%20claim',
};
