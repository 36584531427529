import styled from 'styled-components';
import { colors } from '../../../../constants/styles';
import { SlideLeft } from '../../../../styles/global.styles';
import { belowSize } from '../../../../utils/Breakpoints';

export const Container = styled(SlideLeft)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-self: stretch;
  overflow-x: hidden;
  ${belowSize.md`
   margin-left: 10px;
  `}
`;

export const Row = styled.div`
  display: flex;
  /* align-items: flex-start; */
  overflow-x: hidden;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Separator = styled.div`
  margin-bottom: 24px;
  margin-top: 12px;
  background-image: linear-gradient(
    to right,
    ${colors.routeText2} 10%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: top;
  background-size: 6px 1px;
  background-repeat: repeat-x;
  height: 1px;
`;
