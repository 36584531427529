export const TypographyContent = {
  title: 'Verify Phone Number',
  description:
    'It will only be used to verify your identity not shared with 3rd parties for any other purpose.',
  termsOfService:
    'Your info is secure. By entering in your phone number, you agree to the ',
  dataRates: '. Message and data rates may apply.',
};

export const InputContent = {
  phoneNumber: 'Phone Number',
  verificationCode: 'Verification Code',
  invalidPhoneNumber: 'Invalid phone number',
  invalidVerificationCode: 'Invalid verification code',
  phoneTooltip:
    'At this time we only support the countries listed in the dropdown.',
  verificationCodeTooltip:
    'This code is sent securely by text or phone (your choice). Once received, enter the code into the verification code field.',
};

export const ButtonContent = {
  next: 'Next',
  cancel: 'Cancel',
  sms: 'Text',
  call: 'Call',
  resend: 'Resend Code',
};

export const AltContent = {
  shieldIcon: 'Shield of protection with a checkmark',
};

export const LinkRef = {
  termsOfService: 'https://route.com/terms-and-conditions'
}