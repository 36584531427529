import React, { Fragment } from 'react';
import { Container, DownloadImg, RouteLogo } from './AppStoreHeader.styles';

import RouteLogoImg from '../../../assets/images/route-logo-black.svg';
import DownloadIos from '../../../assets/images/download-ios.svg';
import DownloadGoogle from '../../../assets/images/download-google-play.svg';

const AppStoreHeader: React.FC = () => {
  const iosLink =
    'https://apps.apple.com/us/app/route-order-package-tracker/id1449803898';
  const androidLink =
    'https://play.google.com/store/apps/details?id=com.route.app';
  const iosAlt = 'download-ios';
  const androidAlt = 'download-google-play';

  const isiOS = () => {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform);
    // if we want to add Mac computer filter
    // || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  };

  const determineMobileAppType = () => {
    const ua = navigator.userAgent.toLowerCase();
    const isAndroid = ua.indexOf('android') > -1 && ua.indexOf('mobile');
    if (isiOS()) {
      return (
        <a href={iosLink} target="blank" data-testid="ios-link">
          <DownloadImg src={DownloadIos} alt={iosAlt} />
        </a>
      );
    } else if (isAndroid) {
      return (
        <a href={androidLink} target="blank" data-testid="android-link">
          <DownloadImg src={DownloadGoogle} alt={androidAlt} />
        </a>
      );
    } else
      return (
        <Fragment>
          <a href={iosLink} target="blank" data-testid="ios-link">
            <DownloadImg src={DownloadIos} alt={iosAlt} />
          </a>
          <a href={androidLink} target="blank" data-testid="android-link">
            <DownloadImg src={DownloadGoogle} alt={androidAlt} />
          </a>
        </Fragment>
      );
  };
  return (
    <Container>
      <RouteLogo src={RouteLogoImg} alt="Route logo" />
      <div>{determineMobileAppType()}</div>
    </Container>
  );
};

export default AppStoreHeader;
