import { useContext, useEffect } from 'react';

import { StoreContext } from '../stores/useStore';
import { RootStore } from '../stores/RootStore';

export const useStores = (): RootStore => useContext(StoreContext);

export const useClickOutside = (
  ref: React.RefObject<HTMLDivElement>,
  callback: () => void
): void => {
  const handleClick = (e: MouseEvent) => {
    // @ts-ignore
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };
  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
};
