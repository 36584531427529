import React from 'react';
import { Redirect } from 'react-router-dom';

const BrandRedirect: React.FC = () => {
    return (
        <Redirect to="/not-found" />
    );
};

export default BrandRedirect;
