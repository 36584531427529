import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  overflow-x: hidden;
`;

export const AnimationContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const fadeIn = keyframes`
0% {
  opacity: 0;
}
100% {
  opacity: 1;
}
`;

export const TextContent = styled.div`
  animation: ${fadeIn} ease 0.35s;
  text-align: center;
`;

export const Animation = styled.div`
  width: 44px;
  height: 44px;
`;
