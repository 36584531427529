export const TypographyContent = {
  title: 'Damaged Details',
  descriptionPlaceholder: 'What happened to your package?',
};

export const ButtonContent = {
  next: 'Next',
  cancel: 'Cancel',
  uploadPhoto: 'Upload a photo',
};
