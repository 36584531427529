import React, { FC } from 'react';
import { IButtonRowProps } from './interface';
import { Container } from './ButtonRow.styles';
import Typography from '../../UI/Typography';
import { colors } from '../../../constants/styles';
import Button from '../Button';

const ButtonRow: FC<IButtonRowProps> = (props: IButtonRowProps) => {
  const { text, icon } = props;

  return (
    <Container
      className={props.className}
      onClick={props.onClick}
      data-testid="clickable"
    >
      <Button
        icon={icon}
        mode="light"
        overrideBackground={"none"}
        overrideSizeIcon="26px"
      />
      <Typography variant="h7" color={colors.routeText1}>
        {text}
      </Typography>
    </Container>
  );
};

export default ButtonRow;
