import styled from 'styled-components';
import { colors } from '../../../constants/styles';

interface IChecked {
  checked: boolean;
  round?: boolean;
  size?: string;
}

export const CheckboxContainer = styled.div`
  display: inline-block;
  margin: 4px 8px 8px 8px;
`;

export const ClickableContainer = styled.label`
  cursor: pointer;
`;

export const Icon = styled.img`
  pointer-events: none;

  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 33%;
  width: 50%;
`;
// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
export const HiddenCheckbox = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  white-space: nowrap;
  width: 1px;
`;

export const StyledCheckbox = styled.div`
  display: inline-block;
  width: ${(props: IChecked) => (props.size ? props.size : '30px')};
  height: ${(props: IChecked) => (props.size ? props.size : '30px')};
  background: ${(props: IChecked) =>
    props.checked ? colors.routeButtonFill1 : colors.transparent};
  border: 2px solid ${colors.routeButtonFill1};
  border-radius: ${(props: IChecked) => (props.round ? '50%' : '3px')};
  transition: all 150ms;

  pointer-events: none;

  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
  }
`;
