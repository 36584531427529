export const TypographyContent = {
  title: 'We currently only support\n refunds via PayPal',
  subtitle: `Don't have an account?\n It's easy, fast, and free to setup.\n Tap the link below to get started.`,
  setupPayPal: 'Setup with PayPal',

  feedbackTitle: "We'd love your feedback",
  feedbackSubtitle:
    'What digital wallets do you currently use?\n(select all that apply)',

  submitButton: 'Submit feedback',

  // Thank You page
  thankYouFeedback: 'Thank you for your feedback',
  thankYouFeedbackTitle:
    'We will let you know when more digital wallets are accepted at Route.',

  thankYouOkayButton: 'Okay',
};
