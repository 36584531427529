export const TypographyContent = {
  title: 'REACHED YOUR LIMIT',
  description:
    'You can contact support to get help or hold tight for 3 hours to try again.',
};

export const ButtonContent = {
  support: 'Contact Support',
};

export const LinkContent = {
  support:
    'https://help.route.com/hc/en-us/requests/new?ticket_form_id=360001187214&description=File%20a%20new%20claim',
};
