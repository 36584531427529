import { Shield, ShieldProps } from "shield-js-npm";

export const initializeShield = (setSessionId: (v: string) => void) => {
    const shield = new Shield();

    const props: ShieldProps = {
        siteId: process.env.REACT_APP_SHIELD_SITE_ID ?? '',
    }

    if (props.siteId && props.siteId !== '') {
        shield.init(props).then(() => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            shield.getDeviceIntelligence().then((res: any) => {
                const session_id = res.result.session_id;

                setSessionId(session_id);

            }).catch((err) => {
                //If Shield errors for some reason, do nothing
            });
        })
    }
};