export const TypographyContent = {
  titleSingle: 'Add photos of damaged item',
  titleMultiple: 'Add photos of damaged items',
  descriptionSingle: 'Please upload photos showing damage to the item and packaging. Make sure each photo has good lighting and clearly shows the damage.',
  desriptionMultiple: 'Please upload photos showing damage to items and packaging. Make sure each photo has good lighting and clearly shows the damage.',
  uploadPhoto: 'Upload a photo',
  description: '',
  multipleItemTitle: (index: number, total: number): string => {
    return `Damaged Item ${index} of ${total}`;
  },
};

export const ButtonContent = {
  next: 'Next',
  cancel: 'Cancel',
  uploadPhoto: 'Upload a photo',
};
