/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import { analyticsCodes, logEvent } from '../../../../../services/analytics';
import { useStores } from '../../../../../hooks/app.hook';
import { broadcastView } from '../../../../../services/broadcast';
import { VIEWS } from '../../../../../constants/views';
import { AfterText, Column, FeeTable, SubTitle, Title } from './GiftCardDisclaimer.styles';
import FeeTableImage from '../../../../../assets/images/disclosure-chart.png'
import { colors } from '../../../../../constants/styles';
import { TypographyContent } from './GiftCardDisclaimer.constants';
import ScrollPaper from '../../../../../components/UI/ScrollPaper';
import { ResolutionEnum } from '../../../../../models/Enums/OrderEnum.model';

const GiftCardDisclaimer: FC = observer(() => {
	const {
		claimStore,
		orderStore,
	} = useStores();


	useEffect(() => {
		logEvent(analyticsCodes.screenView, {
			screen: analyticsCodes.prepaidCardDisclaimer,
			merchantId: orderStore.getOrderDetails()?.merchantID,
			orderId: orderStore.getOrderDetails()?.id,
			cardType: ResolutionEnum.PREPAID_CARD_MASTERCARD,
		});
		broadcastView(VIEWS.CLAIM_ITEM_GIFT_CARD_DISCLAIMER);
	}, []);

	return (
		<ScrollPaper
			open={claimStore.viewDisclaimer}
			closeFunction={() => { claimStore.setViewDisclaimer(false) }}
		>
			<Column>
				<Title variant="h5" color={colors.routeText1} align='center'>{TypographyContent.title}</Title>
				<SubTitle variant="l1" color={colors.routeText2} align='center'>{TypographyContent.subtitle}</SubTitle>
				<FeeTable src={FeeTableImage} />
				<AfterText variant="l1" color={colors.routeText2} align='center'>{TypographyContent.afterText}</AfterText>
			</Column>
		</ScrollPaper>
	);
});

export default GiftCardDisclaimer;
