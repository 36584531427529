import React, { FC, useEffect } from 'react';
import {
  MainColumn,
  InputContainer,
  CustomCentralized,
} from './FindOrder.styles';
import Typography from '../../components/UI/Typography';
import TextField from '../../components/Inputs/TextField';
import { observer } from 'mobx-react';
import { TextFieldContent, TypographyContent } from './FindOrder.constants';
import Button from '../../components/Buttons/Button/Button';
import { useStores } from '../../hooks/app.hook';
import { Icon, Page } from '../../styles/global.styles';
import { colors } from '../../constants/styles';
import LoadingSpinner from '../../components/LoadingSpinner';
import { useLocation } from 'react-router-dom';
import isEmail from 'validator/lib/isEmail';
import ResolveLogo from '../../assets/icons/resolve-logo-black.svg';
import Header from '../../components/UI/Header';
import { broadcastView } from '../../services/broadcast';
import { VIEWS } from '../../constants/views';

const FindOrder: FC = observer(() => {
  const location = useLocation();
  const { findOrderStore, commonStore } = useStores();
  const { number, email, errors, loading } = findOrderStore;

  const isNextDisabled = (): boolean => {
    return number === '' || !isEmail(email) || loading;
  };

  useEffect(() => {
    broadcastView(VIEWS.FIND_ORDER)
  }, [])

  useEffect(() => {
    findOrderStore.reset();
  }, [findOrderStore]);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const source = urlParams.get('source') || urlParams.get('claimSource');
    if (source) {
      commonStore.setSource(source);
    }
    const emailRaw = urlParams.get('email') || urlParams.get('emailAddress') || '';
    const ron = urlParams.get('routeOrderNumber') || '';
    const email = emailRaw.replace('\x00', '');
    if (email) {
      findOrderStore.set('email', email.trim());
    }
    if (ron) {
      findOrderStore.set('number', ron.trim());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [findOrderStore, commonStore, location.search]);

  return (
    <Page>
      <Header />
      <CustomCentralized>
        <MainColumn>
          <Icon src={ResolveLogo} alt="Resolve Logo" />
          <br />
          <br />
          <Typography variant="h1" color={colors.routeText1}>
            {TypographyContent.title}
          </Typography>
          <br />
          <br />
          <Typography variant="h7" color={colors.routeText2}>
            {TypographyContent.description}
          </Typography>
          <br />
          <br />
          <br />
          <br />
          <Typography variant="h6" color={colors.routeText1}>
            {TypographyContent.statement}
          </Typography>
          <InputContainer>
            <TextField
              placeholder={TextFieldContent.email.placeholder}
              title={TextFieldContent.email.placeholder}
              toolTipText={TextFieldContent.email.toolTipText}
              errorText={TextFieldContent.email.errorText}
              textType="email"
              value={email}
              onChange={(value: string) => findOrderStore.set('email', value)}
              isError={errors.email}
              readOnly={loading}
            />
            <br />
            <TextField
              placeholder={TextFieldContent.orderNumber.placeholder}
              title={TextFieldContent.orderNumber.placeholder}
              toolTipText={TextFieldContent.orderNumber.toolTipText}
              errorText={TextFieldContent.orderNumber.errorText}
              textType="text"
              value={number}
              onChange={(value: string) => findOrderStore.set('number', value)}
              isError={errors.number}
              readOnly={loading}
            />
            <br />
            {loading ? (
              <LoadingSpinner />
            ) : (
              <Button
                fullWidth
                onClick={() => {
                  findOrderStore.findOrderIssueDetails();
                }}
                disabled={isNextDisabled()}
              >
                {TypographyContent.next}
              </Button>
            )}
          </InputContainer>
        </MainColumn>
      </CustomCentralized>
    </Page>
  );
});

export default FindOrder;
