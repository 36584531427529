import React from 'react';
import { FC } from 'react';
import Typography from '../../components/UI/Typography';
import { colors } from '../../constants/styles';

export interface IEmphasisSpanProps {
  children: React.ReactNode;
}
const EmphasisSpan: FC<IEmphasisSpanProps> = (props: IEmphasisSpanProps) => {
  return (
    <Typography variant="p5" semibold color={colors.routeText1}>
      {props.children}
    </Typography>
  );
};

export const TypographyContent = {
  title: 'Not Approved',
  notApprovedReasonFallback:
    'It looks like you have filed your claim beyond the claim submission window.',
  description: (main: string, email: string): JSX.Element => (
    <>
    <Typography variant="p5" color={colors.routeText1}>
      {main} <br />
      Route has put together an email to send to{' '}
      <EmphasisSpan>{email}</EmphasisSpan> with all of your info if you still
      have questions.
    </Typography>
    </>
  ),
};

export const ButtonContents = {
  emailDraft: 'View email draft',
};

//TODO: CHANGE
export const EmailDraft = (
  orderNumber: string,
  customerName: string
): string => {
  return `Hi,

My items related to my order ${orderNumber} is still having issues. Is there anyway that you could still help me solve this issues I am having?


Thank you,
${customerName}`;
};
