/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import {
  ButtonRowStyled,
  Container,
  ItemIconStyled,
  PhotoThumbnailStyled,
  Row,
  SliderPhotoRow,
  Separator,
} from './ClaimedItemsDamagedPhotoUpload.styles';
import Typography from '../../../../components/UI/Typography';
import { colors } from '../../../../constants/styles';
import { useStores } from '../../../../hooks/app.hook';
import ForegroundContentHeader from '../../../../components/ForegroundContentHeader';
import ForegroundContentModal from '../../../../components/ForegroundContentModal';
import {
  ButtonContent,
  TypographyContent,
} from './ClaimedItemsDamagedPhotoUpload.constants';
import { Icon } from '../../../../styles/global.styles';
import PlusIcon from '../../../../assets/icons/plus-icon-nobg.svg';
import { useRef } from 'react';
import { ImageTypeEnum } from '../../../../models/API/PhotoUploadEndpoint';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import { logEvent, analyticsCodes } from '../../../../services/analytics';
import { VerticalSeparator } from '../../../../styles/global.styles';
import { broadCastAction, broadcastView } from '../../../../services/broadcast';
import { VIEWS } from '../../../../constants/views';
import { ACTIONS } from '../../../../constants/actions';

const ClaimedItemsDamagedPhotoUpload: FC = observer(() => {
  const { claimStore, routingStore, commonStore, damagedStore } = useStores();
  const { displayItem: item } = claimStore;
  const { damagedPhotoUrls, currentItem, loading } = damagedStore;

  const inputFile = useRef<HTMLInputElement>(null);
  if (!item) {
    commonStore.displayError();
    return null;
  }

  useEffect(() => {
    broadcastView(VIEWS.CLAIM_ITEM_DAMAGED_PHOTO_UPLOAD)
  }, [])

  const perishableClick = (value: boolean) => {
    damagedStore.setCurrentItemPerishable(value);
  };

  const nextClickHandle = () => {
    damagedStore.nextItemToUploadPhoto();
  };

  const closeClickHandle = () => {
    broadCastAction(ACTIONS.CANCEL_CLAIM_FILING);
    claimStore.reset();
    routingStore.replace('/order-information');
  };

  const backClickHandle = () => {
    broadCastAction(ACTIONS.NAVIGATE_BACK_CLICK);
    damagedStore.resetUploadStatusOnClaimedItems();
    routingStore.goBack();
  };

  const uploadPhotoClickHandle = () => {
    if (inputFile.current) {
      inputFile.current.value = '';
      inputFile.current.click();
    }
  };

  const handleFileChange = async (files: FileList | null) => {
    if (files) {
      for (const file of Object.values(files)) {
        if (Object.values(ImageTypeEnum).includes(file.type as ImageTypeEnum)) {
          const compressed = await damagedStore.compressAndAddFileForItem(file);
          const reader = new FileReader();
          reader.readAsDataURL(compressed);
          reader.onload = e => {
            damagedStore.addPhotoUrlForItem(
              compressed,
              e.target?.result as string
            );
          };
        }
      }
    }
  };

  const generateTitle = () => {
    const itemsSize = claimStore.claimedItems.length;
    if (itemsSize === 1) return TypographyContent.titleSingle;
    return TypographyContent.titleMultiple;
  };

  const generateDescription = () => {
    const itemsSize = claimStore.claimedItems.length;
    if (itemsSize === 1) return TypographyContent.descriptionSingle;
    return TypographyContent.desriptionMultiple;
  };

  useEffect(() => {
    logEvent(analyticsCodes.screenView, {
      screen: analyticsCodes.photoUpload,
    });
  }, []);

  return (
    <ForegroundContentModal
      open
      mainButton={ButtonContent.next}
      mainButtonClick={nextClickHandle}
      mainButtonDisabled={
        loading ||
        damagedPhotoUrls.filter(
          filtered => filtered.itemIndex === damagedStore.getCurrentIndex()
        ).length === 0
      }
      secondaryButton={ButtonContent.cancel}
      secondaryButtonClick={closeClickHandle}
    >
      <ForegroundContentHeader
        // hasClaimedItemsSelected already checks nullable value
        claimedItems={claimStore.claimedItems}
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        displayItem={item!}
        clickButtonHandle={backClickHandle}
        clickPerishable={perishableClick}
        title={generateTitle()}
        description={generateDescription()}
        perishableRow={currentItem?.item.eligibleForPerishable}
        perishableStatus={currentItem?.perishable}
        clickEditQuantityHandle={() => {
          claimStore.setEditingQuantity();
        }}
        centered={true}
      >
        {loading ? (
          <LoadingSpinner />
        ) : (
          <Container>
            <VerticalSeparator distance={20} />
            <Separator />
            <VerticalSeparator distance={16} />
            <Typography variant="p2" semibold color={colors.routeText1}>
              {(claimStore.claimedItems.length === 1 && (
                currentItem?.item.name
              )) || (
                  " Item " + damagedStore.getCurrentIndex() + "/" + claimStore.claimedItems.length + " · " + currentItem?.item.name
                )}
            </Typography>
            <Row>
              {currentItem && claimStore.claimedItems.length > 1 && (
                <ItemIconStyled
                  noStack
                  overrideSize={112}
                  quantity={currentItem.item.quantity}
                  imageUrl={currentItem.item.imageUrl}
                  alt={`icon of ${currentItem.item.name}`}
                />
              )}
              <SliderPhotoRow>
                {damagedPhotoUrls
                  .slice().reverse() // Note(12, Dec 2023): the scroll needs to be at the last photo, css is row-reverse
                  .filter(
                    filtered =>
                      filtered.itemIndex === damagedStore.getCurrentIndex()
                  )
                  .map((damagePhoto, index) => {
                    return (
                      <PhotoThumbnailStyled
                        key={index}
                        imageUrl={damagePhoto.imageUrl}
                        deleteClick={() => {
                          damagedStore.removePhotoUrl(damagePhoto);
                        }}
                      />
                    );
                  })}
              </SliderPhotoRow>
            </Row>
            <ButtonRowStyled
              text={TypographyContent.uploadPhoto}
              icon={
                <Icon src={PlusIcon} alt="add icon" verticalAlign="middle" />
              }
              onClick={uploadPhotoClickHandle}
            />
            <input
              hidden
              multiple
              onChange={e => handleFileChange(e.target.files)}
              type="file"
              accept=".png,.jpeg,.jpg"
              ref={inputFile}
              id="photo-uploader"
              name="file"
              data-testid="photo-uploader"
            />
          </Container>
        )}
      </ForegroundContentHeader>
    </ForegroundContentModal>
  );
});

export default ClaimedItemsDamagedPhotoUpload;
