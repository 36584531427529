/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { observable, makeObservable, action, runInAction } from 'mobx';
import { RootStore } from '../RootStore';
import * as api from '../../services/api';
import {
  RemedyActions,
  RemedyShippingInformation,
  SubItemActions,
} from '../../models/Remedy.model';
import { ShippingDetails } from '../../models/OrderDetails.model';
import isEmail from 'validator/lib/isEmail';
import { ResolutionEnum } from '../../models/Enums/OrderEnum.model';
import { VenmoInputEnumType, parseVenmoInput } from './Business/InputParser';
import { SplitNames } from '../_constants/SplitStore.constants';

export class InputErrors {
  constructor() {
    makeObservable(this);
  }
  [key: string]: boolean;
  @observable firstName = false;
  @observable lastName = false;
  @observable email = false;
  @observable confirmEmail = false;
  @observable venmoConfirmationInput = false;
}


export class RemedyStore {
  [key: string]: unknown;
  private rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
  }

  @observable routeRemedyActions: Array<RemedyActions> = [];

  @observable paypalEmail = '';

  @observable firstName = '';
  @observable lastName = '';
  @observable address1 = '';
  @observable address2 = '';
  @observable city = '';
  @observable state = '';
  @observable zip = '';
  @observable country = '';
  @observable phone = '';
  @observable company = '';

  @observable giftCardFirstName = '';
  @observable giftCardLastName = '';
  @observable giftCardEmail = '';
  @observable giftCardConfirmEmail = '';

  @observable venmoInput = '';
  @observable venmoConfirmationInput = '';

  errors = new InputErrors();

  verifyConfirmationTimer?: NodeJS.Timeout;
  verifyEmailTimer?: NodeJS.Timeout;

  @action initShippmentVars(
    info: ShippingDetails | RemedyShippingInformation,
    firstName?: string,
    lastName?: string
  ): void {
    this.firstName = firstName || '';
    this.lastName = lastName || '';
    this.address1 = info.address1 || '';
    this.address2 = info.address2 || '';
    this.city = info.city || '';
    this.state = info.state || '';
    this.zip = info.zip || '';
    this.country = info.country || '';
    this.phone = info.phone || '';
    this.country = info.country || '';
  }

  @action setFirstName(value: string): void {
    this.firstName = value;
  }

  @action setLastName(value: string): void {
    this.lastName = value;
  }

  @action setAddress1(value: string): void {
    this.address1 = value;
  }

  @action setAddress2(value: string): void {
    this.address2 = value;
  }

  @action setCity(value: string): void {
    this.city = value;
  }

  @action setState(value: string): void {
    this.state = value;
  }

  @action setZip(value: string): void {
    this.zip = value;
  }

  @action setCountry(value: string): void {
    this.country = value;
  }

  @action setPhone(value: string): void {
    this.phone = value;
  }

  @action setCompany(value: string): void {
    this.company = value;
  }

  @action setGiftCardFirstName(value: string): void {
    if (this.firstNameTimer) clearTimeout(this.firstNameTimer as NodeJS.Timer);
    this.giftCardFirstName = value;
    this.firstNameTimer = setTimeout(() => {
      this.setErrors('firstName', this.giftCardFirstName === '')
    }, 500);
  }

  @action setGiftCardLastName(value: string): void {
    if (this.lastNameTimer) clearTimeout(this.lastNameTimer as NodeJS.Timer);
    this.giftCardLastName = value;
    this.lastNameTimer = setTimeout(() => {
      this.setErrors('lastName', this.giftCardLastName === '')
    }, 500);
  }

  @action setGiftCardEmail(value: string): void {
    this.giftCardEmail = value;
    if (this.verifyEmailTimer) clearTimeout(this.verifyEmailTimer);
    this.verifyEmailTimer = setTimeout(() => {
      this.setErrors('email', !isEmail(value))
    }, 500);
  }

  @action setGiftConfirmCardEmail(value: string): void {
    this.setErrors('confirmEmail', false)
    this.giftCardConfirmEmail = value;
    if (this.verifyConfirmationTimer) clearTimeout(this.verifyConfirmationTimer);
    this.verifyConfirmationTimer = setTimeout(() => {
      this.setErrors('confirmEmail', this.giftCardConfirmEmail !== this.giftCardEmail)
    }, 500);
  }

  @action setVenmoInput(value: string) {
    this.setErrors('venmoInput', false);
    this.venmoInput = value;
  }

  @action setVenmoConfirmationInput(value: string) {
    this.setErrors('venmoConfirmationInput', false);
    this.venmoConfirmationInput = value;
    if (this.verifyConfirmationTimer) clearTimeout(this.verifyConfirmationTimer);
    this.verifyConfirmationTimer = setTimeout(() => {
      this.setErrors('venmoConfirmationInput',
        this.venmoInput !== this.venmoConfirmationInput && this.venmoConfirmationInput !== '')
    }, 500);
  }

  get venmoInputChoice(): VenmoInputEnumType {
    return parseVenmoInput(this.venmoInput);
  }

  venmoDetailsVerify(): boolean {
    return this.venmoInput !== ''
      && !this.errors.venmoConfirmationInput
      && !this.errors.venmoInput
      && this.venmoInput === this.venmoConfirmationInput
  }

  giftCardDetailsVerify(): boolean {
    return !this.errors.confirmEmail
      && !this.errors.email
      && !this.errors.firstName
      && !this.errors.lastName
      && isEmail(this.giftCardEmail)
      && this.giftCardConfirmEmail === this.giftCardEmail
      && this.giftCardFirstName !== ''
      && this.giftCardLastName !== ''
  }

  @action setErrors(key: string, value: boolean): void {
    this.errors[key] = value;
  }

  @action resetSpecificError(key: string): void {
    this.errors[key] = false;
  }

  @action resetErrors(): void {
    this.errors = new InputErrors();
  }

  getShippmentInfo(): RemedyShippingInformation {
    return {
      firstName: this.firstName,
      lastName: this.lastName,
      address1: this.address1,
      address2: this.address2,
      city: this.city,
      state: this.state,
      zip: this.zip,
      country: this.country,
      phone: this.phone,
      company: this.company,
    };
  }

  @action async getRemedyActions(
    items: Array<string>,
    orderNumber: string
  ): Promise<void> {
    const { commonStore } = this.rootStore;
    try {
      const rep = await api.getRemedyAvailability(items, orderNumber);
      if (rep.status === 200 && rep.data) {
        const filteredRemedyActions = this.filterRemedyActions(rep.data.remedyActions);
        const flattenedRemedyActions = this.flatRemedyActions(filteredRemedyActions);
        runInAction(() => {
          this.routeRemedyActions = flattenedRemedyActions;
        });
      } else commonStore.displayError();
    } catch (e) {
      commonStore.displayError();
    }
  }

  @action reset(): void {
    this.routeRemedyActions = [];
    this.paypalEmail = '';
    this.firstName = '';
    this.lastName = '';
    this.address1 = '';
    this.address2 = '';
    this.city = '';
    this.state = '';
    this.zip = '';
    this.country = '';
    this.phone = '';
    this.company = '';
    this.giftCardEmail = '';
    this.giftCardFirstName = '';
    this.giftCardLastName = '';
    this.giftCardConfirmEmail = '';
    this.venmoInput = '';
    this.venmoConfirmationInput = '';
  }

  @action setRemedyActions(actions: RemedyActions[]): void {
    this.routeRemedyActions = actions;
  }

  @action setPayPalEmail(value: string): void {
    this.paypalEmail = value;
  }

  get prepaidCardOptions(): SubItemActions[] {
    return this.routeRemedyActions.find((ra) => ra.value === ResolutionEnum.PREPAID_CARD)?.sub_items || [];
  }

  get giftCardFullName(): string {
    return `${this.giftCardFirstName} ${this.giftCardLastName}`
  }

  filterRemedyActions(rActions: RemedyActions[]): RemedyActions[] {
    let filteredActions = rActions;
    if (!this.rootStore.splitStore.treatmentAllowedUserEmail(SplitNames.REMEDY_VENMO_ALLOWED)) {
      filteredActions = filteredActions.filter((act) => act.value !== ResolutionEnum.REFUND_VENMO)
    }
    if (!this.rootStore.splitStore.treatmentAllowedUserEmail(SplitNames.REMEDY_BLACKHAWK_ALLOWED)) {
      filteredActions = filteredActions.filter((act) => act.value !== ResolutionEnum.PREPAID_CARD)
    }
    return filteredActions;
  }

  getFormattedRemedyActionValues(): string {
    const values = [];
    for (const rra of this.routeRemedyActions) {
      values.push(rra.value)
    }
    return values.toString();
  }


  /**
   * Flattens an array of RemedyActions by pushing sub_items of ResolutionEnum.PREPAID_CARD into the root array.
   *
   * @param {RemedyActions[]} rActions - The array of RemedyActions to flatten.
   * @return {RemedyActions[]} The flattened array of RemedyActions.
   */
  flatRemedyActions(rActions: RemedyActions[]): RemedyActions[] {
    const flatActions = [];
    for (const remedyAction of rActions) {
      if (remedyAction.value === ResolutionEnum.PREPAID_CARD) {
        if (remedyAction.sub_items) {
          flatActions.push(...remedyAction.sub_items as RemedyActions[]);
        }
      } else {
        flatActions.push(remedyAction);
      }
    }
    return flatActions;
  }
}
