export const TypographyContent = {
  title: 'Resolution Preference',
  preferenceAvailability: (storeName: string): string =>
    `Please note if issue is approved, your preference is dependent on availability of items and the discretion of ${storeName}.`,
  cancel: 'Cancel',
};

export const AnalyticsContent = {
  resolutionPreferenceViewed: 'Resolve-ResolutionPreference-Buttons-Viewed',
  resolutionPreferenceSelected: 'Resolve-ResolutionPreference-Button-Tapped',
  resolutionPreferenceChanged: 'Resolve-ResolutionPreferenceChange-Button-Tapped',
  resolutionPreferenceDismiss: 'Resolve-ResolutionPreference-Dismiss-Button-Tapped',
}