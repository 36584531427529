import styled from 'styled-components';
import { colors } from '../../../../constants/styles';

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;

  margin: 20px 40px 0px 40px;
`;

export const InputContainer = styled.div`
  margin: 24px 40px 0px 40px;
`;

export interface ICentered {
  centered?: boolean;
}

export const Link = styled.a`
  color: ${colors.routeText1};
  font-family: RM Neue;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  margin: 20px 40px 0px 40px;
  align-self: ${(props: ICentered) => {
    return props.centered ? 'center' : 'unset';
  }};
`;

export const MoreInfoContainer = styled.div`
  display: flex;
  margin: 28px 40px 0px 40px;
  justify-content: ${(props: ICentered) => {
    return props.centered ? 'center' : 'unset';
  }};
`

export const TextLink = styled.a`
  color: ${colors.routeText2};
  font-weight: 600;
`
