import { CreateClaimItem } from '../models/API/CreateClaimEndpoint';
import { ClaimedItem, IClaimedItem } from '../models/Claim.model';
import { GroupedItem } from '../models/GroupedItems.model';

export const convertGroupedItemToClaimedItem = (
  items: GroupedItem[]
): Array<IClaimedItem> => {
  return items.map(item => {
    return new ClaimedItem(item);
  });
};

export const convertClaimItemsToCreateClaimItems = (
  items: Array<IClaimedItem>
): Array<CreateClaimItem> => {
  const arr = [];
  for (const groupedItem of items) {
    let count = 0;
    for (const item of groupedItem.item.items) {
      if (count < groupedItem.item.quantity) {
        count++;
        arr.push({
          instanceItemId: item.id,
          ...(groupedItem.perishable && {
            markedAsPerishable: groupedItem.perishable,
          }),
        });
      }
    }
  }
  return arr;
};
