import React, { FC, useEffect, useRef } from 'react';
import lottie, { AnimationEventName } from 'lottie-web';

import { useStores } from '../../../../hooks/app.hook';
import ResolveToCheck from '../../../../assets/animations/resolve/resolveToCheck.json';
import { ClaimApprovalAnimationContainer } from './ClaimApprovalAnimation.styles';

export const ClaimApprovalAnimation: FC = () => {
  const lottieContainer = useRef<HTMLDivElement>(null);

  const { claimStore } = useStores();

  useEffect(() => {
    if (lottieContainer.current) {
      const animation = lottie.loadAnimation({
        container: lottieContainer.current,
        renderer: 'svg',
        loop: false,
        autoplay: true,
        animationData: ResolveToCheck,
        name: 'resolution-approval',
      });
      const completeEventName: AnimationEventName = 'complete';
      animation.addEventListener(completeEventName, () => {
        claimStore.acceptedCompleteEventHandler();
      });
    }
  }, [claimStore]);
  return (
    <ClaimApprovalAnimationContainer
      ref={lottieContainer}
      id={'claim-approval-animation'}
    />
  );
};
