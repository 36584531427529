import React, { FC } from 'react';
import { Icon } from '../../../styles/global.styles';
import { ICleanButtonProps } from './interface';
import ButtonStyled from './LinkButton.styles';

const LinkButton: FC<ICleanButtonProps> = (props: ICleanButtonProps) => {
  return (
    <ButtonStyled
      onClick={props.onClick}
      leftIcon={props.leftIcon}
      rightIcon={props.rightIcon}
      overrideFontSize={props.overrideFontSize}
      overrideTextColor={props.overrideTextColor}
      align={props.align ?? 'center'}
    >
      {props.leftIcon && <Icon src={props.leftIcon} alt={props.alt} />}
      {props.children}
      {props.rightIcon && <Icon src={props.rightIcon} alt={props.alt} />}
    </ButtonStyled>
  );
};

export default LinkButton;
