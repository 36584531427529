import styled, { css } from 'styled-components';
import { colors } from '../../constants/styles';

export interface IClickable {
  clickable: boolean;
  disabled?: boolean;
}

export const ClickableContainer = styled.div`
  display: flex;

  background: ${colors.routeButtonFill4};
  border-radius: 10px;

  ${(props: IClickable) => {
    if (props.clickable) {
      return css`
        cursor: pointer;
        &:hover {
          background: ${colors.routeButtonFill3};
        }
      `;
    }
  }}

  ${(props: IClickable) => {
    if (props.disabled) {
      return css`
        opacity: 70%;
      `;
    }
  }}
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 9px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 25px;
`;

export const SubTitleContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
`;

export const SubTitlePreText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
