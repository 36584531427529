import styled from 'styled-components';
import { Centralized } from '../../styles/global.styles';
import { belowSize, aboveSize } from '../../utils/Breakpoints';

const Wrapper = styled.div`
  flex-grow: 1;
  ${aboveSize.sm`
    max-width: 1032px;
    width: 75%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    min-height: 0;
    align-self: center;
  `}
`;

const Container = styled.div`
  margin: 48px 42px 0px 60px;
  display: flex;
  justify-content: space-between;

  ${belowSize.sm`
    flex-direction: column-reverse;
    margin: 24px;
  `}
`;

const MainColumn = styled.div`
  width: 360px;

  ${belowSize.sm`
       width: auto;
  `}
`;

const Image = styled.img`
  width: 26vw;
  max-width: 390px;
  ${belowSize.sm`
      width: 53vw;
      align-self: center;
  `}

  ${aboveSize.md`
      margin-top: 40px;
  `}

  ${aboveSize.xg`
      width: 20vw;
  `}
`;

const InputContainer = styled.div`
  margin-top: 20px;
`;

const CustomCentralized = styled(Centralized)`
  align-items: center;
  justify-content: center;
  ${belowSize.md`
    padding: 0 3%;
  `};
`;

export {
  Container,
  Image,
  MainColumn,
  InputContainer,
  Wrapper,
  CustomCentralized,
};
