import styled from 'styled-components';
import { colors } from '../../../constants/styles';
import { SlideLeft } from '../../../styles/global.styles';
import { belowSize } from '../../../utils/Breakpoints';

export const Container = styled(SlideLeft)`
  position: absolute;
  margin: 3%;
  display: flex;
  flex-grow: 1;
  background: ${colors.routeBackground1};
  overflow: hidden;
  box-shadow: 0px 11px 16px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 0 8px 0 24px;
  align-items: center;
  top: 0;
  z-index: 99;

  ${belowSize.md`
    margin-top: 5%;
  `}
`;
