import styled from 'styled-components';
import Summary from '../../components/Summary';
import { mobileBreakpoint } from '../../constants/styles';
import { aboveSize } from '../../utils/Breakpoints';

export const Wrapper = styled.div`
  @media (min-width: ${mobileBreakpoint}) {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    flex: 1;
    min-height: 0;
    width: 100vw;
  }
`;

export const SummaryStyled = styled(Summary)`
  ${aboveSize.md`
    margin: 48px 60px 48px 60px;
  `}
`;
