import React, { FC } from 'react';
import { IBlurredWrapperProps } from './interface';
import { BlurredWrapperContainer } from './BlurredWrapper.styles';
import { useEffect, useRef } from 'react';

const body = document.querySelector('body');
let activeBlurs = 0;
let scrollPosition = 0;
let previousScrollHeight = 0;

const lockBodyScrollbar = () => {
  scrollPosition = window.pageYOffset;
  previousScrollHeight = document.documentElement.scrollHeight;
  if (body) {
    body.style.setProperty('overflow', 'hidden');
    body.style.setProperty('position', 'fixed');
    body.style.setProperty('top', `-${scrollPosition}px`);
    body.style.setProperty('width', '100%');
  }
}

const unlockBodyScrollbar = () => {
  if (body) {
    body.style.removeProperty('overflow');
    body.style.removeProperty('position');
    body.style.removeProperty('top');
    body.style.removeProperty('width');

    const scrollTo = previousScrollHeight === document.documentElement.scrollHeight ? scrollPosition : 0;
    window.scrollTo(0, scrollTo);
  }
}

const setGlobalActivatedState = (newOpenedState: boolean, firstTimeSetting = false) => {
  if(newOpenedState) {
    if(activeBlurs === 0) lockBodyScrollbar();
    activeBlurs += 1;
  } 
  else {
    if(!firstTimeSetting) activeBlurs -= 1;
    if(activeBlurs === 0) unlockBodyScrollbar();
  }
}

const BlurredWrapper: FC<IBlurredWrapperProps> = (
  props: IBlurredWrapperProps
) => {
  const { children, open } = props;
  const isOpen = useRef(open && true);

  useEffect(() => {
    setGlobalActivatedState(isOpen.current, true);
    return () => {
      if (isOpen.current) setGlobalActivatedState(false);
    }
  },[]);

  useEffect(() => {
    const newState = open && true;
    if (newState !== isOpen.current) {
      isOpen.current = newState;
      setGlobalActivatedState(newState);
    }
  });

  if (open) {
    return <BlurredWrapperContainer>{children}</BlurredWrapperContainer>;
  }
  return null;
};

export default BlurredWrapper;
