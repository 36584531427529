import { keyframes, css, FlattenSimpleInterpolation } from 'styled-components';

const animationStartTime = 150;

const growDuration = 150;
const fadeDelay = 200;
const fadeDuration = 100;
const instanceDelay = 100;

const textInstanceDelay = 25;
const textDuration = 150;

const growStartTime = (index: number) =>
  animationStartTime + instanceDelay * index;

const growStartPercent = (index: number) =>
  (100 * growStartTime(index)) / growTotalTime(index);

const growTotalTime = (index: number) => growStartTime(index) + growDuration;

const fadeInStartTime = (index: number) => growStartTime(index) + fadeDelay;

const fadeInStartPercent = (index: number) =>
  (100 * fadeInStartTime(index)) / fadeInTotalTime(index);

const fadeInTotalTime = (index: number) =>
  fadeInStartTime(index) + fadeDuration;

const textStartTime = (index: number) =>
  animationStartTime + textInstanceDelay * index;

const textTotalTime = (index: number) => textStartTime(index) + textDuration;

const textPercent = (index: number) =>
  (100 * textStartTime(index)) / textTotalTime(index);

const growButtonAnimation = (buttonIndex: number) => keyframes`
  0% {
    transform: scale(0);
  }
  ${growStartPercent(buttonIndex)}% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
`;

export const getItemSelectOptionButtonAnimation = (
  buttonIndex: number
): FlattenSimpleInterpolation => css`
  ${growButtonAnimation(buttonIndex)} ${growTotalTime(buttonIndex)}ms ease
`;

const growIconAnimation = (buttonIndex: number) => keyframes`
  0% {
    opacity: 0;
  }
  ${fadeInStartPercent(buttonIndex)}% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const getItemSelectOptionIconAnimation = (
  buttonIndex: number
): FlattenSimpleInterpolation => css`
  ${growIconAnimation(buttonIndex)} ${fadeInTotalTime(buttonIndex)}ms ease
`;

const showTextAnimation = (buttonIndex: number) => keyframes`
  0% {
    opacity: 0;
		transform: translateY(20px);
  }
  ${textPercent(buttonIndex)}% {
    opacity: 0;
		transform: translateY(20px);
  }
  100% {
    opacity: 1;
		transform: translate(0px)
  }
`;

export const getItemSelectOptionTextAnimation = (
  buttonIndex: number
): FlattenSimpleInterpolation => css`
  ${showTextAnimation(buttonIndex)} ${textTotalTime(buttonIndex)}ms ease-in
`;
