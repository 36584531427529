import React, { FC, RefObject, useRef, useState } from 'react';
import { ITextAreaRowProps } from './interface';
import { ClickableContainer } from './TextAreaRow.styles';
import { colors } from '../../../constants/styles';
import Button from '../../Buttons/Button';
import { TextArea } from './TextAreaRow.styles';

import XIcon from '../../../assets/icons/x-icon';

const TextAreaRow: FC<ITextAreaRowProps> = (props: ITextAreaRowProps) => {
  const ref = useRef() as RefObject<HTMLTextAreaElement>;
  const [focussed, setFocussed] = useState(false);
  const { rows = 1 } = props; // default rows is 1

  const updateTextAreaHeight = (
    element: HTMLTextAreaElement,
    shouldResize = true
  ) => {
    element.style.height = rows * 10 + 16 + 'px';
    if (shouldResize) element.style.height = element.scrollHeight + 'px';
  };

  return (
    <ClickableContainer
      focussed={focussed}
      disabled={props.disabled}
      shouldShowBorder={props.value ? props.value.length > 0 : false}
      data-testid={props.testId}
    >
      <TextArea
        ref={ref}
        id={props.id}
        name={props.name}
        rows={props.rows}
        cols={props.cols}
        value={props.value}
        placeholder={props.placeholder}
        onChange={props.onChange}
        onFocus={() => {
          setFocussed(true);
        }}
        onBlur={e => {
          setFocussed(false);
        }}
        onKeyUp={e => {
          updateTextAreaHeight(e.currentTarget);
        }}
      />
      {props.onCloseClick && props.value && props.value.length > 0 && (
        <Button
          mode="light"
          icon={<XIcon color={colors.routeButtonFill1} width={'7px'} height={'7px'} />}
          overrideBackground={colors.divider}
          overrideSizeIcon={'20px'}
          onClick={() => {
            props.onCloseClick();
            ref.current && updateTextAreaHeight(ref.current, false);
          }}
        ></Button>
      )}
    </ClickableContainer>
  );
};

export default TextAreaRow;
