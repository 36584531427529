import styled from 'styled-components';
import { colors } from '../../constants/styles';
import { belowSize } from '../../utils/Breakpoints';
import {
  ForegroundContentHeaderCSSProps,
  ForegroundHeaderChildrenViewProps,
} from './interface';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 0;
`;

export interface IGrid {
  description?: string | null;
  centered?: boolean;
  zeroBottomPadding?: boolean;
}

export const Grid = styled.div`
  display: grid;
  grid-template-columns: ${(props: IGrid) => {
    return props.centered ? '36px 1fr 36px' : 'auto 1fr';
  }};
  grid-template-rows: ${(props: IGrid) => {
    return props.description ? '110px auto auto 1fr' : '110px auto 1fr';
  }};
  gap: 0px;
  justify-items: stretch;
  flex-grow: 1;
  padding: ${(props: IGrid) => {
    return props.zeroBottomPadding ? '0 10px 0px 10px' : '0 10px 48px 10px';
  }};
  min-height: 0;
`;

export const ButtonContainer = styled.div`
  justify-self: center;
  grid-row: 2;
`;

export const EditQuantityContainer = styled.div`
  align-self: flex-start;
`;

export const ItemInfoContainer = styled.div`
  display: flex;
  align-self: start;
  align-items: center;
  grid-row: 1;
  grid-column-start: 1;
  grid-column-end: ${(props: IGrid) => {
    return props.centered ? 'span 4' : 'span 3';
  }};
  padding: 0 0 30px 0;
`;

interface IItemInfo {
  quantity: number;
}

export const ItemInfoIconColumn = styled.div<IItemInfo>`
  display: flex;
  flex-direction: column;
  padding: ${props => (props.quantity > 1 ? '8px 20px 0px 0px' : '8px 15px 0px 0px')};
  align-self: start;
  height: 60px;
  width: 60px;
`;

export const ItemInfoTextColumn = styled.div<IItemInfo>`
  display: flex;
  flex-direction: column;
  align-self: start;
  justify-content: center;
  padding-top: ${props => (props.quantity > 1 ? '24px' : '30px')};
`;

export const TitleContainer = styled.div`
  align-self: center;
  grid-column: 2;
  grid-row: 2;
  grid-column-start: ${(props: IGrid) => {
    return props.centered ? 'span 2' : 'span 1';
  }};
`;

export const DescriptionContainer = styled.div`
  align-self: center;
  grid-column: 2;
  grid-row: 3;
  grid-column-start: ${(props: IGrid) => {
    return props.centered ? '2' : '1';
  }};
`;

export const ChildrenContainer = styled.div<ForegroundHeaderChildrenViewProps>`
  display: flex;
  grid-column-start: ${(props: ForegroundHeaderChildrenViewProps) =>
    props.fullRow ? 'span 2' : '2'};

  flex-direction: column;
  flex-grow: 1;
  min-height: 0;
  min-width: 0;
  overflow-y: auto;
  overflow-x: hidden;

  //prevent cutting shadows
  margin: -10px;
  padding: 10px;
  ${belowSize.md`
    grid-column-start: span 3

  `}

  ::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${colors.lightGray}33; //20% opacity
    border-radius: 5px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${colors.divider}80;
    border-radius: 10px;
  }
`;

export const Column = styled.div<ForegroundContentHeaderCSSProps>`
  display: flex;
  flex-direction: column;
  align-self: ${props => (props.quantityNotEditable ? 'end' : 'center')};
  justify-content: center;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  grid-column-start: span 2;
  background: ${colors.routeButtonFill4};
  justify-content: space-around;
  margin: -10px;
  padding: 20px;
`;
