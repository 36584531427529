import React, { FC } from 'react';
import { IGeneralInfoProps } from './interface';
import {
  Column,
  ContentRow,
  FlexibleSeparator,
  Row,
} from './GeneralInfo.styles';
import GeneralInfoRow from './GeneralInfoRow';
import { TypographyContents, Links } from './GeneralInfo.constants';
import Typography from '../UI/Typography';
import { GeneralInfoValueType } from './GeneralInfoRow/GeneralInfoRow.constants';
import { GeneralInfoRowValueProps } from './GeneralInfoRow/interface';
import HeadPhoneIcon from '../../assets/icons/headphone-icon.svg';
import PhoneIcon from '../../assets/icons/phone-icon.svg';
import InfoIcon from '../../assets/icons/info-icon.svg';
import { colors } from '../../constants/styles';

const GeneralInfo: FC<IGeneralInfoProps> = (props: IGeneralInfoProps) => {
  const generateSupportInfo = (): Array<GeneralInfoRowValueProps> => {
    return [
      { value: props.supportSite ?? '', type: GeneralInfoValueType.LINK },
      { value: props.supportEmail ?? '', type: GeneralInfoValueType.EMAIL },
    ];
  };

  const generatePhoneInfo = (): GeneralInfoRowValueProps => {
    return {
      value: props.merchantPhone ?? '',
      type: GeneralInfoValueType.PHONE,
    };
  };

  const generateQuestionsInfo = (): GeneralInfoRowValueProps => {
    return {
      value: TypographyContents.viewHelpCenter,
      type: GeneralInfoValueType.ARROW_LINK,
      linkRef: props.viewHelpCenterURL ?? Links.helpCenter,
    };
  };

  return (
    <Column>
      <Row>
        <Typography variant="h5" color={colors.routeText1}>
          {TypographyContents.title}
        </Typography>
      </Row>
      <ContentRow>
        {(props.supportEmail || props.supportSite) &&
          <>
            <GeneralInfoRow
              icon={HeadPhoneIcon}
              rowValues={generateSupportInfo()}
              title={TypographyContents.support}
            />
            <FlexibleSeparator />
          </>
        }
        {props.merchantPhone &&
          <>
            <GeneralInfoRow
              icon={PhoneIcon}
              rowValues={generatePhoneInfo()}
              title={TypographyContents.phoneNumber}
            />
            <FlexibleSeparator />
          </>
        }
        <GeneralInfoRow
          icon={InfoIcon}
          rowValues={generateQuestionsInfo()}
          title={TypographyContents.questions}
        />
      </ContentRow>
      <br />
    </Column>
  );
};

export default GeneralInfo;
