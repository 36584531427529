import { PreAssessmentResultEnum } from '../../models/Enums/PreAssessmentEnum.model';

export enum ScreenRoutes {
  HANG_TIGHT = '/item/hang-tight',
  HOW_CAN_WE_HELP = '/item/can-we-help',
  CONFIRM_TRACKING_NUMBER = '/item/confirm-tracking-number',
  ENTER_TRACKING_NUMBER = '/item/tracking-number-input',
  CONFIRM_CARRIER_UPDATE = '/item/confirm-carrier-update',
  ENTER_TRACKING_URL = '/item/tracking-url-input',
  DO_YOU_HAVE_TRACKING_NUMBER = '/item/tracking-number-confirmation',
  PACKAGE_NEVER_ARRIVED = '/item/never-arrived',
  LOADING_CLAIM_FLOW = '/item/loading',

  RESOLVE_CENTER = '/order-information',
  DAMAGED_FLOW = '/item/damage',
  EMAIL_DRAFT = '/order-help',
}

export enum ClaimFlowType {
  NO_TRACKING_NUMBER_FLOW,
  LABEL_CREATED_FLOW,
  TOO_EARLY_FLOW,
  REGULAR_FLOW,
}

export enum ClaimBlockingAction {
  OKAY,
  STILL_NEED_HELP,
  YES,
  NO,
  CONTINUE,
  NEVER_ARRIVED_PACKAGE,
  OTHER_ISSUE,
  SEND_EMAIL,
  DONT_HAVE_TRACKING_NUMBER,
}

export enum TypographyType {
  TITLE,
  DESCRIPTION,
}

export enum ClaimBlockingScreens {
  HANG_TIGHT,
  HOW_CAN_WE_HELP,
  CONFIRM_TRACKING_NUMBER,
  ENTER_TRACKING_NUMBER,
  CONFIRM_CARRIER_UPDATE,
  ENTER_TRACKING_URL,
  DO_YOU_HAVE_TRACKING_NUMBER,
  PACKAGE_NEVER_ARRIVED,
  LOADING_CLAIM_FLOW,
}

export const mainFlowMap = {
  [PreAssessmentResultEnum.PRESHIPMENT]: ClaimFlowType.LABEL_CREATED_FLOW,
  [PreAssessmentResultEnum.NO_TRACKING]: ClaimFlowType.NO_TRACKING_NUMBER_FLOW,
  [PreAssessmentResultEnum.IN_TRANSIT_WAIT_TO_FILE]:
    ClaimFlowType.TOO_EARLY_FLOW,
  [PreAssessmentResultEnum.CLAIM_BEFORE_EDD_DEADLINE]:
    ClaimFlowType.TOO_EARLY_FLOW,
  [PreAssessmentResultEnum.DELIVERED_WAIT_TO_FILE]:
    ClaimFlowType.TOO_EARLY_FLOW,
  [PreAssessmentResultEnum.IN_TRANSIT_DELAYED]: ClaimFlowType.REGULAR_FLOW,
  [PreAssessmentResultEnum.DELIVERED_EARLY]: ClaimFlowType.REGULAR_FLOW,
  [PreAssessmentResultEnum.DELIVERED]: ClaimFlowType.REGULAR_FLOW,
  [PreAssessmentResultEnum.PAST_DEADLINE]: ClaimFlowType.REGULAR_FLOW,
  [PreAssessmentResultEnum.SUPPOSEDLY_DELIVERED]: ClaimFlowType.REGULAR_FLOW,
};

export const ROUTE_NUMBER_REGEX = new RegExp(/^RT(\w|-){20}$/);
export const TRACKING_NUMBER_REGEX = new RegExp(/^(\w|-)+$/);
