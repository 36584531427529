import React from 'react';
import { observer } from 'mobx-react';
import { useStores } from './hooks/app.hook';
import { createBrowserHistory } from 'history';
import { syncHistoryWithStore } from 'mobx-react-router';
import { Router, Switch, Route } from 'react-router-dom';

import './App.css';

import RouteWithOrder from './components/RouteWithOrder/RouteWithOrder';

import NotFound from './views/NotFound';
import OrderNoAction from './views/OrderNoAction';
import OrderIssueAction from './views/OrderIssueAction';
import GenericError from './views/GenericError';
import ClaimedItemsSelector from './views/ClaimedItems/ClaimedItemsSelector';
import UnrelatedProblem from './views/UnrelatedProblem';
import { InstantAssessmentResultEnum } from './models/Enums/PreAssessmentEnum.model';
import NotApproved from './views/NotApproved';
import EmailSurveyFeedback from './views/EmailSurveyFeedback';
import IssueSummary from './views/Issue/IssueSummary';

import BrandRedirect from '@brands/views/BrandRedirect'
import { printVersion } from './utils';
import RootRedirect from './components/RootRedirect/RootRedirect';

printVersion()

const App: React.FC = observer(() => {
  const { routingStore, orderStore, issueStore } = useStores();
  const browserHistory =
    routingStore.history ??
    syncHistoryWithStore(createBrowserHistory(), routingStore);

  return (
    <Router history={browserHistory}>
      <Switch>
        <Route exact path="/">
          <RootRedirect />
        </Route>

        <RouteWithOrder path={['/order-information', '/summary', '/item']}>
          {orderStore.orderHasNoAction() ? (
            <OrderNoAction />
          ) : (
            <OrderIssueAction />
          )}
        </RouteWithOrder>

        <RouteWithOrder exact path="/order-help">
          <UnrelatedProblem />
        </RouteWithOrder>

        <Route exact path="/email-survey">
          <EmailSurveyFeedback />
        </Route>

        <RouteWithOrder exact path="/issue-redirecting" issueRequired>
          <BrandRedirect />
        </RouteWithOrder>

        <RouteWithOrder exact path="/issue-summary" issueRequired>
          {issueStore.instantAssessmentResult ===
            InstantAssessmentResultEnum.DENIED ? (
            <NotApproved />
          ) : (
            <IssueSummary />
          )}
        </RouteWithOrder>

        <Route path="*">
          <NotFound />
        </Route>
      </Switch>

      <Route path="/item">
        <ClaimedItemsSelector />
      </Route>



      <GenericError />
    </Router>
  );
});

export default App;
