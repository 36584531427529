/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect } from 'react';
import { useStores } from '../../../../hooks/app.hook';
import { TypographyContent } from './ClaimedItemsReviewEditDetails.constants';
import TextField from '../../../../components/Inputs/TextField';
import { MainContainer, Separator } from '../ClaimedItemsReview.styles';
import CleanButton from '../../../../components/Buttons/CleanButton';
import { BottomRow, UpperRow } from './ClaimedItemsReviewEditDetails.styles';
import { observer } from 'mobx-react';
import { VerticalSeparator } from '../../../../styles/global.styles';
import { VIEWS } from '../../../../constants/views';
import { broadcastView } from '../../../../services/broadcast';

const ClaimedItemsReviewEditDetails: FC = observer(() => {
  const { claimStore, reviewStore } = useStores();

  useEffect(() => {
    broadcastView(VIEWS.CLAIM_ITEM_REVIEW_EDIT_DETAILS)
  }, [])

  const saveDetails = () => {
    claimStore.setDescription(reviewStore.editedDetailsField);
    reviewStore.changeEditDetails();
  };

  return (
    <MainContainer>
      <UpperRow>
        <TextField
          value={reviewStore.editedDetailsField}
          noLine
          noTitle
          multiline
          isError={false}
          placeholder={TypographyContent.descriptionPlaceholder}
          onChange={value => {
            reviewStore.setDetailsField(value);
          }}
        />
      </UpperRow>

      <VerticalSeparator distance={300} />
      <Separator />

      <BottomRow>
        <CleanButton onClick={saveDetails}>
          {TypographyContent.doneButton}
        </CleanButton>
      </BottomRow>
    </MainContainer>
  );
});

export default ClaimedItemsReviewEditDetails;
