import { FC } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../../hooks/app.hook';
import { Redirect, Route, Switch } from 'react-router-dom';
import ClaimedItemsDamagedPhotoUpload from './ClaimedItemsDamaged/ClaimedItemsDamagedPhotoUpload';
import BlurredWrapper from '../../components/UI/BlurredWrapper';
import { ForwardIn } from '../../styles/global.styles';
import ClaimedItemsDamagedDescription from './ClaimedItemsDamaged/ClaimedItemsDamagedDescription';
import WISMO from './WISMO';
import ClaimedItemsResolutionPreference from './ClaimedItemsResolutionPreference';
import ClaimedItemsEditQuantity from './ClaimedItemsEditQuantity';
import ClaimedItemsStolenDetails from './ClaimedItemsStolen/ClaimedItemsStolenDetails';
import ClaimedItemsStolenPoliceReport from './ClaimedItemsStolen/ClaimedItemsStolenPoliceReport';
import PerishableItems from './PerishableItems';
import ClaimedItemsLostDetails from './ClaimedItemsLostDetails';
import ShippingIssueSelection from './ShippingIssueSelection';
import ClaimedItemsPhoneVerification from './ClaimedItemsPhoneVerification';
import ClaimedItemsPhoneVerificationLimit from './ClaimedItemsPhoneVerification/ClaimedItemsPhoneVerificationLimit';
import ClaimedItemsReviewDetails from './ClaimedItemsReview';
import ClaimedItemsPaypalEmailLanding from './ClaimedItemsPaypalInformation/ClaimedItemsPaypalLanding';
import ClaimedItemsPaypalEmailCollection from './ClaimedItemsPaypalInformation/ClaimedItemsPaypalEmailCollection';
import ClaimedItemsShippingInformationCollection from './ClaimedItemsShippingInformation/ClaimedItemsShippingInformationCollection';
import ClaimedItemsShippingInformationEdit from './ClaimedItemsShippingInformation/ClaimedItemsShippingInformationEdit';
import ClaimedItemsRefundRemedySurvey from './ClaimedItemRefundRemedy/ClaimedItemRefundRemedySurvey';
import ClaimFlowDecider from './ClaimFlowDecider';
import HangTight from './ClaimedItemBlockingFlow/HangTight';
import LoadingClaimFlow from './ClaimedItemBlockingFlow/LoadingClaimFlow';
import DoYouHaveTrackingNumber from './ClaimedItemBlockingFlow/DoYouHaveTrackingNumber';
import EnterTrackingURL from './ClaimedItemBlockingFlow/EnterTrackingURL';
import ConfirmCarrierUpdate from './ClaimedItemBlockingFlow/ConfirmCarrierUpdate';
import EnterTrackingNumber from './ClaimedItemBlockingFlow/EnterTrackingNumber';
import ConfirmTrackingNumber from './ClaimedItemBlockingFlow/ConfirmTrackingNumber';
import HowCanWeHelp from './ClaimedItemBlockingFlow/HowCanWeHelp';
import { ScreenRoutes } from '../../stores/_constants/ClaimBlockingFlow.constants';
import ClaimedItemsGiftCardDetails from './ClaimedItemsGiftCard/ClaimedItemsGiftCardDetails/ClaimedItemsGiftCardDetails';
import ClaimedItemsVenmoDetails from './ClaimedItemsVenmoDetails/ClaimedItemsVenmoDetails';
import PackageNeverArrived from './ClaimedItemBlockingFlow/PackageNeverArrived';
import FraudVerificationWrapper from './FraudVerificationWrapper';

const ClaimedItemsSelector: FC = observer(() => {
  const { claimStore, orderStore } = useStores();

  if (orderStore.routeOrder && claimStore.isSelectedItems()) {
    return (
      <BlurredWrapper open>
        <Switch>
          <Route exact path="/item">
            <ClaimFlowDecider />
          </Route>
          <Route>
            <ForwardIn>
              {claimStore.editingQuantity ? (
                <ClaimedItemsEditQuantity />
              ) : (
                <Switch>
                  <Route path="/item/resolution">
                    <Switch>
                      <Route exact path="/item/resolution/refund-survey">
                        <ClaimedItemsRefundRemedySurvey />
                      </Route>
                      <Route exact path="/item/resolution/paypal">
                        <ClaimedItemsPaypalEmailLanding />
                      </Route>
                      <Route exact path="/item/resolution/paypal-email">
                        <ClaimedItemsPaypalEmailCollection />
                      </Route>
                      <Route exact path="/item/resolution/venmo">
                        <ClaimedItemsVenmoDetails />
                      </Route>
                      <Route exact path="/item/resolution/gift-card-details">
                        <ClaimedItemsGiftCardDetails />
                      </Route>
                      <Route exact path="/item/resolution/shipping-information">
                        <ClaimedItemsShippingInformationCollection />
                      </Route>
                      <Route
                        exact
                        path="/item/resolution/shipping-information-edit"
                      >
                        <ClaimedItemsShippingInformationEdit />
                      </Route>
                      <Route>
                        <ClaimedItemsResolutionPreference />
                      </Route>
                    </Switch>
                  </Route>
                  <Route exact path="/item/tracking">
                    <WISMO />
                  </Route>
                  <Route exact path="/item/perishable">
                    <PerishableItems />
                  </Route>
                  <Route exact path="/item/shipping-issue">
                    <ShippingIssueSelection />
                  </Route>
                  <Route exact path="/item/review-details">
                    <ClaimedItemsReviewDetails />
                  </Route>
                  <Route path="/item/damage">
                    <Switch>
                      <Route exact path="/item/damage/description">
                        <ClaimedItemsDamagedDescription />
                      </Route>
                      <Route>
                        {claimStore.needsPhotoOnDamaged() ? (
                          <ClaimedItemsDamagedPhotoUpload />
                        ) : (
                          <Redirect to="/item/damage/description" />
                        )}
                      </Route>
                    </Switch>
                  </Route>
                  <Route path="/item/stolen">
                    <Switch>
                      <Route exact path="/item/stolen/police-report">
                        <ClaimedItemsStolenPoliceReport />
                      </Route>
                      <Route>
                        <ClaimedItemsStolenDetails />
                      </Route>
                    </Switch>
                  </Route>
                  <Route path="/item/lost">
                    <ClaimedItemsLostDetails />
                  </Route>
                  <Route exact path="/item/phone-verification">
                    {claimStore.needsPhoneValidation() ? (
                      <ClaimedItemsPhoneVerification />
                    ) : (
                      <Redirect to="/item/shipping-issue" />
                    )}
                  </Route>
                  <Route exact path="/item/verification">
                    <FraudVerificationWrapper />
                  </Route>
                  <Route exact path="/item/phone-verification/limit">
                    <ClaimedItemsPhoneVerificationLimit />
                  </Route>
                  <Route exact path={ScreenRoutes.HANG_TIGHT}>
                    <HangTight />
                  </Route>
                  <Route exact path={ScreenRoutes.HOW_CAN_WE_HELP}>
                    <HowCanWeHelp />
                  </Route>
                  <Route exact path={ScreenRoutes.PACKAGE_NEVER_ARRIVED}>
                    <PackageNeverArrived />
                  </Route>
                  <Route exact path={ScreenRoutes.CONFIRM_TRACKING_NUMBER}>
                    <ConfirmTrackingNumber />
                  </Route>
                  <Route exact path={ScreenRoutes.ENTER_TRACKING_NUMBER}>
                    <EnterTrackingNumber />
                  </Route>
                  <Route exact path={ScreenRoutes.CONFIRM_CARRIER_UPDATE}>
                    <ConfirmCarrierUpdate />
                  </Route>
                  <Route exact path={ScreenRoutes.ENTER_TRACKING_URL}>
                    <EnterTrackingURL />
                  </Route>
                  <Route exact path={ScreenRoutes.DO_YOU_HAVE_TRACKING_NUMBER}>
                    <DoYouHaveTrackingNumber />
                  </Route>
                  <Route exact path={ScreenRoutes.LOADING_CLAIM_FLOW}>
                    <LoadingClaimFlow />
                  </Route>
                  <Route>
                    <Redirect to="/not-found" />
                  </Route>
                </Switch>
              )}
            </ForwardIn>
          </Route>
        </Switch>
      </BlurredWrapper>
    );
  }
  return <Redirect to="/" />;
});

export default ClaimedItemsSelector;
