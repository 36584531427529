export enum OrderStatusEnum {
  EMPTY = '',
  CREATED = 'created',
  TEST = 'test',
  CANCELLED = 'cancelled',
  ARCHIVED = 'archived',
}

export enum IssueStatusTextColorEnum {
  RED = 'red',
  BLUE = 'blue',
  GREEN = 'green',
  YELLOW = 'yellow',
  TEXT = 'text',
  GRAY = 'gray',
}

export enum OrderInsuredStatusEnum {
  EMPTY = '',
  NOT_INSURED = 'not_insured',
  INSURED_SELECTED = 'insured_selected',
  INSURED_NOT_SELECTED = 'insured_not_selected',
}

export enum ResolutionEnum {
  REFUND = 'refund',
  REFUND_PAYPAL = 'refund_via_paypal',
  REFUND_VENMO = 'refund_via_venmo',
  REPLACE = 'replace',
  REFUND_ONLY = 'refund_only',
  REORDER = 'reorder',
  PREPAID_CARD = 'refund_via_prepaid_card',
  PREPAID_CARD_MASTERCARD = 'mastercard_prepaid_card',
  GIFT_CARD_MERCHANT = 'merchant_gift_card',
  NONE = '',
}

export enum MerchantContactTypeEnum {
  SUPPORT = 'support',
  COMPANY = 'company',
  RETURNS = 'returns',
}

export enum ProgressIndicatorEnum {
  ZERO = 0,
  ONE = 1,
  TWO = 2,
  THREE = 3,
}

export enum MerchantSettingFlag {
  AFFIDAVIT_VERIFICATION = 'affidavit_verification',
  PHONE_VERIFICATION = 'phone_verification',
  CUSTOMER_DATA_COLLECTION = 'remedy_customer_data_collection',
  REQUIRED_PHOTO_ON_DAMAGED = 'required_photo_on_damaged_claim',
}

export enum IssueStatusEnum {
  APPROVED_RESULT = 'approved',
  SOFT_DENIED_RESULT = 'soft_denied',
  PENDING_REVIEW_RESULT = 'pending_review',
  IN_REVIEW_RESULT = 'in_review',
  NOT_APPROVED_RESULT = 'not_approved',
  ACTION_REQUIRED_RESULT = 'action_required',
  REMEDY_STATUS_FINALIZED = 'finalized',
  REMEDY_STATUS_CANCELLED = 'cancelled',
  REMEDY_STATUS_REPLACEMENT_IN_PROGRESS = 'replacement_in_progress',
  REMEDY_STATUS_REFUND_IN_PROGRESS = 'refund_in_progress',
}
