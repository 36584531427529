import styled from 'styled-components';
import { aboveSize, belowSize } from '../../utils/Breakpoints';

export interface IExtendedProps {
  extended?: boolean;
  extendedVertical?: boolean;
}

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 16px;
  ${belowSize.md`
      margin-top: 18px;
  `}
`;

export const ContentRow = styled.div`
  display: flex;
  justify-content: space-between;

  ${belowSize.lg`
    flex-direction: column;
  `}
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-grow: 1;
  width: ${(props: IExtendedProps) =>
    !props.extendedVertical ? (!props.extended ? '33%' : '100%') : 'unset'};
  min-width: 240px;
  max-width: ${(props: IExtendedProps) =>
    !props.extendedVertical ? (!props.extended ? '330px' : 'unset') : 'unset'};
  ${belowSize.md`
      max-width: unset;
      width: unset;
  `}
`;

export const Separator = styled.div`
  margin: 16px 0;
  background-position: top;
  background-size: 6px 1px;
  background-repeat: repeat-x;
  border: 0.5px solid rgba(216, 216, 216, 0.7);
`;

export const InvisibleSeparator = styled.div`
  ${aboveSize.md`
    margin: 0 2%;
  `}
`;
export const ButtonContainer = styled.div`
  margin-right: 7px;
`;

export const TooltipContainer = styled.div`
  position: absolute;
  margin-left: 40px;
  margin-top: -4px;
`;
