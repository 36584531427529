import { FC, useRef } from "react";
import { ITooltipProps } from "./interface";
import styled from "styled-components";
import { colors } from "../../../constants/styles";
import CloseIconSVG from '../../../assets/icons/close-circle-icon.svg';
import { useClickOutside } from "../../../hooks/app.hook";

export const TooltipNewDesign: FC<ITooltipProps> = (props: ITooltipProps) => {


    const clickRef = useRef<HTMLDivElement>(null);
    const onClickOutside = () => {
        props.onClose();
    };
    useClickOutside(clickRef, onClickOutside);

    return props.isOpen ? (
        <ToolTipWrapper centered={props.centered} ref={clickRef}>
            {props.children}
            <CloseIcon onClick={() => props.onClose()} src={CloseIconSVG}>
            </CloseIcon>
        </ToolTipWrapper>
    ) : null;

}


export interface IWrapperProps {
    centered?: boolean;
}

const ToolTipWrapper = styled.div`
    position: absolute;
    display: flex;
    padding: 16px;
    border-radius: 6px;
    background: ${colors.white};
    color: ${colors.routeText1};
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.16);
    z-index: 3;
    bottom:100%;
    width: 240px;
    left: ${(props: IWrapperProps) =>
        props.centered ? '-110px' : '-220px'};


    &::after {

        content: "";
        position: absolute;

        height: 0;
        width: 0;

        top: 99%;
        right: ${(props: IWrapperProps) => props.centered ? '48.5%' : '21px'};

        border-style: solid;
        border-width: 8px 8px 0 8px;
        border-color: white transparent transparent transparent;

        transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -webkit-transform: translateX(-50%);

    }
`

export const CloseIcon = styled.img`
  cursor: pointer;
  display: flex;
  margin-right: -6px;
  margin-top: -6px;
  margin-left: 6px;
  height: 15px;
  width: 15px;
`;
