export const TypographyContents = {
  questions: 'Still have questions?',
  viewHelpCenter: 'View Help Center',
  phoneNumber: 'Phone Number',
  support: 'Support',
  title: 'General Info',
};

export const Links = {
  helpCenter: 'https://help.route.com',
};
