import dayjs from 'dayjs';
import { ClaimTypeEnum } from '../../../models/Enums/ClaimEnum.model';
import {
  DeliveryStatusEnum,
  deliveryStatusLocalized,
} from '../../../models/Enums/DeliveryStatusEnum.model';
import { ResolutionEnum } from '../../../models/Enums/OrderEnum.model';

export const TypographyContent = {
  claimTypeDefaultText: (claimType: ClaimTypeEnum): string => {
    switch (claimType) {
      case ClaimTypeEnum.DAMAGED:
        return 'Damaged';
      case ClaimTypeEnum.LOST:
        return 'Lost';
      case ClaimTypeEnum.STOLEN:
        return 'Stolen';
    }
  },

  refundPayPal: 'Refund via PayPal',

  refundEmail: (email: string): string => {
    return `To ${email}`;
  },

  replacementShippingInfo: 'Shipping Info',

  claimDetails: 'Claim Details',
  issueReported: 'Issue Reported:',
  notes: 'Notes:',
  preferredResolutionTitle: 'Resolution Preference:',

  preferredResolutionType: {
    replace: 'Replacement',
    refund: 'Refund',
    other: 'None',
  },

  venmoAccount: 'Venmo Account:',
  prepaidCardInfo: 'Prepaid Card Recipient Info:',
  giftCardInfo: 'Gift Card Delivery Email:',

  addressInfo: (city?: string, state?: string, zip?: string): string => {
    let str = '';

    if (city) {
      str += city;
      if (state || zip) str += ', ';
      if (state) str += state + ' ';
      if (zip) str += zip;
    } else {
      if (state) str += state + ' ';
      if (zip) str += zip;
    }

    return str;
  },

  shippingStatus: (status: DeliveryStatusEnum, date: string): string => {
    return `Status: ${deliveryStatusLocalized(status)} · ${dayjs(date).format(
      'MMMM D YYYY, h:mm A'
    )}`;
  },

  edd: (date: string): string => {
    return `Estimated delivery: ${dayjs(date).format('MMMM D, YYYY')}`;
  },

  resolutionType: (customerPreferredResolution: ResolutionEnum | null): string => {
    if (customerPreferredResolution) {
      switch (customerPreferredResolution) {
        case ResolutionEnum.REFUND:
          return 'Refund original payment method';
        case ResolutionEnum.REPLACE:
        case ResolutionEnum.REORDER:
          return 'Replace item';
        case ResolutionEnum.REFUND_PAYPAL:
          return 'Refund via PayPal';
        case ResolutionEnum.REFUND_VENMO:
          return 'Refund via Venmo';
        case ResolutionEnum.PREPAID_CARD:
        case ResolutionEnum.PREPAID_CARD_MASTERCARD:
          return 'Refund to Mastercard®';
        case ResolutionEnum.GIFT_CARD_MERCHANT:
          return 'Refund to Gift Card';
      }
    }
    return '';
  },

  viewHelpCenter: 'View Help Center',
  support: 'Support',
};

export const Links = {
  helpCenter: 'https://help.route.com',
};