import styled from 'styled-components';
import { colors } from '../../../../constants/styles';
import { NoSlide } from '../../../../styles/global.styles';

export const Container = styled(NoSlide)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-self: stretch;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${colors.lightGray}33; // 20% opacity
    border-radius: 5px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${colors.divider}80;
    border-radius: 10px;
  }
`;

export const MainButtonContainer = styled.div`
  display: flex;
`;

export const EditContainer = styled.div`
  margin: 0 10px;
`;

export const SubtitleContainer = styled.div`
  margin-top: 17px;
  margin-bottom: 32px;
  text-align: center;
`;

export const CityStateLine = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  gap: 16px;
`;

export const CityContainer = styled.div`
  min-width: 0;
  flex-grow: 1;
`;

export const StateContainer = styled.div`
  min-width: 0;
  flex-grow: 1;
`;
