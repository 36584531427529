import React, { FC, useState } from 'react';
import { colors } from '../../constants/styles';
import Button from '../Buttons/Button';
import TextField from '../Inputs/TextField';
import Typography from '../UI/Typography';
import { TypographyContent } from './EmailModal.constants';
import BlurredWrapper from '../UI/BlurredWrapper';
import {
  ButtonsContainer,
  Column,
  Container,
  ContainerFlex,
  EmailContent,
  EmailRecipient,
  RoundPaper,
  TitleContainer,
} from './EmailModal.styles';
import { IEmailModalProps } from './interface';

const EmailModal: FC<IEmailModalProps> = (props: IEmailModalProps) => {
  const { open, emailTo, description } = props;

  const [emailDescription, setEmailDescription] = useState(description);

  const copyAllText = () => {
    return emailDescription;
  };

  const handleCopyAll = () => {
    navigator.clipboard.writeText(copyAllText());
    if (props.onCopy) props.onCopy();
  };

  const handleClose = () => {
    props.onClose();
  };

  return (
    <div>
      {open && (
        <BlurredWrapper open>
          <ContainerFlex>
            <Container>
              <TitleContainer>
                <Typography variant="h5" color={colors.routeText1}>
                  {TypographyContent.title}
                </Typography>
              </TitleContainer>

              <EmailRecipient>
                <RoundPaper>
                  <Typography variant="p5" color={colors.routeText2}>
                    {TypographyContent.emailRecipient(emailTo)}
                  </Typography>
                </RoundPaper>
              </EmailRecipient>

              <EmailContent>
                <RoundPaper grow>
                  <TextField
                    value={emailDescription}
                    noLine
                    noTitle
                    isError={false}
                    placeholder={''}
                    onChange={value => {
                      setEmailDescription(value);
                    }}
                    multiline
                    fullHeight
                    textColor={colors.routeText2}
                    fontSize={'17px'}
                    lineHeight={'24px'}
                    fontWeight={'400'}
                  />
                </RoundPaper>
              </EmailContent>
            </Container>

            <ButtonsContainer>
              <Column>
                <Button fullWidth onClick={handleCopyAll}>
                  {TypographyContent.copyButton}
                </Button>
                <Button fullWidth onClick={handleClose} mode="light">
                  {TypographyContent.closeButton}
                </Button>
              </Column>
            </ButtonsContainer>
          </ContainerFlex>
        </BlurredWrapper>
      )}
    </div>
  );
};

export default EmailModal;
