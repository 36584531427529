import React, { FC, useEffect, useRef } from 'react';
import lottie, { AnimationEventName } from 'lottie-web';
import ResolveLoading from '../../../../assets/animations/resolve/resolveLoading.json';

import { useStores } from '../../../../hooks/app.hook';
import { ClaimSubmissionProgressAnimationContainer } from './ClaimSubmissionProgressAnimation.styles';

export const ClaimSubmissionProgressAnimation: FC = () => {
  const lottieContainer = useRef<HTMLDivElement>(null);
  const { claimStore } = useStores();

  useEffect(() => {
    if (lottieContainer.current) {
      const animation = lottie.loadAnimation({
        container: lottieContainer.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: ResolveLoading,
        name: 'loading',
      });

      const loopCompleteEventName: AnimationEventName = 'loopComplete';
      animation.addEventListener(loopCompleteEventName, () => {
        if (claimStore.loadingAnimationFinished) {
          animation.removeEventListener(loopCompleteEventName);
          animation.stop();
          animation.destroy();
        } else {
          claimStore.progressLoopCompleteEventHandler();
        }
      });
    }
  }, [claimStore]);
  return (
    <ClaimSubmissionProgressAnimationContainer
      ref={lottieContainer}
      id={'submission-progress-animation'}
    />
  );
};
