import React, { FC, useEffect } from 'react';
import Paper from '../../components/UI/Paper';
import { Container, Image, Center } from './NotFound.styles';

import { useStores } from '../../hooks/app.hook';
import Button from '../../components/Buttons/Button';
import NotFoundCharacter from '../../assets/images/not-found-v2.svg';
import { isMobileView } from '../../utils/Breakpoints';
import { Page } from '../../styles/global.styles';
import AppStoreHeader from '../../components/UI/AppStoreHeader';
import Footer from '../../components/UI/Footer';
import { broadcastView } from '../../services/broadcast';
import { VIEWS } from '../../constants/views';

const NotFound: FC = () => {
  const { routingStore, commonStore } = useStores();

  useEffect(() => {
    broadcastView(VIEWS.NOT_FOUND)
  }, [])

  useEffect(() => {
    commonStore.reset();
  }, [commonStore]);

  const mobile = isMobileView();
  const clickBackHandle = () => {
    routingStore.push('/');
  };

  return (
    <Page>
      <AppStoreHeader />
      <Paper>
        <Container>
          <h1>404</h1>
          {mobile && (
            <Image alt="not-found-character" src={NotFoundCharacter} />
          )}
          <h2>This route doesn't looks familiar.</h2>
          <Center>
            <Button fullWidth onClick={clickBackHandle}>Go Back</Button>
          </Center>
        </Container>
      </Paper>
      <Footer />
    </Page>
  );
};

export default NotFound;
