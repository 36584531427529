import styled from 'styled-components';
import { colors } from '../../constants/styles';
import { aboveSize, belowSize } from '../../utils/Breakpoints';

export interface IExtendedProps {
  extended?: boolean;
}

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-grow: 1;
  width: 100%;
`;

export const ContentRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 16px 0;

  ${belowSize.md`
    flex-direction: column;
  `}
`;

export const FlexibleSeparator = styled.div`
  ${aboveSize.md`
    margin: 0 2%;
  `}
  ${belowSize.md`
    margin: 16px;
    background-image: linear-gradient(
      to right,
      ${colors.routeText2} 10%,
      rgba(255, 255, 255, 0) 0%
    );
    background-position: top;
    background-size: 6px 1px;
    background-repeat: repeat-x;
    height: 1px;
  `}
`;
