import styled from 'styled-components';
import ItemIcon from '../../../../components/IconOrLogos/ItemIcon';
import PhotoThumbnail from '../../../../components/IconOrLogos/PhotoThumbnail';
import Typography from '../../../../components/UI/Typography';
import { colors } from '../../../../constants/styles';
import { aboveSize, belowSize } from '../../../../utils/Breakpoints';
import {
  RowRightContainer,
} from '../ClaimedItemsReview.styles';

export const TextContainer = styled(Typography)`
  display: flex;
  flex-grow: 1;
  flex-basis: min-content;
`;

export const RowLeftContainerAffidavit = styled.div`
  max-width: 55px;
  align-self: flex-start;
  padding-right: 12px;
`;

export const RowRightContainerAffidavit = styled(RowRightContainer)`
  flex-basis: min-content;
  flex-grow: 3;
  margin-bottom: 6px;
  align-items: flex-start;
  gap: 10px;
`;

export const SliderPhotoRow = styled.div`
  display: flex;
`;

export const Photos = styled.div`
  display: flex;
  flex-grow: 1;
  overflow-x: auto;

  ::-webkit-scrollbar {
    height: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${colors.lightGray}33; //20% opacity
    border-radius: 5px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${colors.divider}80;
    border-radius: 10px;
  }
`;

export const PhotoThumbnailStyled = styled(PhotoThumbnail)`
  margin: 0px 5px 0px 0px;

  display: flex;

  justify-content: flex-end;
  padding: 4px;
  ${belowSize.md`
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-width: 40px;
  `}
  ${aboveSize.md`
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-width: 50px;
  `}
`;

export const ItemIconStyled = styled(ItemIcon)`
  margin-right: 8px;
  box-shadow: unset;
`;

export const Container = styled.div`
  display: flex;
`;
