/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import { ButtonsContainer, TitleContainer } from './HowCanWeHelp.styles';
import { useStores } from '../../../../hooks/app.hook';
import { logEvent, analyticsCodes } from '../../../../services/analytics';
import { colors } from '../../../../constants/styles';
import { Icon, VerticalSeparator } from '../../../../styles/global.styles';

import { ButtonContent, GET_HELP_SOMETHING_ELSE_LINK } from './HowCanWeHelp.constants';
import { isMobileView } from '../../../../utils/Breakpoints';
import ClickableContainerRow from '../../../../components/Buttons/ClickableContainerRow';
import {
  ClaimBlockingAction,
  ClaimBlockingScreens,
  ScreenRoutes,
} from '../../../../stores/_constants/ClaimBlockingFlow.constants';
import { broadCastAction, broadcastView } from '../../../../services/broadcast';
import { VIEWS } from '../../../../constants/views';
import { ClaimTypeEnum } from '../../../../models/Enums/ClaimEnum.model';
import { ACTIONS } from '../../../../constants/actions';
import ForegroundContentModalNew from '../../../../components/ForegroundContentModalNew';
import Typography from '../../../../components/UI/Typography';
import LostIcon from '../../../../assets/icons/magnifier-icon.svg';
import UnrelatedIcon from '../../../../assets/icons/unrelated-icon.svg';
import DamagedIcon from '../../../../assets/icons/damaged-icon.svg';
import ReturnIcon from '../../../../assets/icons/return-icon.svg';

const HowCanWeHelp: FC = observer(() => {
  const {
    claimStore,
    routingStore,
    commonStore,
    orderStore,
    claimBlockingStore,
  } = useStores();

  const screen = ClaimBlockingScreens.HOW_CAN_WE_HELP;

  const item = claimStore.displayItem;

  if (!item) {
    commonStore.displayError();
    return null;
  }

  const damagedClickHandle = () => {
    claimStore.initializeClaim(ClaimTypeEnum.DAMAGED);
  };

  const neverArrivedClickHandle = () => {
    routingStore.push(
      claimBlockingStore.getButtonRoute(
        screen,
        ClaimBlockingAction.NEVER_ARRIVED_PACKAGE
      )
    );
  };

  const otherClickHandle = () => {
    window.open(GET_HELP_SOMETHING_ELSE_LINK, '_blank', 'noreferrer');
    routingStore.push(ScreenRoutes.RESOLVE_CENTER);
  };

  const returnClickHandle = () => {
    if (orderStore.routeOrder?.merchant.returnsUrl)
      window.open(orderStore.routeOrder?.merchant.returnsUrl, '_blank');
  };

  const cancelClickHandle = () => {
    claimStore.reset();
    claimBlockingStore.reset();
    broadCastAction(ACTIONS.CANCEL_CLAIM_FILING);
    routingStore.replace('/order-information');
  };

  useEffect(() => {
    broadcastView(VIEWS.CLAIM_ITEM_BLOCKING_HOW_CAN_WE_HELP);
    logEvent(analyticsCodes.screenView, {
      screen: claimBlockingStore.getAnalytics(screen),
      merchantId: orderStore.getOrderDetails()?.merchantID,
      orderId: orderStore.getOrderDetails()?.id,
    });
  }, []);

  const getTitle = () => {
    return claimBlockingStore.getTitle(screen);
  };

  const mobile = isMobileView();

  const claimTextCategories = claimStore.getClaimTextCategories();

  return (
    <ForegroundContentModalNew
      secondaryButton={ButtonContent.cancel(claimTextCategories.cancel)}
      secondaryButtonClick={cancelClickHandle}
    >
      <TitleContainer >
        <Typography variant="h5" color={colors.routeText1}>
          {getTitle()}
        </Typography>
      </TitleContainer>

      <ButtonsContainer>
        <ClickableContainerRow
          icon={<Icon src={LostIcon} alt={'lost magnifying glass icon'} verticalAlign="middle" />}
          onClick={neverArrivedClickHandle}
          backgroundColor={colors.routeButtonFill3}
          text={ButtonContent.neverArrived(claimTextCategories.neverArrived)}
          fontVariant={!mobile ? 'h7' : 'p4'}
          padding="12px 16px 12px 24px"
        />

        <VerticalSeparator distance={16} />

        <ClickableContainerRow
          icon={<Icon src={DamagedIcon} alt={'damaged glass icon'} verticalAlign="middle" />}
          onClick={damagedClickHandle}
          backgroundColor={colors.routeButtonFill3}
          text={ButtonContent.damaged(claimTextCategories.damaged)}
          fontVariant={!mobile ? 'h7' : 'p4'}
          padding="12px 16px 12px 24px"
        />


        {orderStore.routeOrder?.merchant.returnsUrl && orderStore.routeOrder?.merchant.returnsUrl !== '' && (
          <>
            <VerticalSeparator distance={16} />

            <ClickableContainerRow
              icon={<Icon src={ReturnIcon} alt={'return refresh icon'} verticalAlign="middle" />}
              onClick={returnClickHandle}
              backgroundColor={colors.routeButtonFill3}
              text={ButtonContent.return(claimTextCategories.return)}
              fontVariant={!mobile ? 'h7' : 'p4'}
              padding="12px 16px 12px 24px"
            />
          </>
        )}

        <VerticalSeparator distance={16} />

        <ClickableContainerRow
          icon={<Icon src={UnrelatedIcon} alt={'four square icon'} verticalAlign="middle" />}
          onClick={otherClickHandle}
          backgroundColor={colors.routeButtonFill3}
          text={ButtonContent.other(claimTextCategories.other)}
          fontVariant={!mobile ? 'h7' : 'p4'}
          padding="12px 16px 12px 24px"
        />
      </ButtonsContainer>

    </ForegroundContentModalNew>
  );
});

export default HowCanWeHelp;
