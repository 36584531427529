/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../../../hooks/app.hook';
import BackIcon from '../../../assets/icons/back-icon.svg';
import CloseIcon from '../../../assets/icons/close-icon.svg';
import { analyticsCodes, logEvent } from '../../../services/analytics';
import ClaimedItemsReviewEditDetails from './ClaimedItemsReviewEditDetails';
import ClaimedItemsReviewIssueType from './ClaimedItemsReviewEditIssueType';
import ClaimedItemsReviewMainScreen from './ClaimedItemsReviewMainScreen';
import { ClaimSubmissionProgressDisplay } from '../ClaimSubmissionDisplay/ClaimSubmissionProgressDisplay/ClaimSubmissionProgressDisplay';
import { ClaimApprovalDisplay } from '../ClaimSubmissionDisplay/ClaimApprovalDisplay/ClaimApprovalDisplay';
import ForegroundContentPage from '../../../components/ForegroundContentPage';
import { ACTIONS } from '../../../constants/actions';
import { broadCastAction } from '../../../services/broadcast';

const ClaimedItemsReviewDetails: FC = observer(() => {
  const {
    claimStore,
    routingStore,
    commonStore,
    reviewStore,
    damagedStore,
    orderStore,
  } = useStores();

  const {
    displayItem: item,
    loading,
    showInstantApprovalCheckbox,
    loadingAnimationFinished,
    submitInProgress,
  } = claimStore;

  const {
    editIssueType,
    editPhotos,
    editDetails,
    editResolution,
  } = reviewStore;

  if (!item) {
    commonStore.displayError();
    return null;
  }

  useEffect(() => {
    logEvent(analyticsCodes.resolveReview, {});
  }, []);

  const submitClickHandle = async () => {
    claimStore.submittingClaimFromReviewDetailsScreen = true;
    claimStore.submitClaim();
  };

  const cancelClickHandle = async () => {
    claimStore.reset();
    reviewStore.reset();
    damagedStore.reset();
    broadCastAction(ACTIONS.CANCEL_CLAIM_FILING);
    routingStore.replace('/order-information');
  };

  const restartClaim = () => {
    claimStore.resetClaim();
    reviewStore.reset();
    damagedStore.reset();
    routingStore.replace('/item/shipping-issue');
  };

  const backClickHandle = () => {
    reviewStore.isReview = false;
    if (editIssueType) {
      openEditIssueType();
    } else if (editPhotos) {
      openEditPhotos();
    } else if (editDetails) {
      openEditDetails();
    } else if (editResolution) {
      openEditResolutionPreferenceDetails();
    } else {
      claimStore.setAffidavitAcknowleged(false);
      broadCastAction(ACTIONS.NAVIGATE_BACK_CLICK);
      routingStore.goBack();
    }
  };

  const openEditIssueType = () => {
    claimStore.setAffidavitAcknowleged(false);
    reviewStore.changeEditIssueType();
  };

  const openEditPhotos = () => {
    claimStore.setAffidavitAcknowleged(false);
    reviewStore.changeEditPhotos();
  };

  const openEditPhotosScreen = () => {
    claimStore.setAffidavitAcknowleged(false);
    reviewStore.isReview = true;
    damagedStore.initialize();
    routingStore.replace('/item/damage');
  };

  const openEditDetails = () => {
    claimStore.setAffidavitAcknowleged(false);
    reviewStore.editedDetailsField = claimStore.description;
    reviewStore.changeEditDetails();
  };

  const openEditResolutionPreferenceDetails = () => {
    claimStore.setAffidavitAcknowleged(false);
    reviewStore.isReview = true;
    routingStore.replace('/item/resolution');
  };

  const openEditGiftCardDetails = () => {
    claimStore.setAffidavitAcknowleged(false);
    reviewStore.isReview = true;
    routingStore.replace('/item/resolution/gift-card-details');
  };
  const openEditVenmoHandler = () => {
    claimStore.setAffidavitAcknowleged(false);
    reviewStore.isReview = true;
    routingStore.replace('/item/resolution/venmo');
  };

  const isClaimSubmissionProgressDisplayToBeShown: boolean =
    !loadingAnimationFinished && submitInProgress;
  const isClaimApprovalDisplayToBeShown: boolean =
    loadingAnimationFinished && showInstantApprovalCheckbox;

  const noGlobalNavCheck = (): boolean => {
    if (editDetails || editIssueType || editPhotos || editResolution)
      return false;
    return true;
  };

  const getForegroundContentFullHeaderButtonIcon = (): string => {
    if (editIssueType) {
      return '';
    } else if (
      !editIssueType &&
      !editPhotos &&
      !editDetails &&
      !editResolution
    ) {
      return BackIcon;
    } else {
      return CloseIcon;
    }
  };

  const getForegroundContentFullHeaderButtonLabel = (): string => {
    if (editIssueType) {
      return '';
    } else if (
      !editIssueType &&
      !editPhotos &&
      !editDetails &&
      !editResolution
    ) {
      return 'back';
    } else {
      return 'close';
    }
  };

  if (isClaimSubmissionProgressDisplayToBeShown) {
    return <ClaimSubmissionProgressDisplay />;
  } else if (isClaimApprovalDisplayToBeShown) {
    return <ClaimApprovalDisplay />;
  } else {
    return (
      <ForegroundContentPage
        navigationHandle={!editIssueType ? backClickHandle : undefined}
        navigationIconLabel={getForegroundContentFullHeaderButtonLabel()}
        navigationIcon={getForegroundContentFullHeaderButtonIcon()}
        itemImage={item.item.imageUrl ?? ''}
        itemLabel={orderStore.getMerchantName() + ' ' + item.item.name}
        itemQuantity={claimStore.claimedItems.reduce(
          (acc, items) => acc + items.item.quantity,
          0
        )}
        fullHeight={editDetails ? true : false}
        noGlobalNav={noGlobalNavCheck()}
        contentHeaderTitle="Review Claim Details"
      >
        {!loading &&
          !editIssueType &&
          !editPhotos &&
          !editDetails &&
          !editResolution && (
            <ClaimedItemsReviewMainScreen
              editIssueHandler={openEditIssueType}
              editPhotosHandler={openEditPhotosScreen}
              editDetailsHandler={openEditDetails}
              editResolutionHandler={openEditResolutionPreferenceDetails}
              editGiftCardHandler={openEditGiftCardDetails}
              editVenmoHandler={openEditVenmoHandler}
              submitClickHandler={submitClickHandle}
              cancelClickHandler={cancelClickHandle}
            />
          )}

        {!loading && editIssueType && (
          <ClaimedItemsReviewIssueType
            yesAction={restartClaim}
            noAction={backClickHandle}
          />
        )}

        {!loading && editDetails && <ClaimedItemsReviewEditDetails />}
      </ForegroundContentPage>
    );
  }
});

export default ClaimedItemsReviewDetails;
