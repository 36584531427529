import styled from 'styled-components';
import { NoSlide } from '../../../styles/global.styles';

export const MainContainer = styled(NoSlide)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-self: stretch;
`;

export const Separator = styled.div`
  border: 0.5px solid rgba(216, 216, 216, 0.7);
`;

export const Row = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;

  justify-content: center;

  margin: 5px 0 5px 0;
  position: relative;
  /* Standard/BG-HoverSelected */
  '&:hover': {
    background: #efefef;
    border-radius: 8px;
  }
`;

export const RowLeftContainer = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  flex-grow: 3;

  flex-basis: min-content;
  max-width: 75%;
`;

export const RowRightContainer = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: end;
`;
export const ButtonsContainer = styled.div`
  width: 100%;
`;
