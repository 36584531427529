const mobileBreakpoint = '680px';

export interface ColorsI {
  [key: string]: string;
  red: string;
  textReversed: string;
  white: string;
  divider: string;
  lightGray: string;
  gray: string;
  black: string;
  resolveServiceGreen: string;
  resolveServiceOrange: string;
  resolveServiceBlue: string;
  resolveServiceRed: string;
  resolveServiceYellow: string;
  transparent: string;
  darkButtonBackground: string;
  lightGrayButtonBackground: string;

  routeText1: string;
  routeText2: string;
  routeText3: string;
  routeText4: string;
  routeError1: string;

  routeBackground1: string;
  routeBackground2: string;
  routeBackground3: string;

  routeButtonFill1: string;
  routeButtonFill2: string;
  routeButtonFill3: string;
  routeButtonFill4: string;
  routeButtonFill5: string;
  routeButtonFill6: string;

  routeBorderFill1: string;
}

const routeBrandColors = {
  grayscale1: '#000000',
  grayscale2: '#6A6A6D',
  grayscale3: '#F2F2F2',
  grayscale4: '#FAFAFA',
  grayscale5: '#FFFFFF',
  grayscale6: '#AAAAAA',

  lightBlue1: '#9CF5F5',

  error1: '#D80108',
};

const colors: ColorsI = {
  red: '#F06350',
  textReversed: '#FFFFFF',
  white: '#FFFFFF',
  lightGray: '#FAFAFA',
  divider: '#DDDDDD',
  gray: '#EEEEEE',
  black: '#000000',
  resolveServiceGreen: '#249d0e',
  resolveServiceOrange: '#FFBB23',
  resolveServiceBlue: '#5Dc8db',
  resolveServiceRed: '#ff6060',
  resolveServiceYellow: '#FFBB23',
  transparent: '#00000000',
  darkButtonBackground: '#222222',
  lightGrayButtonBackground: '#FBFBFB',

  routeText1: routeBrandColors.grayscale1,
  routeText2: routeBrandColors.grayscale2,
  routeText3: routeBrandColors.grayscale5,
  routeText4: routeBrandColors.grayscale6,

  routeError1: routeBrandColors.error1,

  routeBackground1: routeBrandColors.grayscale1,
  routeBackground2: routeBrandColors.grayscale4,
  routeBackground3: routeBrandColors.grayscale5,

  routeButtonFill1: routeBrandColors.grayscale1,
  routeButtonFill2: routeBrandColors.grayscale2,
  routeButtonFill3: routeBrandColors.grayscale3,
  routeButtonFill4: routeBrandColors.grayscale4,
  routeButtonFill5: routeBrandColors.grayscale5,
  routeButtonFill6: routeBrandColors.lightBlue1,

  routeBorderFill1: routeBrandColors.grayscale2,
};

export { mobileBreakpoint, colors };
