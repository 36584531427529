/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../../../../hooks/app.hook';
import ForegroundContentModal from '../../../../components/ForegroundContentModal';
import ForegroundContentDetails from '../../../../components/ForegroundContentDetails';
import { AnalyticsContent, ButtonContent, TextFieldContent, TypographyContent } from './ClaimedItemsGiftCardDetails.constants';
import { broadCastAction, broadcastView } from '../../../../services/broadcast';
import { VIEWS } from '../../../../constants/views';
import { ACTIONS } from '../../../../constants/actions';
import { BgIcon, Column, ViewDetailsLink } from './ClaimedItemsGiftCardDetails.styles';
import TextField from '../../../../components/Inputs/TextField';
import { analyticsButtons, analyticsCodes, logEvent, logEventNew } from '../../../../services/analytics';
import GiftCardDisclaimer from './GiftCardDisclaimer/GiftCardDisclaimer';
import { ResolutionEnum } from '../../../../models/Enums/OrderEnum.model';
import MastercardIcon from '../../../../assets/icons/mastercard-icon.svg';

const ClaimedItemsGiftCardDetails: FC = observer(() => {
  const rootStore = useStores();
  const {
    claimStore,
    orderStore,
    routingStore,
    commonStore,
    remedyStore,
  } = rootStore;

  const {
    giftCardEmail,
    giftCardFirstName,
    giftCardLastName,
    giftCardConfirmEmail,
    errors } = remedyStore;
  const item = claimStore.displayItem;

  if (!item) {
    commonStore.displayError();
    return null;
  }

  const backButtonHandle = () => {
    broadCastAction(ACTIONS.NAVIGATE_BACK_CLICK);
    routingStore.goBack();
  };

  const cancelClickHandle = () => {
    claimStore.reset();
    logEventNew(AnalyticsContent.enterMailButtonTapped, rootStore, {
      button_function: analyticsButtons.cancel,
    });
    broadCastAction(ACTIONS.CANCEL_CLAIM_FILING);
    routingStore.replace('/order-information');
  };

  const continueClickHandle = () => {
    logEventNew(AnalyticsContent.enterMailButtonTapped, rootStore, {
      button_function: analyticsButtons.continue,
    });
    routingStore.push('/item/review-details');
  };

  const showDisclaimer = () => {
    claimStore.setViewDisclaimer(true);
  }

  useEffect(() => {
    logEvent(analyticsCodes.screenView, {
      screen: analyticsCodes.prepaidCardDetailsCollection,
      merchantId: orderStore.getOrderDetails()?.merchantID,
      orderId: orderStore.getOrderDetails()?.id,
      cardType: ResolutionEnum.PREPAID_CARD_MASTERCARD,
    });
    broadcastView(VIEWS.CLAIM_ITEM_GIFT_CARD_DETAILS);
  }, []);

  const getDescription = () => {
    return (
      <>
        {TypographyContent.description}
        <br /><ViewDetailsLink href='#disclaimer' onClick={() => showDisclaimer()}>{TypographyContent.viewDetails}</ViewDetailsLink>
      </>
    );
  }

  const isNextDisabled = (): boolean => {
    return !remedyStore.giftCardDetailsVerify();
  };

  return (
    <>
      <GiftCardDisclaimer />
      <ForegroundContentModal
        open
        mainButton={ButtonContent.continue}
        mainButtonClick={continueClickHandle}
        mainButtonDisabled={isNextDisabled()}
        secondaryButton={ButtonContent.cancel}
        secondaryButtonClick={cancelClickHandle}
      >
        <ForegroundContentDetails
          title={TypographyContent.title}
          backButton={backButtonHandle}
          description={getDescription()}
          icon={<BgIcon src={MastercardIcon} alt="gift card icon" />}
          centered
          verticalStretch
        >
          <Column>
            <TextField
              placeholder={TextFieldContent.firstName.placeholder}
              title={TextFieldContent.firstName.placeholder}
              errorText={TextFieldContent.firstName.errorText}
              textType="text"
              value={giftCardFirstName}
              onChange={(value: string) => remedyStore.setGiftCardFirstName(value)}
              isError={errors.firstName}
            />
            <TextField
              placeholder={TextFieldContent.lastName.placeholder}
              title={TextFieldContent.lastName.placeholder}
              errorText={TextFieldContent.lastName.errorText}
              textType="text"
              value={giftCardLastName}
              onChange={(value: string) => remedyStore.setGiftCardLastName(value)}
              isError={errors.lastName}
            />
            <TextField
              placeholder={TextFieldContent.email.placeholder}
              title={TextFieldContent.email.placeholder}
              errorText={TextFieldContent.email.errorText}
              textType="email"
              value={giftCardEmail}
              onChange={(value: string) => remedyStore.setGiftCardEmail(value)}
              isError={errors.email}
            />
            <TextField
              placeholder={TextFieldContent.confirmEmail.placeholder}
              title={TextFieldContent.confirmEmail.placeholder}
              errorText={TextFieldContent.confirmEmail.errorText}
              textType="email"
              value={giftCardConfirmEmail}
              onChange={(value: string) => remedyStore.setGiftConfirmCardEmail(value)}
              isError={errors.confirmEmail}
            />

          </Column>
        </ForegroundContentDetails>
      </ForegroundContentModal>
    </>
  );
});

export default ClaimedItemsGiftCardDetails;
