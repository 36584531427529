export const TypographyContents = {
  title: 'Get your refund via PayPal',
  subtitle: 'Enter the email address associated with your PayPal account.',
  textInputPlaceHolder: 'PayPal email address',
  textInputTitle: 'PayPal email address',
  nextButton: 'Continue',
  noPayPal: "Don't have PayPal?",
  skipButton: 'Skip',
};

export const AnalyticsContent = {
  enterMailButtonTapped: 'Resolve-PayPalRefund-EnterEmail-Button-Tapped',
}