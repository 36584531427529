import styled from 'styled-components';
import { belowSize } from '../../utils/Breakpoints';

export const Container = styled.div`
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-items: center;
  h1 {
    color: #dddddd;
    margin: 130px 0px 90px 0px;
    font-weight: bold;
    font-size: 297px;
    line-height: 114px;
    position: relative;

    ${belowSize.md`
        font-size: 188px;
        line-height: 200px;
        margin: 30px;
    `}
  }
  h2 {
    margin-bottom: 48px;
  }
`;

export const Image = styled.img`
  position: absolute;
  /* margin: 0 auto; */
  top: 148px;
  transform: translateX(-24px);
  width: 80px;
  margin-left: auto;
  margin-right: auto;
`;

export const Center = styled.div`
  display: flex;
  justify-content: center;
  align-self: stretch;
  flex-direction: column;
  margin: 20px 40%;

  ${belowSize.md`
    margin: 20px;
  `}
`;
