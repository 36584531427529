import React, { useRef, Ref } from 'react';
import ItemIcon from '../IconOrLogos/ItemIcon';
import Typography from '../UI/Typography';
import { IClaimedSelectableRowProps } from './interface';
import { ClickableContainer, Row, Column } from './ClaimedSelectableRow.styles';
import { TypographyContents } from './ClaimedSelectableRow.constants';
import { colors } from '../../constants/styles';
import { DateFormatterLong } from '../../utils';
import { useStores } from '../../hooks/app.hook';

const ClaimedSelectableRow = React.forwardRef(
  (props: IClaimedSelectableRowProps, ref: Ref<HTMLDivElement>) => {
    const { claim, statusSubtitle } = props;
    const { animationStore } = useStores();

    const domRef = useRef<HTMLDivElement>(null);
    if (!ref) {
      ref = domRef;
    }

    const clickHandle = () => {
      if (props.clickHandle) {
        animationStore.setAnimationTarget(ref);
        props.clickHandle(claim);
      }
    };

    const dateFormatOn = (text: string, date: string) => {
      const dateFormatted = DateFormatterLong(date);
      return `${text} ${dateFormatted}`;
    };

    const getTitle = (): string => {
      if (claim.claimId && claim.claimStatusHeader) {
        return claim.claimStatusHeader;
      }
      return claim.claimItems[0]?.name;
    };

    const getSubtitleContent = () => {
      if (claim.claimId && claim.claimItems[0]?.updatedOn) {
        return dateFormatOn(
          TypographyContents.updatedOn,
          claim.claimItems[0]?.updatedOn
        );
      }
      return TypographyContents.shipping;
    };

    const getQuantity = (): number => {
      return claim.claimItems.length;
    };

    return (
      <ClickableContainer
        clickable={!!props.clickHandle}
        onClick={clickHandle}
        data-testid="clickable"
        ref={ref}
        disabled={props.disabled}
      >
        <Row>
          <ItemIcon
            imageUrl={claim.claimItems[0]?.imageUrl ?? ''}
            alt={`${claim.claimItems[0]?.name} icon`}
            quantity={getQuantity()}
          />
          <Column>
            <Typography variant="l1" semibold color={colors.routeText1}>
              {getTitle()}
            </Typography>
            {statusSubtitle && (
              <Typography variant="l1" color={colors.routeText2}>
                {getSubtitleContent()}
              </Typography>
            )}
          </Column>
        </Row>
      </ClickableContainer>
    );
  }
);

export default ClaimedSelectableRow;
