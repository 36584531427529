export const TypographyContent = {
  title: "Where's My Package?",
  descriptionPlaceholder: 'What happened to your package?',

  damaged: 'Damaged',
  lost: 'Lost',
  stolen: 'Stolen',
  issueUnrelated: 'Issue unrelated to lost or stolen',
};

export const ButtonContent = {
  cancel: 'Cancel',
};
