/* eslint-disable react/jsx-no-undef */
import React, { useState, ChangeEvent } from 'react';
import {
  Container,
  Element as InputElement,
  Label,
  LabelAnimated,
  Icon,
  InputContent,
  ToolTipWrapper,
  InputLineContainer,
  ErrorContainer,
  CloseIcon,
  ToolTipContainer,
  ToolTipExtender,
  TextAreaElement,
  Row,
  LabelSpan,
} from './TextField.styles';
import ToolTip from '../../../assets/icons/tooltip.svg';
import { ITextFieldProps } from './interface';
import Typography from '../../UI/Typography';
import XIcon from '../../../assets/icons/x-icon';
import { colors } from '../../../constants/styles';
import CountrySelect from './CountrySelect';

export const TextField: React.FC<ITextFieldProps> = ({
  placeholder,
  noTitle,
  noAnimation,
  value,
  toolTipText,
  errorText,
  textType,
  readOnly,
  noLine,
  isError,
  multiline,
  phoneProps,
  onChange,
  fullHeight,
  textColor,
  fontSize,
  lineHeight,
  fontWeight,
  inputMode,
  autoFocus,
}: ITextFieldProps) => {
  const [toolTipOpen, setToolTipOpen] = useState(false);
  const [animatedLabelClass, setAnimatedLabelClass] = useState<string>("small");
  const [isLabelResizable, setIsLabelResizable] = useState(true);

  // Adjusts animated label size on cases where it doesnt matches the input field state
  // Filled input field = small label , empty input field = big label
  if (!noAnimation && isLabelResizable === true) {
    if (animatedLabelClass === "small" || animatedLabelClass === "large") {
      if (value.length > 0 && animatedLabelClass !== "small") {
        setAnimatedLabelClass("small");
      }
      else if (value.length === 0 && animatedLabelClass !== "large") {
        setAnimatedLabelClass("large");
      }
    }
  }

  const handleChange = (
    event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
  ) => {
    if (onChange) {
      onChange(event.target.value);
    }
  };

  const handleFocusIn = (
    event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
  ) => {
    setIsLabelResizable(false);
    if (!noAnimation && event.target.value.length === 0) {
      setAnimatedLabelClass("small");
    }
  };

  const handleFocusOut = (
    event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
  ) => {
    setIsLabelResizable(true);
    if (!noAnimation && event.target.value.length === 0) {
      setAnimatedLabelClass("large");
    }
  };

  return (
    <Container fullHeight={fullHeight}>
      {toolTipOpen && toolTipText && (
        <ToolTipContainer>
          <ToolTipWrapper>
            <Typography variant="p5" color={colors.routeText3}>
              {toolTipText}
            </Typography>
            <CloseIcon onClick={() => setToolTipOpen(false)}>
              <XIcon />
            </CloseIcon>
          </ToolTipWrapper>
          <ToolTipExtender />
        </ToolTipContainer>
      )}
      <InputLineContainer isError={isError} noLine={noLine}>
        <InputContent>
          <Label className={noAnimation ? "" : "hidden"}>
            <Typography
              variant="p6"
              color={isError ? colors.routeError1 : colors.routeText2}
            >
              {noAnimation && !noTitle && value.length !== 0 ? (placeholder) : " "}
            </Typography>
            <LabelAnimated
              className={animatedLabelClass}
              smallFontSize={"12px"}
              smallLineHeight={"14px"}
              smallFontColor={colors.routeText2}
              largeFontSize={fontSize ?? '17px'}
              largeLineHeight={fontSize ? "unset" : "24px"}
              largeFontColor={colors.routeText2}
              largeOffsetX={phoneProps ? 36 : 0}
              errorFontColor={colors.routeError1}
              isError={isError}
            >
              <LabelSpan>
                {!noTitle && !noAnimation ? (placeholder) : " "}
              </LabelSpan>
            </LabelAnimated>
          </Label>
          <Row>
            {multiline ? (
              <TextAreaElement
                autoFocus={autoFocus}
                value={value}
                readOnly={readOnly}
                onChange={handleChange}
                onFocus={handleFocusIn}
                onBlur={handleFocusOut}
                placeholder={placeholder}
                textColor={textColor}
                fontSize={fontSize}
                lineHeight={lineHeight}
                fontWeight={fontWeight}
                hasAnimatedLabel={!noAnimation}
              />
            ) : (
              <>
                {phoneProps ? (
                  <CountrySelect
                    selectItems={phoneProps.selectItems}
                    value={phoneProps.value}
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    onChange={phoneProps.onChange}
                  />
                ) : null}
                <InputElement
                  autoFocus={autoFocus}
                  inputMode={inputMode}
                  readOnly={readOnly}
                  placeholder={placeholder}
                  type={textType}
                  value={value}
                  onChange={handleChange}
                  onFocus={handleFocusIn}
                  onBlur={handleFocusOut}
                  textColor={textColor}
                  fontSize={fontSize}
                  lineHeight={lineHeight}
                  fontWeight={fontWeight}
                  hasAnimatedLabel={!noAnimation}
                />
              </>
            )}
          </Row>
        </InputContent>
        <div>
          {toolTipText && (
            <Icon
              alt={`${placeholder} input tooltip`}
              src={ToolTip}
              onClick={() => setToolTipOpen(!toolTipOpen)}
            />
          )}
        </div>
      </InputLineContainer>

      <ErrorContainer>
        {isError && (
          <Typography variant="p6" color={colors.routeError1}>
            {errorText}
          </Typography>
        )}
      </ErrorContainer>
    </Container>
  );
};

export default TextField;
