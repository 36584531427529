import styled from 'styled-components';

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;

  margin: 47px 40px 0px 40px;
`;

export const InputContainer = styled.div`
  margin: 24px 40px 0px 40px;
`;
