import styled from 'styled-components';

export const TrackingNumberContainer = styled.div`
  display: flex;

  margin-top: 20px;
  margin: 28px 40px 0px 40px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  // justify-content: center;
  margin: 32px 16px;
`;
