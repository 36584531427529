
import { isValidPhoneNumber } from 'libphonenumber-js';
import isEmail from 'validator/lib/isEmail';

export const VenmoInputEnum =
{
    PHONE: 'phone',
    USER_ID: 'user_id',
    EMAIL: 'email'
};

type Keys = keyof typeof VenmoInputEnum;
export type VenmoInputEnumType = typeof VenmoInputEnum[Keys];

export const parseVenmoInput = (value: string): VenmoInputEnumType => {
    if (isEmail(value)) return VenmoInputEnum.EMAIL;
    if (isValidPhoneNumber(value, 'US')) return VenmoInputEnum.PHONE;
    return VenmoInputEnum.USER_ID;
}