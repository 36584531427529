import React, { FC } from 'react';
import { IForegroundContentModalProps } from './interface';
import {
  Container,
  Column,
  ContainerFlex,
} from './ForegroundContentModal.styles';
import Paper from '../UI/Paper';
import Button from '../Buttons/Button';

const ForegroundContentModal: FC<IForegroundContentModalProps> = (
  props: IForegroundContentModalProps
) => {
  const {
    children,
    radius,
    mainButton,
    mainButtonDisabled,
    secondaryButton,
  } = props;

  return (
    <ContainerFlex>
      <Container>
        <Paper radius={radius} overrideOverflow={true}>{children}</Paper>
        <Column>
          {mainButton && (
            <Button
              mode="dark"
              fullWidth
              disabled={mainButtonDisabled}
              onClick={() =>
                props.mainButtonClick ? props.mainButtonClick() : ''
              }
            >
              {mainButton}
            </Button>
          )}
          {secondaryButton && (
            <Button
              mode="light"
              fullWidth
              onClick={() =>
                props.secondaryButtonClick ? props.secondaryButtonClick() : ''
              }
            >
              {secondaryButton}
            </Button>
          )}
        </Column>
      </Container>
    </ContainerFlex>
  );
};

export default ForegroundContentModal;
