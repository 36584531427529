/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import {
  ButtonsContainer,
  DateLine,
  StatusLine,
  TrackingInfoContainer,
} from './ConfirmCarrierUpdate.styles';
import { useStores } from '../../../../hooks/app.hook';
import ForegroundContentModal from '../../../../components/ForegroundContentModal';
import { logEvent, analyticsCodes } from '../../../../services/analytics';
import ForegroundContentDetails from '../../../../components/ForegroundContentDetails';
import { colors } from '../../../../constants/styles';
import { Icon, VerticalSeparator } from '../../../../styles/global.styles';
import { ButtonContent, TypographyContents } from './ConfirmCarrierUpdate.constants';
import { isMobileView } from '../../../../utils/Breakpoints';
import ClickableContainerRow from '../../../../components/Buttons/ClickableContainerRow';
import Typography from '../../../../components/UI/Typography';
import YesIcon from '../../../../assets/icons/yes-icon.svg';
import NoIcon from '../../../../assets/icons/no-icon.svg';
import {
  ClaimBlockingAction,
  ClaimBlockingScreens,
} from '../../../../stores/_constants/ClaimBlockingFlow.constants';
import { broadCastAction, broadcastView } from '../../../../services/broadcast';
import { VIEWS } from '../../../../constants/views';
import { DateFormatterShort } from '../../../../utils';
import { ACTIONS } from '../../../../constants/actions';

const ConfirmCarrierUpdate: FC = observer(() => {
  const {
    claimStore,
    routingStore,
    commonStore,
    orderStore,
    claimBlockingStore,
  } = useStores();

  const screen = ClaimBlockingScreens.CONFIRM_CARRIER_UPDATE;

  const item = claimStore.displayItem;

  if (!item) {
    commonStore.displayError();
    return null;
  }

  const yesClickHandle = () => {
    routingStore.push(
      claimBlockingStore.getButtonRoute(screen, ClaimBlockingAction.YES)
    );
  };

  const noClickHandle = () => {
    routingStore.push(
      claimBlockingStore.getButtonRoute(screen, ClaimBlockingAction.NO)
    );
  };

  const backButtonHandle = () => {
    broadCastAction(ACTIONS.NAVIGATE_BACK_CLICK);
    routingStore.goBack();
  };

  const cancelClickHandle = () => {
    claimStore.reset();
    claimBlockingStore.reset();
    broadCastAction(ACTIONS.CANCEL_CLAIM_FILING);
    routingStore.replace('/order-information');
  };

  const getTitle = () => {
    return claimBlockingStore.getTitle(screen);
  };

  const dateFormatOn = (text: string, date: string) => {
    const dateFormatted = DateFormatterShort(date);
    return `${text} ${dateFormatted}`;
  };

  useEffect(() => {
    broadcastView(VIEWS.CLAIM_ITEM_BLOCKING_CONFIRM_CARRIER_UPDATE);
    logEvent(analyticsCodes.screenView, {
      screen: claimBlockingStore.getAnalytics(screen),
      merchantId: orderStore.getOrderDetails()?.merchantID,
      orderId: orderStore.getOrderDetails()?.id,
    });
  }, []);

  const mobile = isMobileView();

  return (
    <ForegroundContentModal
      open
      secondaryButton={ButtonContent.cancel}
      secondaryButtonClick={cancelClickHandle}
    >
      <ForegroundContentDetails title={getTitle()} backButton={backButtonHandle}>
        <TrackingInfoContainer>
          <StatusLine>
            <Typography color={colors.routeText1} variant={'p5'} semibold>
              {orderStore.getCarrierUpdateStatus(item.item.shipmentId)}
            </Typography>
            <Typography color={colors.routeText1} variant={'p5'} semibold>
              {' · '}
            </Typography>
            <Typography color={colors.routeText1} variant={'p5'} semibold>
              {orderStore.getCarrierUpdateCurrentLocation(item.item.shipmentId)}
            </Typography>
          </StatusLine>

          <DateLine>
            <Typography color={colors.routeText1} variant={'p5'}>
              {dateFormatOn(TypographyContents.arrivingOn, orderStore.getEstimatedDelivery(item.item.shipmentId) ?? '')}
            </Typography>
          </DateLine>
        </TrackingInfoContainer>

        <VerticalSeparator distance={32} />

        <ButtonsContainer>
          <ClickableContainerRow
            onClick={yesClickHandle}
            backgroundColor={colors.routeButtonFill3}
            icon={<Icon src={YesIcon} alt="yes icon" />}
            minWidth=""
            minHeight=""
            text={ButtonContent.yes}
            fontVariant={!mobile ? 'h7' : 'p4'}
            padding="12px 16px 12px 24px"
          />

          <VerticalSeparator distance={16} />

          <ClickableContainerRow
            onClick={noClickHandle}
            backgroundColor={colors.routeButtonFill3}
            icon={<Icon src={NoIcon} alt="no icon" />}
            minWidth=""
            minHeight=""
            text={ButtonContent.no}
            fontVariant={!mobile ? 'h7' : 'p4'}
            padding="12px 16px 12px 24px"
          />
        </ButtonsContainer>
      </ForegroundContentDetails>
    </ForegroundContentModal>
  );
});

export default ConfirmCarrierUpdate;
