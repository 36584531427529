import styled, { css } from 'styled-components';
import {
  getElementAnimation,
  AnimatedElement,
} from '../../../animations/animationFactory';
import { IAnimation } from '../../../animations/animationTypes';
import { colors } from '../../../constants/styles';
import { belowSize } from '../../../utils/Breakpoints';
import { IButtonProps } from './interface';

export interface IButtonStyled {
  fullWidth?: boolean;
  mode?: string;
  overrideBackground?: string;
  animation?: IAnimation;
  overrideSizeIcon?: string;
}

type ButtonContainerProp = {
  disabled?: boolean;
  isFullscreen?: boolean;
};

export const ButtonContainer = styled.div<ButtonContainerProp>`
  ${({ disabled }) => disabled && `pointer-events: none;`}
  cursor: pointer;
  display: flex;
  border-radius: 6px;
  margin: 10px 0;
  flex-grow: ${(props: ButtonContainerProp) =>
    props.isFullscreen ? '0' : '1'};
  align-self: stretch;
`;

export const TextButton = styled.button`
  background: ${(props: IButtonStyled) =>
    props.overrideBackground ??
    (props.mode && props.mode === 'light'
      ? colors.routeButtonFill3
      : colors.black)};

  border-radius: 6px;
  border: none;
  width: ${(props: IButtonStyled) => (props.fullWidth ? '100%' : 'auto')};
  transition: background-color 0.25s ease;
  padding: 11px;
  min-width: ${(props: IButtonStyled) => (props.fullWidth ? 'unset' : '327px')};
  align-self: center;
  &:not(:disabled) {
    cursor: pointer;
    &:active {
      -webkit-transform: scale(0.99);
      transform: scale(0.99);
    }
  }
  &:disabled {
    opacity: 30%;
  }

  ${belowSize.md`
     min-width: unset;
     width: 100%;
  `}
`;

export const IconButton = styled.button<IButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 0;
  padding: 0;
  width: ${(props: IButtonStyled) => props.overrideSizeIcon ?? '42px'};
  height: ${(props: IButtonStyled) => props.overrideSizeIcon ?? '42px'};

  background: ${(props: IButtonStyled) =>
    props.overrideBackground ??
    (props.mode && props.mode === 'light'
      ? colors.routeButtonFill4
      : colors.routeButtonFill1)};

  border: none;
  border-radius: 50%;

  transition: background-color 0.25s ease;

  animation: ${props =>
    getElementAnimation(AnimatedElement.Button, props.animation)};

  & > img {
    animation: ${props =>
    getElementAnimation(AnimatedElement.Icon, props.animation)};
  }
  ${(props: IButtonProps) => {
    return props.shadowed
      ? css`
          filter: drop-shadow(0px 11px 16px rgba(0, 0, 0, 0.1));
        `
      : '';
  }}

  &:not(:disabled) {
    cursor: pointer;
    &:active {
      -webkit-transform: scale(0.75);
      transform: scale(0.75);
    }
  }

  &:disabled {
    background-color: ${colors.gray};
  }
`;
