import React from 'react';
import { useLayoutEffect, useState, FC } from 'react';
import styled from 'styled-components';
import { getAnchorTopOffset } from '../../../utils/animation';
import {
  ClaimedItemAnimationProps,
  Simple,
} from './ClaimedItemAnimation.styles';

export const ClaimedItemAnimation: FC<ClaimedItemAnimationProps> = props => {
  const [layoutDone, setLayoutDone] = useState(false);
  useLayoutEffect(() => {
    setLayoutDone(true);
  }, []);

  // This needs to be inside FC due to styled components weird behavior of
  // not properly rendering with dynamic values on transform property
  const Animated = styled(Simple)<ClaimedItemAnimationProps>`
    animation: 200ms moving 0s;
    animation-timing-function: ease-out;
    @keyframes moving {
      0% {
        transform: translateY(
          ${props =>
            getAnchorTopOffset(props.anchorElement, props.anchoredElement)}px
        );
      }
      100% {
        transform: translateY(0);
      }
    }
  `;

  return layoutDone ? (
    <Animated {...props}></Animated>
  ) : (
    <Simple>{props.children}</Simple>
  );
};
