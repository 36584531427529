import { TextCategory } from "../../../../models/Enums/TextCategory.model";


// Note (August 6th, 2024): Borarderie Merchant requested different copy for this screen.
export const ButtonContent = {
  damaged: (category: TextCategory): string => {
    switch (category) {
      case TextCategory.DAMAGE_OR_SPOILED:
        return 'My package was damaged or spoiled in transit';

      default:
        return 'My item was damaged';
    }
  },
  neverArrived: (category: TextCategory): string => {
    switch (category) {
      default:
        return 'My item never arrived';
    }
  },

  return: (category: TextCategory): string => {
    switch (category) {
      default:
        return 'I want to return this item';
    }
  },
  other: (category: TextCategory): string => {
    switch (category) {
      default:
        return 'Other';
    }
  },
  cancel: (category: TextCategory): string => {
    switch (category) {
      default:
        return 'Cancel';
    }
  },
};

export const GET_HELP_SOMETHING_ELSE_LINK = 'https://shoppers.help.route.com/hc/en-us/articles/6017831679511';