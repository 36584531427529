import React, { FC } from 'react';
import { IButtonProps } from './interface';
import { TextButton, IconButton, ButtonContainer } from './Button.styles';
import Typography from '../../UI/Typography';

const Button: FC<IButtonProps> = (props: IButtonProps) => {
  if (props.icon) {
    return (
      <IconButton
        shadowed={props.shadowed}
        className={props.className}
        overrideBackground={props.overrideBackground}
        onClick={props.onClick}
        disabled={props.disabled}
        mode={props.mode}
        aria-label={props.ariaLabel}
        animation={props.animation}
        overrideSizeIcon={props.overrideSizeIcon}
        data-testid={props.testId}
      >
        {props.icon}
      </IconButton>
    );
  }
  return (
    <ButtonContainer
      onClick={props.onClick}
      disabled={props.disabled}
      isFullscreen={props.isFullscreen}
    >
      <TextButton
        className={props.className}
        overrideBackground={props.overrideBackground}
        disabled={props.disabled}
        fullWidth={props.fullWidth}
        mode={props.mode}
        aria-label={props.ariaLabel}
        data-testid={props.testId}
      >
        <Typography
          variant="p5"
          semibold
          color={props.mode === 'light' ? 'black' : 'white'}
        >
          {props.children}
        </Typography>
      </TextButton>
    </ButtonContainer>
  );
};

export default Button;
