export enum ClaimTypeEnum {
  LOST = 'lost',
  STOLEN = 'stolen',
  DAMAGED = 'damaged',
}

export enum ClaimStatusEnum {
  INELIGIBLE = 'ineligible',
  ELIGIBLE = 'eligible',
  APPROVED = 'approved',
  NOT_APPROVED = 'not_approved',
  CLOSED = 'closed',
  PENDING_REVIEW = 'pending_review',
  IN_REVIEW = 'in_review',
  ACTION_REQUIRED = 'action_required',
  RESOLVED = 'resolved',
}
