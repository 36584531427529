import styled from 'styled-components';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import { SlideLeft } from '../../../../styles/global.styles';

export const Container = styled(SlideLeft)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-self: stretch;
  margin: 10px 0px 0px 10px;
  overflow-x: hidden;
`;

export const LoadSpinnerStyled = styled(LoadingSpinner)`
  margin-left: -78px;
`;
