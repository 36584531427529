export const TypographyContents = {
  title: 'Order Details',
  orderNumber: 'Order Number',
  orderDate: 'Order Date',
  email: 'Email',
  customerName: 'Customer Name',
  estimatedDeliveryDate: 'Estimated Delivery Date',
  shippedTo: 'Shipped To',
  copied: 'Copied!',
};

export const ButtonContents = {
  copyAll: 'Copy All',
};
