import styled from 'styled-components';
import { colors } from '../../../constants/styles';
import { IPaperProps } from './interface';

export const Container = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background: ${colors.white};
  ${(props: IPaperProps) => {
    if (!props.overrideOverflow) {
      return 'overflow: hidden';
    } else {
      return 'overflow: unset';
    }
  }}

  border-radius: ${(props: IPaperProps) => {
    return (props.radius ?? 6) + 'px';
  }};
`;
