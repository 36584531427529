import React, { FC } from 'react';
import {
  Container,
  DeliverStatusContainer,
  StatusContainer,
  TrackingBarBackground,
  TrackingBarContainer,
  TrackingBar,
} from './TrackingProgressBar.styles';

import {
  StatusLabel,
  TrackingBarWidth,
} from './TrackingProgressBar.constants';
import { ITrackingProgressBar } from './interface';
import Typography from '../UI/Typography';
import { colors } from '../../constants/styles';
import { DateFormatterShort } from '../../utils';

const TrackingProgressBar: FC<ITrackingProgressBar> = props => {
  const { shipment, descText } = props;
  const status = shipment?.deliveryStatus;
  const barWidth = TrackingBarWidth(status, shipment?.estimatedDeliveryDate);

  return (
    <Container>
      {shipment && (
        <StatusContainer>
          {status && (
            <Typography variant="l1" color={colors.routeText1} semibold>
              {StatusLabel(status)}
            </Typography>
          )}
          {shipment.estimatedDeliveryDate && (
            <Typography variant="p2" color={colors.routeText2}>
              Arriving {DateFormatterShort(shipment.estimatedDeliveryDate)}
            </Typography>
          )}
        </StatusContainer>
      )}
      <TrackingBarBackground>
        <TrackingBarContainer width={barWidth}>
          <TrackingBar />
        </TrackingBarContainer>
      </TrackingBarBackground>
      <DeliverStatusContainer>
        <Typography variant="p2" color={colors.routeText2}>
          {descText}
        </Typography>
      </DeliverStatusContainer>
    </Container>
  );
};

export default TrackingProgressBar;
