import styled from 'styled-components';
import Button from '../../Buttons/Button';

export interface ISwitch {
  active: boolean;
}

export interface IThumbnail {
  imageUrl?: string;
}

export const Thumbnail = styled.div`
  display: flex;

  justify-content: flex-end;
  padding: 4px;
  width: 104px;
  height: 104px;
  min-width: 104px;
  min-width: 104px;

  border-radius: 6px;
  overflow: hidden;
  background-image: url(${(props: IThumbnail) => props.imageUrl});
  background-size: cover;
  background-position-y: center;
  background-repeat: no-repeat;
`;

export const StyledButton = styled(Button)`
  /* transform: translateX(4px) translateY(4px); */
`;
