export const TypographyContents = {
  title: 'Resolve Center',
  subtitle: (email: string): string => {
    return `We’ll email ${email} with any updates.`;
  },
  subtitleMobile: (email: string): string => {
    return `We’ll email ${email}\nwith any updates.`;
  },
  orderDetails: 'Order Details',
};

export const ButtonContents = {
  close: 'Close',
  emailDraft: 'View email draft',
};

export const Links = {
  hibbettHelpCenter:
    'https://www.hibbett.com/on/demandware.static/-/Sites-Hibbett-US-Library/default/v766bea33fb09b03e958fced1209281777267d8c2/images/Files/Hibbett-Claims-PDF-11.2021-Update.pdf?version=1,636,393,243,000',
};
