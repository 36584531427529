/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import {
  Column,
  Container,
  Content,
} from './ClaimedItemsResolutionPreference.styles';
import Typography from '../../../components/UI/Typography';
import { colors } from '../../../constants/styles';
import { useStores } from '../../../hooks/app.hook';
import ForegroundContentHeader from '../../../components/ForegroundContentHeader';
import ForegroundContentModal from '../../../components/ForegroundContentModal';
import { AnalyticsContent, TypographyContent } from './ClaimedItemsResolutionPreference.constants';
import { Icon, VerticalSeparator } from '../../../styles/global.styles';
import ReplaceIconBlack from '../../../assets/icons/replace-icon-black.svg';
import RefundIcon from '../../../assets/icons/refund-icon.svg';
import PayPalIcon from '../../../assets/icons/paypal-icon.svg';
import VenmoIcon from '../../../assets/icons/venmo-icon-mini.svg';
import GiftCardIcon from '../../../assets/icons/gift-card-icon.svg';
import GiftCardRibbonIcon from '../../../assets/icons/gift-card-icon-ribbon.svg';
import ForwardIcon from '../../../assets/icons/forward-icon.svg';
import { ResolutionEnum } from '../../../models/Enums/OrderEnum.model';
import LoadingSpinner from '../../../components/LoadingSpinner';
import BackIcon from '../../../assets/icons/back-icon.svg';
import CloseIcon from '../../../assets/icons/close-icon.svg';
import ResolutionSelectableRow from '../../../components/ResolutionSelectableRow';
import { analyticsButtons, logEventNew } from '../../../services/analytics';
import { VIEWS } from '../../../constants/views';
import { broadCastAction, broadcastView } from '../../../services/broadcast';
import { ACTIONS } from '../../../constants/actions';

const ClaimedItemsResolutionPreference: FC = observer(() => {
  const rootStore = useStores();
  const {
    claimStore,
    routingStore,
    orderStore,
    reviewStore,
    remedyStore,
  } = rootStore;
  const { displayItem: item, loading } = claimStore;
  const { routeOrder } = orderStore;
  const { isReview } = reviewStore;

  if (!item || !routeOrder) {
    //never reaching code
    return null;
  }

  useEffect(() => {
    logEventNew(AnalyticsContent.resolutionPreferenceViewed, rootStore, {
      resolution_types_available: remedyStore.getFormattedRemedyActionValues(),
    });
    broadcastView(VIEWS.CLAIM_ITEM_RESOLUTION_PREFERENCE);
  }, [])

  const closeClickHandle = () => {
    claimStore.reset();
    reviewStore.reset();
    logEventNew(AnalyticsContent.resolutionPreferenceDismiss, rootStore, {
      button_function: analyticsButtons.cancel,
    });
    broadCastAction(ACTIONS.CANCEL_CLAIM_FILING);
    routingStore.replace('/order-information');
  };

  const backClickHandle = () => {
    if (isReview) {
      routingStore.replace('/item/review-details');
    } else {
      logEventNew(AnalyticsContent.resolutionPreferenceDismiss, rootStore, {
        button_function: analyticsButtons.goBack,
      });
      broadCastAction(ACTIONS.NAVIGATE_BACK_CLICK);
      routingStore.goBack();
    }
  };

  const selectResolution = (resolutionType: ResolutionEnum, title: string) => {
    if (isReview) {
      if (resolutionType !== claimStore.claim?.customerPreferredResolution) {
        logEventNew(AnalyticsContent.resolutionPreferenceChanged, rootStore, {
          resolution_type: resolutionType,
          button_title: title,
        });
      }
    } else {
      logEventNew(AnalyticsContent.resolutionPreferenceSelected, rootStore, {
        resolution_type: resolutionType,
        button_title: title,
      });
    }
    claimStore.setCustomerPreferredResolution(resolutionType);
    navigateToSelectedResolution(resolutionType);
  };

  const navigateToSelectedResolution = (resolutionType: ResolutionEnum) => {
    switch (resolutionType) {
      case ResolutionEnum.REFUND_PAYPAL:
        if (claimStore.needsRemedyConfigValidation()) {
          routingStore.push('/item/resolution/paypal');
        } else {
          claimStore.resetRemedyConfigs();
          claimStore.finishClaim();
        }
        break;
      case ResolutionEnum.REFUND_VENMO:
        routingStore.push('/item/resolution/venmo');
        break;
      case ResolutionEnum.REPLACE:
        routingStore.push('/item/resolution/shipping-information');
        break;
      case ResolutionEnum.PREPAID_CARD_MASTERCARD:
        routingStore.push('/item/resolution/gift-card-details');
        break;
      case ResolutionEnum.GIFT_CARD_MERCHANT:
      default:
        claimStore.resetRemedyConfigs();
        claimStore.finishClaim();
    }
  };

  const getIcon = (resolutionType: ResolutionEnum): string => {
    switch (resolutionType) {
      case ResolutionEnum.REPLACE:
      case ResolutionEnum.REORDER:
        return ReplaceIconBlack;
      case ResolutionEnum.REFUND:
        return RefundIcon;
      case ResolutionEnum.REFUND_PAYPAL:
        return PayPalIcon;
      case ResolutionEnum.REFUND_VENMO:
        return VenmoIcon;
      case ResolutionEnum.PREPAID_CARD:
      case ResolutionEnum.PREPAID_CARD_MASTERCARD:
        return GiftCardIcon;
      case ResolutionEnum.GIFT_CARD_MERCHANT:
        return GiftCardRibbonIcon;
      default:
        return '';
    }
  };

  return (
    <ForegroundContentModal
      open
      secondaryButton={TypographyContent.cancel}
      secondaryButtonClick={closeClickHandle}
    >
      <ForegroundContentHeader
        claimedItems={claimStore.claimedItems}
        displayItem={item}
        clickButtonHandle={backClickHandle}
        buttonIcon={isReview ? CloseIcon : BackIcon}
        title={TypographyContent.title}
        centered
      >
        {loading ? (
          <LoadingSpinner />
        ) : (
          <Container>
            <Content>
              <Column>
                {remedyStore.routeRemedyActions?.map((item, index) => {
                  return (
                    <div key={index}>
                      <ResolutionSelectableRow
                        onClick={() => {
                          selectResolution(item.value, item.title);
                        }}
                        icon={getIcon(item.value)}
                        preferOption={item.title}
                        subtitle={item.description}
                        buttonIcon={
                          <Icon src={ForwardIcon} verticalAlign="middle" />
                        }
                        disabled={!item.enabled}
                        testId={'select-'.concat(item.value)}
                      />

                      {index !== remedyStore.routeRemedyActions.length - 1 && (
                        <VerticalSeparator distance={16} />
                      )}
                    </div>
                  );
                })}
              </Column>

              <VerticalSeparator distance={24} />

              {/* This div is needed to prevent line height scroll */}
              <div>
                <Typography variant="p2" color={colors.routeText2}>
                  {TypographyContent.preferenceAvailability(
                    routeOrder.merchant.name
                  )}
                </Typography>
              </div>
            </Content>
          </Container>
        )}
      </ForegroundContentHeader>
    </ForegroundContentModal>
  );
});

export default ClaimedItemsResolutionPreference;
