/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
const SVG = ({ color = '#FFFFFF', width = '12px', height = '12px' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Close</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5948 2.21492C11.9853 1.82439 11.9853 1.19123 11.5948 0.800706C11.2043 0.410181 10.5711 0.410181 10.1806 0.800706L6.37987 4.60141L2.57918 0.800718C2.18865 0.410194 1.55549 0.410194 1.16496 0.800718C0.774439 1.19124 0.774439 1.82441 1.16496 2.21493L4.96566 6.01562L1.16496 9.81632C0.774439 10.2068 0.774439 10.84 1.16496 11.2305C1.55549 11.6211 2.18865 11.6211 2.57918 11.2305L6.37987 7.42984L10.1806 11.2305C10.5711 11.6211 11.2043 11.6211 11.5948 11.2305C11.9853 10.84 11.9853 10.2069 11.5948 9.81633L7.79408 6.01562L11.5948 2.21492Z"
      fill={color}
    />
  </svg>
);

export default SVG;
