import React, { FC, useEffect } from 'react';
import {
  Container,
  AnimationContent,
  TextContent,
} from './ClaimSubmissionDisplay.styles';
import { useStores } from '../../../hooks/app.hook';
import Typography from '../../../components/UI/Typography';
import { colors } from '../../../constants/styles';
import ForegroundContentPage from '../../../components/ForegroundContentPage';
import { VIEWS } from '../../../constants/views';
import { broadcastView } from '../../../services/broadcast';

interface ClaimSubmissionDisplayProps {
  animation: React.ReactNode;
  animationText: string;
}

export const ClaimSubmissionDisplay: FC<ClaimSubmissionDisplayProps> = ({
  animation,
  animationText,
}) => {
  const { commonStore, claimStore, orderStore } = useStores();
  const { displayItem } = claimStore;

  useEffect(() => {
    broadcastView(VIEWS.CLAIM_ITEM_SUBMISSION)
  }, [])

  if (!displayItem) {
    commonStore.displayError();
    return null;
  }
  const item = displayItem.item;


  return (
    <ForegroundContentPage
      itemImage={item.imageUrl ?? ''}
      itemLabel={orderStore.getMerchantName() + ' ' + item.name}
      itemQuantity={claimStore.claimedItems.reduce(
        (acc, items) => acc + items.item.quantity,
        0
      )}
    >
      <Container>
        <AnimationContent>
          {animation}
          <Typography variant="p7" color={colors.routeText1} semibold>
            <TextContent>{animationText}</TextContent>
          </Typography>
        </AnimationContent>
        <br />
      </Container>
    </ForegroundContentPage>
  );
};
