import { postAction, postError, postView } from "../adapters/messageAdapter"

export const broadcastView = (id: string) => {
	postView({ id })
}
export const broadCastError = () => {
	postError()
}
export const broadCastAction = (action_id: string) => {
	postAction({ action_id })
}
