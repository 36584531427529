import { makeObservable, observable } from 'mobx';
import { ClaimTypeEnum } from './Enums/ClaimEnum.model';
import { ResolutionEnum } from './Enums/OrderEnum.model';
import { GroupedItem } from './GroupedItems.model';

export class Claim {
  [key: string]: unknown;
  @observable public description = '';
  @observable public claimedItems: Array<IClaimedItem> = [];

  type: ClaimTypeEnum;
  customerPreferredResolution?: ResolutionEnum;
  policeReport?: string;
  policeDepartment?: string;
  phoneVerified = false;
  @observable affidavitAcknowledged = false;
  prepaidCardOptions?: PrepaidCardOptions;

  constructor(claimType: ClaimTypeEnum) {
    this.type = claimType;
    makeObservable(this);
  }
}

export interface IClaimedItem {
  item: GroupedItem;
  perishable: boolean;
  proofPhotos?: Array<File>;
  uploadedPhotos: boolean;
}

export class ClaimedItem implements IClaimedItem {
  public item: GroupedItem;
  public perishable: boolean;
  public uploadedPhotos: boolean;

  constructor(item: GroupedItem, perishable?: boolean) {
    this.item = item;
    this.perishable = perishable ?? false;
    this.uploadedPhotos = false
  }
}

export interface PrepaidCardOptions {
  type: string;
  fullName: string;
  email: string;
}