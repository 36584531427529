import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../../../hooks/app.hook';
import TrackingMap from '../../../components/TrackingMap';
import TrackingProgressBar from '../../../components/TrackingProgressBar';
import { Container, NoTrackingContainer, NoTrackingIcon } from './WISMO.styles';
import Typography from '../../../components/UI/Typography';
import { colors } from '../../../constants/styles';
import { TypographyContent, ButtonContent } from './WISMO.constants';
import ForegroundContentModal from '../../../components/ForegroundContentModal';
import ForegroundContentHeader from '../../../components/ForegroundContentHeader';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { analyticsCodes, logEvent } from '../../../services/analytics';
import { VIEWS } from '../../../constants/views';
import { broadCastAction, broadcastView } from '../../../services/broadcast';
import { ACTIONS } from '../../../constants/actions';

const WISMO: FC = observer(() => {
  const { orderStore, claimStore, routingStore } = useStores();
  const { displayItem, claimedItems } = claimStore;
  const claimEligible = claimStore.hasAtLeastOneShouldDisplayClaim();
  const shipment =
    displayItem &&
    displayItem.item.shipmentId &&
    orderStore.getShipment(displayItem.item.shipmentId);

  const wismoDescription = claimedItems[0]?.item.wismoDescription;

  useEffect(() => {
    broadcastView(VIEWS.CLAIM_ITEM_WISMO);
  }, []);

  useEffect(() => {
    const shipmentStatus =
      shipment && shipment.deliveryStatus ? shipment.deliveryStatus : 'unknown';

    if (wismoDescription) {
      logEvent(analyticsCodes.WISMO, {
        screen: analyticsCodes.WISMO,
        assessment: wismoDescription,
        loggedIn: false,
        shipmentStatus: shipmentStatus,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wismoDescription]);

  const closeClickHandle = () => {
    claimStore.reset();
    broadCastAction(ACTIONS.CANCEL_CLAIM_FILING);
    logEvent(analyticsCodes.closeWISMO, {
      tapped: ButtonContent.exit,
    });
    routingStore.replace('/order-information');
  };

  const nextClickHandle = () => {
    logEvent(analyticsCodes.reportWISMO, {
      tapped: ButtonContent.report,
    });
    routingStore.replace('/item/shipping-issue');
  };

  const backClickHandle = () => {
    broadCastAction(ACTIONS.NAVIGATE_BACK_CLICK);
    routingStore.goBack();
  };

  return (
    <ForegroundContentModal
      open
      mainButton={ButtonContent.exit}
      mainButtonClick={closeClickHandle}
      secondaryButton={claimEligible ? ButtonContent.report : undefined}
      secondaryButtonClick={claimEligible ? nextClickHandle : undefined}
    >
      <ForegroundContentHeader
        quantityNotEditable={true}
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        displayItem={displayItem!}
        claimedItems={claimedItems}
        clickButtonHandle={backClickHandle}
        title={TypographyContent.title}
        centered
        zeroBottomPadding
      >
        <Container>
          {!shipment ? (
            <NoTrackingContainer>
              <NoTrackingIcon
                src={TypographyContent.noTrackImg}
                alt={TypographyContent.noTrackingAlt}
              />
              <br />
              <Typography variant="p4" color={colors.routeText2} semibold>
                {TypographyContent.noTrackTitle}
              </Typography>
              <TrackingProgressBar />
              <Typography variant="p4" color={colors.routeText2}>
                {TypographyContent.noTrackDesc}
              </Typography>
            </NoTrackingContainer>
          ) : !orderStore.routeOrder ? (
            <LoadingSpinner />
          ) : (
            <React.Fragment>
              <TrackingProgressBar
                shipment={shipment}
                descText={wismoDescription}
              />
              <TrackingMap
                merchant={orderStore.routeOrder.merchant}
                shipment={shipment}
              />
            </React.Fragment>
          )}
        </Container>
      </ForegroundContentHeader>
    </ForegroundContentModal>
  );
});

export default WISMO;
