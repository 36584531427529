import { observer } from 'mobx-react';
import React, { FC } from 'react';
import Button from '../Buttons/Button';
import MerchantLogo from '../IconOrLogos/MerchantLogo';
import OrderDetails from '../OrderDetails';
import Typography from '../UI/Typography';
import { DateFormatterLong } from '../../utils';

import { addressArray } from '../OrderDetails/OrderDetails.utils';
import {
  ButtonContainer,
  Container,
  DescriptionContainer,
  ItemContainer,
  MerchantContainer,
  TitleContainer,
} from './Summary.styles';
import { colors } from '../../constants/styles';
import { ISummaryProps } from './interface';
import { ButtonContents } from './Summary.constants';
import ItemIcon from '../IconOrLogos/ItemIcon';
import {
  HorizontalSeparator,
  VerticalSeparator,
} from '../../styles/global.styles';

const Summary: FC<ISummaryProps> = observer((props: ISummaryProps) => {
  const {
    merchant,
    title,
    description,
    order,
    actionButtonText,
    children,
    displayItem,
    claimedItems,
  } = props;

  const item = displayItem?.item;

  return (
    <Container className={props.className}>
      {merchant && (
        <MerchantContainer>
          <MerchantLogo
            src={merchant.imageURL}
            alt={`${merchant.name} logo`}
            name={merchant.name}
            data-testid="merchant-logo"
          />
          <HorizontalSeparator distance={17} />

          <Typography variant="h1" color={colors.routeText1}>
            {merchant.name}
          </Typography>
        </MerchantContainer>
      )}

      <VerticalSeparator distance={57} />

      <TitleContainer>
        <Typography variant="h5" color={colors.routeText1}>
          {title}
        </Typography>
      </TitleContainer>

      <VerticalSeparator distance={16} />

      <DescriptionContainer>
        <Typography variant="p2" color={colors.routeText1}>
          {description}
        </Typography>
      </DescriptionContainer>

      {children}

      <VerticalSeparator distance={49} />

      {item && (
        <>
          <ItemContainer>
            <ItemIcon
              imageUrl={item?.imageUrl ?? ''}
              alt={`item`}
              quantity={claimedItems?.reduce(
                (acc, items) => acc + items.item.quantity,
                0
              )}
            />

            <HorizontalSeparator distance={17} />

            <Typography variant="l1" color={colors.routeText1}>
              {displayItem?.item.name ?? ''}
            </Typography>
          </ItemContainer>

          <VerticalSeparator distance={41} />
        </>
      )}

      <OrderDetails
        shippingAddress={addressArray(order.shippingDetails)}
        sourceOrderNumber={order.sourceOrderNumber}
        email={order.email}
        orderDate={DateFormatterLong(order.createdOn)}
        extendedVertical
      />

      <ButtonContainer>
        {actionButtonText && (
          <Button onClick={props.actionHandle} fullWidth>
            {actionButtonText}
          </Button>
        )}
        <Button
          onClick={props.closeHandle}
          mode={actionButtonText ? 'light' : 'dark'}
          fullWidth
        >
          {ButtonContents.close}
        </Button>
      </ButtonContainer>
    </Container>
  );
});

export default Summary;
