import React, { FC } from 'react';
import Typography from '../UI/Typography';
import { IActionItemsProps } from './interface';
import {
  Container,
  Paper,
  ClaimSelectableRowContainer,
  ShipmentHeader,
  Separator,
} from './ActionItems.styles';
import { TypographyContent } from './ActionItems.constants';
import ClaimSelectableRow from '../ClaimSelectableRow';
import { colors } from '../../constants/styles';
import { GroupedItem } from '../../models/GroupedItems.model';
import ClaimedSelectableRow from '../ClaimedSelectableRow';

const ActionItems: FC<IActionItemsProps> = (props: IActionItemsProps) => {
  const {
    claimedItems,
    shipmentIssueItems,
    shipmentIssueItemsNotInsured,
    selectClaim,
  } = props;

  const clickClaimItems = (items: Array<GroupedItem>, display: GroupedItem) => {
    props.selectItemsToClaimHandle(items, display);
  };

  return (
    <Container>
      <Paper>
        {claimedItems.length > 0 && (
          <React.Fragment>
            <Typography variant="h5" color={colors.routeText1}>
              {TypographyContent.claimUpdates}
            </Typography>
            {claimedItems.map((claim, index) => {
              return (
                <ClaimSelectableRowContainer key={index}>
                  <ClaimedSelectableRow
                    clickHandle={() => selectClaim && selectClaim(claim)}
                    claim={claim}
                    statusSubtitle
                  />
                </ClaimSelectableRowContainer>
              );
            })}
          </React.Fragment>
        )}

        {shipmentIssueItems.length > 0 && (
          <React.Fragment>
            <Typography variant="h5" color={colors.routeText1}>
              {TypographyContent.selectItem}
            </Typography>
            {shipmentIssueItems.map((itemsArr, indexer) => {
              return (
                <React.Fragment key={indexer}>
                  <ShipmentHeader>
                    {shipmentIssueItems.length > 1 && (
                      <Typography variant="p2" color={colors.routeText1}>
                        Package {indexer + 1} of {shipmentIssueItems.length}
                      </Typography>
                    )}
                  </ShipmentHeader>
                  {itemsArr.length > 1 && (
                    <ClaimSelectableRowContainer key={indexer}>
                      <ClaimSelectableRow
                        clickHandle={() =>
                          clickClaimItems(itemsArr, itemsArr[0])
                        }
                        targetItems={itemsArr}
                        statusSubtitle
                      />
                    </ClaimSelectableRowContainer>
                  )}
                  {itemsArr.map((item, index) => (
                    <ClaimSelectableRowContainer key={index}>
                      <ClaimSelectableRow
                        clickHandle={() => clickClaimItems([item], item)}
                        targetItems={item}
                        statusSubtitle
                      />
                    </ClaimSelectableRowContainer>
                  ))}
                </React.Fragment>
              );
            })}
          </React.Fragment>
        )}

        {shipmentIssueItemsNotInsured.length > 0 && (
          <React.Fragment>
            {(claimedItems.length > 0 || shipmentIssueItems.length > 0) && (
              <Separator />
            )}
            <Typography variant="p2" color={colors.routeText2}>
              {TypographyContent.itemsNotEligible}
            </Typography>
            {shipmentIssueItemsNotInsured.map((itemsArr, indexer) => {
              return (
                <React.Fragment key={indexer}>
                  <ShipmentHeader>
                    {shipmentIssueItemsNotInsured.length > 1 && (
                      <Typography variant="p2" color={colors.routeText1}>
                        Package {indexer + 1} of{' '}
                        {shipmentIssueItemsNotInsured.length}
                      </Typography>
                    )}
                  </ShipmentHeader>
                  {itemsArr.map((item, index) => (
                    <ClaimSelectableRowContainer key={index}>
                      <ClaimSelectableRow
                        targetItems={item}
                        statusSubtitle
                        disabled
                      />
                    </ClaimSelectableRowContainer>
                  ))}
                </React.Fragment>
              );
            })}
          </React.Fragment>
        )}
      </Paper>
    </Container>
  );
};

export default ActionItems;
