import styled from 'styled-components';
import { colors } from '../../../../constants/styles';
import { NoSlide } from '../../../../styles/global.styles';
import { IReversible, ISelectableRow } from './interface';

export const ContainerIssueType = styled(NoSlide)`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  min-height: 0;
`;

export const Column = styled.div<IReversible>`
  display: flex;
  flex-direction: ${(props: IReversible) =>
    props.reverse ? 'column-reverse' : 'column'};
  flex-grow: 1;
`;

export const SelectableRow = styled.div<ISelectableRow>`
  display: flex;
  justify-content: stretch;
  padding: 16px;
  border-radius: 10px;
  background: ${colors.routeButtonFill4};
  align-items: center;
  cursor: pointer;
`;

export const IconContainer = styled.div`
  display: flex;
  border-radius: 5px;
  background: ${colors.white};
  padding: 2px;
  height: fit-content;
  margin-right: 12px;
`;

export const Content = styled(Column)`
  overflow: auto;

  min-height: 0;
`;

// end for issue type
