import React, { FC, useState } from 'react';
import { colors } from '../../../constants/styles';
import Typography from '../../UI/Typography';
import { IItemIconProps } from './interface';
import { Container, Icon, Quantity, Wrapper } from './ItemIcon.styles';
import MissingImg from './missing-img.svg';

const ItemIcon: FC<IItemIconProps> = (props: IItemIconProps) => {
  const { quantity, imageUrl, alt, noStack, noShadow, overrideSize, overrideRadius, overrideQuantitySize } = props;
  const [img, setImg] = useState(imageUrl);
  const isMultiItem = (): boolean => {
    return !!quantity && quantity > 1;
  };
  return (
    <Wrapper multiItem={!noStack && isMultiItem()}>
      <Container
        multiItem={!noStack && isMultiItem()}
        className={props.className}
        noShadow={noShadow}
      >
        <Icon
          src={img}
          onError={() => setImg(MissingImg)}
          alt={alt}
          overrideSize={overrideSize}
          overrideRadius={overrideRadius}
        />
        {isMultiItem() && (
          <Quantity data-testid="quantity" overrideSize={overrideSize} overrideQuantitySize={overrideQuantitySize}>
            <Typography variant="p4" color={colors.routeText1} semibold>
              {quantity}
            </Typography>
          </Quantity>
        )}
      </Container>
    </Wrapper>
  );
};

export default ItemIcon;
