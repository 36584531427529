import styled from 'styled-components';
import { aboveSize, belowSize } from '../../../../utils/Breakpoints';
import { Row } from '../ClaimedItemsReview.styles';

export const BottomRow = styled.div`
  ${belowSize.md`
    flex-flow: row;
    align-self: center;
    margin-bottom: 5px;
    margin-top: 5px;
  `}
  ${aboveSize.md`
    flex-flow: row;
    align-self: center;
    margin-bottom: 15px;
    margin-top: 15px;
  `}
`;

export const UpperRow = styled(Row)`
  flex-grow: 3;
  ${belowSize.md`
    max-height: 100%;
  `}
`;
