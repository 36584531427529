import styled, { css } from 'styled-components';
import PhotoThumbnail from '../../../components/IconOrLogos/PhotoThumbnail';
import { colors } from '../../../constants/styles';
import { SlideLeft } from '../../../styles/global.styles';
import { belowSize } from '../../../utils/Breakpoints';
import { ISelectableRow } from './interface';

export const Container = styled(SlideLeft)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-self: stretch;

  min-height: 0;
  ${belowSize.md`
    padding-bottom: 40px;
    margin-left: 10px;
  `}
`;

export const PhotoThumbnailStyled = styled(PhotoThumbnail)`
  margin: 0px 8px 0px 0px;
  cursor: pointer;
  ${(props: ISelectableRow) => {
    if (props.highlight)
      return css`
        position: relative;
        &:after {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(
            271.05deg,
            rgba(40, 184, 227, 0.7) 1.66%,
            rgba(245, 245, 245, 0.7) 108.23%
          );
        }
      `;
  }}
`;

export const SliderPhotoRow = styled.div`
  display: flex;
  min-height: 112px;
  overflow-x: auto;
  margin-bottom: 20px;

  ::-webkit-scrollbar {
    height: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${colors.lightGray}33; //20% opacity
    border-radius: 5px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${colors.divider}80;
    border-radius: 10px;
  }
`;

export const Row = styled.div`
  margin-top: 30px;
  display: flex;
  align-items: flex-start;
  ${belowSize.md`
      margin-top: 10px;
  `}
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  border-top: 1px dashed #cccccc;
  padding-top: 20px;
`;
