
import styled from "styled-components";
import { colors } from "../../../constants/styles";
import { SlideUp } from "../../../styles/global.styles";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;

`
export const Content = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	padding: 20px;
	overflow-y: auto;
`

export const Wrapper = styled(SlideUp)`
	position: absolute;
	bottom: 0;
	flex-grow: 1;
    height: 90%;
	width: 100%;
    background: ${colors.white};
    border-radius: 8px 8px 0px 0px;
    z-index: 10;
`

export const BackButton = styled.div`
  display: flex;
  z-index: 1;
  position: relative;
  padding-left: 4px;
  padding-top: 20px;
  padding-bottom: 20px;
`;

export const Blurred = styled.div`
	z-index: 5;
	position: absolute;
	width: 100%;
	height: 100%;
	backdrop-filter: blur(30px);
	@-moz-document url-prefix() {
		background-color: rgba(220, 220, 220, 0.9);
	}
	animation-name: blurring;
	animation-duration: 0.8s;
	@keyframes blurring {
		0% {
		backdrop-filter: blur(0px);
		}
		100% {
		backdrop-filter: blur(30px);
		}
	}
`
