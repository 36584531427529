import { observable, action, makeObservable, reaction } from 'mobx';
import {
  ClaimBlockingScreens,
  ClaimFlowType,
  ClaimBlockingAction,
  mainFlowMap,
  TypographyType,
  ROUTE_NUMBER_REGEX,
  TRACKING_NUMBER_REGEX,
} from '../_constants/ClaimBlockingFlow.constants';
import { PreAssessmentResultEnum } from '../../models/Enums/PreAssessmentEnum.model';
import { screensFlowMap } from '../_constants/ClaimBlockingFlowMap.constants';
import { claimBlockingAnalyticsCodes } from '../_constants/ClaimBlockingFlowAnalytics.constants';
import { claimBlockingTypographyConstants } from '../_constants/ClaimBlockingFlowTypography.constants';
import { RootStore } from '../RootStore';
import isURL from 'validator/lib/isURL';

export class ClaimBlockingErrors {
  constructor() {
    makeObservable(this);
  }
  [key: string]: boolean;
  @observable trackingNumber = false;
  @observable trackinURL = false;
}

export interface IClaimBlockingStore {
  trackingNumber: string;
  carrier: string;
  trackingURL: string;
  errors?: ClaimBlockingErrors;
}

export class ClaimBlockingStore implements IClaimBlockingStore {
  [key: string]: unknown;

  private rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
    this.preassessmentResult = undefined;

    reaction(
      () => this.trackingNumber,
      (trackingNumber: string) => {
        if (
          trackingNumber !== '' &&
          (ROUTE_NUMBER_REGEX.test(trackingNumber) || !TRACKING_NUMBER_REGEX.test(trackingNumber))
        ) {
          this.setErrors('trackingNumber', true);
        }
      }
    );
    reaction(
      () => this.trackingURL,
      (trackingURL: string) => {
        if (!isURL(trackingURL)) {
          this.setErrors('trackingURL', true);
        }
      }
    );
  }

  @observable trackingNumber = '';
  @observable carrier = '';
  @observable trackingURL = '';
  @observable preassessmentResult: PreAssessmentResultEnum | undefined;

  errors = new ClaimBlockingErrors();

  @action set(key: string, value: string): void {
    this.resetSpecificError(key);
    this[key] = value;
  }

  @action setErrors(key: string, value: boolean): void {
    this.errors[key] = value;
  }

  @action resetSpecificError(key: string): void {
    this.errors[key] = false;
  }

  @action resetErrors(): void {
    this.errors = new ClaimBlockingErrors();
  }

  @action reset(): void {
    this.trackingNumber = '';
    this.carrier = '';
    this.trackingURL = '';
    this.preassessmentResult = undefined;
    this.resetErrors();
  }

  @action setPreassessmentResult(
    pr: PreAssessmentResultEnum | undefined
  ): void {
    this.preassessmentResult = pr;
  }

  makeScreenFlowKey = (
    flowType: ClaimFlowType,
    screen: ClaimBlockingScreens,
    action: ClaimBlockingAction
  ): string => {
    return `${flowType}|${screen}|${action}`;
  };

  makeAnalyticsKey = (
    flowType: ClaimFlowType,
    screen: ClaimBlockingScreens
  ): string => {
    return `${flowType}|${screen}`;
  };

  makeTypographyKey = (
    flowType: ClaimFlowType,
    screen: ClaimBlockingScreens,
    typographyType: TypographyType
  ): string => {
    return `${flowType}|${screen}|${typographyType}`;
  };

  getFlowType = (): ClaimFlowType => {
    return this.preassessmentResult
      ? mainFlowMap[this.preassessmentResult] ?? ClaimFlowType.REGULAR_FLOW
      : ClaimFlowType.REGULAR_FLOW;
  };

  getButtonRoute = (
    screen: ClaimBlockingScreens,
    action: ClaimBlockingAction
  ): string => {
    return (
      //@ts-ignore
      screensFlowMap[
      this.makeScreenFlowKey(this.getFlowType(), screen, action)
      ] ?? '/not-found'
    );
  };

  getAnalytics = (screen: ClaimBlockingScreens): string => {
    //@ts-ignore
    return claimBlockingAnalyticsCodes[
      this.makeAnalyticsKey(this.getFlowType(), screen)
    ];
  };

  getTitle = (screen: ClaimBlockingScreens): string => {
    return (
      //@ts-ignore
      claimBlockingTypographyConstants[
      this.makeTypographyKey(this.getFlowType(), screen, TypographyType.TITLE)
      ] ?? ''
    );
  };

  getDescription = (screen: ClaimBlockingScreens): string => {
    return (
      //@ts-ignore
      claimBlockingTypographyConstants[
      this.makeTypographyKey(
        this.getFlowType(),
        screen,
        TypographyType.DESCRIPTION
      )
      ] ?? ''
    );
  };

  shouldHangTightShowTracking = (): boolean => {
    return this.getFlowType() === ClaimFlowType.LABEL_CREATED_FLOW;
  };

  finishClaimBlocking = (): void => {
    if (this.rootStore.claimStore.isClaimInitialized()) {
      this.rootStore.claimStore.continueClaim();
    } else {
      this.rootStore.routingStore.replace('/item/shipping-issue');
    }
  }
}
