import { FC } from 'react';
import { IScrollPaperProps } from './interface';
import { BackButton, Blurred, Container, Content, Wrapper } from './ScrollPaper.styles';
import Button from '../../Buttons/Button';
import { Icon } from '../../../styles/global.styles';
import { colors } from '../../../constants/styles';
import BackIcon from '../../../assets/icons/back-icon.svg';

const ScrollPaper: FC<IScrollPaperProps> = (props: IScrollPaperProps) => {
	return props.open ? (
		<Blurred>
			<Wrapper>
				<Container>

					<BackButton>
						<Button
							mode="light"
							overrideBackground={colors.white}
							ariaLabel="back"
							onClick={props.closeFunction}
							icon={<Icon src={BackIcon} verticalAlign="middle" />}
						/>
					</BackButton>
					<Content>
						{props.children}
					</Content>
				</Container>
			</Wrapper>
		</Blurred>
	) : null;
};

export default ScrollPaper;
