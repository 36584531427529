/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect } from 'react';
import { TypographyContent } from './ClaimedItemsReviewMainScreen.constants';
import {
  MainContainer,
  Row,
  RowLeftContainer,
  RowRightContainer,
  Separator,
  ButtonsContainer,
} from '../ClaimedItemsReview.styles';
import Typography from '../../../../components/UI/Typography';
import { colors } from '../../../../constants/styles';
import CleanButton from '../../../../components/Buttons/CleanButton';
import {
  Container,
  Photos,
  PhotoThumbnailStyled,
  RowLeftContainerAffidavit,
  RowRightContainerAffidavit,
  SliderPhotoRow,
  TextContainer,
} from './ClaimedItemsReviewMainScreen.styles';
import Checkbox from '../../../../components/Inputs/Checkbox';
import { useStores } from '../../../../hooks/app.hook';
import { observer } from 'mobx-react';
import { ClaimTypeEnum } from '../../../../models/Enums/ClaimEnum.model';
import { IClaimedItemsReviewMainScreen } from './interface';
import Button from '../../../../components/Buttons/Button';
import { VerticalSeparator } from '../../../../styles/global.styles';
import { broadcastView } from '../../../../services/broadcast';
import { VIEWS } from '../../../../constants/views';
import { ResolutionEnum } from '../../../../models/Enums/OrderEnum.model';
import { GiftCardDisclaimerTooltip } from '../../../../components/GiftCardDisclaimerTooltip/GiftCardDisclaimerTooltip';

const ClaimedItemsReviewMainScreen: FC<IClaimedItemsReviewMainScreen> = observer(
  (props: IClaimedItemsReviewMainScreen) => {
    const {
      submitClickHandler,
      cancelClickHandler,
      editIssueHandler,
      editPhotosHandler,
      editDetailsHandler,
      editResolutionHandler,
      editGiftCardHandler,
      editVenmoHandler,
    } = props;
    const { claimStore, damagedStore, reviewStore, remedyStore, orderStore } = useStores();
    const { damagedPhotoUrls } = damagedStore;

    const { loading } = claimStore;

    const {
      editIssueType,
      editPhotos,
      editDetails,
      editResolution,
      popUpOpen,
    } = reviewStore;

    useEffect(() => {
      broadcastView(VIEWS.CLAIM_ITEM_REVIEW_MAIN)
    }, [])

    const disabledSubmitButton = () => {
      return (
        !claimStore.affidavitAcknowledged() ||
        (editIssueType && editPhotos && editDetails && editResolution) ||
        loading
      );
    };

    return (
      <MainContainer>
        <Row>
          <RowLeftContainer>
            <Typography variant="p4" color={colors.routeText2} semibold>
              {TypographyContent.issueReported}
            </Typography>
            <Typography variant="p4" color={colors.routeText1}>
              {TypographyContent.issueDefaultText(claimStore.claim, claimStore.getClaimTextCategories())}
            </Typography>
          </RowLeftContainer>
          <RowRightContainer>
            <CleanButton
              onClick={editIssueHandler}
              overrideFontSize={15}
              overrideFontWeight={500}
              overrideTextColor={colors.routeText1}
              noTextDecoration
            >
              {TypographyContent.editButton}
            </CleanButton>
          </RowRightContainer>
        </Row>

        <VerticalSeparator distance={20} />
        <Separator />
        <VerticalSeparator distance={20} />

        {claimStore.claim && claimStore.claim.type === ClaimTypeEnum.DAMAGED && claimStore.needsPhotoOnDamaged() && (
          <React.Fragment>
            <Row>
              <RowLeftContainer>
                <Typography variant="p4" color={colors.routeText1} semibold>
                  {TypographyContent.damagePhotos}
                </Typography>
                <Photos>
                  <SliderPhotoRow>
                    {damagedPhotoUrls.map((damagePhoto, index) => {
                      return (
                        <PhotoThumbnailStyled
                          key={index}
                          imageUrl={damagePhoto.imageUrl}
                        />
                      );
                    })}
                  </SliderPhotoRow>
                </Photos>
              </RowLeftContainer>
              <RowRightContainer>
                <CleanButton
                  onClick={editPhotosHandler}
                  overrideFontSize={15}
                  overrideFontWeight={500}
                  overrideTextColor={colors.routeText1}
                  noTextDecoration
                >
                  {TypographyContent.editButton}
                </CleanButton>
              </RowRightContainer>
            </Row>

            <VerticalSeparator distance={20} />
            <Separator />
            <VerticalSeparator distance={20} />
          </React.Fragment>
        )}

        <Row>
          <RowLeftContainer>
            <Typography variant="p4" color={colors.routeText2} semibold>
              {TypographyContent.issueDetailsText(claimStore.claim)}
            </Typography>
            {claimStore.description ? (
              <TextContainer variant="p4" color={colors.routeText1}>
                {claimStore.description}
              </TextContainer>
            ) : (
              <Typography variant="p4" color={colors.routeText4}>
                {TypographyContent.detailsPlaceholder}
              </Typography>
            )}
          </RowLeftContainer>
          <RowRightContainer>
            <CleanButton
              onClick={editDetailsHandler}
              overrideFontSize={15}
              overrideFontWeight={500}
              overrideTextColor={colors.routeText1}
              noTextDecoration
            >
              {TypographyContent.editButton}
            </CleanButton>
          </RowRightContainer>
        </Row>

        <VerticalSeparator distance={20} />
        <Separator />
        <VerticalSeparator distance={20} />

        <Row>
          <RowLeftContainer>
            <Typography variant="p4" color={colors.routeText2} semibold>
              {TypographyContent.resolutionPreference}
            </Typography>
            <Typography variant="p4" color={colors.routeText1}>
              {TypographyContent.resolutionType(claimStore.claim)}
            </Typography>
          </RowLeftContainer>
          <RowRightContainer>
            <CleanButton
              onClick={editResolutionHandler}
              overrideTextColor={colors.routeText1}
              overrideFontSize={15}
              overrideFontWeight={500}
              noTextDecoration
            >
              {TypographyContent.editButton}
            </CleanButton>
          </RowRightContainer>
        </Row>

        <VerticalSeparator distance={20} />
        <Separator />
        <VerticalSeparator distance={20} />
        {claimStore.claim?.customerPreferredResolution === ResolutionEnum.PREPAID_CARD_MASTERCARD && (
          <>
            <Row>
              <RowLeftContainer>
                <Typography variant="p4" color={colors.routeText2} semibold>
                  {TypographyContent.prepaidRecipientInfo}
                </Typography>
                <Typography variant="p4" color={colors.routeText1}>
                  {remedyStore.giftCardFullName}
                </Typography>
                <Typography variant="p4" color={colors.routeText1}>
                  {remedyStore.giftCardEmail}
                </Typography>
              </RowLeftContainer>
              <RowRightContainer>
                <CleanButton
                  onClick={editGiftCardHandler}
                  overrideTextColor={colors.routeText1}
                  overrideFontSize={15}
                  overrideFontWeight={500}
                  noTextDecoration
                >
                  {TypographyContent.editButton}
                </CleanButton>
              </RowRightContainer>
            </Row>

            <VerticalSeparator distance={20} />
            <Separator />
            <VerticalSeparator distance={20} />
          </>
        )}
        {claimStore.claim?.customerPreferredResolution === ResolutionEnum.GIFT_CARD_MERCHANT && (
          <>
            <Row>
              <RowLeftContainer>
                <Typography variant="p4" color={colors.routeText2} semibold>
                  {TypographyContent.giftCardInfo}
                </Typography>
                <Typography variant="p4" color={colors.routeText1}>
                  {orderStore.routeOrder?.order.email}
                </Typography>
              </RowLeftContainer>
              <RowRightContainer>
                <GiftCardDisclaimerTooltip
                  isOpen={popUpOpen}
                  setIsOpen={(b) => { reviewStore.setPopUpOpen(b) }}
                  email={orderStore.routeOrder?.order.email ?? 'your email'}
                  merchant={orderStore.routeOrder?.merchant.name ?? 'the Merchant'}
                />

              </RowRightContainer>
            </Row>

            <VerticalSeparator distance={20} />
            <Separator />
            <VerticalSeparator distance={20} />
          </>
        )}
        {claimStore.claim?.customerPreferredResolution === ResolutionEnum.REFUND_VENMO && (
          <>
            <Row>
              <RowLeftContainer>
                <Typography variant="p4" color={colors.routeText2} semibold>
                  {TypographyContent.venmoInfo}
                </Typography>
                <Typography variant="p4" color={colors.routeText1}>
                  {remedyStore.venmoInput}
                </Typography>
              </RowLeftContainer>
              <RowRightContainer>
                <CleanButton
                  onClick={editVenmoHandler}
                  overrideTextColor={colors.routeText1}
                  overrideFontSize={15}
                  overrideFontWeight={500}
                  noTextDecoration
                >
                  {TypographyContent.editButton}
                </CleanButton>
              </RowRightContainer>
            </Row>

            <VerticalSeparator distance={20} />
            <Separator />
            <VerticalSeparator distance={20} />
          </>
        )}
        <Row>
          <Container>
            <RowLeftContainerAffidavit>
              <Checkbox
                checked={claimStore.affidavitAcknowledged()}
                onChange={val => {
                  claimStore.setAffidavitAcknowleged(val);
                }}
                round={true}
                size={'20px'}
              />
            </RowLeftContainerAffidavit>
            <RowRightContainerAffidavit>
              <Typography variant="p4" color={colors.routeText1}>
                {TypographyContent.agreeStatement.statement}
              </Typography>
              <span>
                <Typography variant="p4" color={colors.routeText1} bold>
                  {TypographyContent.agreeStatement.warningLabel}
                </Typography>
                <Typography variant="p4" color={colors.routeText1}>
                  {TypographyContent.agreeStatement.warningMessage}
                </Typography>
                <Typography variant="p4" color={colors.routeText1} bold>
                  {TypographyContent.agreeStatement.warningMessageEmphasis}
                </Typography>
              </span>
            </RowRightContainerAffidavit>
          </Container>
        </Row>

        <VerticalSeparator distance={40} />

        <Row>
          <ButtonsContainer>
            <Button
              mode="dark"
              fullWidth={true}
              isFullscreen={true}
              disabled={disabledSubmitButton()}
              onClick={submitClickHandler}
            >
              {TypographyContent.submitClaim}
            </Button>

            <VerticalSeparator distance={16} />
            <Button
              mode="light"
              fullWidth={true}
              isFullscreen={true}
              onClick={cancelClickHandler}
            >
              {TypographyContent.cancel}
            </Button>
          </ButtonsContainer>
        </Row>
      </MainContainer>
    );
  }
);

export default ClaimedItemsReviewMainScreen;
