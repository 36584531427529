import React from 'react';
import {
  CheckboxContainer,
  ClickableContainer,
  HiddenCheckbox,
  Icon,
  StyledCheckbox,
} from './Checkbox.styles';
import Checkmark from '../../../assets/icons/checkmark.svg';

import { ICheckboxProps } from './interface';

export const Checkbox: React.FC<ICheckboxProps> = ({
  className,
  checked,
  onChange,
  round,
  size,
}: ICheckboxProps) => {
  return (
    <ClickableContainer className={className} data-testid="checkbox">
      <CheckboxContainer>
        <HiddenCheckbox
          type="checkbox"
          checked={checked}
          onChange={e => {
            onChange(e.target.checked);
          }}
        />
        <StyledCheckbox checked={checked} round={round} size={size}>
          <Icon src={Checkmark} />
        </StyledCheckbox>
      </CheckboxContainer>
    </ClickableContainer>
  );
};

export default Checkbox;
