import styled from 'styled-components';
import { Icon } from '../../../../styles/global.styles';
import { colors } from '../../../../constants/styles';

export const Column = styled.div`
   display: flex;
   flex-direction: column;
   margin: 24px 40px;
`
export const BgIcon = styled(Icon)`
  display: flex;
  background: ${colors.routeButtonFill3};
  border-radius: 5px;
  height: 43px;
  width: 63px;
`

export const ViewDetailsLink = styled.a`
  color: ${colors.routeText2};
  font-weight: 600;
`
