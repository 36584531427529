import styled from 'styled-components';
import { colors } from '../../../constants/styles';
import { ICleanButtonProps } from './interface';

const Button = styled.button`
  align-items: center;
  display: flex;
  justify-content: ${(props: ICleanButtonProps) => props.align};

  font-size: ${(props: ICleanButtonProps) =>
    props.overrideFontSize ? `${props.overrideFontSize}px` : '17px'};
  line-height: 24px;
  font-weight: 600;
  color: ${(props: ICleanButtonProps) =>
    props.overrideTextColor ? props.overrideTextColor : colors.routeButtonFill1};

  background: unset;
  padding: 0;
  border: none;
  border-radius: 25px;

  cursor: pointer;
`;

export default Button;
