import styled from 'styled-components';
import ButtonRow from '../../../../components/Buttons/ButtonRow';
import ItemIcon from '../../../../components/IconOrLogos/ItemIcon';
import PhotoThumbnail from '../../../../components/IconOrLogos/PhotoThumbnail';
import { colors } from '../../../../constants/styles';
import { SlideLeft } from '../../../../styles/global.styles';

export const Container = styled(SlideLeft)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-self: stretch;
  margin: 0;
  min-height: 0;
`;

export const ButtonRowStyled = styled(ButtonRow)`
  margin: 16px 0 0 0;
`;

export const SliderPhotoRow = styled.div`
  display: flex;
  flex-direction: row-reverse; // Note(12, Dec 2023): the scroll needs to be at the last photo
  min-height: 112px;
  overflow-x: auto;

  ::-webkit-scrollbar {
    height: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${colors.lightGray}33; //20% opacity
    border-radius: 5px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${colors.divider}80;
    border-radius: 10px;
  }
`;

export const Row = styled.div`
  margin: 14px 0 0 0;
  display: flex;
  align-items: flex-start;
`;

export const PhotoThumbnailStyled = styled(PhotoThumbnail)`
  margin: 0px 8px 0px 0px;
`;

export const ItemIconStyled = styled(ItemIcon)`
  margin-right: 8px;
  box-shadow: unset;
`;

export const Separator = styled.div`
  border: 0.5px solid rgba(216, 216, 216, 0.7);
`;
