import styled, { css } from 'styled-components';
import { colors } from '../../constants/styles';

export interface IClickable {
  clickable: boolean;
  disabled?: boolean;
}

export const ClickableContainer = styled.div`
  display: flex;

  background: ${colors.routeButtonFill4};
  border-radius: 10px;

  justify-content: stretch;
  padding: 16px;
  align-items: center;

  ${(props: IClickable) => {
    if (props.clickable) {
      return css`
        cursor: pointer;
        &:hover {
          -webkit-transform: scale(0.98);
          transform: scale(0.98);
        }
      `;
    }
  }}

  ${(props: IClickable) => {
    if (props.disabled) {
      return css`
        opacity: 70%;
      `;
    }
  }}
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const IconContainer = styled.div`
  display: flex;
  border-radius: 5px;
  padding: 2px;
  height: fit-content;
  margin-right: 12px;
`;
