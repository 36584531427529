export const TextFieldContent = {
  email: {
    placeholder: 'Email',
    toolTipText: 'Use the email that you made the purchase with.',
    errorText: 'Sorry, we couldn’t find this email.',
  },
  orderNumber: {
    placeholder: 'Order Number',
    errorText: 'Sorry, we couldn’t find this order number.',
    toolTipText: `Use the Route Order Number which can be found in your confirmation email. Or use the store's order number associated with your purchase.`,
  },
};

export const TypographyContent = {
  title: 'Resolve Shipping Problems',
  description: `Was your package lost, damaged or stolen while being shipped? Then let’s get it taken care of.`,
  statement: 'Start by entering your order details.',
  next: 'Next',
};
