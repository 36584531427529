import styled from 'styled-components';
import { colors } from '../../../../constants/styles';

export const TrackingNumberContainer = styled.div`
  display: flex;
  justify-content: center;

  margin-top: 20px;
  margin: 28px 40px 0px 40px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;

  margin: 40px 40px 0px 40px;
`;

export interface ICentered {
  centered?: boolean;
}

export const MoreInfoContainer = styled.div`
  display: flex;
  margin: 28px 40px 0px 40px;
  justify-content: ${(props: ICentered) => {
    return props.centered ? 'center' : 'unset';
  }};
`

export const TextLink = styled.a`
  color: ${colors.routeText2};
  font-weight: 600;
`
