import { observer } from 'mobx-react';
import React, { } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { useStores } from '../../hooks/app.hook';
import FindOrder from '../../views/FindOrder';

const RootRedirect: React.FC = observer(() => {
  const { orderStore, findOrderStore, commonStore } = useStores();
  const location = useLocation();

  const shouldRedirectToOrderInformation = () => {
    const urlParams = new URLSearchParams(location.search);

    const source = urlParams.get('source') || urlParams.get('claimSource');
    if (source) {
      commonStore.setSource(source);
    }
    const skipSearch = urlParams.get('skipSearch');
    const email = urlParams.get('email') || urlParams.get('emailAddress');
    const ron = urlParams.get('routeOrderNumber');

    if (skipSearch && email && ron) {
      findOrderStore.set('number', ron);
      findOrderStore.set('email', email);
      orderStore.setOrderBasicInfo(email, ron)
      return true;
    }

    return false;
  };

  return (
    shouldRedirectToOrderInformation() ?
      <Redirect to="/order-information" />
      :
      <FindOrder />
  );
});

export default RootRedirect;
