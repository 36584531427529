/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, Fragment, useEffect } from 'react';
import { observer } from 'mobx-react';
import {
  Container,
  Column,
  Separator,
} from './ClaimedItemsEditQuantity.styles';
import { useStores } from '../../../hooks/app.hook';
import ForegroundContentHeader from '../../../components/ForegroundContentHeader';
import ForegroundContentModal from '../../../components/ForegroundContentModal';
import { ButtonContent } from './ClaimedItemsEditQuantity.constants';
import GroupedItemQuantityRow from '../../../components/GroupedItemQuantityRow';
import { VIEWS } from '../../../constants/views';
import { broadcastView } from '../../../services/broadcast';

const ClaimedItemsEditQuantity: FC = observer(() => {
  const { claimStore, commonStore } = useStores();
  const { displayItem: item, claimedItems } = claimStore;

  if (!item) {
    commonStore.displayError();
    return null;
  }

  useEffect(() => {
    broadcastView(VIEWS.CLAIM_ITEM_EDIT_QUANTITY)
  }, [])

  const updateClickHandle = () => {
    claimStore.updateQuantities();
    claimStore.setEditingQuantity(false);
  };

  const closeClickHandle = () => {
    claimStore.setEditingQuantity(false);
  };
  return (
    <ForegroundContentModal
      open
      mainButton={ButtonContent.updateQuantity}
      mainButtonClick={updateClickHandle}
      secondaryButton={ButtonContent.cancel}
      secondaryButtonClick={closeClickHandle}
    >
      <ForegroundContentHeader claimedItems={claimedItems} displayItem={item}>
        <Container>
          <Column>
            {claimedItems.map((claimedItem, index) => {
              const groupedItem = claimedItem.item;
              const currentQuantity = claimStore.itemQuantityMap.get(
                groupedItem
              );
              if (claimedItem.item.items.length > 1) {
                return (
                  <Fragment key={`qt_row_${index}`}>
                    <GroupedItemQuantityRow
                      item={groupedItem}
                      targetQuantity={currentQuantity}
                      addClickHandle={() => {
                        claimStore.setItemQuantity(
                          groupedItem,
                          currentQuantity + 1
                        );
                      }}
                      subtractClickHandle={() => {
                        claimStore.setItemQuantity(
                          groupedItem,
                          currentQuantity - 1
                        );
                      }}
                    />
                    {index + 1 !== claimedItems.length && <Separator />}
                  </Fragment>
                );
              }
              return null;
            })}
          </Column>
        </Container>
      </ForegroundContentHeader>
    </ForegroundContentModal>
  );
});

export default ClaimedItemsEditQuantity;
