import styled, { css } from 'styled-components';
import { colors } from '../../constants/styles';
import {
  Centralized,
  slideLeftKeys,
  slideUpKeys,
} from '../../styles/global.styles';
import { aboveSize, belowSize } from '../../utils/Breakpoints';

export interface IFullSlide {
  transition?: 'slide-up' | 'slide-left';
}

export const Wrapper = styled.div`
  position: absolute;
  height: 100%
  overflow: hidden;

  ${aboveSize.md`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    flex: 1;
    min-height: 0;
    width: 100vw;
    overflow: hidden;
  `}

  ${belowSize.md`
    display: flex;
    flex: 1;
    overflow: auto;
    width: 100%;
  `}
`;

export const CustomCentralized = styled(Centralized)`
  padding: 5% 15% 6% 15%;
  ${belowSize.md`
    padding: 0px;
  `}
  ${aboveSize.xg`
    padding: 5% 15% 6% 15%;
  `};
  ${aboveSize.md`
    overflow: hidden;
  `}
`;

const SLIDE_UP = css`
  animation: ${slideUpKeys} 0.5s forwards;
  -webkit-animation: ${slideUpKeys} 0.5s forwards;
`;

const SLIDE_LEFT = css`
  animation: ${slideLeftKeys} 0.5s forwards;
  -webkit-animation: ${slideLeftKeys} 0.5s forwards;
`;

const NO_SLIDE = css`
  animation: ${slideLeftKeys} 0s forwards;
  -webkit-animation: ${slideLeftKeys} 0s forwards;
`;

export const FullSlide = styled.div`
  flex-grow: 1;
  ${aboveSize.md`
    overflow: hidden;
  `}

  display: flex;
  transform-origin: 50% 100%;
  -webkit-transform-origin: 50% 100%;

  ${(props: IFullSlide) => {
    switch (props.transition) {
      case 'slide-up':
        return SLIDE_UP;
      case 'slide-left':
        return SLIDE_LEFT;
      default:
        return NO_SLIDE;
    }
  }};
`;

export const CustomPaper = styled.div`
  box-shadow: 0px 16.9493px 21.792px rgba(0, 0, 0, 0.05);
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background: ${colors.white};
  border-radius: 12px;

  ${belowSize.md`
    border-radius: 0px;
    padding-top: 92px;
  `}

  ${aboveSize.md`
    overflow: hidden;
  `}
`;

export const Container = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: row;

  ${belowSize.md`
    flex-direction: column;
  `}

  ${aboveSize.md`
    overflow: hidden;
  `}
`;

export const TopInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;

  ${belowSize.md`
    width: unset;
    max-height: 370px;
    min-height: 330px;
  `}
`;

export const ScrollContainer = styled.div`
  display: flex;
  flex-direction: column;

  margin: auto 0;
  padding-right: 4px;

  max-height: 100%;

  overflow-y: auto;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${colors.lightGray}33; // 20% opacity
    border-radius: 5px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${colors.divider}80;
    border-radius: 10px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 50%;
  padding: 24px 32px 24px 32px;

  background: white;
  box-sizing: border-box;

  overflow-y: auto;

  ${belowSize.md`
    width: unset;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    padding: 40px 28px 40px 28px;
  `}
`;

export const TopContainerButton = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;

  padding: 24px;
  ${belowSize.md`
    padding: 51px 0px 0px 16px;
  `}
`;

export const ItemContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
`;

export const Store = styled.div`
  align-items: center;
  text-align: center;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: left;
  align-content: left;
  align-items: center;
`;

export const Separator = styled.div`
  background-image: linear-gradient(
    to right,
    ${colors.routeText2} 10%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: top;
  background-size: 6px 1px;
  background-repeat: repeat-x;
  height: 1px;
`;

export const LineHorinzontal = css`
  width: 80%;
  border-top: 1px solid #ddd;
`;

export const LineVertical = css`
  height: 80%;
  border-left: 1px solid #ddd;
`;

export const Line = styled.div`
  display: flex;
  align-self: center;
  justify-self: center;

  ${belowSize.md(LineHorinzontal)}

  ${aboveSize.md(LineVertical)}
`;
