import {
  ClaimBlockingScreens,
  ClaimFlowType,
  TypographyType,
} from './ClaimBlockingFlow.constants';

// This maps the contents of screens that are not driven by the backend
// Key: "TYPE|SCREEN|CONTENT"
// Value: text of content
// Note: "Hang tight" and "It's on the way" screens have title and description coming from the backend
export const claimBlockingTypographyConstants = {
  // --- Label Created Flow --- //
  // How can we help
  [`${ClaimFlowType.LABEL_CREATED_FLOW}|${ClaimBlockingScreens.HOW_CAN_WE_HELP}|${TypographyType.TITLE}`]: 'How can we help with your order?',

  // Confirm tracking number
  [`${ClaimFlowType.LABEL_CREATED_FLOW}|${ClaimBlockingScreens.CONFIRM_TRACKING_NUMBER}|${TypographyType.TITLE}`]: 'Please confirm this is the correct tracking number:',

  // Confirm carrier update
  [`${ClaimFlowType.LABEL_CREATED_FLOW}|${ClaimBlockingScreens.CONFIRM_CARRIER_UPDATE}|${TypographyType.TITLE}`]: 'Please confirm this is the latest carrier update:',

  // Enter tracking url
  [`${ClaimFlowType.LABEL_CREATED_FLOW}|${ClaimBlockingScreens.ENTER_TRACKING_URL}|${TypographyType.TITLE}`]: 'We may have the wrong tracking information associated with this package.',
  [`${ClaimFlowType.LABEL_CREATED_FLOW}|${ClaimBlockingScreens.ENTER_TRACKING_URL}|${TypographyType.DESCRIPTION}`]: 'Please copy and paste the tracking url for this package.',

  // Enter tracking number
  [`${ClaimFlowType.LABEL_CREATED_FLOW}|${ClaimBlockingScreens.ENTER_TRACKING_NUMBER}|${TypographyType.TITLE}`]: "To continue, we'll need a bit more detail about your order.",

  // --- --- //

  // --- No Tracking Flow --- //
  // How can we help
  [`${ClaimFlowType.NO_TRACKING_NUMBER_FLOW}|${ClaimBlockingScreens.HOW_CAN_WE_HELP}|${TypographyType.TITLE}`]: 'How can we help with your order?',

  // Do you have tracking number
  [`${ClaimFlowType.NO_TRACKING_NUMBER_FLOW}|${ClaimBlockingScreens.DO_YOU_HAVE_TRACKING_NUMBER}|${TypographyType.TITLE}`]: 'Do you have the tracking number from your order confirmation email?',

  // Enter tracking number
  [`${ClaimFlowType.NO_TRACKING_NUMBER_FLOW}|${ClaimBlockingScreens.ENTER_TRACKING_NUMBER}|${TypographyType.TITLE}`]: "To continue, we'll need a bit more detail about your order.",

  // --- --- //

  // --- Too Early Flow --- //
  // How can we help
  [`${ClaimFlowType.TOO_EARLY_FLOW}|${ClaimBlockingScreens.HOW_CAN_WE_HELP}|${TypographyType.TITLE}`]: 'How can we help with your order?',

  // Confirm tracking number
  [`${ClaimFlowType.TOO_EARLY_FLOW}|${ClaimBlockingScreens.CONFIRM_TRACKING_NUMBER}|${TypographyType.TITLE}`]: 'Please confirm this is the correct tracking number:',

  // Confirm carrier update
  [`${ClaimFlowType.TOO_EARLY_FLOW}|${ClaimBlockingScreens.CONFIRM_CARRIER_UPDATE}|${TypographyType.TITLE}`]: 'Please confirm this is the latest carrier update:',

  // Enter tracking url
  [`${ClaimFlowType.TOO_EARLY_FLOW}|${ClaimBlockingScreens.ENTER_TRACKING_URL}|${TypographyType.TITLE}`]: `We may have the wrong
tracking information associated with this package.`,
  [`${ClaimFlowType.TOO_EARLY_FLOW}|${ClaimBlockingScreens.ENTER_TRACKING_URL}|${TypographyType.DESCRIPTION}`]: 'Please copy and paste the tracking url for this package.',

  // Enter tracking number
  [`${ClaimFlowType.TOO_EARLY_FLOW}|${ClaimBlockingScreens.ENTER_TRACKING_NUMBER}|${TypographyType.TITLE}`]: "To continue, we'll need a bit more detail about your order.",
};
