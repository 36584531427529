import React, { FC } from 'react';
import { IForegroundContentHeaderProps } from './interface';
import {
  Container,
  Column,
  Grid,
  ChildrenContainer,
  ButtonContainer,
  EditQuantityContainer,
  ItemInfoContainer,
  ItemInfoIconColumn,
  ItemInfoTextColumn,
  Row,
  TitleContainer,
  DescriptionContainer,
} from './ForegroundContentHeader.styles';
import ItemIcon from '../IconOrLogos/ItemIcon';
import Typography from '../UI/Typography';
import Button from '../Buttons/Button';
import BackIcon from '../../assets/icons/back-icon.svg';
import { colors } from '../../constants/styles';
import { Icon } from '../../styles/global.styles';
import CleanButton from '../Buttons/CleanButton';
import EditIcon from '../../assets/icons/edit-icon.svg';
import { TypographyContents } from './ForegroundContentHeader.constants';
import SwitchButton from '../Buttons/SwitchButton';

const ForegroundContentHeader: FC<IForegroundContentHeaderProps> = (
  props: IForegroundContentHeaderProps
) => {
  const {
    children,
    claimedItems,
    displayItem,
    perishableStatus,
    perishableRow,
    quantityNotEditable,
    clickEditQuantityHandle,
    clickButtonHandle,
    buttonIcon,
    clickPerishable,
    title,
    description,
    centered,
    zeroBottomPadding,
  } = props;

  const item = displayItem.item;

  const showEditQuantity = () => {
    return claimedItems.some(claimedItem => {
      return claimedItem.item.items.length > 1;
    });
  };

  const PerishableRow = () => {
    return (
      <Row>
        <Column>
          <Typography variant="p4" bold color={colors.routeText1}>
            {TypographyContents.perishableTitle}
          </Typography>
          <Typography variant="p0" semibold color={colors.routeText2}>
            {TypographyContents.perishableDescription}
          </Typography>
        </Column>

        <SwitchButton
          active={perishableStatus}
          setActive={(val: boolean) => {
            if (clickPerishable) clickPerishable(val);
          }}
        />
      </Row>
    );
  };

  const itemQty = claimedItems.reduce(
    (acc, items) => acc + items.item.quantity,
    0
  );

  return (
    <Container>
      <Grid description={description} centered={centered} zeroBottomPadding={zeroBottomPadding}>
        <ItemInfoContainer>
          <ItemInfoIconColumn quantity={itemQty}>
            <ItemIcon
                imageUrl={item.imageUrl ?? ''}
                alt={`item`}
                quantity={itemQty}
              />
          </ItemInfoIconColumn>
          <ItemInfoTextColumn quantity={itemQty}>
            <Typography variant="p4" color={colors.routeText1} semibold>
              {claimedItems.length > 1 ? TypographyContents.allItems : item.name}
            </Typography>
            {!quantityNotEditable &&
              showEditQuantity() &&
              clickEditQuantityHandle && (
                <EditQuantityContainer>
                  <CleanButton onClick={clickEditQuantityHandle}>
                    <Typography variant="p3" color={colors.routeText1}>
                      {TypographyContents.editQuantity}
                    </Typography>{' '}
                    <Icon
                      src={EditIcon}
                      alt="Edit Quantity icon"
                      verticalAlign="middle"
                    />
                  </CleanButton>
                </EditQuantityContainer>
              )}
          </ItemInfoTextColumn>
        </ItemInfoContainer>
        {clickButtonHandle && (
          <ButtonContainer>
            <Button
              mode="light"
              overrideBackground={colors.white}
              ariaLabel="back"
              onClick={clickButtonHandle}
              icon={<Icon src={buttonIcon || BackIcon} verticalAlign="middle" />}
            ></Button>
          </ButtonContainer>
        )}
        <TitleContainer centered={centered}>
          <Typography variant="h5" color={colors.routeText1}>
            {title}
          </Typography>
        </TitleContainer>
        {description && (
          <DescriptionContainer centered={centered}>
            <Typography variant="p2" color={colors.routeText1}>
              {description}
            </Typography>
          </DescriptionContainer>
        )}

        <ChildrenContainer fullRow={!clickButtonHandle}>
          {children}
        </ChildrenContainer>

        {perishableRow && <PerishableRow />}
      </Grid>
    </Container>
  );
};
export default ForegroundContentHeader;
