/* eslint-disable react-hooks/exhaustive-deps */
import { FC } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../../../hooks/app.hook';
import ForegroundContentModal from '../../../components/ForegroundContentModal';
import {
  ButtonContent,
} from './FraudVerificationWrapper.constants';
import PersonaReact from 'persona-react';
import { PERSONA_VERIFICATION_STATUSES } from '../../../models/Persona.model';
import styled from 'styled-components';
import { broadCastAction } from '../../../services/broadcast';
import { ACTIONS } from '../../../constants/actions';
import LoadingSpinner from '../../../components/LoadingSpinner';

const FraudVerificationWrapper: FC = observer(() => {
  const { claimStore, commonStore, fraudStore, routingStore, orderStore } = useStores();

  const { iframeLoading } = fraudStore;

  const routeOrder = orderStore.routeOrder;

  const item = claimStore.displayItem;

  if (!item) {
    commonStore.displayError();
    return null;
  }

  const handleOnComplete = (inquiryId: string) => {
    fraudStore.continueClaimAfterVerification(inquiryId, PERSONA_VERIFICATION_STATUSES.COMPLETED);
  }

  const handleCancel = () => {
    claimStore.reset();
    broadCastAction(ACTIONS.CANCEL_CLAIM_FILING);
    routingStore.replace('/order-information');
  }

  const handleOnError = (status: number) => {
    fraudStore.setError(status);
    fraudStore.continueClaimAfterVerification('', PERSONA_VERIFICATION_STATUSES.ERROR);
  }

  return <ForegroundContentModal
    open
    mainButton={ButtonContent.cancel}
    mainButtonClick={handleCancel}
  >
    <>
      {iframeLoading && (<LoadingSpinner />)}
      <Container visible={!iframeLoading}>
        <PersonaReact
          // This refers to a production demo template owned by Persona
          templateId={process.env.REACT_APP_PERSONA_TEMPLATE_ID ?? ""}
          environmentId={process.env.REACT_APP_PERSONA_ENVIRONMENT_ID ?? ""}
          referenceId={routeOrder?.order.email ?? ""}
          onComplete={({ inquiryId, status, fields }) => handleOnComplete(inquiryId)}
          onError={({ status }) => handleOnError(status)}
          onReady={() => fraudStore.setIframeLoading(false)}
          fields={
            {
              order_id: routeOrder?.order.id ?? '',
              shipment_id: item.item.shipmentId ?? '',
              merchant_id: routeOrder?.merchant.id ?? '',
            }
          }
        />
      </Container>
    </>
  </ForegroundContentModal >
});

type Props = {
  visible: boolean
}

const Container = styled.div<Props>`
  display: ${props => props.visible ? 'flex' : 'none'};
  flex-direction: column;
  flex-grow: 1;

  & iframe {
    flex-grow: 1;
  }
`;

export default FraudVerificationWrapper;
