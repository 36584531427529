import { Claim } from '../../../../models/Claim.model';
import { ClaimTypeEnum } from '../../../../models/Enums/ClaimEnum.model';
import { ResolutionEnum } from '../../../../models/Enums/OrderEnum.model';
import { ClaimTextCategories } from '../../../../models/ClaimTextCategories.model';
import { TextCategory } from '../../../../models/Enums/TextCategory.model';

const IssueTexts = {
  damaged: (category: TextCategory): string => {
    switch (category) {
      case TextCategory.DAMAGE_OR_SPOILED:
        return 'My package was damaged or spoiled during transit';

      default:
        return 'My package was damaged.';
    }
  },

  lost: (category: TextCategory): string => {
    switch (category) {
      default:
        return 'My package was lost.';
    }
  },

  stolen: (category: TextCategory): string => {
    switch (category) {
      default:
        return 'My package was stolen.';
    }
  },
};

export const TypographyContent = {
  issueReported: 'Issue Reported',

  issueDefaultText: (claim: Claim | null, textCategories: ClaimTextCategories): string => {
    if (claim) {
      switch (claim.type) {
        case ClaimTypeEnum.DAMAGED:
          return IssueTexts.damaged(textCategories.damaged);
        case ClaimTypeEnum.LOST:
          return IssueTexts.lost(textCategories.lost);
        case ClaimTypeEnum.STOLEN:
          return IssueTexts.damaged(textCategories.stolen);
      }
    }
    return '';
  },

  changeButton: 'Change',

  damagePhotos: 'Damaged Photos',

  editButton: 'Edit',

  issueDetailsText: (claim: Claim | null): string => {
    if (claim) {
      switch (claim.type) {
        case ClaimTypeEnum.DAMAGED:
          return 'Additional Details';
        case ClaimTypeEnum.LOST:
          return 'Lost Details';
        case ClaimTypeEnum.STOLEN:
          return 'Stolen Details';
      }
    }
    return '';
  },

  resolutionPreference: 'Resolution Preference',

  resolutionType: (claim: Claim | null): string => {
    if (claim && claim.customerPreferredResolution) {
      switch (claim.customerPreferredResolution) {
        case ResolutionEnum.REFUND:
          return 'Refund original payment method';
        case ResolutionEnum.REPLACE:
        case ResolutionEnum.REORDER:
          return 'Replace item';
        case ResolutionEnum.REFUND_PAYPAL:
          return 'Refund via PayPal';
        case ResolutionEnum.REFUND_VENMO:
          return 'Refund via Venmo';
        case ResolutionEnum.PREPAID_CARD_MASTERCARD:
          return `Refund to Mastercard®`;
        case ResolutionEnum.GIFT_CARD_MERCHANT:
          return `Refund to Gift Card`;
      }
    }
    return '';
  },

  prepaidRecipientInfo: "Prepaid Card Recipient Info",
  giftCardInfo: "Gift Card Delivery Email",

  venmoInfo: "Venmo Account",

  detailsPlaceholder: '(Tell us what happened)',

  agreeStatement: {
    statement: 'I certify that any information I submit is truthful and accurate.',
    warningLabel: 'WARNING: ',
    warningMessage: 'I understand providing false or misleading information in an attempt to obtain compensation to which I am not entitled constitutes ',
    warningMessageEmphasis: 'insurance fraud.',
  },

  submitClaim: 'Submit claim',
  cancel: 'Cancel',
};
