import styled from 'styled-components';
import { mobileBreakpoint } from '../../constants/styles';

export const Wrapper = styled.div`
  @media (min-width: ${mobileBreakpoint}) {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    flex: 1;
    min-height: 0;
    width: 100vw;
  }
`;
