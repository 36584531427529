export const TextFieldContent = {
  trackingURL: {
    placeholder: 'Tracking URL',
    errorText: 'Please enter a valid tracking URL',
  },
};

export const ButtonContent = {
  continue: 'Continue',
  cancel: 'Cancel',
};
