/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import { ButtonContainer, TextButtonContainer } from './PackageNeverArrived.styles';
import { useStores } from '../../../../hooks/app.hook';
import ForegroundContentModal from '../../../../components/ForegroundContentModal';
import { logEvent, analyticsCodes } from '../../../../services/analytics';
import ForegroundContentDetails from '../../../../components/ForegroundContentDetails';
import { colors } from '../../../../constants/styles';
import Button from '../../../../components/Buttons/Button';
import HangTightPackage from '../../../../assets/icons/hang-tight-package.svg';
import { Icon } from '../../../../styles/global.styles';
import { ButtonContent } from './PackageNeverArrived.constants';
import { ClaimBlockingAction, ClaimBlockingScreens } from '../../../../stores/_constants/ClaimBlockingFlow.constants';
import { broadCastAction, broadcastView } from '../../../../services/broadcast';
import { VIEWS } from '../../../../constants/views';
import { ACTIONS } from '../../../../constants/actions';
import CleanButton from '../../../../components/Buttons/CleanButton';
import { isMobileView } from '../../../../utils/Breakpoints';
import { queryAndConvertIfPossibleTimezone } from '../../../../utils';
import { replaceClaimPolicyLink } from '../utils';

const PackageNeverArrived: FC = observer(() => {
  const {
    claimStore,
    routingStore,
    commonStore,
    orderStore,
    claimBlockingStore,
  } = useStores();

  const screen = ClaimBlockingScreens.PACKAGE_NEVER_ARRIVED;

  const item = claimStore.displayItem;

  if (!item) {
    commonStore.displayError();
    return null;
  }

  const okayClickHandle = () => {
    claimStore.reset();
    claimBlockingStore.reset();
    broadCastAction(ACTIONS.CANCEL_CLAIM_FILING);
    routingStore.replace('/order-information');
  };

  const backButtonHandle = () => {
    broadCastAction(ACTIONS.NAVIGATE_BACK_CLICK);
    routingStore.goBack();
  };

  useEffect(() => {
    broadcastView(VIEWS.CLAIM_ITEM_BLOCKING_PACKAGE_NEVER_ARRIVED);
    logEvent(analyticsCodes.screenView, {
      screen: claimBlockingStore.getAnalytics(screen),
      merchantId: orderStore.getOrderDetails()?.merchantID,
      orderId: orderStore.getOrderDetails()?.id,
    });
  }, []);

  const stillNeedHelpClickHandle = () => {
    routingStore.push(
      claimBlockingStore.getButtonRoute(
        screen,
        ClaimBlockingAction.STILL_NEED_HELP
      )
    );
  };

  const getDescription = () => {
    return replaceClaimPolicyLink(queryAndConvertIfPossibleTimezone(item.item.wismoDescription));
  }

  return (
    <ForegroundContentModal open>
      <ForegroundContentDetails
        icon={<Icon src={HangTightPackage} alt="hang tight package icon" />}
        title={item.item.wismoHeader}
        description={getDescription()}
        backButton={backButtonHandle}
        centered={!isMobileView()}
      >
        <ButtonContainer>
          <Button
            onClick={okayClickHandle}
            fullWidth
            overrideBackground={colors.routeButtonFill6}
            mode="light"
          >
            {ButtonContent.okay}
          </Button>
        </ButtonContainer>
        <TextButtonContainer>
          <CleanButton
            onClick={stillNeedHelpClickHandle}
            overrideFontSize={12}
            overrideLineHeight={16}
            overrideTextColor={colors.routeText2}
            noTextDecoration
          >
            {ButtonContent.stillNeedHelp}
          </CleanButton>
        </TextButtonContainer>
      </ForegroundContentDetails>
    </ForegroundContentModal>
  );
});

export default PackageNeverArrived;
