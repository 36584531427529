import styled from 'styled-components';
import { colors } from '../../../constants/styles';

export const Container = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 16px;
  gap: 16px;

  cursor: pointer;
  border-radius: 6px;
  background: ${colors.routeBackground2};
`;
