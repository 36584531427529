import styled, { keyframes } from 'styled-components';
import { TrackingProgressBarProps } from './interface';

export const Container = styled.div`
  margin: 10px 0 15px 0;
`;

export const DeliverStatusContainer = styled.div`
  margin-top: 10px;
`;

export const StatusContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const TrackingBarBackground = styled.div`
    background-color: #EEE
    border-radius: 8px
    height: 6px
    width: 100%
`;

export const TrackingBarContainer = styled.div<TrackingProgressBarProps>`
    height: 6px
    max-width: 100%
    ${props => `width: ${props.width}`}
`;

const expandTrackingBar = keyframes`
    0% {
        width: 0%
    }
    100% {
        width: 100%
    }
`;

export const TrackingBar = styled.div`
  background: linear-gradient(90deg, #2E76CA 0%, #4FE7C8 100%);
  border-radius: 6.50667px;
  height: 6px
  width: 0%
  animation: ${expandTrackingBar} 0.5s cubic-bezier(0, 0, 0.58, 1) 0.5s;
  animation-fill-mode: forwards
`;
