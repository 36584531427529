import {
  ClaimBlockingAction,
  ClaimBlockingScreens,
  ClaimFlowType,
  ScreenRoutes,
} from './ClaimBlockingFlow.constants';

// This map has the routing for all claim blocking flow screens
// Key: "TYPE|SCREEN|ACTION"
// Value: route
export const screensFlowMap = {
  // --- Label Created Flow --- //

  // How can we help
  [`${ClaimFlowType.LABEL_CREATED_FLOW}|${ClaimBlockingScreens.HOW_CAN_WE_HELP}|${ClaimBlockingAction.NEVER_ARRIVED_PACKAGE}`]: ScreenRoutes.CONFIRM_TRACKING_NUMBER,

  // Confirm tracking number
  [`${ClaimFlowType.LABEL_CREATED_FLOW}|${ClaimBlockingScreens.CONFIRM_TRACKING_NUMBER}|${ClaimBlockingAction.YES}`]: ScreenRoutes.CONFIRM_CARRIER_UPDATE,
  [`${ClaimFlowType.LABEL_CREATED_FLOW}|${ClaimBlockingScreens.CONFIRM_TRACKING_NUMBER}|${ClaimBlockingAction.NO}`]: ScreenRoutes.ENTER_TRACKING_NUMBER,

  // Confirm carrier update
  [`${ClaimFlowType.LABEL_CREATED_FLOW}|${ClaimBlockingScreens.CONFIRM_CARRIER_UPDATE}|${ClaimBlockingAction.YES}`]: ScreenRoutes.HANG_TIGHT,
  [`${ClaimFlowType.LABEL_CREATED_FLOW}|${ClaimBlockingScreens.CONFIRM_CARRIER_UPDATE}|${ClaimBlockingAction.NO}`]: ScreenRoutes.ENTER_TRACKING_URL,

  // Enter tracking url
  [`${ClaimFlowType.LABEL_CREATED_FLOW}|${ClaimBlockingScreens.ENTER_TRACKING_URL}|${ClaimBlockingAction.CONTINUE}`]: ScreenRoutes.LOADING_CLAIM_FLOW,

  // Enter tracking number
  [`${ClaimFlowType.LABEL_CREATED_FLOW}|${ClaimBlockingScreens.ENTER_TRACKING_NUMBER}|${ClaimBlockingAction.CONTINUE}`]: ScreenRoutes.LOADING_CLAIM_FLOW,
  [`${ClaimFlowType.LABEL_CREATED_FLOW}|${ClaimBlockingScreens.ENTER_TRACKING_NUMBER}|${ClaimBlockingAction.DONT_HAVE_TRACKING_NUMBER}`]: ScreenRoutes.HANG_TIGHT,

  // Hang tight
  [`${ClaimFlowType.LABEL_CREATED_FLOW}|${ClaimBlockingScreens.HANG_TIGHT}|${ClaimBlockingAction.OKAY}`]: ScreenRoutes.RESOLVE_CENTER,
  // --- --- //

  // --- No Tracking Flow --- //

  // How can we help
  [`${ClaimFlowType.NO_TRACKING_NUMBER_FLOW}|${ClaimBlockingScreens.HOW_CAN_WE_HELP}|${ClaimBlockingAction.NEVER_ARRIVED_PACKAGE}`]: ScreenRoutes.ENTER_TRACKING_NUMBER,

  // Enter tracking number
  [`${ClaimFlowType.NO_TRACKING_NUMBER_FLOW}|${ClaimBlockingScreens.ENTER_TRACKING_NUMBER}|${ClaimBlockingAction.CONTINUE}`]: ScreenRoutes.LOADING_CLAIM_FLOW,
  [`${ClaimFlowType.NO_TRACKING_NUMBER_FLOW}|${ClaimBlockingScreens.ENTER_TRACKING_NUMBER}|${ClaimBlockingAction.DONT_HAVE_TRACKING_NUMBER}`]: ScreenRoutes.HANG_TIGHT,

  // Hang tight
  [`${ClaimFlowType.NO_TRACKING_NUMBER_FLOW}|${ClaimBlockingScreens.HANG_TIGHT}|${ClaimBlockingAction.OKAY}`]: ScreenRoutes.RESOLVE_CENTER,
  // --- --- //

  // --- Too Early Flow --- //

  // How can we help
  [`${ClaimFlowType.TOO_EARLY_FLOW}|${ClaimBlockingScreens.HOW_CAN_WE_HELP}|${ClaimBlockingAction.NEVER_ARRIVED_PACKAGE}`]: ScreenRoutes.PACKAGE_NEVER_ARRIVED,

  // Package never arrived
  [`${ClaimFlowType.TOO_EARLY_FLOW}|${ClaimBlockingScreens.PACKAGE_NEVER_ARRIVED}|${ClaimBlockingAction.OKAY}`]: ScreenRoutes.RESOLVE_CENTER,
  [`${ClaimFlowType.TOO_EARLY_FLOW}|${ClaimBlockingScreens.PACKAGE_NEVER_ARRIVED}|${ClaimBlockingAction.STILL_NEED_HELP}`]: ScreenRoutes.CONFIRM_TRACKING_NUMBER,

  // Confirm tracking number
  [`${ClaimFlowType.TOO_EARLY_FLOW}|${ClaimBlockingScreens.CONFIRM_TRACKING_NUMBER}|${ClaimBlockingAction.YES}`]: ScreenRoutes.CONFIRM_CARRIER_UPDATE,
  [`${ClaimFlowType.TOO_EARLY_FLOW}|${ClaimBlockingScreens.CONFIRM_TRACKING_NUMBER}|${ClaimBlockingAction.NO}`]: ScreenRoutes.ENTER_TRACKING_NUMBER,

  // Confirm carrier update
  [`${ClaimFlowType.TOO_EARLY_FLOW}|${ClaimBlockingScreens.CONFIRM_CARRIER_UPDATE}|${ClaimBlockingAction.YES}`]: ScreenRoutes.HANG_TIGHT,
  [`${ClaimFlowType.TOO_EARLY_FLOW}|${ClaimBlockingScreens.CONFIRM_CARRIER_UPDATE}|${ClaimBlockingAction.NO}`]: ScreenRoutes.ENTER_TRACKING_URL,

  // Enter tracking url
  [`${ClaimFlowType.TOO_EARLY_FLOW}|${ClaimBlockingScreens.ENTER_TRACKING_URL}|${ClaimBlockingAction.CONTINUE}`]: ScreenRoutes.LOADING_CLAIM_FLOW,

  // Enter tracking number
  [`${ClaimFlowType.TOO_EARLY_FLOW}|${ClaimBlockingScreens.ENTER_TRACKING_NUMBER}|${ClaimBlockingAction.CONTINUE}`]: ScreenRoutes.LOADING_CLAIM_FLOW,
  [`${ClaimFlowType.TOO_EARLY_FLOW}|${ClaimBlockingScreens.ENTER_TRACKING_NUMBER}|${ClaimBlockingAction.DONT_HAVE_TRACKING_NUMBER}`]: ScreenRoutes.HANG_TIGHT,

  // Hang tight
  [`${ClaimFlowType.TOO_EARLY_FLOW}|${ClaimBlockingScreens.HANG_TIGHT}|${ClaimBlockingAction.OKAY}`]: ScreenRoutes.RESOLVE_CENTER,
};
