import React, { FC, useEffect } from 'react';
import { ClaimApprovalAnimation } from './ClaimApprovalAnimation';
import { ClaimSubmissionDisplay } from '../ClaimSubmissionDisplay';
import { VIEWS } from '../../../../constants/views';
import { broadcastView } from '../../../../services/broadcast';

export const ClaimApprovalDisplay: FC = () => {
  useEffect(() => {
    broadcastView(VIEWS.CLAIM_ITEM_APPROVAL_ANIMATION)
  }, [])
  return (
    <ClaimSubmissionDisplay
      animation={<ClaimApprovalAnimation />}
      animationText={'Claim approved!'}
    ></ClaimSubmissionDisplay>
  );
};
