import React, { FC } from 'react';
import { IActionSelectionRowProps } from './interface';
import { ClickableContainer, Value } from './ActionSelectionRow.styles';
import { Icon } from '../../../styles/global.styles';
import Typography from '../../UI/Typography';
import { colors } from '../../../constants/styles';
import Button from '../Button';

const ActionSelectionRow: FC<IActionSelectionRowProps> = (
  props: IActionSelectionRowProps
) => {
  const { value, icon, iconAlt } = props;

  return (
    <ClickableContainer onClick={() => props.onClick()} data-testid="clickable">
      <Button
        mode="light"
        icon={<Icon src={icon} alt={iconAlt} verticalAlign="middle" />}
        animation={props.animation}
      />
      <Value animation={props.animation}>
        <Typography variant="h7" color={colors.routeText1}>
          {value}
        </Typography>
      </Value>
    </ClickableContainer>
  );
};

export default ActionSelectionRow;
