import { DeliveryStatusEnum } from '../../models/Enums/DeliveryStatusEnum.model';

export const TrackingBarWidth = (
  status: string | undefined,
  eta: string | undefined
): string => {
  switch (status) {
    case DeliveryStatusEnum.NOT_AVAILABLE:
      return '5%';
    case DeliveryStatusEnum.ATTEMPT_FAIL:
      return '95%';
    case DeliveryStatusEnum.ACTIVE:
      return '0%';
    case DeliveryStatusEnum.NOT_ACTIVE:
      return '0%';
    case DeliveryStatusEnum.DELIVERED:
      return '100%';
    case DeliveryStatusEnum.REGISTERED:
      return '0%';
    case DeliveryStatusEnum.PENDING:
      return '10%';
    case DeliveryStatusEnum.INFO_RECEIVED:
      return '10%';
    case DeliveryStatusEnum.IN_TRANSIT:
      if (!eta) return '50%';
      const daysFromETA = Math.ceil(
        //@ts-ignore
        (new Date(eta) - new Date()) / (1000 * 60 * 60 * 24)
      );
      return `${Math.max(50, 80 - 5 * daysFromETA)}%`;
    case DeliveryStatusEnum.OUT_FOR_DELIVERY:
      return '90%';
    case DeliveryStatusEnum.UNKNOWN_CARRIER:
      return '10%';
    case DeliveryStatusEnum.EXPIRED:
      return '10%';
    case DeliveryStatusEnum.AVAILABLE_FOR_PICKUP:
      return '95%';
    case DeliveryStatusEnum.EXCEPTION:
      return '20%';
    case DeliveryStatusEnum.RETURN_TO_SENDER:
      return '90%';
    default:
      return '10%';
  }
};

export const StatusLabel = (status: DeliveryStatusEnum): string => {
  switch (status) {
    case DeliveryStatusEnum.NOT_AVAILABLE:
      return 'Waiting for Tracking';
    case DeliveryStatusEnum.ATTEMPT_FAIL:
      return 'Delivery Attempted';
    case DeliveryStatusEnum.ACTIVE:
      return 'Getting Info...';
    case DeliveryStatusEnum.NOT_ACTIVE:
      return 'Finding Package...';
    case DeliveryStatusEnum.DELIVERED:
      return 'Delivered';
    case DeliveryStatusEnum.REGISTERED:
      return 'Getting Info...';
    case DeliveryStatusEnum.PENDING:
      return 'Ready to Ship';
    case DeliveryStatusEnum.INFO_RECEIVED:
      return 'Ready to Ship';
    case DeliveryStatusEnum.IN_TRANSIT:
      return 'In Transit';
    case DeliveryStatusEnum.OUT_FOR_DELIVERY:
      return 'Out for Delivery';
    case DeliveryStatusEnum.UNKNOWN_CARRIER:
      return 'Carrier Not Supported';
    case DeliveryStatusEnum.EXPIRED:
      return 'Tracking Expired';
    case DeliveryStatusEnum.AVAILABLE_FOR_PICKUP:
      return 'Available for Pickup';
    case DeliveryStatusEnum.EXCEPTION:
      return 'Carrier Problem';
    case DeliveryStatusEnum.RETURN_TO_SENDER:
      return 'Return to Sender';
    default:
      return 'Waiting for Tracking';
  }
};
