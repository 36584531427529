import { observer } from 'mobx-react';
import React from 'react';
import { FC } from 'react';
import { Icon, VerticalSeparator } from '../../styles/global.styles';
import PayPalLogo from '../../assets/icons/paypal-logo.svg';
import { IPayPalRefundProps } from './interface';
import {
  Container,
  IconContainer,
  TextContainer,
  InputContainer,
  MainButtonContainer,
  SecondaryButtonContainer,
  Content,
} from './PayPalRefund.styles';
import Typography from '../UI/Typography';
import { colors } from '../../constants/styles';
import TextField from '../Inputs/TextField';
import Button from '../Buttons/Button';
import CleanButton from '../Buttons/CleanButton';
import { useStores } from '../../hooks/app.hook';
import isEmail from 'validator/lib/isEmail';
import { AnalyticsContent, TypographyContents } from './PayPalRefund.constants';
import { analyticsButtons, logEventNew } from '../../services/analytics';

const PayPalRefund: FC<IPayPalRefundProps> = observer(
  (props: IPayPalRefundProps) => {
    const rootStore = useStores()
    const { claimStore, remedyStore, routingStore } = rootStore;
    const { showEmail, email, onClick } = props;

    const isNextDisabled = (): boolean => {
      return showEmail && (!isEmail(email) || claimStore.loading);
    };

    const noPayPalHandle = (): void => {
      logEventNew(AnalyticsContent.enterMailButtonTapped, rootStore, {
        button_function: analyticsButtons.noPaypal,
      });
      routingStore.push('/item/resolution/refund-survey');
    };

    return (
      <Container>
        <Content>
          <IconContainer>
            <Icon src={PayPalLogo} verticalAlign="middle" />
          </IconContainer>

          <VerticalSeparator distance={16} />

          <TextContainer>
            <Typography
              variant={'h3'}
              color={colors.routeText1}
              align={'center'}
            >
              {TypographyContents.title}
            </Typography>
          </TextContainer>

          <VerticalSeparator distance={16} />

          <TextContainer>
            <Typography
              variant={'p2'}
              color={colors.routeText1}
              align={'center'}
            >
              {TypographyContents.subtitle}
            </Typography>
          </TextContainer>

          {showEmail && (
            <React.Fragment>
              <VerticalSeparator distance={36} />
              <InputContainer>
                <TextField
                  placeholder={TypographyContents.textInputPlaceHolder}
                  title={TypographyContents.textInputTitle}
                  textType="email"
                  value={email}
                  onChange={(value: string) => {
                    remedyStore.setPayPalEmail(value);
                  }}
                />
              </InputContainer>
            </React.Fragment>
          )}

          <VerticalSeparator distance={50} />

          <MainButtonContainer>
            <Button
              overrideBackground={colors.black}
              onClick={onClick}
              disabled={isNextDisabled()}
              fullWidth
            >
              {TypographyContents.nextButton}
            </Button>
          </MainButtonContainer>

          <SecondaryButtonContainer>
            <CleanButton
              overrideFontSize={14}
              overrideTextColor={colors.routeText2}
              onClick={noPayPalHandle}
            >
              {TypographyContents.noPayPal}
            </CleanButton>
          </SecondaryButtonContainer>
        </Content>
      </Container>
    );
  }
);

export default PayPalRefund;
