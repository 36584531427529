import { action, makeObservable, observable } from 'mobx';
import { RootStore } from './RootStore';
import { PERSONA_VERIFICATION_STATUSES } from '../models/Persona.model';
import { ClaimTypeEnum } from '../models/Enums/ClaimEnum.model';

export class FraudStore {
    private rootStore: RootStore;
    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeObservable(this);
    }

    @observable personaInquiryId = "";

    @observable iframeLoading = true;

    @observable error = 0;

    @observable verificationStatus = PERSONA_VERIFICATION_STATUSES.NOT_AVAILABLE;

    @observable claimTypeStarted?: ClaimTypeEnum;

    @action continueClaimAfterVerification(inquiryId: string, verificationStatus: typeof PERSONA_VERIFICATION_STATUSES[keyof typeof PERSONA_VERIFICATION_STATUSES]) {
        this.verificationStatus = verificationStatus;
        this.personaInquiryId = inquiryId;

        if (this.claimTypeStarted) {
            this.rootStore.claimStore.initializeClaim(this.claimTypeStarted);
        }
    }

    @action setInquiryId(inquiryId: string) {
        this.personaInquiryId = inquiryId;
    }

    @action setVerificationStatus(status: typeof PERSONA_VERIFICATION_STATUSES[keyof typeof PERSONA_VERIFICATION_STATUSES]) {
        this.verificationStatus = status;
    }

    @action setError(error: number) {
        this.error = error;
    }

    @action setIframeLoading(loading: boolean) {
        this.iframeLoading = loading;
    }

    @action setClaimStartedProps(type: ClaimTypeEnum) {
        this.claimTypeStarted = type;
    }

    @action reset() {
        this.error = 0;
        this.verificationStatus = PERSONA_VERIFICATION_STATUSES.NOT_AVAILABLE;
        this.personaInquiryId = "";
        this.iframeLoading = true;
        this.claimTypeStarted = undefined;
    }
}
