export const TypographyContent = {
  whereIsMyPackage: "My item never arrived",
  issueUnrelated: 'Other',
  contactMerchant: 'Contact support',
  damaged: 'My item was damaged',
  return: 'I want to return this item',

  cancel: 'Cancel',
};

export enum ActionTypeSelectionEnum {
  WISMO,
  DAMAGE,
  RETURN,
  ISSUE_UNRELATED,
}
