import { ReactNode, Ref } from 'react';
import styled from 'styled-components';

export type ClaimedItemAnimationProps = {
  anchorElement: Ref<HTMLDivElement> | null;
  anchoredElement: Ref<HTMLDivElement> | null;
  children: ReactNode;
};

export const Simple = styled.div`
  width: 100%;
`;
