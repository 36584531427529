import React, { FC, useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import {
  ButtonContainer,
  Column,
  Container,
} from './ClaimedItemsActionSelection.style';
import ClaimSelectableRow from '../../../components/ClaimSelectableRow';
import { useStores } from '../../../hooks/app.hook';
import ActionSelectionRow from '../../../components/Buttons/ActionSelectionRow';
import {
  ActionTypeSelectionEnum,
  TypographyContent,
} from './ClaimedItemsActionSelection.constants';
import LostIcon from '../../../assets/icons/magnifier-icon.svg';
import UnrelatedIcon from '../../../assets/icons/unrelated-icon.svg';
import Button from '../../../components/Buttons/Button';
import { ClaimedItemAnimation } from '../ClaimedItemAnimation/ClaimedItemAnimation';
import { AnimationType } from '../../../animations/animationTypes';
import { ClaimTypeEnum } from '../../../models/Enums/ClaimEnum.model';
import DamagedIcon from '../../../assets/icons/damaged-icon.svg';
import ReturnIcon from '../../../assets/icons/return-icon.svg';
import { VIEWS } from '../../../constants/views';
import { broadCastAction, broadcastView } from '../../../services/broadcast';
import { ACTIONS } from '../../../constants/actions';

const ClaimedItemsActionSelection: FC = observer(() => {
  const { claimStore, routingStore, animationStore, orderStore } = useStores();
  const { claimedItems, displayItem } = claimStore;
  const { routeOrder } = orderStore;
  const { anchorElement } = animationStore;

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    broadcastView(VIEWS.CLAIM_ITEM_ACTION_SELECTION);
  }, []);

  const claimEligible = claimStore.hasAtLeastOneShouldDisplayClaim();

  const actionClickHandle = (actionType: ActionTypeSelectionEnum) => {
    claimStore.resetClaim();
    switch (actionType) {
      case ActionTypeSelectionEnum.WISMO:
        routingStore.push('/item/tracking');
        break;
      case ActionTypeSelectionEnum.DAMAGE:
        claimStore.initializeClaim(ClaimTypeEnum.DAMAGED);
        break;
      case ActionTypeSelectionEnum.RETURN:
        if (routeOrder?.merchant.returnsUrl)
          window.open(routeOrder?.merchant.returnsUrl, '_blank');
        break;
      default:
        broadCastAction(ACTIONS.CONTACT_SUPPORT);
        routingStore.replace('/order-help');
        break;
    }
  };

  const closeClick = () => {
    broadCastAction(ACTIONS.CANCEL_CLAIM_FILING);
    claimStore.reset();
    routingStore.replace('/order-information');
  };

  let index = 0;

  return (
    <ClaimedItemAnimation anchorElement={anchorElement} anchoredElement={ref}>
      <Container>
        <ClaimSelectableRow
          ref={ref}
          targetItems={
            claimedItems.length > 1 || !displayItem?.item
              ? claimedItems.map(claim => {
                return claim.item;
              })
              : displayItem?.item
          }
        />
        <Column>
          <ActionSelectionRow
            icon={LostIcon}
            animation={{ type: AnimationType.itemSelect, index: index++ }}
            value={TypographyContent.whereIsMyPackage}
            iconAlt={`wismo icon`}
            onClick={() => actionClickHandle(ActionTypeSelectionEnum.WISMO)}
          />
          {claimEligible && (
            <ActionSelectionRow
              icon={DamagedIcon}
              value={TypographyContent.damaged}
              animation={{ type: AnimationType.itemSelect, index: index++ }}
              iconAlt={`damaged glass icon`}
              onClick={() => actionClickHandle(ActionTypeSelectionEnum.DAMAGE)}
            />
          )}
          {routeOrder?.merchant.returnsUrl && routeOrder?.merchant.returnsUrl !== '' && (
            <ActionSelectionRow
              icon={ReturnIcon}
              value={TypographyContent.return}
              animation={{ type: AnimationType.itemSelect, index: index++ }}
              iconAlt={`return arrow icon`}
              onClick={() => actionClickHandle(ActionTypeSelectionEnum.RETURN)}
            />
          )}
          <ActionSelectionRow
            icon={UnrelatedIcon}
            animation={{ type: AnimationType.itemSelect, index: index++ }}
            iconAlt={`four squares icon`}
            value={
              claimEligible
                ? TypographyContent.issueUnrelated
                : TypographyContent.contactMerchant
            }
            onClick={() =>
              actionClickHandle(ActionTypeSelectionEnum.ISSUE_UNRELATED)
            }
          />
        </Column>
        <ButtonContainer>
          <Button fullWidth onClick={closeClick}>
            {TypographyContent.cancel}
          </Button>
        </ButtonContainer>
      </Container>
    </ClaimedItemAnimation>
  );
});

export default ClaimedItemsActionSelection;
