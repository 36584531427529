import React from 'react';
import {
  Logo
} from './FooterLogo.styles';
import RouteLogoBlack from '../../../../shared/assets/images/route-logo-black.svg';

const FooterLogo: React.FC = () => {
  return (
    <>
      <Logo src={RouteLogoBlack} alt="Route logo" />
    </>
  );
};

export default FooterLogo;
