export enum AnimationType {
  none,
  itemSelect,
}

export interface IAnimation {
  type: AnimationType;
  index: number;
}

export enum ButtonAnimation {
  none = 'none',
  grow = 'grow',
}
