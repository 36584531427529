import styled, { css } from 'styled-components';
import { colors } from '../../../constants/styles';
import copyIcon from '../../../assets/icons/copyIcon.svg';
import { ICleanButtonProps } from './interface';

interface Map {
  [key: string]: string;
}

const Button = styled.button`
  width: ${(props: ICleanButtonProps) => (props.icon ? '32px' : 'unset')};
  max-height: 32px;
  background: unset;
  font-size: ${(props: ICleanButtonProps) =>
    props.overrideFontSize ? `${props.overrideFontSize}px` : '17px'};
  border: none;

  line-height: ${(props: ICleanButtonProps) =>
    props.overrideLineHeight ? `${props.overrideLineHeight}px` : '24px'};
  font-weight: ${(props: ICleanButtonProps) =>
    props.overrideFontWeight ? `${props.overrideFontWeight}` : '600'};
  color: ${(props: ICleanButtonProps) =>
    props.overrideTextColor ? props.overrideTextColor : colors.routeText1};
  padding: 0;
  border-radius: 25px;

  ${(props: ICleanButtonProps) => {
    if (!props.noTextDecoration) {
      return css`
          text-decoration-line: underline;
          text-decoration-skip-ink: none;
          text-decoration-thickness: from-font;
          text-underline-offset: 3px;
      `;
    }
  }}

  &:not(:disabled) {
    cursor: pointer;
  }

  &:disabled {
    background-color: ${colors.gray};
  }
`;

export const IconMap: Map = {
  copy: copyIcon,
};

export default Button;
