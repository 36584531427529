import styled from 'styled-components';

export interface ILogoStyled {
  big?: boolean;
}

const Logo = styled.img`
  /* @ts-ignore */
  width: ${(props: ILogoStyled) => props.big ? '80px' : '60px'};
  height: ${(props: ILogoStyled) => props.big ? '80px' : '60px'};
  /* margin-bottom: 8px; */
  object-fit: cover;
  box-sizing: border-box;
  border-radius: 40px;
`;

export default Logo;
