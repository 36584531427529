import { FC } from 'react';
import { IClickableContainerRowProps } from './interface';
import {
  ClickableContainer,
  IconContainer,
} from './ClickableContainerRow.styles';
import Typography from '../../UI/Typography';
import { colors } from '../../../constants/styles';

const ClickableContainerRow: FC<IClickableContainerRowProps> = (
  props: IClickableContainerRowProps
) => {
  return (
    <ClickableContainer
      onClick={props.onClick}
      backgroundColor={props.backgroundColor}
      padding={props.padding}
    >
      {props.icon && (
        <IconContainer minWidth={props.minWidth} minHeight={props.minHeight}>
          {props.icon}
        </IconContainer>
      )}
      <Typography
        semibold
        variant={props.fontVariant ? props.fontVariant : 'h7'}
        color={colors.routeText1}
      >
        {props.text}
      </Typography>
    </ClickableContainer>
  );
};

export default ClickableContainerRow;
