export const ButtonContent = {
  cancel: 'Cancel',
  continue: 'Continue',
};

export const TypographyContent = {
  title: 'Get your refund via Venmo',
  description: 'Enter the email, username or phone number associated with your Venmo account.',
  dontHaveVenmo: "Don't have Venmo?",
}

export const TextFieldContent = {
  input: {
    placeholder: 'Venmo email, username or phone #',
    errorText: 'Please provide a valid input option.',
  },
  confirmationInput: {
    placeholder: 'Confirm Venmo email, username or phone #',
    errorText: "Must match the previous entry",
  },
};

export const AnalyticsContent = {
  enterMailButtonTapped: 'Resolve-VenmoRefund-EnterEmail-Button-Tapped',
}

export const VenmoLink = 'https://venmo.com/';