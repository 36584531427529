import styled from 'styled-components';
import { belowSize } from '../../utils/Breakpoints';

export interface IExtendedProps {
  extended?: boolean;
}

export const ContainerFlex = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  align-items: stretch;
  overflow: auto;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  justify-content: stretch;
  flex-grow: 1;
  max-width: 750px;
  width: 45%;
  padding: 8% 28% 5% 28%;
  ${belowSize.lg`
     padding: 8% 20% 5% 20%;
  `}
  ${belowSize.sm`
     padding: 10% 4%;
  `}
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  margin-top: 16px;
`;
export const ButtonContainer = styled.div`
  display: flex;
`;
