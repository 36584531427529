import { RouterStore } from 'mobx-react-router';
import { AnimationStore } from './AnimationStore';
import { ClaimStore } from './Claim/ClaimStore';
import { DamagedStore } from './Claim/DamagedStore';
import { StolenStore } from './Claim/StolenStore';
import { CommonStore } from './CommonStore';
import { FindOrderStore } from './Order/FindOrderStore';
import { ItemStore } from './Order/ItemStore';
import { OrderStore } from './Order/OrderStore';
import { IssueStore } from './Issue/IssueStore';
import { PhoneVerificationStore } from './Claim/PhoneVerificationStore';
import { SplitStore } from './SplitStore';
import { EmailSurveyStore } from './Survey/EmailSurveyStore';
import { FeatureStore } from './FeatureStore';
import { ReviewStore } from './Claim/ReviewStore';
import { RemedyStore } from './Claim/RemedyStore';
import { ClaimBlockingStore } from './Claim/ClaimBlockingStore';
import { FraudStore } from './FraudStore';

export class RootStore {
  routingStore: RouterStore;
  commonStore: CommonStore;
  findOrderStore: FindOrderStore;
  orderStore: OrderStore;
  claimStore: ClaimStore;
  animationStore: AnimationStore;
  itemStore: ItemStore;
  damagedStore: DamagedStore;
  stolenStore: StolenStore;
  issueStore: IssueStore;
  phoneVerificationStore: PhoneVerificationStore;
  splitStore: SplitStore;
  emailSurveyStore: EmailSurveyStore;
  featureStore: FeatureStore;
  reviewStore: ReviewStore;
  remedyStore: RemedyStore;
  claimBlockingStore: ClaimBlockingStore;
  fraudStore: FraudStore;

  constructor() {
    this.routingStore = new RouterStore();
    this.commonStore = new CommonStore(this);
    this.findOrderStore = new FindOrderStore(this);
    this.orderStore = new OrderStore(this);
    this.claimStore = new ClaimStore(this);
    this.animationStore = new AnimationStore(this);
    this.itemStore = new ItemStore(this);
    this.damagedStore = new DamagedStore(this);
    this.stolenStore = new StolenStore(this);
    this.issueStore = new IssueStore(this);
    this.phoneVerificationStore = new PhoneVerificationStore(this);
    this.splitStore = new SplitStore(this);
    this.emailSurveyStore = new EmailSurveyStore(this);
    this.featureStore = new FeatureStore(this);
    this.reviewStore = new ReviewStore(this);
    this.remedyStore = new RemedyStore(this);
    this.claimBlockingStore = new ClaimBlockingStore(this);
    this.fraudStore = new FraudStore(this);
  }
}
