/* eslint-disable @typescript-eslint/no-explicit-any */

import { RootStore } from "../stores/RootStore";

// @ts-ignore
const mParticle = window.mParticle;

export const analyticsCodes = {
  claimStarted: 'Claim Started',
  editQuantity: 'Edit Quantity',
  resolveQuantity: 'Resolve - Edit Quantity',
  claimSubmitted: 'Claim Submitted',
  claimSummary: 'Claim Summary',
  resolveSummary: 'Resolve - Claim Summary',
  resolveAccessed: 'Resolve Center Accessed',
  resolveReview: 'Resolve Center Review Details',
  screenView: 'Screen View',
  tapped: 'Tapped',
  WISMO: 'Resolve Center WISMO',
  closeWISMO: 'Resolve Center WISMO Closed',
  reportWISMO: 'Resolve Center WISMO Report An Issue',
  phoneStarted: 'Phone Verification Started',
  phoneCompleted: 'Phone Verification Completed',
  phoneCall: 'Phone Verification Call',
  phoneSms: 'Phone Verification Text',
  affidavitStarted: 'Affidavit Modal Started',
  affidavitCompleted: 'Affidavit Modal Completed',
  photoUpload: 'Resolve Photo Upload',
  photoUploadSuccess: 'Photo Upload Success',
  policeReport: 'Resolve Police Report',
  provideDetails: 'Resolve Provide Details',
  paypalEmailCollection: 'PayPal Email Collection',
  shippingAddressInfoCollection: 'Shipping Address Info Collection',
  refundOptionSurvey: 'Refund Options Customer Survey',
  refundOptionsSurveySubmission: 'Refund Options Customer Survey Submission',
  refundOptionsSurveySubmitted: 'Refund Options Customer Survey Submitted',
  prepaidCardTypeCollection: 'Prepaid Card Type Collection',
  prepaidCardDetailsCollection: 'Prepaid Card Details Collection',
  prepaidCardDisclaimer: 'Prepaid Card Disclaimer',
  venmoDetailsCollection: 'Venmo Details Collection',

  merchantPortal: 'Merchant Portal',
  resolveCenter: 'Resolve Center',

  loadingClaimFlow: 'Loading Claim Flow',

  // claim blocking flow screens analytics codes are in src/shared/stores/_constants/ClaimBlockingFlowAnalytics.constants.ts
};

export const analyticsButtons = {
  continue: 'Continue',
  cancel: 'Cancel',
  goBack: 'Go to Previous Step',
  noPaypal: 'No PayPal',
  noVenmo: 'No Venmo',
};

export const logEvent = (
  eventName: string,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  attributes: any
): void => {
  if (
    process.env.REACT_APP_CUSTOM_NODE_ENV === 'production' ||
    process.env.REACT_APP_CUSTOM_NODE_ENV === 'stage'
  ) {
    mParticle.logEvent(eventName, mParticle.EventType.Other, attributes);
  }
};

export const logPageView = (
  eventName: string,
  url: string,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  attributes: any
): void => {
  if (
    process.env.REACT_APP_CUSTOM_NODE_ENV === 'production' ||
    process.env.REACT_APP_CUSTOM_NODE_ENV === 'stage'
  ) {
    mParticle.logPageView(eventName, { page: url, ...attributes });
  }
};

/* Note 13th Nov 2023: We're changing to this new pattern of log events
* This will be a slow transition because old events are still tagged.
*/
export const logEventNew = (
  eventName: string,
  rootStore: RootStore,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  attributes: any
): void => {
  if (
    process.env.REACT_APP_CUSTOM_NODE_ENV === 'production' ||
    process.env.REACT_APP_CUSTOM_NODE_ENV === 'stage'
  ) {
    const { orderStore, claimStore } = rootStore;
    const mainAttributes = {
      merchant_key: orderStore.getOrderDetails()?.merchantID,
      route_order_key: orderStore.getOrderDetails()?.id,
      claim_type: claimStore.claim?.type,
      claim_amount: claimStore.calculateClaimedAmount(),
      number_of_unique_items: claimStore.claimedItems.length,
      quantity: claimStore.calculateClaimedQuantity(),
    };
    mParticle.logEvent(eventName, mParticle.EventType.Other, { ...mainAttributes, ...attributes });
  }
};
