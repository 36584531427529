import styled from 'styled-components';
import { NoSlide } from '../../../../styles/global.styles';

export const Container = styled(NoSlide)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-self: stretch;
  overflow-x: hidden;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: [content] 1fr;
  grid-template-rows: [top] 1fr [bottom] 1fr;
  gap: 16px;
  flex-grow: 1;
  background: #f4f4f4;
`;

const TopRow = styled.div`
  grid-column: content;
  grid-row: top;
  max-width: 1fr;
`;

const BottomRow = styled.div`
  grid-column: content;
  grid-row: bottom;
  max-width: 1fr;
`;

export const ItemContent = styled(TopRow)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Store = styled.div`
  align-items: center;
  text-align: center;
`;

export const Content = styled(BottomRow)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const MainButtonContainer = styled.div`
  width: 100%;
`;

export const Separator = styled.div`
  border: 0.5px solid rgba(216, 216, 216, 0.7);
`;

export const ShippingInfoContainer = styled.div`
  display: flex;
`;

export const LeftShippingInfoContent = styled.div`
  align-items: flex-start;
  flex-direction: column;
  flex-grow: 1;
  padding-left: 24px;
`;

export const RightShippingInfoContent = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  min-width: 56px;
`;
