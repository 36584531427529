/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import { ButtonsContainer } from './DoYouHaveTrackingNumber.styles';
import { useStores } from '../../../../hooks/app.hook';
import ForegroundContentModal from '../../../../components/ForegroundContentModal';
import { logEvent, analyticsCodes } from '../../../../services/analytics';
import ForegroundContentDetails from '../../../../components/ForegroundContentDetails';
import { colors } from '../../../../constants/styles';
import { Icon, VerticalSeparator } from '../../../../styles/global.styles';
import { ButtonContent } from './DoYouHaveTrackingNumber.constants';
import { isMobileView } from '../../../../utils/Breakpoints';
import ClickableContainerRow from '../../../../components/Buttons/ClickableContainerRow';
import TrackingNumberIcon from '../../../../assets/icons/tracking-number.svg';
import {
  ClaimBlockingAction,
  ClaimBlockingScreens,
} from '../../../../stores/_constants/ClaimBlockingFlow.constants';
import { broadCastAction, broadcastView } from '../../../../services/broadcast';
import { VIEWS } from '../../../../constants/views';
import { ACTIONS } from '../../../../constants/actions';

const DoYouHaveTrackingNumber: FC = observer(() => {
  const {
    claimStore,
    routingStore,
    commonStore,
    orderStore,
    claimBlockingStore,
  } = useStores();

  const screen = ClaimBlockingScreens.DO_YOU_HAVE_TRACKING_NUMBER;

  const item = claimStore.displayItem;

  if (!item) {
    commonStore.displayError();
    return null;
  }

  const yesClickHandle = () => {
    routingStore.push(
      claimBlockingStore.getButtonRoute(screen, ClaimBlockingAction.YES)
    );
  };

  const noClickHandle = () => {
    routingStore.push(
      claimBlockingStore.getButtonRoute(screen, ClaimBlockingAction.NO)
    );
  };

  const backButtonHandle = () => {
    broadCastAction(ACTIONS.NAVIGATE_BACK_CLICK);
    routingStore.goBack();
  };

  const cancelClickHandle = () => {
    claimStore.reset();
    claimBlockingStore.reset();
    broadCastAction(ACTIONS.CANCEL_CLAIM_FILING);
    routingStore.replace('/order-information');
  };

  useEffect(() => {
    broadcastView(VIEWS.CLAIM_ITEM_BLOCKING_DO_YOU_HAVE_TRACKING_NUMBER);
    logEvent(analyticsCodes.screenView, {
      screen: claimBlockingStore.getAnalytics(screen),
      merchantId: orderStore.getOrderDetails()?.merchantID,
      orderId: orderStore.getOrderDetails()?.id,
    });
  }, []);

  const getTitle = () => {
    return claimBlockingStore.getTitle(screen);
  };

  const mobile = isMobileView();

  return (
    <ForegroundContentModal
      open
      secondaryButton={ButtonContent.cancel}
      secondaryButtonClick={cancelClickHandle}
    >
      <ForegroundContentDetails
        icon={
          <Icon src={TrackingNumberIcon} alt="tracking number package icon" />
        }
        title={getTitle()}
        backButton={backButtonHandle}
      >
        <ButtonsContainer>
          <ClickableContainerRow
            onClick={yesClickHandle}
            backgroundColor={colors.routeButtonFill3}
            minWidth=""
            minHeight=""
            text={ButtonContent.yes}
            fontVariant={!mobile ? 'h7' : 'p4'}
            padding="12px 16px 12px 24px"
          />

          <VerticalSeparator distance={16} />

          <ClickableContainerRow
            onClick={noClickHandle}
            backgroundColor={colors.routeButtonFill3}
            minWidth=""
            minHeight=""
            text={ButtonContent.no}
            fontVariant={!mobile ? 'h7' : 'p4'}
            padding="12px 16px 12px 24px"
          />
        </ButtonsContainer>
      </ForegroundContentDetails>
    </ForegroundContentModal>
  );
});

export default DoYouHaveTrackingNumber;
