import styled, { css } from 'styled-components';
import { colors } from '../../constants/styles';
import {
  Centralized,
  slideLeftKeys,
  slideUpKeys,
} from '../../styles/global.styles';
import { aboveSize, belowSize } from '../../utils/Breakpoints';

export interface IFullSlide {
  transition?: 'slide-up' | 'slide-left';
}

export interface IScrollContainerProps extends INoGlobalNavigation {
  fullHeight?: boolean;
}

export interface INoGlobalNavigation {
  noGlobalNav?: boolean;
}

export const Wrapper = styled.div`
  position: absolute;
  height: 100%
  overflow: hidden;

  ${aboveSize.md`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    flex: 1;
    min-height: 0;
    width: 100vw;
    overflow: hidden;
  `}

  ${belowSize.md`
    display: flex;
    flex: 1;
    overflow: auto;
    width: 100%
  `}
`;

export const CustomCentralized = styled(Centralized)`
  padding: 5% 15% 6% 15%;
  ${belowSize.md`
    padding: 0px;
  `}
  ${aboveSize.xg`
    padding: 5% 15% 6% 15%;
  `};
  ${aboveSize.md`
    overflow: hidden;
  `}
`;

const SLIDE_UP = css`
  animation: ${slideUpKeys} 0.5s forwards;
  -webkit-animation: ${slideUpKeys} 0.5s forwards;
`;

const SLIDE_LEFT = css`
  animation: ${slideLeftKeys} 0.5s forwards;
  -webkit-animation: ${slideLeftKeys} 0.5s forwards;
`;

const NO_SLIDE = css`
  animation: ${slideLeftKeys} 0s forwards;
  -webkit-animation: ${slideLeftKeys} 0s forwards;
`;

export const FullSlide = styled.div`
  flex-grow: 1;
  ${aboveSize.md`
    overflow: hidden;
  `}

  display: flex;
  transform-origin: 50% 100%;
  -webkit-transform-origin: 50% 100%;

  ${(props: IFullSlide) => {
    switch (props.transition) {
      case 'slide-up':
        return SLIDE_UP;
      case 'slide-left':
        return SLIDE_LEFT;
      default:
        return NO_SLIDE;
    }
  }};
`;

export const CustomPaper = styled.div`
  box-shadow: 0px 16.9493px 21.792px rgba(0, 0, 0, 0.05);
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background: ${colors.white};
  border-radius: 12px;

  ${belowSize.md`
    border-radius: 0px;
  `}

  ${aboveSize.md`
    overflow: hidden;
  `}
`;

export const Container = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  background: linear-gradient(90deg, #ffffff 39.45%, #f4f4f4 59.2%), #ffffff;
  ${belowSize.md`
    flex-direction: column;
    background: linear-gradient(360deg, #F4F4F4 78.26%, #FFFFFF 97.8%) #FFFFFF;
  `}

  ${aboveSize.md`
    overflow: hidden;
  `}
`;

export const TopInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 58.2%;
  ${belowSize.md`
    width: unset;
    max-height: 370px;
    min-height: 330px;
  `}

  ${(props: INoGlobalNavigation) => {
    if (!props.noGlobalNav) {
      return aboveSize.md`
        padding-top: 80px;
      `;
    }
  }}
`;

export const ScrollContainer = styled.div<IScrollContainerProps>`
  ${(props: INoGlobalNavigation) => {
    if (props.noGlobalNav) {
      return css`
        grid-column-start: content;
        grid-row-start: content;
        height: 100%;
      `;
    }
  }}

  display: flex;
  flex-direction: column;

  margin: auto 0;
  ${(props: INoGlobalNavigation) => {
    if (!props.noGlobalNav) {
      return css`
        padding-right: 4px;
        overflow: unset;

        ::-webkit-scrollbar {
          width: 4px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
          background: ${colors.lightGray}33; // 20% opacity
          border-radius: 5px;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
          background: ${colors.divider}80;
          border-radius: 10px;
        }
      `;
    }
  }}

  max-height: 100%;
  ${(props: IScrollContainerProps) => {
    if (props.fullHeight) return 'height: 100%;';
  }}
`;

export const BottomInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 41.8%;
  ${(props: INoGlobalNavigation) => {
    if (!props.noGlobalNav) {
      return css`
        padding: 24px 32px 24px 32px;
        ${aboveSize.md`
          padding-top: 80px;
        `};
      `;
    } else {
      return css`
        flex-grow: 1;
      `;
    }
  }}

  background: white;
  box-sizing: border-box;

  overflow-y: auto;

  ${belowSize.md`
    height: 100%;
    width: unset;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
  `}
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: [navButton] 10% [content] 80% [right] 10%;
  grid-template-rows: [nav] 104px [content] auto;
  ${belowSize.sm`
    width: 100vw;
  `}

  /* As Grid is only used when noGlobalNav is true, no need to test its value here */
  overflow-y: auto;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${colors.lightGray}33; // 20% opacity
    border-radius: 5px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${colors.divider}80;
    border-radius: 10px;
  }
`;

export const TopContainerButton = styled.div`
  padding: 24px;
  position: absolute;
  ${belowSize.md`
    padding: 51px 0px 0px 16px;
  `}
`;

export const ItemContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
`;

export const Store = styled.div`
  align-items: center;
  text-align: center;
  padding: 0 16px;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: left;
  align-content: left;
  align-items: center;
`;

export const Separator = styled.div`
  background-image: linear-gradient(
    to right,
    ${colors.routeText2} 10%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: top;
  background-size: 6px 1px;
  background-repeat: repeat-x;
  height: 1px;
`;

export const NavigationButtons = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-grow: 1;
  padding: 15px;
  justify-content: space-between;
  align-items: center;
`;

export const RightButton = styled.div`
  padding-right: 10px;
`;

export const NavButton = styled.div`
  grid-column-start: navButton;
  grid-row-start: nav;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NavText = styled.div<IScrollContainerProps>`
  grid-column-start: content;
  grid-row-start: nav;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
