import React, { FC } from 'react';
import Typography from '../../UI/Typography';
import {
  Column,
  Container,
  TranslatedIcon,
  Link,
  TextContainer,
} from './GeneralInfoRow.styles';
import { GeneralInfoRowValueProps, IGeneralInfoRowProps } from './interface';
import { GeneralInfoValueType } from './GeneralInfoRow.constants';
import { colors } from '../../../constants/styles';
import { broadCastAction } from '../../../services/broadcast';
import { ACTIONS } from '../../../constants/actions';

const GeneralInfoRow: FC<IGeneralInfoRowProps> = (
  props: IGeneralInfoRowProps
) => {
  const hrefBuild = (rowValue: GeneralInfoRowValueProps) => {
    let href = '';
    if (rowValue.type) {
      switch (rowValue.type) {
        case GeneralInfoValueType.EMAIL:
          href += 'mailto:';
          break;
        case GeneralInfoValueType.PHONE:
          href += 'tel:';
          break;
      }
    }
    href += rowValue.linkRef ?? rowValue.value;
    return href;
  };

  const hrefEvents = (rowValue: GeneralInfoRowValueProps) => {
    if (rowValue.type === GeneralInfoValueType.LINK || rowValue.type === GeneralInfoValueType.ARROW_LINK) {
      broadCastAction(ACTIONS.NAVIGATE_EXTERNAL);
    }
  };

  const displayValue = (value: string, type?: GeneralInfoValueType) => {
    if (
      type &&
      (type === GeneralInfoValueType.ARROW_LINK ||
        type === GeneralInfoValueType.EMAIL ||
        type === GeneralInfoValueType.LINK)
    )
      return (
        <Typography
          variant="l1"
          semibold
          color={colors.routeText1}
          underline
          underlineOffset={3}
        >
          {value}
        </Typography>
      );

    return (
      <Typography variant="l1" semibold color={colors.routeText1}>
        {value}
      </Typography>
    );
  };

  const displayContent = () => {
    const arr = Array.isArray(props.rowValues)
      ? props.rowValues
      : [props.rowValues];

    const filtered = arr.filter(rowValue => {
      return rowValue !== null && rowValue.value !== '';
    });
    if (filtered.length === 0) {
      return displayValue('-');
    }
    return filtered.map((rowValue, i) => {
      if (rowValue.type) {
        return (
          <Link
            key={i}
            href={hrefBuild(rowValue)}
            target="_blank"
            rel="external noopener noreferrer"
            onClick={() => hrefEvents(rowValue)}
          >
            {displayValue(rowValue.value, rowValue.type)}
            <br />
          </Link>
        );
      }
      return (
        <div key={i}>
          {displayValue(rowValue.value)}
          <br />
        </div>
      );
    });
  };

  const icon = props.icon;
  return (
    <Container>
      <Column>
        <TranslatedIcon src={icon} />
        <TextContainer>
          <Typography variant="p2" color={colors.routeText2}>
            {props.title}
          </Typography>
          {displayContent()}
        </TextContainer>
      </Column>
    </Container>
  );
};

export default GeneralInfoRow;
