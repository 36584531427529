import styled from 'styled-components';
import { belowSize } from '../../../utils/Breakpoints';

export const Container = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  ${belowSize.lg`
    margin-top: 8px;
  `}
`;

export const Column = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
`;
