export const TypographyContent = {
  title: 'Shipping Address',
  subtitle: (merchantName: string | null): string =>
    `Enter your address to receive your replacement item from ${merchantName || ''}.`,

  firstName: 'Name',
  lastName: 'Last Name',
  address1: 'Street Address',
  address2: 'Apt / Suite / P.O. Box',
  city: 'City',
  state: 'State',
  zip: 'ZIP code',
  country: 'Country',
  phone: 'Phone',
  company: 'Company',

  saveButton: 'Save',
};
