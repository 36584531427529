import React from 'react';
import { FC } from 'react';
import Typography from '../../components/UI/Typography';
import { colors } from '../../constants/styles';

export interface ITypographyContent {
  title: string;
  description: FC;
}

export interface IEmphasisSpanProps {
  children: React.ReactNode;
}
const EmphasisSpan: FC<IEmphasisSpanProps> = (props: IEmphasisSpanProps) => {
  return (
    <Typography variant="p5" semibold color={colors.routeText1}>
      {props.children}
    </Typography>
  );
};

export const TypographyContent = {
  orderUnprotected: (store: string, email: string): ITypographyContent => {
    return {
      title: 'Order Unprotected',
      description: () => (
        <Typography variant="p5" color={colors.routeText1}>
          This order is not covered by Route package protection. In order to
          resolve your issue, you'll need to contact {store} for further
          assistance at <EmphasisSpan>{email}</EmphasisSpan>.
        </Typography>
      ),
    };
  },
  orderOutsideTimeframe: (date: string, email: string): ITypographyContent => {
    return {
      title: `The window to report an issue closed on ${date}`,
      description: () => (
        <Typography variant="p5" color={colors.routeText1}>
          We’ve put together an email to send to{' '}
          <EmphasisSpan>{email}</EmphasisSpan> with all of your info if you
          still have questions.
        </Typography>
      ),
    };
  },
};

export const ButtonContents = {
  close: 'Close',
  emailDraft: 'View email draft',
};

export const EmailDraft = (
  orderNumber: string,
  customerName: string
): string => {
  return `Hi,

My items related to my order ${orderNumber} is still having issues. Is there anyway that you could still help me solve this issues I am having?


Thank you,
${customerName}`;
};
