import styled from 'styled-components';
import { SlideLeft } from '../../../styles/global.styles';
import { IReversible } from './interface';

export const Container = styled(SlideLeft)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-self: stretch;
  margin: 10px 0px 0px 0px;
`;

export const Column = styled.div<IReversible>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const Content = styled(Column)`
  overflow: auto;
  min-height: 0;
`;
