export enum ActionType {
  DELIVERY_VERIFICATION = 'delivery_verification',
  ADDITIONAL_PHOTOS_REQUIRED = 'additional_photos_required',
}

export enum PreAssessmentResultEnum {
  // Label created
  PRESHIPMENT = 'preshipment',

  // No tracking
  NO_TRACKING = 'no_tracking',

  // Too early
  IN_TRANSIT_WAIT_TO_FILE = 'in_transit_wait_to_file',
  CLAIM_BEFORE_EDD_DEADLINE = 'claim_before_edd_deadline',
  DELIVERED_WAIT_TO_FILE = 'delivered_wait_to_file',

  // Regular
  IN_TRANSIT_DELAYED = 'in_transit_delayed',
  DELIVERED_EARLY = 'delivered_early',
  DELIVERED = 'delivered',
  PAST_DEADLINE = 'past_deadline',
  SUPPOSEDLY_DELIVERED = 'supposedly_delivered',
}

export enum InstantAssessmentResultEnum {
  OPEN = 'Open',
  APPROVED = 'Approved',
  DENIED = 'Denied',
}
