import { observable, makeObservable, action, runInAction, reaction } from 'mobx';
import { RootStore } from './RootStore';
import { APP_SOURCE_ORIGINS, SOURCE_ORIGINS } from './_constants/Common.constants';
import { broadCastError } from '../services/broadcast';
import { initializeShield } from '../services/shield';

export interface ICommonStore {
  loading: boolean;
  error: boolean;
}

export class CommonStore implements ICommonStore {
  [key: string]: unknown;
  private rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this);

    reaction(
      () => this.error,
      (error: boolean) => {
        if (error) {
          broadCastError();
        }
      }
    );

    initializeShield((s) => this.setShieldSession(s));
  }

  @observable loading = false;

  @observable error = false;

  @observable source: undefined | string;

  @observable shieldSession?: string;

  @action setSource(val: string): void {
    this.source = val;
  }

  @action displayError(): void {
    runInAction(() => (this.error = true));
  }

  @action reset(): void {
    runInAction(() => (this.error = false));
    this.rootStore.orderStore.reset();
    this.rootStore.claimStore.reset();
  }

  @action setShieldSession(val: string): void {
    this.shieldSession = val;
  }

  get platform(): string {
    return 'web-v2';
  }

  isMerchantSource(): boolean {
    return !!this.source && this.source === SOURCE_ORIGINS.MERCHANT_PORTAL;
  }

  isAdminToolsSource(): boolean {
    return !!this.source && this.source === SOURCE_ORIGINS.ADMIN_TOOLS;
  }

  isMobileAppSource(): boolean {
    return !!this.source &&
      Object.values(APP_SOURCE_ORIGINS)
        .includes(this.source);
  }
}
