export interface PhotoUploadRequest {
  image: string;
  imageType: ImageTypeEnum;
  instanceItems: Array<string>;
  email: string;
  customerID?: string;
}

export enum ImageTypeEnum {
  JPG = 'image/jpg',
  JPEG = 'image/jpeg',
  PNG = 'image/png',
}
