import styled from 'styled-components';
import { colors } from '../../constants/styles';
import { belowSize } from '../../utils/Breakpoints';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  max-width: 1080px;
`;

export const Paper = styled.div`
  padding: 21px 36px;

  ${belowSize.lg`
    padding: 16px;
  `}
`;

export const ClaimSelectableRowContainer = styled.div`
  margin: 12px 0;
`;

export const ClaimSelectableAllRowContainer = styled.div`
  margin: 22px 0 19px 0;
`;

export const ShipmentHeader = styled.div`
  margin-top: 8px;
  margin-bottom: -3px;
`;

export const Separator = styled.div`
  margin: 16px 0;
  background-image: linear-gradient(
    to right,
    ${colors.routeText2} 10%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: top;
  background-size: 6px 1px;
  background-repeat: repeat-x;
  height: 1px;
`;
