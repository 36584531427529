import styled from 'styled-components';
import { belowSize } from '../../../utils/Breakpoints';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-self: stretch;
  margin: 10% 30% 10% 30%;

  ${belowSize.md`
      margin: 25% 6% 10% 6%;
  `}
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 40px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;
