import styled from 'styled-components';
import { colors } from '../../../constants/styles';

type ClickableContainerProp = {
  backgroundColor?: string;
  padding?: string;
};

type IconContainerProp = {
  minWidth?: string;
  minHeight?: string;
};

export const ClickableContainer = styled.div<ClickableContainerProp>`
  display: flex;
  padding: ${(props: ClickableContainerProp) =>
    props.padding ? props.padding : '8px'}
  align-items: center;
  cursor: pointer;

  background: ${(props: ClickableContainerProp) =>
    props.backgroundColor ? props.backgroundColor : colors.routeButtonFill4};
  border-radius: 6px;
`;

export const IconContainer = styled.div<IconContainerProp>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  background: rgba(255, 255, 255, 0);
  border-radius: 5px;
  margin-right: 12px;
  ${(props: IconContainerProp) => {
    if (props.minWidth === '') return '';
    else if (props.minWidth) return `min-width: ${props.minWidth}`;
    else return 'min-width: 34px';
  }}
  ${(props: IconContainerProp) => {
    if (props.minHeight === '') return '';
    else if (props.minHeight) return `min-height: ${props.minHeight}`;
    else return 'min-height: 34px';
  }}
`;
