export const TextFieldContent = {
  trackingNumber: {
    placeholder: 'Enter tracking number',
    errorText: 'Please enter a valid carrier tracking number',
  },

  carrier: {
    placeholder: 'Enter carrier (optional)',
  },
};

export const ButtonContent = {
  continue: 'Continue',
  cancel: 'Cancel',
};

export const TypographyContent = {
  description: 'Please provide the tracking number found in the order’s shipment confirmation email.',
  visitHelpCenter: `Need help? Visit our `,
  helpCenter: 'Help Center',
  forMoreInfo: ' for more information on how to file a claim.',
  dontHaveTrackingNumber: "Don't have a tracking number?",
}

export const HelpCenterLink = 'https://shoppers.help.route.com/hc/en-us/articles/6017831679511';