/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import {
  ClickableContainer,
  Container,
  IconContainer,
} from './ShippingIssueSelection.styles';
import Typography from '../../../components/UI/Typography';
import { colors } from '../../../constants/styles';
import { useStores } from '../../../hooks/app.hook';
import ForegroundContentHeader from '../../../components/ForegroundContentHeader';
import ForegroundContentModal from '../../../components/ForegroundContentModal';
import {
  ButtonContent,
  TypographyContent,
} from './ShippingIssueSelection.constants';
import DamagedIcon from '../../../assets/icons/damaged-icon.svg';
import LostIcon from '../../../assets/icons/magnifier-icon.svg';
import StolenIcon from '../../../assets/icons/stolen-icon.svg';
import UnrelatedIcon from '../../../assets/icons/unrelated-icon.svg';
import { Icon, VerticalSeparator } from '../../../styles/global.styles';
import { ClaimTypeEnum } from '../../../models/Enums/ClaimEnum.model';
import { VIEWS } from '../../../constants/views';
import { broadCastAction, broadcastView } from '../../../services/broadcast';
import { ACTIONS } from '../../../constants/actions';

const ShippingIssueSelection: FC = observer(() => {
  const { claimStore, routingStore, commonStore } = useStores();

  const item = claimStore.displayItem;

  if (!item) {
    commonStore.displayError();
    return null;
  }

  useEffect(() => {
    broadcastView(VIEWS.CLAIM_ITEM_SHIPPING_ISSUE_SELECTION)
  }, [])

  const closeClickHandle = () => {
    claimStore.reset();
    broadCastAction(ACTIONS.CANCEL_CLAIM_FILING);
    routingStore.replace('/order-information');
  };

  const backClickHandle = () => {
    broadCastAction(ACTIONS.NAVIGATE_BACK_CLICK);
    routingStore.goBack();
  };

  return (
    <ForegroundContentModal
      open
      mainButton={ButtonContent.cancel}
      mainButtonClick={closeClickHandle}
    >
      <ForegroundContentHeader
        claimedItems={claimStore.claimedItems}
        // hasClaimedItemsSelected already checks nullable value
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        displayItem={item!}
        clickButtonHandle={backClickHandle}
        title={TypographyContent.title}
        centered
      >

        <Container>
          <ClickableContainer
            onClick={() => {
              claimStore.initializeClaim(ClaimTypeEnum.LOST);
            }}
          >
            <IconContainer>
              <Icon src={LostIcon} />
            </IconContainer>
            <Typography semibold variant="h7" color={colors.routeText1}>
              {TypographyContent.lost}
            </Typography>
          </ClickableContainer>
          <VerticalSeparator distance={16} />
          <ClickableContainer
            onClick={() => {
              claimStore.initializeClaim(ClaimTypeEnum.STOLEN);
            }}
          >
            <IconContainer>
              <Icon src={StolenIcon} />
            </IconContainer>
            <Typography semibold variant="h7" color={colors.routeText1}>
              {TypographyContent.stolen}
            </Typography>
          </ClickableContainer>
          <VerticalSeparator distance={16} />
          <ClickableContainer
            onClick={() => {
              claimStore.initializeClaim(ClaimTypeEnum.DAMAGED);
            }}
          >
            <IconContainer>
              <Icon src={DamagedIcon} />
            </IconContainer>
            <Typography semibold variant="h7" color={colors.routeText1}>
              {TypographyContent.damaged}
            </Typography>
          </ClickableContainer>
          <VerticalSeparator distance={16} />
          <ClickableContainer
            onClick={() => {
              routingStore.push('/order-help');
            }}
          >
            <IconContainer>
              <Icon src={UnrelatedIcon} />
            </IconContainer>
            <Typography semibold variant="h7" color={colors.routeText1}>
              {TypographyContent.issueUnrelated}
            </Typography>
          </ClickableContainer>
        </Container>

      </ForegroundContentHeader >
    </ForegroundContentModal >
  );
});

export default ShippingIssueSelection;
