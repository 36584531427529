import styled, { css } from 'styled-components';
import { ITypographyProps } from './interface';

const variantMap = {
  h1: `font-weight: 700;
  font-size: 40px;
  line-height: 42px;`,

  h2: `font-weight: 600;
  font-size: 24px;
  line-height: 30px;`,

  h3: `font-weight: 700;
  font-size: 28px;
  line-height: 32px;`,

  h4: `font-weight: 600;
  font-size: 17px;
  line-height: 24px;`,

  h5: `font-weight: 600;
  font-size: 20px;
  line-height: 23px;`,

  h6: `font-weight: 700;
  font-size: 19px;
  line-height: 22px;`,

  h7: `font-weight: 600;
  font-size: 17px;
  line-height: 24px;`,

  p0: `font-size: 12px;
  line-height: 16px;`,

  p1: `font-size: 17px;
  line-height: 24px;`,

  p2: `font-size: 14px;
  line-height: 18px;`,

  p3: `font-size: 14px;
  line-height: 16px;`,

  p4: `font-size: 14px;
  line-height: 18px;`,

  p5: `font-size: 17px;
  line-height: 24px;`,

  p6: `font-size: 12px;
  line-height: 14px;`,

  p7: `font-size: 20px;
  line-height: 24px;`,

  p8: `font-size: 13px;
  line-height: 17px;`,

  l1: `font-size: 14px;
  line-height: 18px;`,
};

export const Component = styled.span`
  font-family: 'RM Neue', sans-serif;
  white-space: pre-wrap;

  ${(props: ITypographyProps) => {
    let cssString = '';
    // @ts-ignore
    cssString += props.color ? `color: ${props.color};` : '';
    cssString += props.variant
      ? cssString.concat(variantMap[props.variant])
      : '';
    cssString += props.align
      ? cssString.concat(`text-align: ${props.align};`)
      : '';
    cssString += props.uppercase
      ? cssString.concat('text-transform: uppercase;')
      : '';
    cssString += props.semibold ? cssString.concat('font-weight: 600;') : '';
    cssString += props.bold ? cssString.concat('font-weight: 700;') : '';
    cssString += props.underline
      ? cssString.concat('text-decoration-line: underline;')
      : '';
    cssString += props.underlineOffset
      ? cssString.concat(`text-underline-offset: ${props.underlineOffset}px;`)
      : '';

    return css`
      ${cssString}
    `;
  }}
`;
