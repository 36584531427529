import React, { FC } from 'react';
import { ICleanButtonProps } from './interface';
import ButtonStyled, { IconMap } from './CleanButton.styles';

const CleanButton: FC<ICleanButtonProps> = (props: ICleanButtonProps) => {
  return (
    <ButtonStyled
      {...props}
    >
      {props.icon && <img src={IconMap[props.icon]} alt={props.alt} />}
      {props.children}
    </ButtonStyled>
  );
};

export default CleanButton;
