/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import { ButtonContainer, InputContainer } from './EnterTrackingURL.styles';
import { useStores } from '../../../../hooks/app.hook';
import ForegroundContentModal from '../../../../components/ForegroundContentModal';
import { logEvent, analyticsCodes } from '../../../../services/analytics';
import ForegroundContentDetails from '../../../../components/ForegroundContentDetails';
import { colors } from '../../../../constants/styles';
import Button from '../../../../components/Buttons/Button';
import TrackingNumberIcon from '../../../../assets/icons/tracking-number.svg';
import { Icon } from '../../../../styles/global.styles';
import { ButtonContent, TextFieldContent } from './EnterTrackingURL.constants';
import TextField from '../../../../components/Inputs/TextField';
import {
  ClaimBlockingAction,
  ClaimBlockingScreens,
} from '../../../../stores/_constants/ClaimBlockingFlow.constants';
import { broadCastAction, broadcastView } from '../../../../services/broadcast';
import { VIEWS } from '../../../../constants/views';
import { ACTIONS } from '../../../../constants/actions';

const EnterTrackingURL: FC = observer(() => {
  const {
    claimStore,
    routingStore,
    commonStore,
    orderStore,
    claimBlockingStore,
  } = useStores();

  const screen = ClaimBlockingScreens.ENTER_TRACKING_URL;

  const item = claimStore.displayItem;

  const { trackingURL, errors } = claimBlockingStore;

  if (!item) {
    commonStore.displayError();
    return null;
  }

  const continueClickHandle = () => {
    routingStore.push(
      claimBlockingStore.getButtonRoute(screen, ClaimBlockingAction.CONTINUE)
    );
  };

  const cancelClickHandle = () => {
    claimStore.reset();
    claimBlockingStore.reset();
    broadCastAction(ACTIONS.CANCEL_CLAIM_FILING);
    routingStore.replace('/order-information');
  };

  const backButtonHandle = () => {
    broadCastAction(ACTIONS.NAVIGATE_BACK_CLICK);
    routingStore.goBack();
  };

  useEffect(() => {
    broadcastView(VIEWS.CLAIM_ITEM_BLOCKING_ENTER_TRACKING_URL);
    logEvent(analyticsCodes.screenView, {
      screen: claimBlockingStore.getAnalytics(screen),
      merchantId: orderStore.getOrderDetails()?.merchantID,
      orderId: orderStore.getOrderDetails()?.id,
    });
  }, []);

  const getTitle = () => {
    return claimBlockingStore.getTitle(screen);
  };

  const getDescription = () => {
    return claimBlockingStore.getDescription(screen);
  };

  return (
    <ForegroundContentModal
      open
      mainButton={ButtonContent.cancel}
      mainButtonClick={cancelClickHandle}
    >
      <ForegroundContentDetails
        icon={
          <Icon src={TrackingNumberIcon} alt="tracking number package icon" />
        }
        title={getTitle()}
        description={getDescription()}
        backButton={backButtonHandle}
      >
        <InputContainer>
          <TextField
            placeholder={TextFieldContent.trackingURL.placeholder}
            errorText={TextFieldContent.trackingURL.errorText}
            textType="text"
            value={trackingURL}
            onChange={(value: string) =>
              claimBlockingStore.set('trackingURL', value)
            }
            isError={errors.trackingURL}
          />
        </InputContainer>
        <ButtonContainer>
          <Button
            onClick={continueClickHandle}
            fullWidth
            overrideBackground={colors.routeButtonFill6}
            mode="light"
            disabled={claimBlockingStore.trackingURL === '' || claimBlockingStore.errors['trackingURL']}
          >
            {ButtonContent.continue}
          </Button>
        </ButtonContainer>
      </ForegroundContentDetails>
    </ForegroundContentModal>
  );
});

export default EnterTrackingURL;
