/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../../../../hooks/app.hook';
import {
  Container,
  MainButtonContainer,
  EditContainer,
  SubtitleContainer,
  CityStateLine,
  CityContainer,
  StateContainer,
} from './ClaimedItemsShippingInformationEdit.styles';
import CloseIcon from '../../../../assets/icons/close-icon.svg';
import Typography from '../../../../components/UI/Typography';
import { colors } from '../../../../constants/styles';
import Button from '../../../../components/Buttons/Button';
import TextField from '../../../../components/Inputs/TextField';
import { VerticalSeparator } from '../../../../styles/global.styles';
import { TypographyContent } from './ClaimedItemsShippingInformationEdit.constants';
import ForegroundContentPage from '../../../../components/ForegroundContentPage';
import { VIEWS } from '../../../../constants/views';
import { broadcastView } from '../../../../services/broadcast';

const ClaimedItemsShippingInformationEdit: FC = observer(() => {
  const {
    routingStore,
    remedyStore,
    claimStore,
    orderStore,
    commonStore,
  } = useStores();

  const { displayItem } = claimStore;

  const item = displayItem?.item;

  if (!item) {
    commonStore.displayError();
    return null;
  }

  useEffect(() => {
    broadcastView(VIEWS.CLAIM_ITEM_SHIPPING_INFORMATION_EDIT)
  }, [])

  const {
    firstName,
    lastName,
    address1,
    address2,
    city,
    state,
    zip,
    country,
    phone,
    company,
  } = remedyStore;

  const closeClickHandle = () => {
    // restore values from claim store.
    remedyStore.initShippmentVars(
      claimStore.shippingInfo,
      orderStore.getOrderDetails()?.firstName,
      orderStore.getOrderDetails()?.lastName
    );
    routingStore.replace('/item/resolution/shipping-information');
  };

  const save = () => {
    claimStore.setShippingInfo(remedyStore.getShippmentInfo()); // save new values on claim store.
    routingStore.replace('/item/resolution/shipping-information');
  };

  const shouldSave = (): boolean => {
    return firstName && lastName && address1 && city && state && zip && country
      ? true
      : false;
  };

  return (
    <ForegroundContentPage
      navigationHandle={closeClickHandle}
      navigationIcon={CloseIcon}
      navigationIconLabel={'close'}
      itemImage={item.imageUrl ?? ''}
      itemLabel={orderStore.getMerchantName() + ' ' + item.name}
      itemQuantity={claimStore.claimedItems.reduce(
        (acc, items) => acc + items.item.quantity,
        0
      )}
    >
      <Container>
        <Typography align={'center'} color={colors.routeText1} variant={'p7'}>
          {TypographyContent.title}
        </Typography>

        <SubtitleContainer>
          <Typography align={'center'} color={colors.routeText1} variant={'p4'}>
            {TypographyContent.subtitle(orderStore.getMerchantName())}
          </Typography>
        </SubtitleContainer>

        <EditContainer>
          <TextField
            placeholder={TypographyContent.firstName}
            textType="text"
            value={firstName}
            onChange={(value: string) => {
              remedyStore.setFirstName(value);
            }}
            readOnly={false}
          />

          <br />

          <TextField
            placeholder={TypographyContent.lastName}
            textType="text"
            value={lastName}
            onChange={(value: string) => {
              remedyStore.setLastName(value);
            }}
            readOnly={false}
          />

          <br />

          <TextField
            placeholder={TypographyContent.address1}
            textType="text"
            value={address1}
            onChange={(value: string) => {
              remedyStore.setAddress1(value);
            }}
            readOnly={false}
          />

          <br />

          <TextField
            placeholder={TypographyContent.address2}
            textType="text"
            value={address2}
            onChange={(value: string) => {
              remedyStore.setAddress2(value);
            }}
            readOnly={false}
          />

          <br />

          <CityStateLine>
            <CityContainer>
              <TextField
                placeholder={TypographyContent.city}
                textType="text"
                value={city}
                onChange={(value: string) => {
                  remedyStore.setCity(value);
                }}
                readOnly={false}
              />
            </CityContainer>
            <StateContainer>
              <TextField
                placeholder={TypographyContent.state}
                textType="text"
                value={state}
                onChange={(value: string) => {
                  remedyStore.setState(value);
                }}
                readOnly={false}
              />
            </StateContainer>
          </CityStateLine>

          <br />

          <TextField
            placeholder={TypographyContent.zip}
            textType="text"
            value={zip}
            onChange={(value: string) => {
              remedyStore.setZip(value);
            }}
            readOnly={false}
          />

          <br />

          <TextField
            placeholder={TypographyContent.country}
            textType="text"
            value={country}
            onChange={(value: string) => {
              remedyStore.setCountry(value);
            }}
            readOnly={false}
          />

          <br />

          <TextField
            placeholder={TypographyContent.phone}
            textType="text"
            value={phone}
            onChange={(value: string) => {
              remedyStore.setPhone(value);
            }}
            readOnly={false}
          />

          <br />

          <TextField
            placeholder={TypographyContent.company}
            textType="text"
            value={company}
            onChange={(value: string) => {
              remedyStore.setCompany(value);
            }}
            readOnly={false}
          />

          <VerticalSeparator distance={16} />

          <MainButtonContainer>
            <Button
              onClick={save}
              overrideBackground={colors.darkButtonBackground}
              fullWidth={true}
              disabled={!shouldSave()}
            >
              {TypographyContent.saveButton}
            </Button>
          </MainButtonContainer>
        </EditContainer>
      </Container>
    </ForegroundContentPage>
  );
});

export default ClaimedItemsShippingInformationEdit;
