import styled, { css } from 'styled-components';
import { colors } from '../../constants/styles';
import { Centralized } from '../../styles/global.styles';
import { aboveSize, belowSize } from '../../utils/Breakpoints';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-grow: 1;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 19px;
`;

export const MoreInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${belowSize.lg`
    padding: 0 16px;
  `}
  ${aboveSize.lg`
    width: 1020px;
  `}
  margin: 16px 0px;
`;

export const BannerContainer = styled.div`
  display: flex;
  justify-content: center;
`
export interface IBannerStyled {
  bannerUrl?: string;
  contained?: boolean;
}

export const Banner = styled.div`
  max-width: 1080px;
  background: ${colors.black};
  background-image: url(${(props: IBannerStyled) => props.bannerUrl ?? 'unset'});
  background-repeat: no-repeat;
  background-size: cover;
  flex-grow: 1;

  aspect-ratio: 3/1;
  ${(props: IBannerStyled) =>
    props.contained &&
    css`
      background-position: center;
      background-size: contain;
    `}

    @supports not (aspect-ratio: 3 / 1) {
    /* Fallback for Safari/IE/Older Browsers */
      &::before {
        content: '';
        display: block;
        padding-top: calc(1 / 3 * 100%); /* Calculate the padding for 2.7:1 aspect ratio */
      }
    }
`;

export const MerchantContainer = styled.div`
  display: flex;
  justify-content: center;
  transform: translateY(-50%);
`;

export const CustomCentralized = styled(Centralized)`
  ${aboveSize.lg`
    align-items: center;
  `}
`;
