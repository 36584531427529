import styled from 'styled-components';
import { SlideLeft } from '../../../styles/global.styles';
import { belowSize } from '../../../utils/Breakpoints';
import Typography from '../../../components/UI/Typography';
import { colors } from '../../../constants/styles';

export const Container = styled(SlideLeft)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-self: stretch;
  overflow-x: hidden;
  ${belowSize.md`
    margin-left: 10px;
    margin-right: 10px;
    flex-grow: 0;
  `}
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  ${belowSize.md`
    flex-grow: 0;
  `}
`;

export const Row = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  ${belowSize.md`
    flex-grow: 0;
  `}
`;

export const LeftRow = styled(Row)`
  margin-top: 12px;
  justify-content: left;
`;

export const ShieldContainer = styled(Row)`
  margin-top: 12px;
  justify-content: flex-start;
  flex-direction: column;
`;

export const ShieldIcon = styled.img`
  margin-bottom: 1em;
  height: 6em;
`;

export const TermsOfServiceContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 20px;
  flex-grow: 1;
  ${belowSize.md`
    flex-grow: 0;
  `}
`;
export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  ${belowSize.md`
    flex-grow: 0;
    padding: 0px 0px 15px;
  `}
`;

export const DescriptionTypo = styled(Typography)`
  margin-top: 16px;
  ${belowSize.md`
      margin: 16px 4% 0 4%;
  `}

`

export const TextLink = styled.a`
  color: ${colors.routeText2}
`