import styled from 'styled-components';

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;

  margin: 47px 40px 0px 40px;
`;


export const TextButtonContainer = styled.div`
  display: flex;
  justify-content: center;

  margin: 32px 40px 0px 40px;
`;
