import {
  ClaimBlockingScreens,
  ClaimFlowType,
} from './ClaimBlockingFlow.constants';

// This map has the routing for all claim blocking flow screens
// Key: "TYPE|SCREEN"
// Value: analytics code
export const claimBlockingAnalyticsCodes = {
  // --- Label Created Flow --- //
  // Hang tight
  [`${ClaimFlowType.LABEL_CREATED_FLOW}|${ClaimBlockingScreens.HANG_TIGHT}`]: 'Label Created - Hang Tight',

  // How can we help
  [`${ClaimFlowType.LABEL_CREATED_FLOW}|${ClaimBlockingScreens.HOW_CAN_WE_HELP}`]: 'Label Created - How Can We Help',

  // Confirm tracking number
  [`${ClaimFlowType.LABEL_CREATED_FLOW}|${ClaimBlockingScreens.CONFIRM_TRACKING_NUMBER}`]: 'Label Created - Confirm Tracking Number',

  // Confirm carrier update
  [`${ClaimFlowType.LABEL_CREATED_FLOW}|${ClaimBlockingScreens.CONFIRM_CARRIER_UPDATE}`]: 'Label Created - Confirm Carrier Update',

  // Its on the way
  [`${ClaimFlowType.LABEL_CREATED_FLOW}|${ClaimBlockingScreens.PACKAGE_NEVER_ARRIVED}`]: 'Label Created - Package Ships Soon',

  // Enter tracking url
  [`${ClaimFlowType.LABEL_CREATED_FLOW}|${ClaimBlockingScreens.ENTER_TRACKING_URL}`]: 'Label Created - Enter Tracking URL',

  // Enter tracking number
  [`${ClaimFlowType.LABEL_CREATED_FLOW}|${ClaimBlockingScreens.ENTER_TRACKING_NUMBER}`]: 'Label Created - Enter Tracking Number',

  // --- --- //

  // --- No Tracking Flow --- //
  // Hang tight
  [`${ClaimFlowType.NO_TRACKING_NUMBER_FLOW}|${ClaimBlockingScreens.HANG_TIGHT}`]: 'No Tracking Number - Hang Tight',

  // How can we help
  [`${ClaimFlowType.NO_TRACKING_NUMBER_FLOW}|${ClaimBlockingScreens.HOW_CAN_WE_HELP}`]: 'No Tracking Number - How Can We Help',

  // Do you have tracking number
  [`${ClaimFlowType.NO_TRACKING_NUMBER_FLOW}|${ClaimBlockingScreens.DO_YOU_HAVE_TRACKING_NUMBER}`]: 'No Tracking Number - Do You Have Tracking Number',

  // Its on the way
  [`${ClaimFlowType.NO_TRACKING_NUMBER_FLOW}|${ClaimBlockingScreens.PACKAGE_NEVER_ARRIVED}`]: 'No Tracking Number - Your Order Is Being Fulfilled',

  // Enter tracking number
  [`${ClaimFlowType.NO_TRACKING_NUMBER_FLOW}|${ClaimBlockingScreens.ENTER_TRACKING_NUMBER}`]: 'No Tracking Number - Enter Tracking Number',

  // --- --- //

  // --- Too Early Flow --- //
  // Hang tight
  [`${ClaimFlowType.TOO_EARLY_FLOW}|${ClaimBlockingScreens.HANG_TIGHT}`]: 'Too Early - Hang Tight',

  // How can we help
  [`${ClaimFlowType.TOO_EARLY_FLOW}|${ClaimBlockingScreens.HOW_CAN_WE_HELP}`]: 'Too Early - How Can We Help',

  // Confirm tracking number
  [`${ClaimFlowType.TOO_EARLY_FLOW}|${ClaimBlockingScreens.CONFIRM_TRACKING_NUMBER}`]: 'Too Early - Confirm Tracking Number',

  // Confirm carrier update
  [`${ClaimFlowType.TOO_EARLY_FLOW}|${ClaimBlockingScreens.CONFIRM_CARRIER_UPDATE}`]: 'Too Early - Confirm Carrier Update',

  // Its on the way
  [`${ClaimFlowType.TOO_EARLY_FLOW}|${ClaimBlockingScreens.PACKAGE_NEVER_ARRIVED}`]: 'Too Early - Help Is On The Way',

  // Enter tracking url
  [`${ClaimFlowType.TOO_EARLY_FLOW}|${ClaimBlockingScreens.ENTER_TRACKING_URL}`]: 'Too Early - Enter Tracking URL',

  // Enter tracking number
  [`${ClaimFlowType.TOO_EARLY_FLOW}|${ClaimBlockingScreens.ENTER_TRACKING_NUMBER}`]: 'Too Early - Enter Tracking Number',
};
