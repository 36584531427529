import styled from 'styled-components';
import { aboveSize, belowSize } from '../../../utils/Breakpoints';

export const Container = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-self: stretch;
  flex-grow: 1;
  ${aboveSize.md`
    width: 33%;
  `}
`;

export const Row = styled.div`
  display: flex;
`;

export const Column = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;

export const TranslatedIcon = styled.img`
  align-self: flex-start;
  margin-right: 10px;
  ${aboveSize.md`
    position: absolute;
  `}
  ${belowSize.md`
    transform: translateY(18px);
  `}
`;

export const Icon = styled.img`
  align-self: center;
  vertical-align: middle;
`;

export const Link = styled.a`
  text-decoration: none;
`;

export const TextContainer = styled.div`
  margin-left: 25px;
  display: flex;
  flex-direction: column;
`;
