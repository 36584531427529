import { observer } from 'mobx-react';
import React, { FC, useEffect } from 'react';
import { useState } from 'react';
import AppStoreHeader from '../../components/UI/AppStoreHeader';
import EmailModal from '../../components/EmailModal';
import Paper from '../../components/UI/Paper';
import { useStores } from '../../hooks/app.hook';
import { Centralized } from '../../styles/global.styles';
import { DateFormatterLong } from '../../utils';
import { isMobileView } from '../../utils/Breakpoints';
import {
  ButtonContents,
  EmailDraft,
  TypographyContent,
} from './OrderNoAction.constants';
import { SummaryStyled, Wrapper } from './OrderNoAction.styles';
import { MerchantContactTypeEnum } from '../../models/Enums/OrderEnum.model';
import Footer from '../../components/UI/Footer';
import { broadCastAction, broadcastView } from '../../services/broadcast';
import { VIEWS } from '../../constants/views';
import { ACTIONS } from '../../constants/actions';

const OrderNoAction: FC = observer(() => {
  const { routingStore, orderStore, claimStore } = useStores();
  const routeOrder = orderStore.routeOrder;

  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const mobile = isMobileView();

  useEffect(() => {
    broadcastView(VIEWS.ORDER_NO_ACTION)
  }, [])

  const closeHandle = () => {
    broadCastAction(ACTIONS.CANCEL_CLAIM_FILING);
    routingStore.push('/');
    orderStore.reset();
  };

  const closeEmailModalHandle = () => {
    broadCastAction(ACTIONS.CLOSE_MODAL);
    setEmailModalOpen(false);
  };

  const lastDateToFileClaim = () => {
    const lastDate = orderStore.getLastDateToFileClaim();
    const dateFormatted = lastDate
      ? DateFormatterLong(lastDate.toString())
      : '';
    return dateFormatted;
  };

  const item = claimStore.displayItem;

  if (!routeOrder) {
    // never reaching code, router won't allow render without order
    routingStore.replace('/error');
    return null;
  } else {
    const isInsured = orderStore.orderInsured();

    const supportContacts = orderStore.getMerchantContacts(
      MerchantContactTypeEnum.SUPPORT
    );

    const typographyContents = isInsured
      ? TypographyContent.orderOutsideTimeframe(
        lastDateToFileClaim(),
        supportContacts?.email ?? '-'
      )
      : TypographyContent.orderUnprotected(
        routeOrder.merchant.name,
        supportContacts?.email ?? '-'
      );

    return (
      <Wrapper>
        {!mobile && <AppStoreHeader />}
        <Centralized>
          <Paper>
            <SummaryStyled
              closeHandle={closeHandle}
              actionHandle={() => setEmailModalOpen(true)}
              actionButtonText={
                isInsured ? ButtonContents.emailDraft : undefined
              }
              order={routeOrder.order}
              title={typographyContents.title}
              description={<typographyContents.description />}
              merchant={{
                name: routeOrder.merchant.name,
                imageURL: routeOrder.merchant.imageURL,
              }}
              displayItem={item}
              claimedItems={claimStore.claimedItems}
            />
          </Paper>
        </Centralized>
        {!mobile && <Footer />}
        <EmailModal
          description={EmailDraft(
            routeOrder.order.orderNumber,
            `${routeOrder.order.firstName} ${routeOrder.order.lastName}`
          )}
          emailTo={
            orderStore.getMerchantContacts(MerchantContactTypeEnum.SUPPORT)
              ?.email ?? '-'
          }
          open={emailModalOpen}
          onClose={closeEmailModalHandle}
        />
      </Wrapper>
    );
  }
});

export default OrderNoAction;
