import styled from 'styled-components';

export interface IClickable {
  clickable: boolean;
}

export const Row = styled.div`
  display: flex;
  align-items: center;
`;

export const NameContainer = styled.div`
  display: flex;
  flex-grow: 1;
  margin: 0px 20px;
`;

export const QuantityEditorContainer = styled.div`
  display: flex;
`;

export const TargetQuantityContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0px 20px;
`;
