import styled from 'styled-components';
import { colors } from '../../constants/styles';
import { belowSize } from '../../utils/Breakpoints';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  align-self: stretch;
  justify-content: center;
  flex-grow: 1;
  margin: 12% 24% 10% 24%;
  ${belowSize.sm`
     margin: 20px 4% 40px 4%;
  `}
  animation-name: TRANSITION-IN;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;

  @keyframes TRANSITION-IN {
    0% {
      -webkit-transform: scale(0.5);
      opacity: 0;
      -webkit-filter: blur(50px);
    }
    100% {
      -webkit-transform: scale(1);
      -webkit-filter: blur(0px);
    }
  }

  background-color: white;
  border-radius: 6px;
  margin-bottom: unset;
`;

export const ButtonsContainer = styled(Container)`
  background-color: unset;
  border-radius: unset;
  flex-grow: 0;
  margin-top: unset;
  margin-bottom: 10%;
`;

export const TitleContainer = styled.div`
  margin: 24px 48px 0px 48px;
`;

export const EmailRecipient = styled.div`
  margin: 32px 48px 0px 48px;
`;

export const EmailContent = styled.div`
  display: flex;
  flex-grow: 1;
  margin: 16px 48px 48px 48px;
`;

export interface IRoundPaperProps {
  grow?: boolean;
}

export const RoundPaper = styled.div`
  color: ${colors.routeText2};
  margin: 10px 0;
  padding: 4px;
  background: ${colors.white};
  border-radius: 8px;
  flex-grow: ${(props: IRoundPaperProps) => (props.grow ? '1' : 'unset')};
  border-color: black;
  border: 1px solid ${colors.routeBorderFill1};
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;

  ${belowSize.sm`
    align-self: unset;
  `}
`;

export const ContainerFlex = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  align-items: stretch;
  overflow: auto;
  flex-direction: column;
`;
