import React from 'react';
import { Redirect } from 'react-router-dom';
import { useStores } from '../../hooks/app.hook';
import ClaimedItemsActionSelection from './ClaimedItemsActionSelection/ClaimedItemsActionSelection';
import { ClaimFlowType } from '../../stores/_constants/ClaimBlockingFlow.constants';
import { SplitNames } from '../../stores/_constants/SplitStore.constants';

const ClaimFlowDecider: React.FC = () => {
  const { claimBlockingStore, splitStore } = useStores();

  const goToRegularFlow = (): boolean => {
    if (claimBlockingStore.getFlowType() === ClaimFlowType.REGULAR_FLOW
      || (!splitStore.treatmentAllowedMerchantId(SplitNames.RESOLVE_CLAIM_DEFLECTION_SPLIT_NAME))) {
      return true;
    }
    return false;
  };

  return goToRegularFlow() ? (
    <ClaimedItemsActionSelection />
  ) : (
    <Redirect to="/item/can-we-help" />
  );
};

export default ClaimFlowDecider;
