import styled from 'styled-components';

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  // justify-content: center;
  margin: 32px 16px;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: left;
  margin: 40px 40px 0;
  text-align: 'center';
`;