/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import { ButtonContainer, InputContainer, Link, MoreInfoContainer, TextLink } from './EnterTrackingNumber.styles';
import { useStores } from '../../../../hooks/app.hook';
import ForegroundContentModal from '../../../../components/ForegroundContentModal';
import { logEvent, analyticsCodes } from '../../../../services/analytics';
import ForegroundContentDetails from '../../../../components/ForegroundContentDetails';
import { colors } from '../../../../constants/styles';
import Button from '../../../../components/Buttons/Button';
import TrackingNumberIcon from '../../../../assets/icons/tracking-number.svg';
import { Icon } from '../../../../styles/global.styles';
import {
  ButtonContent,
  HelpCenterLink,
  TextFieldContent,
  TypographyContent,
} from './EnterTrackingNumber.constants';
import TextField from '../../../../components/Inputs/TextField';
import {
  ClaimBlockingAction,
  ClaimBlockingScreens,
} from '../../../../stores/_constants/ClaimBlockingFlow.constants';
import { broadCastAction, broadcastView } from '../../../../services/broadcast';
import { VIEWS } from '../../../../constants/views';
import { ACTIONS } from '../../../../constants/actions';
import { isMobileView } from '../../../../utils/Breakpoints';
import Typography from '../../../../components/UI/Typography';

const EnterTrackingNumber: FC = observer(() => {
  const {
    claimStore,
    routingStore,
    commonStore,
    orderStore,
    claimBlockingStore,
  } = useStores();

  const screen = ClaimBlockingScreens.ENTER_TRACKING_NUMBER;

  const item = claimStore.displayItem;

  const { trackingNumber, carrier, errors } = claimBlockingStore;

  if (!item) {
    commonStore.displayError();
    return null;
  }

  const continueClickHandle = () => {
    routingStore.push(
      claimBlockingStore.getButtonRoute(screen, ClaimBlockingAction.CONTINUE)
    );
  };

  const cancelClickHandle = () => {
    claimStore.reset();
    claimBlockingStore.reset();
    broadCastAction(ACTIONS.CANCEL_CLAIM_FILING);
    routingStore.replace('/order-information');
  };


  const handleDontHaveTracking = () => {
    routingStore.push(
      claimBlockingStore.getButtonRoute(screen, ClaimBlockingAction.DONT_HAVE_TRACKING_NUMBER)
    );
  };

  useEffect(() => {
    broadcastView(VIEWS.CLAIM_ITEM_BLOCKING_ENTER_TRACKING_NUMBER);
    logEvent(analyticsCodes.screenView, {
      screen: claimBlockingStore.getAnalytics(screen),
      merchantId: orderStore.getOrderDetails()?.merchantID,
      orderId: orderStore.getOrderDetails()?.id,
    });
  }, []);

  const getTitle = () => {
    return claimBlockingStore.getTitle(screen);
  };

  const visitHelpCenterContent = () => {
    return (
      <>
        {TypographyContent.visitHelpCenter}
        <TextLink href={HelpCenterLink} target="_blank" onClick={() => broadCastAction(ACTIONS.NAVIGATE_EXTERNAL)}>{TypographyContent.helpCenter}</TextLink>
        {TypographyContent.forMoreInfo}
      </>
    );
  }

  const backButtonHandle = () => {
    broadCastAction(ACTIONS.NAVIGATE_BACK_CLICK);
    routingStore.goBack();
  };

  return (
    <ForegroundContentModal
      open

      mainButton={ButtonContent.cancel}
      mainButtonClick={cancelClickHandle}
    >
      <ForegroundContentDetails
        icon={
          <Icon src={TrackingNumberIcon} alt="tracking number package icon" />
        }
        title={getTitle()}
        description={TypographyContent.description}
        centered={!isMobileView()}
        backButton={backButtonHandle}
      >
        <InputContainer>
          <TextField
            placeholder={TextFieldContent.trackingNumber.placeholder}
            errorText={TextFieldContent.trackingNumber.errorText}
            textType="text"
            value={trackingNumber}
            onChange={(value: string) =>
              claimBlockingStore.set('trackingNumber', value)
            }
            isError={errors.trackingNumber}
          />
          <br />
          <TextField
            placeholder={TextFieldContent.carrier.placeholder}
            textType="text"
            value={carrier}
            onChange={(value: string) =>
              claimBlockingStore.set('carrier', value)
            }
          />
        </InputContainer>
        <ButtonContainer>
          <Button
            onClick={continueClickHandle}
            fullWidth
            overrideBackground={colors.routeButtonFill6}
            mode="light"
            disabled={claimBlockingStore.trackingNumber === '' || claimBlockingStore.errors['trackingNumber']}
          >
            {ButtonContent.continue}
          </Button>
        </ButtonContainer>
        <Link href='#' onClick={handleDontHaveTracking} centered={!isMobileView()}>{TypographyContent.dontHaveTrackingNumber}</Link>
        <MoreInfoContainer centered={!isMobileView()}>
          <Typography color={colors.routeText2} variant={'l1'} semibold>
            {visitHelpCenterContent()}
          </Typography>
        </MoreInfoContainer>
      </ForegroundContentDetails>
    </ForegroundContentModal>
  );
});

export default EnterTrackingNumber;
