import { colors } from '../../../../constants/styles';
import styled from 'styled-components';

export const TrackingInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  margin-top: 20px;
  margin: 28px 40px 0px 40px;
`;

export const StatusLine = styled.div`
  display: flex;
  justify-content: stretch;
  flex-grow: 1;
`;

export const DateLine = styled.div`
  display: flex;
  justify-content: stretch;
  flex-grow: 0;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  // justify-content: center;
  margin: 32px 16px;
`;

export const ClickableContainer = styled.div`
  display: flex;
  padding: 12px 16px 12px 24px;
  align-items: center;
  cursor: pointer;

  background: ${colors.routeButtonFill4};
  border-radius: 6px;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  background: rgba(255, 255, 255, 0);
  border-radius: 5px;
  margin-right: 12px;
  min-width: 34px;
  min-height: 34px;
`;
