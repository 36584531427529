import { observer } from 'mobx-react';
import { FC } from 'react';
import Typography from '../UI/Typography';

import React from 'react';
import BackIcon from '../../assets/icons/back-icon.svg';
import { colors } from '../../constants/styles';
import { Icon, VerticalSeparator } from '../../styles/global.styles';
import Button from '../Buttons/Button';
import {
  BackButton,
  Container,
  DescriptionContainer,
  IconContainer,
  ScrollableContent,
  TitleContainer,
} from './ForegroundContentDetails.styles';
import { IForegroundContentDetailsProps } from './interface';

const ForegroundContentDetails: FC<IForegroundContentDetailsProps> = observer(
  (props: IForegroundContentDetailsProps) => {
    const { icon, title, description, children, backButton, centered, verticalStretch } = props;

    return (
      <React.Fragment>
        {backButton && (
          <>
            <BackButton>
              <Button
                mode="light"
                overrideBackground={colors.white}
                ariaLabel="back"
                onClick={backButton}
                icon={<Icon src={BackIcon} verticalAlign="middle" />}
              />
            </BackButton>
          </>
        )}

        <Container verticalStretch={verticalStretch}>
          <ScrollableContent>
            {icon && (
              <IconContainer>{icon}</IconContainer>
            )}

            <TitleContainer centered={centered}>
              <Typography variant="h5" color={colors.routeText1}>
                {title}
              </Typography>
            </TitleContainer>

            {description && (
              <>
                <VerticalSeparator distance={16} />

                <DescriptionContainer centered={centered}>
                  <Typography variant="p2" color={colors.routeText2}>
                    {description}
                  </Typography>
                </DescriptionContainer>
              </>
            )}
            {children}
          </ScrollableContent>
        </Container>
      </React.Fragment>
    );
  }
);

export default ForegroundContentDetails;
