import { css } from 'styled-components';
import { useEffect, useState } from 'react';

export const breakpoints = {
  xs: 480,
  sm: 768,
  md: 992,
  lg: 1200,
  xg: 1648,
};

type cssParams = Parameters<typeof css>;
const keys = Object.keys(breakpoints) as Array<keyof typeof breakpoints>;

export const belowSize = keys.reduce((accumulator, label) => {
  accumulator[label] = (...args: cssParams) => {
    return css`
      @media (max-width: ${breakpoints[label] + 'px'}) {
        ${css(...args)};
      }
    `;
  };
  return accumulator;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}, {} as Record<keyof typeof breakpoints, (...args: any[]) => any>);

export const aboveSize = keys.reduce((accumulator, label) => {
  accumulator[label] = (...args: cssParams) => {
    return css`
      @media (min-width: ${breakpoints[label] + 'px'}) {
        ${css(...args)};
      }
    `;
  };
  return accumulator;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}, {} as Record<keyof typeof breakpoints, (...args: any[]) => any>);

export const WindowSize = (): number => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
  }, [windowWidth]);
  return windowWidth;
};

export const isMobileView = (): boolean => {
  return WindowSize() < breakpoints.md;
};
