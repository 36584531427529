import React, { FC } from 'react';
import { ClaimSubmissionDisplay } from '../ClaimSubmissionDisplay';
import { ClaimSubmissionProgressAnimation } from './ClaimSubmissionProgressAnimation';

export const ClaimSubmissionProgressDisplay: FC = () => {
  return (
    <ClaimSubmissionDisplay
      animation={<ClaimSubmissionProgressAnimation />}
      animationText={'Reviewing your claim'}
    ></ClaimSubmissionDisplay>
  );
};
