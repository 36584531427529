import React, { FC } from 'react';
import Button from '../../Buttons/Button';
import { ISnackbarProps } from './interface';
import { Container } from './Snackbar.styles';
import XIcon from '../../../assets/icons/x-icon';
import Typography from '../Typography';
import { colors } from '../../../constants/styles';

const Snackbar: FC<ISnackbarProps> = (props: ISnackbarProps) => {
  return props.isOpen ? (
    <Container>
      <Typography variant="p2" color={colors.white} align="center">
        {props.message}
      </Typography>
      <Button
        icon={<XIcon color={colors.white} />}
        onClick={props.closeHandle}
      />
    </Container>
  ) : null;
};

export default Snackbar;
