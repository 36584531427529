import { FC } from 'react';
import Typography from '../../UI/Typography';
import { ToolTipCaret, ToolTipContainer, ToolTipWrapper } from './Tooltip.styles';
import { ITooltipProps } from './interface';
import { colors } from '../../../constants/styles';

const Tooltip: FC<ITooltipProps> = (
    props: ITooltipProps
) => {
    return props.open ? (
        <ToolTipContainer>
            <ToolTipWrapper>
                <Typography variant="p5" color={colors.routeText3}>
                    {props.text}
                </Typography>
            </ToolTipWrapper>
            <ToolTipCaret />
        </ToolTipContainer>
    ) : null;
};

export default Tooltip;
