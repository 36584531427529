import styled from 'styled-components';
import { colors } from '../../../constants/styles';

export const Column = styled.div`
   display: flex;
   flex-direction: column;
   margin: 24px 40px;
`

export const ViewDetailsLink = styled.a`
  color: ${colors.routeText2};
  font-weight: 600;
`
