import { makeObservable, action, observable } from 'mobx';
import { RootStore } from '../RootStore';
import { SplitNames } from '../_constants/SplitStore.constants';

export class StolenStore {
  [key: string]: unknown;
  private rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
  }

  @observable policeReport = '';
  @observable policeDepartment = '';

  @action setPoliceReport(value: string): void {
    this.policeReport = value;
  }

  @action setPoliceDepartment(value: string): void {
    this.policeDepartment = value;
  }

  @action setClaimPoliceReport(): void {
    this.rootStore.claimStore.setClaimPoliceReport(
      this.policeReport,
      this.policeDepartment
    );
  }

  @action verifyPoliceReport(): boolean {
    if (
      this.rootStore.splitStore.treatmentAllowedUserEmail(
        SplitNames.POLICE_REPORT_SPLIT_NAME
      )
    ) {
      return false;
    }

    // We don't have that threshold value in the order anymore, so just return false\
    return false;
  }

  @action reset(): void {
    this.policeDepartment = '';
    this.policeReport = '';
  }
}
