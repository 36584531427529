import styled from 'styled-components';
import { NoSlide } from '../../styles/global.styles';
import { belowSize } from '../../utils/Breakpoints';

export const Container = styled(NoSlide)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-self: stretch;
`;

export const TopContainerButton = styled.div`
  padding: 24px;
  position: absolute;
  ${belowSize.md`
    padding: 51px 0px 0px 16px;
  `}
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
  width: 100%;
`;

export const MainButtonContainer = styled.div`
  width: 100%;
`;

export const SecondaryButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  flex-grow: 1;
  margin-top: 10px;
  ${belowSize.md`
    flex-grow: 0;
  `}
`;

export const Skip = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-grow: 1;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;
