/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../../../../hooks/app.hook';
import { analyticsCodes, logEvent } from '../../../../services/analytics';
import { PaymentsSurveyEnum } from '../../../../models/Enums/PaymentsSurveyEnum.model';

import ForegroundDualContentPage from '../../../../components/ForegroundDualContentPage';
import Button from '../../../../components/Buttons/Button';
import CheckboxRow from '../../../../components/CheckboxRow';
import TextAreaRow from '../../../../components/Inputs/TextAreaRow';
import LinkButton from '../../../../components/Buttons/LinkButton';

import Typography from '../../../../components/UI/Typography';

import { colors } from '../../../../constants/styles';
import { VerticalSeparator } from '../../../../styles/global.styles';
import { Container } from './ClaimedItemRefundRemedySurvey.styles';
import { TypographyContent } from './ClaimedItemRefundRemedySurvey.constants';

import ApplePayIcon from '../../../../assets/icons/apple-pay-icon.svg';
import GooglePayIcon from '../../../../assets/icons/google-pay-icon.svg';
import ChashAppIcon from '../../../../assets/icons/cash-app-icon.svg';
import ZelleIcon from '../../../../assets/icons/zelle-icon.svg';
import BackIcon from '../../../../assets/icons/back-icon.svg';
import ForwardIcon from '../../../../assets/icons/forward-icon.svg';
import { broadCastAction, broadcastView } from '../../../../services/broadcast';
import { VIEWS } from '../../../../constants/views';
import { ACTIONS } from '../../../../constants/actions';

const ClaimedItemRefundRemedySurvey: FC = observer(() => {
  const { claimStore, routingStore, orderStore } = useStores();
  const { displayItem: item, refundOptionsSurveySent } = claimStore;
  const { routeOrder } = orderStore;
  const [others, setOthers] = useState('');

  useEffect(() => {
    broadcastView(VIEWS.CLAIM_ITEM_REFUND_REMEDY_SURVEY);
    logEvent(analyticsCodes.screenView, {
      screen: analyticsCodes.refundOptionSurvey,
    });
  }, []);

  const [checkboxOptions, setCheckboxOptions] = useState({
    [PaymentsSurveyEnum.APPLE_PAY]: false,
    [PaymentsSurveyEnum.GOOGLE_PAY]: false,
    [PaymentsSurveyEnum.CASH_APP]: false,
    [PaymentsSurveyEnum.ZELLE]: false,
  });

  if (!item || !routeOrder) {
    //never reaching code
    return null;
  }

  const backClickHandle = () => {
    broadCastAction(ACTIONS.NAVIGATE_BACK_CLICK);
    routingStore.goBack();
  };

  const noPayPalHandle = (): void => {
    window.open(
      'https://www.paypal.com/us/webapps/mpp/account-selection',
      '_blank',
      'noopener,noreferrer'
    );
  };

  const shouldDisableSubmitFeedback = () => {
    if (
      checkboxOptions[PaymentsSurveyEnum.APPLE_PAY] === false &&
      checkboxOptions[PaymentsSurveyEnum.GOOGLE_PAY] === false &&
      checkboxOptions[PaymentsSurveyEnum.CASH_APP] === false &&
      checkboxOptions[PaymentsSurveyEnum.ZELLE] === false &&
      others.trim().length === 0
    ) {
      return true;
    }
    return false;
  };

  const sumbitFeedback = () => {
    logEvent(analyticsCodes.refundOptionsSurveySubmission, {
      ...checkboxOptions,
      others: others,
    });

    claimStore.setRefundOptionsSurveySent(true);
  };

  const thankYouDoneAction = () => {
    routingStore.replace('/item/resolution/paypal');
  };

  const thankYouPage = () => {
    return (
      <React.Fragment>
        <Container>
          <Typography
            variant="p7"
            semibold
            color={colors.black}
            align={'center'}
          >
            {TypographyContent.thankYouFeedback}
          </Typography>

          <VerticalSeparator distance={16} />

          <Typography variant="p4" color={colors.black} align={'center'}>
            {TypographyContent.thankYouFeedbackTitle}
          </Typography>
        </Container>

        <VerticalSeparator distance={116} />
        <div>
          <Button
            fullWidth
            isFullscreen
            onClick={thankYouDoneAction}
            overrideBackground={colors.black}
          >
            {TypographyContent.thankYouOkayButton}
          </Button>
        </div>
      </React.Fragment>
    );
  };

  return (
    <ForegroundDualContentPage
      navigationHandle={backClickHandle}
      navigationIconLabel={'back'}
      navigationIcon={BackIcon}
    >
      <>
        <Container>
          <Typography
            variant="h2"
            semibold
            color={colors.black}
            align={'center'}
          >
            {TypographyContent.title}
          </Typography>

          <VerticalSeparator distance={16} />

          <Typography variant="p4" color={colors.black} align={'center'}>
            {TypographyContent.subtitle}
          </Typography>
        </Container>

        <VerticalSeparator distance={48} />

        <Container>
          <LinkButton
            onClick={() => {
              noPayPalHandle();
            }}
            rightIcon={ForwardIcon}
            overrideTextColor={colors.black}
          >
            {TypographyContent.setupPayPal}
          </LinkButton>
        </Container>
      </>

      <>
        {refundOptionsSurveySent ? (
          thankYouPage()
        ) : (
          <>
            <Container>
              <Typography
                variant="p7"
                semibold
                color={colors.black}
                align={'center'}
              >
                {TypographyContent.feedbackTitle}
              </Typography>

              <VerticalSeparator distance={16} />

              <Typography variant="p4" color={colors.black} align={'center'}>
                {TypographyContent.feedbackSubtitle}
              </Typography>
            </Container>

            <VerticalSeparator distance={48} />

            <Container>
              <CheckboxRow
                onClick={() => {
                  setCheckboxOptions({
                    ...checkboxOptions,
                    [PaymentsSurveyEnum.APPLE_PAY]: !checkboxOptions[
                      PaymentsSurveyEnum.APPLE_PAY
                    ],
                  });
                }}
                icon={ApplePayIcon}
                preferOption={'Apple Pay'}
                disabled={false}
                testId={'select-apple-pay'}
                color={colors.black}
                selected={checkboxOptions[PaymentsSurveyEnum.APPLE_PAY]}
              />

              <VerticalSeparator distance={8} />

              <CheckboxRow
                onClick={() => {
                  setCheckboxOptions({
                    ...checkboxOptions,
                    [PaymentsSurveyEnum.GOOGLE_PAY]: !checkboxOptions[
                      PaymentsSurveyEnum.GOOGLE_PAY
                    ],
                  });
                }}
                icon={GooglePayIcon}
                preferOption={'Google Pay'}
                disabled={false}
                testId={'select-google-pay'}
                color={colors.black}
                selected={checkboxOptions[PaymentsSurveyEnum.GOOGLE_PAY]}
              />

              <VerticalSeparator distance={8} />

              <CheckboxRow
                onClick={() => {
                  setCheckboxOptions({
                    ...checkboxOptions,
                    [PaymentsSurveyEnum.CASH_APP]: !checkboxOptions[
                      PaymentsSurveyEnum.CASH_APP
                    ],
                  });
                }}
                icon={ChashAppIcon}
                preferOption={'Cash App'}
                disabled={false}
                testId={'select-cash-app'}
                color={colors.black}
                selected={checkboxOptions[PaymentsSurveyEnum.CASH_APP]}
              />

              <VerticalSeparator distance={8} />

              <CheckboxRow
                onClick={() => {
                  setCheckboxOptions({
                    ...checkboxOptions,
                    [PaymentsSurveyEnum.ZELLE]: !checkboxOptions[
                      PaymentsSurveyEnum.ZELLE
                    ],
                  });
                }}
                icon={ZelleIcon}
                preferOption={'Zelle'}
                disabled={false}
                testId={'select-zelle'}
                color={colors.black}
                selected={checkboxOptions[PaymentsSurveyEnum.ZELLE]}
              />

              <VerticalSeparator distance={8} />

              <TextAreaRow
                name={''}
                value={others}
                placeholder={'Other...'}
                disabled={false}
                color={colors.black}
                testId={'select-other'}
                onChange={e => {
                  setOthers(e.currentTarget.value);
                }}
                onCloseClick={() => {
                  setOthers('');
                }}
              />
            </Container>

            <VerticalSeparator distance={8} />

            <div>
              <Button
                fullWidth
                isFullscreen
                onClick={sumbitFeedback}
                overrideBackground={colors.black}
                disabled={shouldDisableSubmitFeedback()}
              >
                {TypographyContent.submitButton}
              </Button>
            </div>
          </>
        )}
      </>
    </ForegroundDualContentPage>
  );
});

export default ClaimedItemRefundRemedySurvey;
