/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import {
  Column,
  Container,
  Row,
  Separator,
} from './ClaimedItemsStolenPoliceReport.styles';
import Typography from '../../../../components/UI/Typography';
import { colors } from '../../../../constants/styles';
import { useStores } from '../../../../hooks/app.hook';
import ForegroundContentHeader from '../../../../components/ForegroundContentHeader';
import ForegroundContentModal from '../../../../components/ForegroundContentModal';
import {
  ButtonContent,
  TypographyContent,
} from './ClaimedItemsStolenPoliceReport.constants';
import TextField from '../../../../components/Inputs/TextField';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import { logEvent, analyticsCodes } from '../../../../services/analytics';
import { broadCastAction, broadcastView } from '../../../../services/broadcast';
import { VIEWS } from '../../../../constants/views';
import { ACTIONS } from '../../../../constants/actions';

const ClaimedItemsStolenPoliceReport: FC = observer(() => {
  const { claimStore, routingStore, commonStore, stolenStore } = useStores();
  const { policeDepartment, policeReport } = stolenStore;
  const { displayItem: item, loading } = claimStore;

  if (!item) {
    commonStore.displayError();
    return null;
  }

  const nextClickHandle = () => {
    stolenStore.setClaimPoliceReport();
    claimStore.verifyResolutionScreenOrSubmitClaim();
  };

  const closeClickHandle = () => {
    claimStore.reset();
    broadCastAction(ACTIONS.CANCEL_CLAIM_FILING);
    routingStore.replace('/order-information');
  };

  const backClickHandle = () => {
    broadCastAction(ACTIONS.NAVIGATE_BACK_CLICK);
    routingStore.goBack();
  };

  useEffect(() => {
    broadcastView(VIEWS.CLAIM_ITEM_STOLEN_POLICE_REPORT);
    logEvent(analyticsCodes.screenView, {
      screen: analyticsCodes.policeReport,
    });
  }, []);

  return (
    <ForegroundContentModal
      open
      mainButton={ButtonContent.next}
      mainButtonClick={nextClickHandle}
      secondaryButton={ButtonContent.cancel}
      secondaryButtonClick={closeClickHandle}
    >
      <ForegroundContentHeader
        claimedItems={claimStore.claimedItems}
        // hasClaimedItemsSelected already checks nullable value
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        displayItem={item!}
        clickButtonHandle={backClickHandle}
        title={TypographyContent.title}
      >
        {loading ? (
          <LoadingSpinner />
        ) : (
          <Container>
            <Column>
              <Row>
                <TextField
                  value={policeReport}
                  noLine
                  noTitle
                  isError={false}
                  placeholder={TypographyContent.policeReportPlaceholder}
                  onChange={value => {
                    stolenStore.setPoliceReport(value);
                  }}
                />
              </Row>
              <Separator />
              <Typography variant="p7" color={colors.routeText1}>
                {TypographyContent.policeDepartment}
              </Typography>
              <Row>
                <TextField
                  value={policeDepartment}
                  noLine
                  noTitle
                  isError={false}
                  placeholder={TypographyContent.policeDepartmentPlaceholder}
                  onChange={value => {
                    stolenStore.setPoliceDepartment(value);
                  }}
                />
              </Row>
            </Column>
          </Container>
        )}
      </ForegroundContentHeader>
    </ForegroundContentModal>
  );
});

export default ClaimedItemsStolenPoliceReport;
