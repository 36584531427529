import React, { FC, useEffect } from 'react';
import {
  Container,
  Logo,
  TextFieldContainer,
  TitleContainer,
  Row,
  Column,
  RouteLogo as RouteLogoDownload,
  FiveStarImage,
  LinkRoute,
  ButtonContainer,
  Separator,
} from './EmailSurveyFeedback.styles';

import Button from '../../components/Buttons/Button';
import { Page } from '../../styles/global.styles';
import {
  ButtonContent,
  Links,
  TypographyContent,
} from './EmailSurveyFeedback.constants';
import RouteLogo from '../../assets/images/route-header-logo.svg';
import Typography from '../../components/UI/Typography';
import { colors } from '../../constants/styles';
import TextField from '../../components/Inputs/TextField';
import LoadingSpinner from '../../components/LoadingSpinner';
import { useStores } from '../../hooks/app.hook';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';
import RouteLogoImg from '../../assets/images/route-logo-download.svg';
import FiveStarRating from '../../assets/images/five-star-rating.svg';
import { broadCastAction, broadcastView } from '../../services/broadcast';
import { VIEWS } from '../../constants/views';
import { ACTIONS } from '../../constants/actions';
const EmailSurveyFeedback: FC = observer(() => {
  const location = useLocation();
  const { emailSurveyStore, commonStore } = useStores();

  const {
    feedbackContent,
    rating,
    id,
    feedbackSent,
    loading,
    downloadEnabled,
  } = emailSurveyStore;

  useEffect(() => {
    broadcastView(VIEWS.EMAIL_SURVEY);
    const urlParams = new URLSearchParams(location.search);
    const campaignId = urlParams.get('id');
    const rating = urlParams.get('rating');
    const download = urlParams.get('download');
    if (!campaignId || !rating) {
      commonStore.displayError();
    } else {
      emailSurveyStore.setAndSendRating(campaignId, rating);
    }
    if (download && download === '1') {
      emailSurveyStore.setDownloadEnabled();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendFeedbackReason = () => {
    emailSurveyStore.sendFeedbackReason();
  };

  return id && rating ? (
    <Page>
      <Container>
        <Logo src={RouteLogo} />
        {feedbackSent ? (
          <Typography variant="h3" color={colors.routeText1}>
            {TypographyContent.thankYou}
          </Typography>
        ) : (
          <>
            <TitleContainer>
              <Typography variant="h3" color={colors.routeText1}>
                {TypographyContent.title}
              </Typography>
            </TitleContainer>
            <Typography variant="p5" color={colors.routeText1}>
              {TypographyContent.description}
            </Typography>
            <Typography variant="p5" color={colors.routeText1} semibold>
              {TypographyContent.subDescription}
            </Typography>
            <TextFieldContainer>
              <TextField
                data-testid="additional-details"
                value={feedbackContent}
                placeholder=""
                noLine
                noTitle
                isError={false}
                onChange={value => {
                  emailSurveyStore.setFeedbackContent(value);
                }}
                multiline
              />
            </TextFieldContainer>
            {loading ? (
              <LoadingSpinner />
            ) : (
              <ButtonContainer>
                <Button
                  overrideBackground={colors.routeText1}
                  onClick={sendFeedbackReason}
                  disabled={!feedbackContent || feedbackContent === ''}
                >
                  {ButtonContent.sendFeedback}
                </Button>
              </ButtonContainer>
            )}
          </>
        )}
        {downloadEnabled && (
          <Column>
            <Separator />
            <Row>
              <RouteLogoDownload src={RouteLogoImg} />
              <Column>
                <Typography variant="p2" color={colors.routeText2} semibold>
                  {TypographyContent.downloadAppTitle}
                </Typography>
                <Typography variant="p2" color={colors.routeText2}>
                  {TypographyContent.downloadAppSubtitle}
                </Typography>
                <FiveStarImage src={FiveStarRating} />
                <LinkRoute href={Links.downloadRoute} onClick={() => broadCastAction(ACTIONS.NAVIGATE_EXTERNAL)}>
                  {ButtonContent.downloadRoute}
                </LinkRoute>
              </Column>
            </Row>
          </Column>
        )}
      </Container>
    </Page>
  ) : null;
});

export default EmailSurveyFeedback;
