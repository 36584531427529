import { observer } from 'mobx-react';
import React, { FC, useEffect, useState } from 'react';
import AppStoreHeader from '../../components/UI/AppStoreHeader';
import Paper from '../../components/UI/Paper';
import { useStores } from '../../hooks/app.hook';
import { Centralized } from '../../styles/global.styles';
import { isMobileView } from '../../utils/Breakpoints';
import {
  ButtonContents,
  EmailDraft,
  TypographyContent,
} from './NotApproved.constants';
import { Wrapper } from './NotApproved.styles';
import { MerchantContactTypeEnum } from '../../models/Enums/OrderEnum.model';
import Footer from '../../components/UI/Footer';
import EmailModal from '../../components/EmailModal';
import Summary from '../../components/Summary';
import { broadcastView } from '../../services/broadcast';
import { VIEWS } from '../../constants/views';

const NotApproved: FC = observer(() => {
  const {
    routingStore,
    orderStore,
    issueStore,
    commonStore,
    claimStore,
  } = useStores();
  const routeOrder = orderStore.routeOrder;
  const { currentClaim } = issueStore;
  const mobile = isMobileView();

  const [emailModalOpen, setEmailModalOpen] = useState(false);

  useEffect(() => {
    broadcastView(VIEWS.NOT_APPROVED)
  }, [])

  const closeHandle = () => {
    routingStore.push('/order-information');
  };

  const closeEmailModalHandle = () => {
    setEmailModalOpen(false);
  };

  const getDescription = () => {
    return currentClaim && currentClaim.claimStatusReason !== ''
      ? currentClaim.claimStatusReason
      : TypographyContent.notApprovedReasonFallback;
  };

  const item = claimStore.displayItem;

  if (!item) {
    commonStore.displayError();
    return null;
  }

  if (!routeOrder) {
    // never reaching code, router won't allow render without order
    routingStore.replace('/error');
    return null;
  } else {
    const supportContacts = orderStore.getMerchantContacts(
      MerchantContactTypeEnum.SUPPORT
    );

    return (
      <Wrapper>
        {!mobile && <AppStoreHeader />}
        <Centralized>
          <Paper>
            <Summary
              closeHandle={closeHandle}
              actionHandle={() => setEmailModalOpen(true)}
              actionButtonText={ButtonContents.emailDraft}
              order={routeOrder.order}
              title={TypographyContent.title}
              description={TypographyContent.description(
                getDescription(),
                supportContacts?.email ?? '-'
              )}
              merchant={{
                name: routeOrder.merchant.name,
                imageURL: routeOrder.merchant.imageURL,
              }}
              displayItem={item}
              claimedItems={claimStore.claimedItems}
            />
          </Paper>
        </Centralized>
        {!mobile && <Footer />}
        <EmailModal
          description={EmailDraft(
            routeOrder.order.orderNumber,
            `${routeOrder.order.firstName} ${routeOrder.order.lastName}`
          )}
          emailTo={
            orderStore.getMerchantContacts(MerchantContactTypeEnum.SUPPORT)
              ?.email ?? '-'
          }
          open={emailModalOpen}
          onClose={closeEmailModalHandle}
        />
      </Wrapper>
    );
  }
});

export default NotApproved;
