import React, { FC } from 'react';
import Typography from '../UI/Typography';
import { IResolutionSelectableRowProps } from './interface';
import {
  ClickableContainer,
  Column,
  IconContainer,
} from './ResolutionSelectableRow.styles';
import { colors } from '../../constants/styles';
import { Icon } from '../../styles/global.styles';
import Button from '../Buttons/Button';

const ResolutionSelectableRow: FC<IResolutionSelectableRowProps> = (
  props: IResolutionSelectableRowProps
) => {
  const clickHandle = (): void => {
    if (!props.disabled) {
      props.onClick();
    }
  };

  return (
    <ClickableContainer
      clickable={!props.disabled}
      onClick={clickHandle}
      disabled={props.disabled}
      data-testid={props.testId}
    >
      <IconContainer>
        <Icon src={props.icon} alt="package icon" />
      </IconContainer>
      <Column>
        <Typography
          variant="p4"
          color={props.color || colors.routeText1}
          semibold
        >
          {props.preferOption}
        </Typography>
        {props.subtitle && (
          <Typography variant="p0" color={props.color || colors.routeText2}>
            {props.subtitle}
          </Typography>
        )}
      </Column>
      {!props.disabled && props.buttonIcon && (
        <Button
          overrideSizeIcon="26px"
          mode="light"
          overrideBackground={colors.white}
          icon={props.buttonIcon}
          disabled={props.disabled}
        />
      )}
    </ClickableContainer>
  );
};

export default ResolutionSelectableRow;
