import { SlideLeft } from '../../styles/global.styles';
import styled from 'styled-components';

export const BackButton = styled.div`
  display: flex;
  z-index: 1;
  position: relative;
  padding-left: 8.25px;
  padding-top: 31px;
  padding-bottom: 20px;
`;

export interface IJustifyProps {
  centered?: boolean;
  verticalStretch?: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: ${(props: IJustifyProps) =>
    props.verticalStretch ? 'stretch' : 'center'};
  align-items: stretch;
  overflow-x: hidden;
  height: 100%;
`;

export const ScrollableContent = styled(SlideLeft)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 82px;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: left;
  margin: 0px 40px;
  text-align: ${(props: IJustifyProps) =>
    props.centered ? 'center' : 'unset'};

  align-self: ${(props: IJustifyProps) =>
    props.centered ? 'center' : 'unset'};
`;

export const DescriptionContainer = styled.div`
  display: flex;
  margin: 0px 40px;
  align-self:  ${(props: IJustifyProps) =>
    props.centered ? 'center' : 'unset'};
  text-align: ${(props: IJustifyProps) =>
    props.centered ? 'center' : 'unset'};
`;

