import styled, { css } from 'styled-components';
import { colors } from '../../../constants/styles';

export interface IClickable {
  disabled?: boolean;
  focussed?: boolean;
  shouldShowBorder?: boolean;
}

export const ClickableContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: stretch;

  padding: 16px;
  border-radius: 10px;
  border: 1px solid transparent;
  background: ${colors.routeButtonFill4};

  ${(props: IClickable) => {
    if (props.disabled) {
      return css`
        opacity: 70%;
      `;
    }
  }}

  ${(props: IClickable) => {
    if (props.focussed) {
      return css`
        border: 1px solid ${colors.black};
      `;
    }
  }}

  ${(props: IClickable) => {
    if (props.shouldShowBorder) {
      return css`
        border: 1px solid ${colors.black};
      `;
    }
  }}
`;

export const TextArea = styled.textarea`
  width: 100%;
  margin: 0;
  padding: 0;

  font-family: RM Neue;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 16px;

  background: transparent;
  border: none;
  outline: none;
  overflow: hidden;
  resize: none;
`;
